import * as React from "react";
import { useState } from "react";
import styled from "styled-components";

import { copyToClipboard } from "libs/clipboard";
import { TooltipWrapper } from "ui";

import icon from "./icon.svg";

const delay = 300;

export function CopyToClipboardButton(props: { text: string }) {
  const [copied, setCopied] = useState(false);

  function handleClick() {
    copyToClipboard(props.text);
    setCopied(true);
  }

  function handleMouseLeave() {
    setTimeout(() => setCopied(false), delay + 200);
  }

  return (
    <CopyToClipboardButtonRoot>
      <TooltipWrapper
        tooltip={!copied ? "Copy to clipboard" : "Copied to clipboard!"}
        delayHide={copied ? delay : 0}
      >
        <Icon onMouseLeave={handleMouseLeave} onClick={handleClick} />
      </TooltipWrapper>
    </CopyToClipboardButtonRoot>
  );
}

const CopyToClipboardButtonRoot = styled.div`
  display: inline-block;
  cursor: pointer;
  user-select: none;
`;

const Icon = styled(icon)`
  display: inline-block;
  &:hover {
    .icon {
      fill: #4f31ff;
    }
  }
`;
