import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
// import "simplebar/dist/simplebar.min.css";
import styled, { css } from "styled-components";
import { colors } from "styles/color-tokens";
import { TextSuggestion } from "./text-suggestion";

interface Props<T> {
  suggestions: T[];
  renderSuggestion(props: {
    suggestion: T;
    isActive: boolean;
  }): React.ReactNode;
  onSelect(suggestion: T): void;
  className?: string;
  suggestionHeader?: React.ReactNode;
}

function Popup<T>(props: Props<T>) {
  const scrollRef = useRef<SimpleBar | null>();
  const suggestionRef = useRef<HTMLDivElement | null>();
  const selectedSuggestionIndex = useSuggestionsState(
    props.suggestions,
    props.onSelect,
    scrollRef,
    suggestionRef
  );

  return (
    <Suggestions
      data-testid="suggestions"
      className={props.className}
      hasSuggestionHeader={!!props.suggestionHeader}
    >
      {props.suggestionHeader && props.suggestionHeader()}
      <SuggestionsScroller
        style={{
          maxHeight: SuggestionsPopupMaxHeight,
          minHeight: 30,
        }}
        ref={scrollRef}
        hasSuggestionHeader={!!props.suggestionHeader}
      >
        {props.suggestions.map((suggestion, index) => (
          <Suggestion
            ref={suggestionRef}
            key={index}
            onClick={() => props.onSelect(suggestion)}
            $isActive={selectedSuggestionIndex === index}
          >
            {props.renderSuggestion ? (
              props.renderSuggestion({
                suggestion,
                isActive: selectedSuggestionIndex === index,
              })
            ) : (
              <TextSuggestion suggestion={suggestion as any as string} />
            )}
          </Suggestion>
        ))}
      </SuggestionsScroller>
    </Suggestions>
  );
}

function useSuggestionsState<T>(
  suggestions: T[],
  onSelect: (selected: T) => void,
  scrollRef: T,
  suggestionRef: T
) {
  const [index, setIndex] = useState<number>(0);

  function handleKeyDown(e: any) {
    if (
      scrollRef &&
      suggestionRef &&
      scrollRef.current &&
      suggestionRef.current
    ) {
      const { contentWrapperEl } = scrollRef.current;
      const suggestionHeight =
        suggestionRef.current.getBoundingClientRect().height;

      if (!contentWrapperEl) {
        return;
      }

      switch (e.key) {
        case "Enter": {
          if (index) {
            onSelect(suggestions[index]);
            e.preventDefault();
            e.stopPropagation();
          }
          break;
        }
        case "ArrowUp": {
          e.preventDefault();
          if (!index) {
            return;
          }
          setIndex(index - 1);
          contentWrapperEl.scrollTo(
            0,
            contentWrapperEl.scrollTop - suggestionHeight
          );
          break;
        }
        case "ArrowDown": {
          e.preventDefault();
          if (index && index + 1 === suggestions.length) {
            return;
          }
          setIndex((index ?? -1) + 1);
          contentWrapperEl.scrollTo(
            0,
            contentWrapperEl.scrollTop + suggestionHeight
          );
          break;
        }
        case "Escape": {
          setIndex(0);
        }
      }
    }
  }

  useEffect(() => {
    document.body.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, [index]);

  return index;
}

export const SuggestionsPopup = styled(Popup)``;

export const SuggestionsPopupMaxHeight = 200;

const Suggestions = styled.div<{ hasSuggestionHeader: boolean }>`
  position: relative;
  width: 100%;
  height: auto;
  max-height: ${SuggestionsPopupMaxHeight}px;
  border-radius: 5px;
  overflow: hidden;
  background: ${colors.white};
`;

const Suggestion = styled.div<{ $isActive: boolean }>`
  width: 100%;
  cursor: pointer;

  --suggestion-text-color: ${colors.neutral800};
  --suggestion-border-color: transparent;
  --suggestion-background-color: transparent;

  --suggestion-text-black-color: ${colors.black};
  --suggestion-border-purple-color: ${colors.primary200};
  --suggestion-background-purple-color: ${colors.primary100};

  ${(props) =>
    props.$isActive &&
    css`
      --suggestion-border-color: ${colors.primary200};
      --suggestion-background-color: ${colors.primary100};
    `}

  &:hover {
    --suggestion-border-color: ${colors.primary200};
    --suggestion-background-color: ${colors.primary100};
  }
`;

const SuggestionsScroller = styled.div<{
  hasSuggestionHeader: boolean;
}>`
  padding-top: ${({ hasSuggestionHeader }) =>
    hasSuggestionHeader ? "32px" : 0};
  overflow-x: auto;
`;
