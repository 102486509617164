import { CreateMessageAction } from "actions/conversationInbox";
import { AccountPlatformType } from "shared/types/accounts";

export type InterlocutorPlatformType = AccountPlatformType | "SK";

export enum MetaReaction {
  Smile = "smile",
  Angry = "angry",
  Sad = "sad",
  Wow = "wow",
  Love = "love",
  Like = "like",
  Dislike = "dislike",
  Other = "other",
}

export type ReactionType = {
  id: string;
  reactee: string;
  reaction: MetaReaction;
  emoji?: string;
  createdAt: string;
};

export type AttachmentGenericElementButton = {
  type: "open_url" | string;
  url: string;
  title: string;
};

export type AttachmentGenericElement = {
  title: string;
  image_url?: string;
  subtitle?: string;
  default_action?: {
    type: "open_url" | string;
    url: string;
  };
  buttons?: AttachmentGenericElementButton[];
};

export type AttachmentCoordinates = {
  lat: number;
  long: number;
};

export interface Attachment {
  type: string;
  payload: {
    url?: string;
    title?: string;
    coordinates?: AttachmentCoordinates;
    generic?: {
      elements: AttachmentGenericElement[];
    };
  };
}

export interface SyncedMessage extends Message {
  conversationId: string;
  conversation?: Pick<
    Conversation,
    | "id"
    | "type"
    | "status"
    | "assignedTo"
    | "isStarred"
    | "unreadCount"
    | "removed"
    | "isMention"
    | "account"
    | "mentionees"
  >;
}

export enum MessageStatus {
  pending = "PENDING",
  failed = "FAILED",
  success = "SUCCESS",
  public = "PUBLIC",
}

export type Message = {
  type: MessageType;
  id: string;
  text: string;
  isInternal: boolean;
  /** system message does not have sender */
  sender?: Partial<Interlocutor>;
  unread: boolean;
  createdAt: string | Date;

  reactions: ReactionType[] | undefined;
  liked?: boolean;
  story?: {
    type: string;
    url: string;
  };
  post?: string;
  attachments?: Attachment[];
  replyTo?: Message;
  isFailedToSend?: boolean;
  status?: MessageStatus;
  isReationProcessing?: boolean;
  attachment?: FbCommentAttachment;
  hidden?: boolean;
  deleted?: boolean;
  isHiddenProcessing?: boolean;
  sentFromSked?: boolean;
  lastCreatedRequestPalyload?: CreateMessageAction["payload"];
};

export interface ThreadedComment extends Message {
  type: MessageType;
  level: number;
  parentId: string;
}

export interface LiCommentMessage extends Message {
  content?: {
    entity: { digitalmediaAsset: string };
    type: "IMAGE";
    url?: string;
  }[];
}

export interface FbCommentAttachment {
  media?: {
    image?: {
      src: string;
    };
    source?: string;
  };
  type: string | "animated_image_share" | "photo" | "sticker" | "video";
  url: string;
}

export type Pagination<T> = {
  next: string | null;
  previous: string | null;
  hasPrevious: boolean;
  hasNext: boolean;
  items: T[];
};

export type Tag = {
  id: string;
  color: string;
  label: string;
  createdAt: string;
};

export type Interlocutor = {
  id: string;
  name: string;
  image: string;
  platform: InterlocutorPlatformType;
  platformId: string;
  followersCount: number;
  followingMe: boolean;
  followingHim: boolean;
  handle: string;
  isUnknownSource?: boolean;
  unknownUser?: boolean;
};

export enum Status {
  open = "OPEN",
  closed = "CLOSED",
}

export enum MessageType {
  igStoryMention = "igStoryMention",
  igStoryReply = "igStoryReply",
  fbMessage = "fbMessage",
  fbComment = "fbComment",
  fbThreadedComment = "fbThreadedComment",
  fbPost = "fbPost",
  igPost = "igPost",
  igMessage = "igMessage",
  igMention = "igMention",
  igMentionV2 = "igMentionV2",
  igMentionPost = "igMentionPost",
  igMentionInPostV2 = "igMentionInPostV2",
  igComment = "igComment",
  igThreadedComment = "igThreadedComment",
  liComment = "liComment",
  liThreadedComment = "liThreadedComment",
  liPost = "liPost",
  ttThreadedComment = "ttThreadedComment",
  gmbReviewReply = "gmbReviewReply",
  gmbAnwser = "gmbAnswer",
  system = "system",
  internal = "internal",
  typing = "typing",
  messageCreating = "messageCreating",
}

export type ConversationPostType =
  | "igCommentPost"
  | "igCaptionPost"
  | "fbPost"
  | "igPost";

export type ConversationPost = {
  type: ConversationPostType;
  caption?: string;
  text?: string;
  createdAt: string;
  id: string;
  platform: AccountPlatformType;
  platformId: string;
  thumbnail?: string;
  url: string;
  mediaType?: "IMAGE" | "VIDEO" | "CAROUSEL_ALBUM" | "EMBED";
  mediaUrl?: string;
  owner?: Interlocutor;

  /** client state */
  resyncing?: boolean;
  resyncRequested?: boolean;
};

export type PostPreviewIn =
  | "comment"
  | "mention-in-comment"
  | "mention-in-caption"
  | "new-post-on-page"
  | "tagged-in-post";

export interface LinkedInPost extends ConversationPost {
  resolvedContent?: LIResolvedPostContent;
  content?: LIPostContent;
  fromWebhookData?: {
    mediaCategory: string;
  };
}

export interface TiktokPost extends ConversationPost {
  content?: TTPostContent;
}

export interface LIPostContent {
  article?: LIArticleContent;
  carousel?: LICarouselContent;
  poll?: LIPollContent;
  multiImage?: LIMultiImageContent;
  media?: LIMediaContent;
}

export interface TTPostContent {
  caption?: string;
  embedUrl?: string;
  url?: string;
  mediaUrl?: string;
  thumbnail?: string;
}

export interface LIMultiImageContent {
  images: { id: string }[];
}

export interface LIPollContent {
  question: string;
  settings: LIPollSettings;
  options: LIPollOptions[];
  uniqueVotersCount: number;
}

export interface LIPollSettings {
  voteSelectionType: "SINGLE_VOTE" | "MULTIPLE_VOTE";
  duration: "ONE_DAY" | "THREE_DAYS" | "SEVEN_DAYS" | "FOURTEEN_DAYS";
  isVoterVisibleToAuthor?: boolean;
}

export interface LIPollOptions {
  text: string;
  isVotedByViewer?: boolean;
  voteCount?: number;
}

export interface LIArticleContent {
  description?: string;
  source: string;
  thumbnail?: string;
  thumbnailAltText?: string;
  title: string;
}

export interface LICarouselContent {
  id: string;
  cards: LICarouselCard[];
}

export interface LICarouselCard {
  landingPage: string;
  media: LIMediaContent;
}

export interface LIMediaContent {
  id: string;
  title?: string;
  altText?: string;
}

export enum LIResolvedPostContentType {
  images = "images",
  image = "image",
  video = "video",
  carousel = "carousel",
}
export interface LIImageResolvedPostContent {
  type: LIResolvedPostContentType.image;
  expiredAt: number;
  url: string;
}
export interface LIImagesResolvedPostContent {
  type: LIResolvedPostContentType.images;
  images: LIImageResolvedPostContent[];
}
export interface LIVideoResolvedPostContent {
  type: LIResolvedPostContentType.video;
  expiredAt: number;
  url: string;
}
export interface LICarouselResolvedPostContent {
  type: LIResolvedPostContentType.carousel;
  images: LIImageResolvedPostContent[];
}

export type LIResolvedPostContent =
  | LIImageResolvedPostContent
  | LIImagesResolvedPostContent
  | LIVideoResolvedPostContent
  | LICarouselResolvedPostContent;

export interface TTResolvedPostContent {
  id: string;
  caption: string;
  embedUrl: string;
  url: string;
  mediaUrl: string;
  thumbnail: string;
}

export type ConversationType =
  | "MENTION"
  | "MENTION_V2"
  | "COMMENT"
  | "MESSAGE"
  | "POST"
  | "TAGGED_POST"
  | "THREADED_COMMENT"
  | "REVIEW"
  | "QA";

export const ConversationTypeLabels: Record<ConversationType, string> = {
  MENTION: "Tagged",
  MENTION_V2: "Tagged",
  COMMENT: "Comment",
  MESSAGE: "Direct Message",
  POST: "Post",
  TAGGED_POST: "Tagged",
  THREADED_COMMENT: "Comment",
  REVIEW: "Review",
  QA: "Question",
};

export type ConvLastMessage = Pick<
  Message,
  "id" | "text" | "type" | "isInternal" | "createdAt" | "sender" | "deleted"
>;

export type Conversation = {
  id: string;
  type: ConversationType;
  status: Status.open | Status.closed;
  unreadCount: number;
  isStarred: boolean;
  assignedTo: string;
  tags?: Tag[];
  createdAt: string;
  updatedAt: string | Date;
  interlocutor: Interlocutor;
  account: string;
  lastMessage: ConvLastMessage | undefined;
  items?: Message[];
  messages?: Message[];
  next: string | null;
  previous: string | null;
  hasPrevious: boolean;
  hasNext: boolean;
  isComplete?: boolean;
  post?: ConversationPost;
  review?: GmbReview;
  question?: GmbQuestion;
  isMessagesLoading?: boolean;
  isMessagesFetched?: boolean;
  isLastMessagesFetchedError?: boolean;
  lastMessagesFetchedTime?: Date;

  removed?: boolean;
  isMention?: boolean;
  mentionees?: string[];
};
export interface ThreadedConversation extends Conversation {
  participants: {
    _id: string;
    name: string;
    platform: InterlocutorPlatformType;
  }[];
}

export enum ReviewType {
  GMBReview = "gmbReview",
}
export enum GmbReviewStarRating {
  NA = "STAR_RATING_UNSPECIFIED",
  One = "ONE",
  Two = "TWO",
  Three = "THREE",
  Four = "FOUR",
  Five = "FIVE",
}

export const ReviewRatingMap = {
  [GmbReviewStarRating.NA]: 0,
  [GmbReviewStarRating.One]: 1,
  [GmbReviewStarRating.Two]: 2,
  [GmbReviewStarRating.Three]: 3,
  [GmbReviewStarRating.Four]: 4,
  [GmbReviewStarRating.Five]: 5,
};

export interface GmbReview {
  _id: string;
  type: ReviewType;
  platform: AccountPlatformType;
  platformId: string;
  conversation: GMBReviewConversation;
  owner: Interlocutor;
  createdAt: string;
  rating: GmbReviewStarRating;
  comment: string;
}
export interface GMBReviewConversation extends Conversation {
  review: GmbReview;
}
export enum QuestionType {
  GMBQuestion = "gmbQuestion",
}
export interface GmbQuestion {
  _id: string;
  type: QuestionType;
  platform: AccountPlatformType;
  platformId: string;
  conversation: Conversation;
  owner: Interlocutor;
  text: string;
  createdAt: string;
  upvoteCount: number;
  comment: string;
}
export enum GmbQuestionAuthorType {
  Unspecified = "AUTHOR_TYPE_UNSPECIFIED",
  RegularUser = "REGULAR_USER",
  LocalGuide = "LOCAL_GUIDE",
  Merchant = "MERCHANT",
}
export interface GmbQuestionAuthor {
  displayName: string;
  profilePhotoUri: string;
  type: GmbQuestionAuthorType;
}
export interface GoogleMessageItem {
  name: string;
  author: GmbQuestionAuthor;
  upvoteCount?: number;
  text: string;
}
export interface GMBQuestionConversation extends Conversation {
  question: GmbQuestion;
  anwser: GoogleMessageItem[];
}

export type ConversationsResponse = Conversations & { items: Conversation[] };
export type Conversations = {
  next: string | null;
  previous: string | null;
  hasPrevious: boolean;
  hasNext: boolean;
  conversations?: Conversation[];
};

export type ConversationsFilters = {
  all: number | null;
  unassigned: number | null;
  assignedToMe: number | null;
  mentioned: number | null;
  starred: number | null;
  closed: number | null;
  unreadConversations: number | null;
  taggedInPost: number | null;
};

export type MessageTemplate = {
  text: string;
  order: number;
};

export enum MetaData {
  message = "message",
  mention = "mention",
  reaction = "reaction",
  comment = "comment",
  storyReply = "storyReply",
  storyMention = "storyMention",
  liveComment = "liveComment",
}

export type TypingCollaborator = {
  id: string;
  isTeamMessage: boolean;
  convId: string;
  createdAt: number;
};

export enum InboxLocalStorageKey {
  selectedAccounts = "selectedAccounts",
  inboxSelectedConversationTypes = "inboxSelectedConversationTypes",
  inboxConversationViewFilterOpened = "inboxConversationViewFilterOpened",
  inboxConversationTypeFilterOpened = "inboxConversationTypeFilterOpened",
  inboxAccountFilterOpened = "inboxAccountFilterOpened",
}

export enum InboxConstants {
  microlinkApiKey = "JgUARf2HjedOQkin6wQs2Ll11upPpWG5gJ1NTX9c",
}

export interface MentionConversation extends Conversation {
  instagram?: {
    mediaId?: string;
    commentId: string;
  };
}
