import {
  GET_COLLABORATION_COMMENTS_ROUTINE,
  GET_COLLABORATION_USERS_ROUTINE,
  GET_COLLABORATION_UNREADS_COUNT_ROUTINE,
  NEW_COLLABORATION_COMMENT_RECEIVED,
  COLLABORATION_COMMENT_EDITED,
  MARK_COLLABORATION_CHANNEL_AS_READ,
  NEW_UNREAD_NOTIFICATION_RECEIVED,
  CLEAN_STORE_COMMENTS,
  COLLABORATION_COMMENTS_LOADING_ON,
  COLLABORATION_COMMENTS_LOADING_OFF,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import { A } from "../../ui/external-link/external-link.stories";

const initialState = {
  comments: {},
  collaborators: [],
  isRequestingComments: false,
  unreads: [],
};

const collaborationReducer = createReducer(initialState, {
  // set loader when requested comments
  [COLLABORATION_COMMENTS_LOADING_ON]: (state) => {
    return {
      ...state,
      isRequestingComments: true,
    };
  },
  [COLLABORATION_COMMENTS_LOADING_OFF]: (state) => {
    return {
      ...state,
      isRequestingComments: false,
    };
  },
  // update comments and remove loader
  [GET_COLLABORATION_COMMENTS_ROUTINE.SUCCESS]: (state, action) => {
    return {
      ...state,
      comments: action.payload,
    };
  },
  [GET_COLLABORATION_USERS_ROUTINE.SUCCESS]: (state, action) => {
    return {
      ...state,
      collaborators: action.payload,
    };
  },
  [NEW_COLLABORATION_COMMENT_RECEIVED]: (
    state,
    { payload: { comment, userId } }
  ) => {
    const activeThread = _.cloneDeep(state.comments.activeThread);
    if (activeThread && Array.isArray(activeThread)) {
      activeThread.push(comment);
      // mark all comments as read
      for (const comm of activeThread) {
        if (!comm.readBy.includes(userId)) {
          comm.readBy.push(userId);
        }
      }
    }
    return {
      ...state,
      comments: { ...state.comments, activeThread },
    };
  },
  [NEW_UNREAD_NOTIFICATION_RECEIVED]: (state, { payload: { channelId } }) => {
    const unreads = _.cloneDeep(state.unreads);
    // if channelId is not in current unreads array
    if (!unreads.map((u) => u.channelId).includes(channelId)) {
      // then it's a new post and we should add it to array
      unreads.push({ channelId, unreads: 1 });
    } else {
      unreads.forEach((channel) => {
        const isSelected = state.comments?.channelId === channelId;
        if (channel.channelId === channelId && !isSelected) {
          channel.unreads++;
        }
        return channel;
      });
    }
    return {
      ...state,
      unreads,
    };
  },
  [COLLABORATION_COMMENT_EDITED]: (state, { payload: { comment } }) => {
    let activeThread = _.cloneDeep(state.comments.activeThread);
    activeThread = activeThread.map((existingComment) =>
      existingComment._id === comment._id ? comment : existingComment
    );

    return {
      ...state,
      comments: { ...state.comments, activeThread },
    };
  },
  [MARK_COLLABORATION_CHANNEL_AS_READ]: (state, { payload: { channelId } }) => {
    const updateUnreadsCounter = _.cloneDeep(state.unreads).map((channel) => {
      if (channel.channelId === channelId) {
        channel.unreads = 0;
      }
      return channel;
    });

    return {
      ...state,
      unreads: updateUnreadsCounter,
    };
  },
  [GET_COLLABORATION_UNREADS_COUNT_ROUTINE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      unreads: payload,
    };
  },
  [CLEAN_STORE_COMMENTS]: (state) => {
    return { ...state, comments: {} };
  },
});

export default collaborationReducer;
