import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "effector-react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import styled, { css } from "styled-components";
import { isUserPlan } from "features/upsell/plan-utils";

import {
  LoadingSpinner,
  SkedButton,
  SkedCheckbox,
  TextInput,
  TooltipWrapper,
} from "ui";
import { closeModal, openModal } from "libs/modals";
import { CollectionSettings } from "./collectionSettings";
import { getAccounts } from "selectors/skedCoreSelectors";
import { getUser as getCurrentUser } from "selectors/commonSelectors";
import AccountPicker from "components/AccountPicker/AccountPicker";
import { filterAccountsByText } from "utils/accounts";

import { getUserFullName, getUserNameInitials } from "utils/user";

import AllowPostStatusList from "./AllowPostStatusList";
import { useMediaCollections } from "entities/media-collections";
import { MediaCollection } from "api/media-library";
import { $selectedAccountsIds } from "entities/accounts";

import {
  DELETE_USER_MODAL,
  EDIT_USER_MODAL,
  INVITATION_USER_MODAL,
} from "../../UserModals";
import {
  addNewUser,
  fetchUser,
  getAdminUser,
  getUser,
  getUserStatus,
  getUserUpdateStatus,
  updateUser,
  updateUserPreferences,
} from "../../users-model";
import { CollaborationPostStatus } from "features/user";
import { RadioGroupItem } from "@dialogueconsulting/sked-ui";
import { entitlementsPermission } from "utils/entitlements-permission";

export type Status = CollaborationPostStatus & { selected: boolean };

export type SocialListeningPermissions = {
  hasSentiment: boolean;
  hasAudience: boolean;
  hasCompetitorView: boolean;
  hasCompetitorEdit: boolean;
  hasKeywords: boolean;
};
export type ReportBuilderPermission =
  | "full-access"
  | "no-access"
  | "download-only";

export const EditUserModal = ({
  userInfo,
}: {
  userInfo: any;
}): React.ReactElement => {
  const { email, profileImage } = userInfo;
  const dispatch = useDispatch();
  const userStatus = useSelector(getUserStatus);
  const userUpdateStatus = useSelector(getUserUpdateStatus);
  const user = useSelector(getUser);
  const currentUser = useSelector(getCurrentUser);
  const accounts = useSelector(getAccounts);
  const isLoaded = userStatus === "succeeded";
  const isLoading = userUpdateStatus === "loading" || userStatus === "loading";
  const { collections, isLoading: isLoadingMedia } = useMediaCollections();

  const [accessibleAccounts, setAccessibleAccounts] = useState<
    string[] | undefined
  >([]);
  const selectedAccounts = useStore($selectedAccountsIds);

  const isEnterpriseUser = isUserPlan(currentUser, "sked-enterprise");

  const [postStatusList, setPostStatusList] = useState<Status[] | []>([]);
  const [canAddAccounts, setCanAddAccounts] = useState(false);
  const [canManageUsers, setCanManageUsers] = useState(false);
  const [canShowInbox, setShowInbox] = useState(true);
  const [canAccessPaidSocial, setCanAccessPaidSocial] = useState(false);
  const [reportBuilderPermission, setReportBuilderPermission] =
    useState<ReportBuilderPermission>("download-only");

  const [socialListeningPermission, setSlPermission] =
    useState<SocialListeningPermissions>({
      hasSentiment: false,
      hasAudience: false,
      hasCompetitorView: false,
      hasCompetitorEdit: false,
      hasKeywords: false,
    });
  const slSettingsConfig = [
    {
      name: "Sentiment",
      settingsParam: "hasSentiment",
      enabled: entitlementsPermission.hasSocialListeningSentiment(currentUser),
    },
    {
      name: "Audience",
      settingsParam: "hasAudience",
      enabled: entitlementsPermission.hasSocialListeningAudience(currentUser),
    },
    {
      name: "Competitors: View",
      settingsParam: "hasCompetitorView",
      enabled:
        entitlementsPermission.hasSocialListeningCompetitors(currentUser),
    },
    {
      name: "Competitors: Edit",
      settingsParam: "hasCompetitorEdit",
      enabled:
        entitlementsPermission.hasSocialListeningCompetitors(currentUser),
    },
    {
      name: "Keywords",
      settingsParam: "hasKeywords",
      enabled: entitlementsPermission.hasSocialListeningKeyword(currentUser),
    },
  ] as const;

  // this is the object itself, or array of objects
  const [collectionUserCanAccess, setCollectionUserCanAccess] = useState<
    MediaCollection | string[] | null
  >(isEnterpriseUser ? [] : null);
  const [hideOtherCollections, setHideOtherCollections] = useState(false);
  const [hideReviewLink, setHideReviewLink] = useState(false);

  const [initials, setInitials] = useState<string>();

  const isNewUser = useMemo(
    () => !userInfo?._id && !userInfo?.user_id && !user?._id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.user_id, userInfo?.user_id]
  );
  const isShowInboxSection = useSelector(getAdminUser).preferences?.showInbox;
  const disabledSubmit =
    !isLoaded || !(canAddAccounts || accessibleAccounts?.length);

  useEffect(() => {
    if (!userStatus) {
      dispatch(fetchUser(email));
    }
  }, [userStatus, dispatch]);

  useEffect(() => {
    if (isLoaded) {
      setInitials(getUserNameInitials(userInfo));
      setCanAddAccounts(!!user.canAddAccounts);
      setCanManageUsers(Boolean(user.canManageUsers));
      setShowInbox(isNewUser ? true : Boolean(user.showInbox));
      setHideOtherCollections(Boolean(user.hideOtherCollections));
      setHideReviewLink(!!user.hideReviewLink);
      setCanAccessPaidSocial(!!user.fbAdAccountPermission?.fullAccess);
      setReportBuilderPermission(user.reportBuilderPermission);
      setSlPermission({
        hasSentiment: user.socialListeningPermission?.hasSentiment ?? true,
        hasAudience: user.socialListeningPermission?.hasAudience ?? true,
        hasCompetitorView:
          user.socialListeningPermission?.hasCompetitorView ?? true,
        hasCompetitorEdit:
          user.socialListeningPermission?.hasCompetitorEdit ?? true,
        hasKeywords: user.socialListeningPermission?.hasKeywords ?? true,
      });

      if (currentUser.isPostStatusEnabled) {
        const { postStatuses = [] }: { postStatuses: Status[] } = currentUser;
        let updatedPostStatuses;
        if (user.canChangeStatusTo?.length) {
          updatedPostStatuses = postStatuses.map((status) => {
            return {
              ...status,
              selected:
                user.canChangeStatusTo.indexOf(status.key) > -1 ||
                status.isDefault,
            };
          });
        } else {
          updatedPostStatuses = postStatuses.map((status) => {
            return {
              ...status,
              selected: status.isDefault,
            };
          });
        }

        if (isNewUser) {
          updatedPostStatuses = postStatuses.map((status) => ({
            ...status,
            selected: isNewUser,
          }));
          setCanAccessPaidSocial(true);
        }

        setPostStatusList(updatedPostStatuses);
      }
    }
  }, [user, userStatus, currentUser, selectedAccounts]);

  useEffect(() => {
    if (isLoaded && collections && collections.length) {
      if (isEnterpriseUser) {
        if (user.collectionUserCanAccess === null) {
          setCollectionUserCanAccess([]);
        } else {
          setCollectionUserCanAccess(
            Array.isArray(user.collectionUserCanAccess)
              ? user.collectionUserCanAccess
              : [user.collectionUserCanAccess]
          );
        }
      } else {
        const collection = user.collectionUserCanAccess
          ? collections?.find(
              (collection: any) =>
                collection._id === user.collectionUserCanAccess
            ) ?? null
          : collections[0];
        setCollectionUserCanAccess(collection);
      }
    }
  }, [isLoaded, collections, user.collectionUserCanAccess]);

  useEffect(() => {
    if (isLoaded) {
      setAccessibleAccounts([]);
      if (isNewUser) {
        setAccessibleAccounts(selectedAccounts);
      }

      if (!isNewUser || user?.accessibleAccounts?.length) {
        // some of the users has duplicated accounts (maybe some internal issue)
        const filteredAccounts: string[] = [];
        if (user.accessibleAccounts && user.accessibleAccounts.length > 0) {
          user.accessibleAccounts.forEach(
            (accountId: string) =>
              !filteredAccounts.includes(accountId) &&
              filteredAccounts.push(accountId)
          );
        }

        setAccessibleAccounts(filteredAccounts);
      }
    }
  }, [user, isNewUser, userStatus]);

  const fullName = useMemo(() => {
    return getUserFullName({
      fullName: userInfo.fullName || user.fullName,
      lastName: userInfo.lastName || user.lastName,
      firstName: userInfo.firstName,
    });
  }, [
    user.fullName,
    user.lastName,
    userInfo.firstName,
    userInfo.fullName,
    userInfo.lastName,
  ]);

  const [accountFilterText, setAccountsFilterText] = useState("");
  const slEnabled = entitlementsPermission.hasSocialListening(currentUser);
  function handleSelectAccountIds(accounts: any) {
    setAccessibleAccounts(accounts);
  }

  function handleCanAddAccounts() {
    setCanAddAccounts(!canAddAccounts);
  }

  function handleCanManageUsers() {
    setCanManageUsers(!canManageUsers);
  }

  function handleManageInbox() {
    setShowInbox(!canShowInbox);
  }

  function handleManagePaidSocialAccess() {
    setCanAccessPaidSocial(!canAccessPaidSocial);
  }

  function handleHideOtherCollections() {
    setHideOtherCollections(!hideOtherCollections);
  }

  function handleHideReviewLink() {
    setHideReviewLink(!hideReviewLink);
  }

  function handleDelete() {
    dispatch(
      openModal({
        type: DELETE_USER_MODAL,
        props: { email: userInfo.email, accountName: fullName },
      })
    );
  }

  async function submit() {
    const collectionUserCanAccessEmptyArray = Array.isArray(
      collectionUserCanAccess
    )
      ? collectionUserCanAccess.length === 0
      : false;

    if (
      hideOtherCollections &&
      (!collectionUserCanAccess || collectionUserCanAccessEmptyArray)
    ) {
      toast.error(
        `Please select at least one collection the user should be restricted to.`
      );

      return;
    }

    let userForUpdate = { ...user };

    if (isNewUser) {
      await dispatch(addNewUser(userInfo));
      const result = await dispatch(fetchUser(userInfo.email));
      userForUpdate = (result as any).payload;
    }

    dispatch(
      updateUser({
        email,
        ids: accessibleAccounts,
      })
    );

    if (userForUpdate.userId) {
      const preferences = {
        hideReviewLink,
        canAddAccounts,
        reportBuilderPermission,
        socialListeningPermission: socialListeningPermission,
        canChangeStatusTo: [],
        canManageUsers,
        showInbox: canShowInbox,
        hideOtherCollections,
        // single string for non-enterprise users, array of strings for enterprise users
        collectionUserCanAccess: isEnterpriseUser
          ? collectionUserCanAccess
          : (collectionUserCanAccess as MediaCollection)._id,
        fbAdAccountPermission: {
          fullAccess: canAccessPaidSocial,
        },
      };
      if (postStatusList?.length) {
        // @ts-ignore
        preferences.canChangeStatusTo = postStatusList
          .filter((item: any) => !!item.selected)
          .map((item: any) => item.key);
      }
      dispatch(
        updateUserPreferences({ userId: userForUpdate.userId, preferences })
      );
    }
    dispatch(closeModal({ type: EDIT_USER_MODAL }));

    if (!userInfo._id) {
      dispatch(
        openModal({
          type: INVITATION_USER_MODAL,
          props: {
            userName: fullName,
          },
        })
      );
    }
  }

  function closeEditModal() {
    dispatch(closeModal({ type: EDIT_USER_MODAL }));
  }

  if (isLoading || isLoadingMedia) {
    return <LoadingSpinner isSlow />;
  }

  function handleAccountFilterTextChange(val: string) {
    setAccountsFilterText(val.trim());
  }

  const filteredAccounts = accounts.filter(
    filterAccountsByText(accountFilterText)
  );

  return (
    <div>
      <Modal
        show
        onHide={closeEditModal}
        animation={true}
        bsStyle="giselle"
        // @ts-ignore
        bsSize="md-reasonable"
      >
        <Form name="accessForm">
          <div className="modal-header">
            <button
              type="button"
              className="close link"
              aria-hidden="true"
              modal-dismiss="true"
              onClick={closeEditModal}
            >
              &times;
            </button>
            <h4>Edit user access</h4>
          </div>

          {isLoaded && (
            <div className="modal-body">
              {user.isNew && (
                <div className="alert alert-info">
                  <p>
                    We have sent an invitation to sign up to {fullName || email}
                    {fullName && <span>({email})</span>}. Set the accounts they
                    can manage below.
                  </p>
                </div>
              )}
              <p>
                Choose the access settings for{" "}
                <span className="tw-font-black">{fullName || email}</span>
                {fullName && <span> ({email})</span>}.
              </p>
              <h3>Account summary</h3>
              <div className="tw-flex tw-justify-center tw-items-center">
                <div className="tw-flex tw-shadow-lg tw-max-w-3/4 tw-w-full">
                  <div className="tw-p-8 tw-pr-2">
                    <AvatarWrapper className="tw-block tw-max-h-full tw-max-w-full tw-bg-green-500 tw-rounded tw-h-56 tw-w-56">
                      {!profileImage && (
                        <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
                          <Initials className="tw-text-black tw-text-5xl">
                            {initials ? initials : "AA"}
                          </Initials>
                        </div>
                      )}
                      {profileImage && (
                        <Avatar src={profileImage} alt="Avatar" />
                      )}
                    </AvatarWrapper>
                  </div>
                  <div className="tw-w-2/3 tw-p-8 tw-pl-2">
                    <p>
                      <span className="tw-font-black">
                        {fullName || "No name yet"}
                      </span>
                      <br />
                      {email}
                    </p>
                    <p className="tw-text-xl">
                      <em>
                        {`Access to ${accessibleAccounts?.length} accounts.`}
                      </em>
                    </p>
                    <p className="tw-text-xl">
                      <span className="tw-font-black">
                        {user.canAddAccounts ? "Can " : "Can't "}
                      </span>
                      add accounts to Sked.
                    </p>
                    <p className="tw-text-xl">
                      Review links are{" "}
                      <span className="tw-font-black">
                        {user.hideReviewLink
                          ? " hidden from "
                          : "visible and editable to "}
                        this collaborator.
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <h3>Account access</h3>
              <div className="alert alert-info">
                The user can post images/videos, view and edit drafts/upcoming
                posts and see archived posts for the selected accounts below.
              </div>
              <AccountPickerSearchInput
                onChange={handleAccountFilterTextChange}
                placeholder="Search accounts"
              />
              <div className="tw-overflow-y-auto custom-scroller tw-max-h-500px">
                <AccountPicker
                  accounts={filteredAccounts}
                  isGlobal={false}
                  selectedAccountIds={accessibleAccounts}
                  onAccountsChange={handleSelectAccountIds}
                />
              </div>
              {(user.userId || isNewUser) && (
                <div>
                  <div className="tw-mb-8">
                    <h3>Adding accounts</h3>
                    <p>Choose if this user should be able to add accounts.</p>
                    <p>
                      Any user can reconnect an account that they can access,
                      should this be necessary.
                    </p>
                    <div className="alert alert-info">
                      <p>
                        For security reasons, only administrators can{" "}
                        <em>remove</em> accounts.
                      </p>
                    </div>
                    <Checkbox
                      label={`Allow ${email} to add accounts to my Sked account`}
                      checked={canAddAccounts}
                      onClick={handleCanAddAccounts}
                    />
                  </div>
                  <div className="tw-mb-8">
                    <h3>Managing users</h3>
                    <p>
                      Choose if this user should be able to manage other users.
                    </p>
                    <p>
                      When enabled, this user will be able to modify permissions
                      for <em>all users</em> (including themselves) on your
                      account, including which social account(s) they can see in
                      the dashboard, along with adding, removing or editing
                      other users to your account.
                    </p>
                    <Checkbox
                      label={`Allow ${email} to manage users on my Sked account`}
                      checked={canManageUsers}
                      onClick={handleCanManageUsers}
                    />
                  </div>
                  {isShowInboxSection && (
                    <div className="tw-mb-8">
                      <h3>Inbox Access</h3>
                      <p>
                        Mark the checkbox to give permission for the user to
                        access and manage the inbox conversations for the
                        accounts they have access to.
                      </p>
                      <Checkbox
                        label={`Allow ${email} to view and manage inbox conversations`}
                        checked={canShowInbox}
                        onClick={handleManageInbox}
                      />
                    </div>
                  )}
                  <div className="tw-mb-8">
                    <h3>Paid Social Reporting Access</h3>
                    <p>
                      Choose to grant access to Paid Social Reporting. If
                      checked, the collaborator will have access to all ad
                      accounts. Tick the checkbox to grant full access.
                    </p>

                    <Checkbox
                      label={`Allow ${email} to have access to Paid Social reporting`}
                      checked={canAccessPaidSocial}
                      onClick={handleManagePaidSocialAccess}
                    />
                  </div>
                  <div className="tw-mb-8">
                    <h3>Restricted Library Access</h3>
                    <p>
                      Limit the access for {email} to view or edit collections
                      in your Library. Choose the collection they should have
                      access to from the dropdown menu.
                    </p>
                    <Checkbox
                      label={`Restrict ${email}'s access to the selected collection(s) only`}
                      checked={hideOtherCollections}
                      onClick={handleHideOtherCollections}
                    />
                  </div>
                  {/* This doesn't work - TODO ask someone for help! */}
                  {hideOtherCollections && (
                    <CollectionSettings
                      collectionUserCanAccess={collectionUserCanAccess}
                      setCollectionUserCanAccess={setCollectionUserCanAccess}
                      collections={collections}
                    />
                  )}
                  {currentUser.isPostStatusEnabled && (
                    <div className="tw-mb-8">
                      <h3>Viewing/creating/editing external review links</h3>
                      <p>
                        Choose whether to hide the window where you can create,
                        view, update or delete external review links.
                      </p>
                      <p>
                        If this is disabled, all users (regardless of the
                        account(s) that they can manage) will be able to
                        see/create/edit all external review links.
                      </p>
                      <Checkbox
                        label={`Hide the external review links window for ${email}`}
                        checked={hideReviewLink}
                        onClick={handleHideReviewLink}
                      />
                    </div>
                  )}
                  {currentUser.isPostStatusEnabled && (
                    <div data-testid="postStatusRestriction">
                      <h3>Post status restrictions</h3>
                      <p>
                        By default, all users can change posts or drafts to any
                        status. You are not able to stop a user from changing
                        statuses <em>at all</em> - use a status like 'In draft'
                        for this purpose.
                      </p>
                      <p>
                        Users will always <em>create</em> posts with the default
                        status, and all users can edit the contents (e.g.
                        caption) of any post, regardless of status.
                      </p>
                      <p>
                        <strong>
                          Allow {fullName || email} to change posts or drafts to
                          these statuses:
                        </strong>
                      </p>
                      {postStatusList.length && (
                        <AllowPostStatusList
                          postStatusList={postStatusList}
                          setPostStatusList={setPostStatusList}
                        />
                      )}
                    </div>
                  )}
                </div>
              )}

              <div>
                <h3>Custom Reports access</h3>
                <p>
                  Manage permissions to access Custom Reports. The user will
                  only have access to the accounts they have existing
                  permissions for.
                </p>
                <RadioGroup>
                  <RadioGroupItemWrap>
                    <RadioGroupItem
                      checked={reportBuilderPermission === "full-access"}
                      onClick={() => setReportBuilderPermission("full-access")}
                    />
                    <p>
                      <strong>Full access</strong>: User can create new reports
                      and download existing reports
                    </p>
                  </RadioGroupItemWrap>
                  <RadioGroupItemWrap>
                    <RadioGroupItem
                      checked={reportBuilderPermission === "download-only"}
                      onClick={() =>
                        setReportBuilderPermission("download-only")
                      }
                    />
                    <p>
                      <strong>Download only</strong>: User can download and send
                      existing reports
                    </p>
                  </RadioGroupItemWrap>
                  <RadioGroupItemWrap>
                    <RadioGroupItem
                      checked={reportBuilderPermission === "no-access"}
                      onClick={() => setReportBuilderPermission("no-access")}
                    />
                    <p>
                      <strong>No access</strong>: User can not access to Custom
                      Reports
                    </p>
                  </RadioGroupItemWrap>
                </RadioGroup>
              </div>

              {slEnabled && (
                <div>
                  <h3>Social listening access</h3>
                  <p>
                    Manage permissions to access Social Listening. The user will
                    only have access to the accounts they have existing
                    permissions for.
                  </p>
                  {slSettingsConfig
                    .filter((item) => item.settingsParam !== "hasKeywords")
                    .map((item) => {
                      return (
                        <div
                          className="checkbox-input tw-pb-4 tw-font-bold"
                          key={item.name}
                        >
                          <Checkbox
                            disabled={!item.enabled}
                            label={`${item.name}`}
                            checked={
                              socialListeningPermission[item.settingsParam]
                            }
                            onClick={() =>
                              setSlPermission({
                                ...socialListeningPermission,
                                [item.settingsParam]:
                                  !socialListeningPermission[
                                    item.settingsParam
                                  ],
                              })
                            }
                          />
                        </div>
                      );
                    })}
                </div>
              )}

              {!isNewUser && (
                <div>
                  <h3>Delete user</h3>
                  <p>
                    This will delete {fullName || email} from Sked. It will not
                    remove any posts or drafts that they have created or
                    published.
                  </p>
                  <div
                    data-testid="deleteUser"
                    className="btn btn-danger"
                    onClick={handleDelete}
                  >
                    Delete user
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="link pull-left"
              onClick={closeEditModal}
            >
              Close
            </button>
            <TooltipWrapper
              tooltip={
                disabledSubmit
                  ? "Each user needs to have access to at least one social media account."
                  : ""
              }
            >
              <Submit
                disabled={disabledSubmit}
                type="primary"
                onClick={submit}
                loading={isLoading}
              >
                Set Access
              </Submit>
            </TooltipWrapper>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

const Submit = styled(SkedButton)<{ disabled: boolean }>`
  button {
    font-weight: normal !important;
    ${(props) =>
      props.disabled &&
      css`
        pointer-events: none;
      `}
  }
`;

const Checkbox = styled(SkedCheckbox)`
  display: block;
`;

const Form = styled.form`
  min-height: 60vh;
`;

const Initials = styled.div`
  text-transform: uppercase;
`;
const AvatarWrapper = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Avatar = styled.img`
  max-height: 140px;
`;

const AccountPickerSearchInput = styled(TextInput)`
  margin: 10px;
  width: calc(100% - 10px);
  font-size: 14px;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const RadioGroupItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    margin: 0;
    font-size: 14px;
  }
`;
