import React, { MouseEvent } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { colors } from "styles/color-tokens";

import { getDescriptions } from "selectors/postFormSelectors";
import { TooltipWrapper } from "ui";
import DescriptionIcon from "../../icons/library.svg";

interface LibraryDescriptionPickerProps {
  toggleDescriptionPicker: () => void;
  isOpen: boolean;
}

export const LibraryDescriptionPicker = (
  props: LibraryDescriptionPickerProps
) => {
  const descriptionsLength = useSelector(getDescriptions).length;

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (descriptionsLength) {
      props.toggleDescriptionPicker();
    }
  };

  return (
    <>
      <TooltipWrapper tooltip={"Descriptions"}>
        <DescriptionButton
          isActive={props.isOpen}
          onClick={handleClick}
          className={!descriptionsLength ? "disabled" : ""}
        >
          <DescriptionIcon />
        </DescriptionButton>
      </TooltipWrapper>
    </>
  );
};

const DescriptionButton = styled.button<{ isActive: boolean }>`
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0;
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 4px ${colors.black}33;
  border: 1px solid
    ${(props) => (props.isActive ? colors.primary500 : colors.primary100)};
  border-radius: 5px;
  background: ${(props) =>
    props.isActive ? colors.primary500 : colors.primary50};
  svg {
    fill: ${(props) => (props.isActive ? colors.white : colors.primary300)};
  }

  &.disabled {
    background-color: ${colors.neutral400};
    box-shadow: none;
    border: none;
    cursor: default;
    svg {
      fill: ${colors.white};
    }
  }
`;
