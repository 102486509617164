import React, { useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/color-tokens";

import { Chip } from "ui/chip";
import { useOpenableState } from "libs/use-openable-state";
import { useClickOutside } from "libs/use-click-outside";
import { Collapse } from "ui/collapse";

import { Button, FormSize, FormThemeProvider } from "ui/ecosystems/forms";
import { DropdownPopup } from "../dropdown";

interface Props<T> {
  value: T;
  renderEditing(): React.ReactNode;
  onChange(value: T): void;
  onCancel?(): void;
  isValueEmpty?(value: T): boolean;
  onRemove(): void;
  label: string;
  className?: string;
  hasValue?: boolean;
}

function SelectedFilterComponent<T>(props: Props<T>) {
  const editing = useOpenableState(!props.hasValue);

  function handleChange() {
    const isEmpty = props.isValueEmpty?.(props.value) ?? !props.value;
    if (!isEmpty) {
      props.onChange(props.value);
    } else {
      props.onRemove();
    }
  }

  useEffect(() => {
    if (!editing.isOpened) {
      handleChange();
    }
  }, [editing.isOpened]);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    editing.close();
  }

  const popupRef = useRef(null);
  useClickOutside(popupRef, () => {
    editing.close();
    props.onCancel?.();
  });

  return (
    <SelectedFilterRoot className={props.className}>
      <Label
        onClick={editing.toggle}
        onRemove={!editing.isOpened ? props.onRemove : undefined}
        isOpened={editing.isOpened}
      >
        {props.label}
      </Label>

      <EditPopup data-testid="filterEditPopup" ref={popupRef}>
        <Collapse isOpened={editing.isOpened}>
          <FormThemeProvider size={FormSize.medium}>
            <Form onSubmit={handleSubmit}>
              {props.renderEditing()}
              <FormAction>
                <Done
                  data-testid="filterDoneButton"
                  text
                  type="button"
                  onClick={editing.close}
                >
                  Done
                </Done>
              </FormAction>
            </Form>
          </FormThemeProvider>
        </Collapse>
      </EditPopup>
    </SelectedFilterRoot>
  );
}

export const SelectedFilter = styled(SelectedFilterComponent)`
  margin-right: 5px;
`;

export const Label = styled(Chip)<{ isOpened?: boolean }>`
  ${(props) =>
    props.isOpened &&
    css`
      background: ${colors.primary500};
      color: ${colors.white};
    `}

  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FormAction = styled.div`
  display: flex;
  justify-content: center;
`;

const Card = styled(DropdownPopup)`
  padding: 8px 9px 12px;
`;

const Form = styled(Card).attrs({
  as: "form",
})`
  min-width: 200px;
  ${FormAction} {
    margin-top: 10px;
  }
`;
const EditPopup = styled.div``;

const Done = styled(Button)`
  color: ${colors.primary500};
`;

const SelectedFilterRoot = styled.div`
  position: relative;

  ${EditPopup} {
    position: absolute;
    top: calc(100% + 7px);
    z-index: 1;
  }
`;
