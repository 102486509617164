import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDescriptions } from "selectors/postFormSelectors";
import styled from "styled-components";
import { colors } from "styles/color-tokens";
import { useTrackEvent } from "libs/analytics";
import { SELECTED_ASSET_DESC } from "constants/Events";

interface DescriptionsListProps {
  captionValue: string;
  onSelect: (description: string) => void;
}

export const DescriptionsList = (props: DescriptionsListProps) => {
  const [descriptions, setDescriptions] = useState(
    useSelector(getDescriptions)
  );
  const trackEvent = useTrackEvent();

  useEffect(() => {
    if (descriptions.length) {
      setDescriptions(
        descriptions.map((description) => {
          const selected = props.captionValue.includes(description.description);

          return { ...description, selected };
        })
      );
    }
  }, [props.captionValue]);

  const onSelect = (description: string) => {
    trackEvent({ eventName: SELECTED_ASSET_DESC });
    props.onSelect(description);
  };

  return (
    <DescriptionsContainer>
      {descriptions.length ? (
        descriptions.map((description, id) => {
          return (
            <DescriptionContainer
              key={`description-${id}`}
              className={description.selected ? "selected" : ""}
              onClick={() => onSelect(description.description)}
            >
              <Thumbnail src={description.thumbnailUrl} />
              <Description>{description.description}</Description>
            </DescriptionContainer>
          );
        })
      ) : (
        <span>No descriptions available.</span>
      )}
    </DescriptionsContainer>
  );
};

const DescriptionsContainer = styled.div`
  position: absolute;
  width: calc(100% - 8px);
  max-height: 104px;
  overflow: auto;
  left: 50%;
  transform: translate(-50%, 0);
  top: 100%;
  background-color: ${colors.white};
  box-shadow: 0px 4px 10px ${colors.black}26;
  border-radius: 0px 0px 5px 5px;
  font-size: 12px;
  text-align: center;
  z-index: 1;

  & > span {
    font-style: italic;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 54px 4px 8px;
  cursor: pointer;

  &:not(:first-child) {
    padding-top: 8px;
  }

  &:hover {
    background-color: ${colors.primary50};
  }

  &.selected > div {
    color: ${colors.neutral400};
  }
`;

const Description = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
`;

const Thumbnail = styled.img`
  width: 24px;
  height: 24px;
`;
