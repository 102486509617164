import styled, { css } from "styled-components";
import { colors } from "styles/color-tokens";

const Link = styled.a<{
  color?: keyof typeof LinkColors;
  underline?: "never" | "hover" | "always";
}>`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;

  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;

  color: ${colors.primary500};
  text-decoration: ${(props) =>
    props.underline === "always" ? "underline" : "none"};

  &:hover {
    color: ${colors.primary600};
    text-decoration: ${(props) =>
      props.underline === "never" ? "none" : "underline"};
  }

  &:disabled,
  &:disabled:hover {
    background: transparent !important;
    color: ${colors.neutral400} !important;
    text-decoration: ${(props) =>
      props.underline === "always" ? "underline" : "none"};
  }

  ${(props) => props.color && LinkColors[props.color]}
`;

const LinkColors = {
  primary: css`
    color: ${colors.primary500};
    &:hover {
      color: ${colors.primary600};
    }
  `,
  info: css`
    color: ${colors.info500};
    &:hover {
      color: ${colors.info600};
    }
  `,
  success: css`
    color: ${colors.success500};
    &:hover {
      color: ${colors.success600};
    }
  `,
  warning: css`
    color: ${colors.warning500};
    &:hover {
      color: ${colors.warning600};
    }
  `,
  danger: css`
    color: ${colors.error500};
    &:hover {
      color: ${colors.error600};
    }
  `,
  neutral: css`
    color: ${colors.neutral400};
    &:hover {
      color: ${colors.neutral500};
    }
  `,
};

export default Link;
