import {
  TOGGLE_INBOX_ITEM,
  TOGGLE_ALL_INBOX_ITEMS,
  TOGGLE_OPEN_INBOX_ITEM_ROUTINE,
  MOVE_INBOX_CURSOR,
  FAVORITE_INBOX_ITEMS_ROUTINE,
  TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE,
  TOGGLE_READ_INBOX_ITEMS_ROUTINE,
  REMOVE_INBOX_ITEMS_ROUTINE,
} from "constants/ActionTypes";
import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import { colors } from "styles/color-tokens";

const initialState = {
  byId: {
    1: {
      _id: 1,
      account: "gisellegray",
      igAccount: "@catscatscats",
      type: "comment",
      comment: "that's so cute!!",
      note: "I have some thoughts on this",
      read: false,
      media: [{ img: "https://picsum.photos/400?1" }],
      favorited: false,
      archived: false,
      created: "2020-03-10 10:10:10",
    },
    2: {
      _id: 2,
      account: "mememe",
      igAccount: "@kiteflyer",
      type: "mention",
      comment: "different comment 😭",
      read: false,
      media: [{ img: "https://picsum.photos/400?2" }],
      tags: [
        { value: "happy customer", color: colors.error500 },
        { value: "big fan", color: colors.success600 },
      ],
      favorited: false,
      archived: false,
      created: "2020-03-10 10:10:10",
    },
    3: {
      _id: 3,
      account: "tuxedocatsofig",
      igAccount: "@benjithecat",
      type: "tagged in",
      comment: "wow @tuxedocatsofig what an amazing cat!",
      read: true,
      media: [{ img: "https://picsum.photos/400?3" }],
      favorited: false,
      archived: false,
      created: "2020-03-10 10:10:10",
    },
    4: {
      _id: 4,
      account: "livelovegiraffe",
      igAccount: "@nike",
      type: "comment",
      comment: "wow @livelovegiraffe what an amazing giraffe you have!",
      read: true,
      media: [{ img: "https://picsum.photos/400?4" }],
      favorited: false,
      archived: false,
      created: "2020-03-10 10:10:10",
    },
  },
  allIds: [1, 2, 3, 4],
  selectedIds: [],
  allSelected: false,
  focusedIndex: 0,
};

// [TOGGLE_FEED_MEDIA]: (state, action) => {},
const inboxItemsReducer = createReducer(initialState, {
  [TOGGLE_ALL_INBOX_ITEMS]: (state, action) => {
    state.allSelected = !state.allSelected;

    state.selectedIds = [];
    if (state.allSelected) {
      state.selectedIds = state.allIds;
    }

    return state;
  },
  [TOGGLE_INBOX_ITEM]: (state, action) => {
    const { id } = action.payload;

    if (state.selectedIds.includes(id)) {
      _.remove(state.selectedIds, (selectedId) => selectedId === id);
    } else {
      state.selectedIds.push(id);
    }

    state.allSelected = isAllSelected(state);

    return state;
  },
  [TOGGLE_OPEN_INBOX_ITEM_ROUTINE.SUCCESS]: (state, action) => {
    const { item } = action.payload;
    const id = item ? item._id : null;
    if (id !== null) {
      const indexOf = _.indexOf(state.allIds, id);
      state.focusedIndex = indexOf !== -1 ? indexOf : 0;
    }
    return state;
  },
  [MOVE_INBOX_CURSOR]: (state, action) => {
    const { direction } = action.payload;
    switch (direction) {
      case "up":
        if (state.focusedIndex !== 0) {
          state.focusedIndex -= 1;
        }
        break;
      case "down":
        if (state.focusedIndex !== state.allIds.length - 1) {
          state.focusedIndex += 1;
        }
        break;
    }
    return state;
  },
  [FAVORITE_INBOX_ITEMS_ROUTINE.SUCCESS]: (state, action) => {
    const { id, favorited } = action.payload;
    state.byId[id].favorited = favorited;
    return state;
  },
  [TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE.SUCCESS]: (state, action) => {
    const { items, archived } = action.payload;
    const newFocusId = calculateNewFocus(state, items);

    items.map((item) => {
      state.byId[item._id].archived = archived;
      _.remove(state.allIds, (id) => id === item._id);
      _.remove(state.selectedIds, (id) => id === item._id);
    });

    state.allSelected = isAllSelected(state);
    state.focusedIndex = _.indexOf(state.allIds, newFocusId);

    return state;
  },
  [TOGGLE_READ_INBOX_ITEMS_ROUTINE.SUCCESS]: (state, action) => {
    const { items, read } = action.payload;
    items.map((item) => {
      state.byId[item._id].read = read;
    });
    return state;
  },
  [REMOVE_INBOX_ITEMS_ROUTINE.SUCCESS]: (state, action) => {
    const { items } = action.payload;
    const newFocusId = calculateNewFocus(state, items);

    items.map((item) => {
      delete state.byId[item._id];
      _.remove(state.allIds, (id) => id === item._id);
      _.remove(state.selectedIds, (id) => id === item._id);
    });

    state.allSelected = isAllSelected(state);
    state.focusedIndex = _.indexOf(state.allIds, newFocusId);

    return state;
  },
});

const calculateNewFocus = (state, items) => {
  let newFocusIndex = 0;
  let maxIndex = state.allIds.length > 0 ? state.allIds.length - 1 : 0;

  let indexMap = [];
  for (let item of items) {
    const index = _.indexOf(state.allIds, item._id);
    if (index === -1) {
      return;
    }

    indexMap.push(index);
  }
  indexMap.sort();

  let lastIndex = null;
  let allowHead = true;
  for (let [i, index] of indexMap.entries()) {
    // New index can't be the first item
    if (index === 0) {
      allowHead = false;
      lastIndex = index;
    }

    // Base case: One item selected at the bottom, move up one
    if (index === maxIndex) {
      newFocusIndex = !allowHead ? null : index - 1;
      break;
    }

    // If there is a gap, then we found our new index, take the first gap!
    if (index - lastIndex > 1) {
      newFocusIndex = lastIndex + 1;
      break;
    }

    // If there is no gap, then always take the item below
    if (index < maxIndex) {
      newFocusIndex = index + 1;
      lastIndex = index;
      continue;
    }

    // We are at the bottom, take the next item above, up to the head.
    if (index === maxIndex) {
      newFocusIndex = allowHead ? 0 : null;
      continue;
    }
  }

  return state.allIds[newFocusIndex];
};

const isAllSelected = (state) => {
  if (
    state.allIds.length > 0 &&
    state.selectedIds.length === state.allIds.length
  ) {
    return true;
  }
  return false;
};

export default inboxItemsReducer;
