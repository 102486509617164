import React from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

import CloseIcon from "./close.svg";

interface Props {
  className?: string;
  children: React.ReactNode;
  onClick?(): void;
  onRemove?(): void;
}

export function ChipComponent(props: Props) {
  function handleRemoveClick(event: Event) {
    event.stopPropagation();
    props.onRemove?.();
  }

  return (
    <ChipRoot className={props.className} onClick={props.onClick}>
      {props.children}
      {props.onRemove && (
        <RemoveButtonContainer>
          <RemoveButton onClick={handleRemoveClick} />
        </RemoveButtonContainer>
      )}
    </ChipRoot>
  );
}

export const Chip = styled(ChipComponent)``;

const RemoveButton = styled(CloseIcon)`
  height: 6px;
  width: 6px;

  :hover {
    color: ${colors.black};
  }
`;

const RemoveButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 12px;

  background-image: linear-gradient(
    270deg,
    ${colors.primary100} 69.44%,
    ${colors.primary100}00 149.16%
  );
`;

const ChipRoot = styled.div`
  padding: 6px 12px 7px 9px;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${colors.neutral800};
  background: ${colors.primary100};
  border-radius: 5px;
  cursor: pointer;

  position: relative;
  ${RemoveButtonContainer} {
    opacity: 0;
    transition: opacity 300ms ease;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 7px;
  }

  &:hover {
    ${RemoveButtonContainer} {
      opacity: 1;
    }
  }
`;
