import React from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

export function CollectionColor({
  className,
  collection,
}: {
  className?: string;
  collection: { preferences?: { color: string } };
}) {
  return (
    <ColorIndicator
      color={collection.preferences?.color ?? colors.neutral400}
      className={className}
    />
  );
}

const ColorIndicator = styled.div<{ color: string }>`
  border-radius: 0.25rem;
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: ${(props) => props.color};
  transition: background-color 0.5s;
`;
