// collections
import { createEffect, forward } from "effector";
import { toast } from "react-toastify";

import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";
import { getCollectionMedia, getCollectionTags } from "api/media-library";
import { createTable } from "libs/effector-normilaze";
import { CollectionFile } from "shared/types/files";

import {
  FileToUpload,
  uploadFilesFx,
  UploadingMode,
} from "./uploading-files.model";
import { resetCollectionsTags } from "./components/collection-media/reset-collection-tags";

// media files
export const mediaFilesTable = createTable<CollectionFile>({
  getIdKey: (item) => item._id,
});

export const uploadFilesToCollectionFx = createEffect(
  async ({
    files,
    collectionId,
    onFileUploaded,
    uploadingMode,
  }: {
    files: FileToUpload[];
    collectionId: string;
    onFileUploaded?: (file: CollectionFile) => void;
    uploadingMode: UploadingMode;
  }) => {
    const result = await uploadFilesFx({
      collectionId,
      files,
      onFileUploaded: (file) => {
        mediaFilesTable.addItem(file);
        onFileUploaded?.(file);
        resetCollectionsTags();
      },
      uploadingMode,
    });

    return result;
  }
);

export const getCollectionTagsFx = createEffect(getCollectionTags);

getCollectionTagsFx.failData.watch((err) => {
  console.error(err);
  toast.error(getUserFriendlyErrorMessage(err));
});

export const geCollectionMediaFilesFx = createEffect(getCollectionMedia);

geCollectionMediaFilesFx.failData.watch((err) => {
  console.error(err);
  toast.error(getUserFriendlyErrorMessage(err));
});

forward({
  from: geCollectionMediaFilesFx.doneData.map((data) => data.list),
  to: mediaFilesTable.addItems,
});
