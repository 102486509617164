import styled from "styled-components";
import { colors } from "styles/color-tokens";

export const Popup = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.neutral100};
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
`;
