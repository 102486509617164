import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Select } from "ui/ecosystems/forms";
import { MediaCollection } from "api/media-library";
import { getSelectedCollection } from "selectors/mediaLibrarySelectors";
import { useSelector } from "react-redux";

interface Props {
  collections: Array<MediaCollection>;
  selectedCollection: MediaCollection | null;
  className?: string;
  disabled?: boolean;
  menuPlacement?: "bottom" | "top" | "auto";
  onChange(value: string | string[]): void;
  isMulti?: boolean;
}

function CollectionSelectorComponent(props: Props) {
  const historicSelectedCollection = useSelector(getSelectedCollection);
  const [currentSelectedCollection, setCurrentSelectedCollection] = useState(
    props.selectedCollection?._id || historicSelectedCollection?._id || null
  );
  useEffect(() => {
    if (
      props.selectedCollection?._id &&
      props.selectedCollection?._id !== currentSelectedCollection
    ) {
      setCurrentSelectedCollection(props.selectedCollection?._id);
    }
  }, [props.selectedCollection?._id, currentSelectedCollection]);

  const handleChange = (collectionId: string) => {
    setCurrentSelectedCollection(collectionId);
    props.onChange(collectionId);
  };
  return (
    <Select
      isMulti={props.isMulti}
      hideSelectedOptions
      className={props.className}
      isSearchable={true}
      placeholder={"Select a collection…"}
      value={currentSelectedCollection}
      options={props.collections.map((collection) => ({
        value: collection._id,
        label: collection.name,
        color: collection?.preferences?.color || null,
        renderLabelOption: (
          <ColoredItem color={collection?.preferences?.color}>
            {collection.name}
          </ColoredItem>
        ),
      }))}
      disabled={props.disabled}
      renderLabel={(option) => option.renderLabelOption}
      renderOption={(option) => option.renderLabelOption}
      showPlaceholderOnFocus
      onChange={handleChange}
      noOptionsMessage={"No other collections"}
      menuPlacement={props.menuPlacement}
    />
  );
}

export const ColoredItem = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;

  &:before {
    display: block;
    content: "";
    width: 9px;
    height: 9px;
    border-radius: 15%;
    background-color: ${(props) => props.color ?? `rgb(204, 204, 204)`};
    margin-right: 10px;
  }
`;

export const CollectionSelector = styled(CollectionSelectorComponent)`
  .select__placeholder {
    // on small screens we need to reduce line-height so that
    // it doesn't overflow the container
    line-height: 14px;
  }
`;
