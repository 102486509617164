import { HEADER_SUPPORT_CLICK } from "constants/Events";
import { useTrackEvent } from "libs/analytics";
import {
  HubSpotHelpChatTopic,
  openSupportChat,
} from "libs/support/open-support-chat";

export function useOpenIntercomChat(
  intercomMessage?: string,
  chatQuery?: HubSpotHelpChatTopic
) {
  const trackEvent = useTrackEvent();
  const openHelp = (): void => {
    console.log("open help");
    trackEvent({
      eventName: HEADER_SUPPORT_CLICK,
      useServices: ["AMP"],
    });

    openSupportChat(intercomMessage, chatQuery);
  };

  return openHelp;
}
