import React from "react";
import ReactDOM from "react-dom";

const tooltipRoot = document.getElementById("tooltip-root");

class PortalWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    tooltipRoot?.appendChild(this.el);
  }

  componentWillUnmount() {
    tooltipRoot?.removeChild(this.el);
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default PortalWrapper;
