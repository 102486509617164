import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { LoadingSpinner } from "ui";
import ArrowLeftIcon from "assets/icons/ai-caption/enter-arrow-left.svg";
import { Textarea, TextareaExtraDesc } from "./AICaption";
import {
  ContentLabelTag,
  Toggle,
  Icon,
  Tooltip,
  Button,
} from "@dialogueconsulting/sked-ui";
import type { Label } from "../../entities/posts/components/templates/post-form-base-template";
import { useTrackEvent } from "libs/analytics";
import { CREATE_BUTTON, UPDATE_TO_CAPTIVATE_CREATE } from "constants/Events";
import { getUser } from "selectors/commonSelectors";
import { openSupportChat } from "../../libs/support";

type Props = {
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  placeholderText: string;
  setUserInput: (userInput: string) => void;
  userInput: string;
  fetchingCaptions: boolean;
  fetchCaptions: (userInput: string, includeEmoji: boolean) => void;
  includeEmoji: boolean;
  setIncludeEmoji: (includeEmoji: boolean) => void;
  includeContentLabel?: boolean;
  setIncludeContentLabel?: (includeContentLabel: boolean) => void;
  fetchingCaptionsIncludeLabel?: boolean;
  contentLabels?: Label[];
  isContentLabelFeatureEnabled?: boolean;
  handleCreateButtonClick: () => void;
  hasCaptions: boolean;
  handleNewIdeaClick: () => void;
};

export const CaptivateForm = ({
  textareaRef,
  placeholderText,
  setUserInput,
  userInput,
  fetchCaptions,
  includeEmoji,
  setIncludeEmoji,
  includeContentLabel,
  setIncludeContentLabel,
  fetchingCaptionsIncludeLabel,
  fetchingCaptions,
  isContentLabelFeatureEnabled,
  contentLabels = [],
  handleCreateButtonClick,
  hasCaptions,
  handleNewIdeaClick,
}: Props) => {
  const trackEvent = useTrackEvent();
  const user = useSelector(getUser);

  const isContentLabelFeatureNotEnabledMessage = (
    <span>
      Your current plan does not include the ability to label content or use
      these labels to generate better captivate outputs. Please{" "}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();

          openSupportChat(
            "I’d like to talk to someone about upgrading my plan to get access to Labels..."
          );
        }}
      >
        Contact Support
      </a>{" "}
      to upgrade your plan.
    </span>
  );

  const fetching = fetchingCaptions || fetchingCaptionsIncludeLabel;

  return (
    <>
      {!hasCaptions && (
        <>
          <Textarea
            placeholder={placeholderText}
            onChange={(e) => {
              setUserInput(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                if (userInput.trim() !== "") {
                  fetchCaptions(userInput.trim(), includeEmoji);
                }
                e.preventDefault();
              }
            }}
            ref={textareaRef}
            value={userInput}
            disabled={fetching}
          ></Textarea>
          <TextareaExtraDesc>
            To generate, click Return/Enter
            <ArrowLeftIcon />
          </TextareaExtraDesc>
          <Row>
            <ToggleWrapper>
              <Toggle
                size="xs"
                checked={includeEmoji}
                onClick={(val) => {
                  setIncludeEmoji(val);
                  sessionStorage.setItem(
                    "includeEmojiCaptivate",
                    val.toString()
                  );
                }}
                disabled={fetching}
              />
              <ToggleLabel>Include emojis</ToggleLabel>
            </ToggleWrapper>
          </Row>
          {setIncludeContentLabel && (
            <Row
              style={{
                marginTop: "6px",
              }}
            >
              <ToggleWrapper>
                <Toggle
                  size="xs"
                  checked={!!includeContentLabel}
                  onClick={() => {
                    setIncludeContentLabel(!includeContentLabel);
                  }}
                  disabled={
                    fetching ||
                    !isContentLabelFeatureEnabled ||
                    contentLabels?.length === 0
                  }
                />
                <ToggleLabel>Include selected Labels</ToggleLabel>

                <Tooltip
                  text={
                    !isContentLabelFeatureEnabled
                      ? isContentLabelFeatureNotEnabledMessage
                      : contentLabels?.length === 0
                      ? "You haven’t selected any labels yet."
                      : ""
                  }
                  textSize="12px"
                  container={
                    document.querySelector(
                      ".modal-giselle.modal-dialog"
                    ) as HTMLElement
                  }
                  contentWidth={
                    !isContentLabelFeatureEnabled ? "280px" : undefined
                  }
                >
                  <div style={{ position: "relative", left: "4px" }}>
                    <Icon name="alert-circle" color="#808080" size={16} />
                  </div>
                </Tooltip>
              </ToggleWrapper>
            </Row>
          )}
          {setIncludeContentLabel &&
            includeContentLabel &&
            contentLabels &&
            contentLabels.length > 0 && (
              <Row>
                <ContentLabelsWrap>
                  {contentLabels?.map((label) => (
                    <ContentLabelTag
                      key={label.value}
                      id={label.value}
                      text={label.label}
                      size="sm"
                      isCampaign={label.isCampaign}
                      {...(!label.isCampaign && {
                        color: label.color,
                        emoji: label.emoji,
                      })}
                    />
                  ))}
                </ContentLabelsWrap>
              </Row>
            )}
          <Row>
            <CreateButton
              hierarchy="primary"
              size="sm"
              text="Create"
              onClick={() => {
                if (userInput.trim() !== "") {
                  if (setIncludeContentLabel) {
                    fetchCaptions?.(userInput.trim(), includeEmoji);

                    if (
                      (includeContentLabel && contentLabels?.length > 0) ||
                      includeEmoji
                    ) {
                      trackEvent({
                        eventName: UPDATE_TO_CAPTIVATE_CREATE,
                        metadata: {
                          "content labels on":
                            includeContentLabel && contentLabels?.length > 0,
                          "emoji on": includeEmoji,
                        },
                      });
                      trackEvent({
                        eventName: CREATE_BUTTON,
                        metadata: {
                          "content labels on":
                            includeContentLabel && contentLabels?.length > 0,
                          "emoji on": includeEmoji,
                        },
                      });
                    }
                  } else {
                    handleCreateButtonClick();
                  }
                }
              }}
              disabled={fetching || userInput.trim() === ""}
            />
          </Row>
        </>
      )}

      {fetching && (
        <LoadingSpinnerStyled isSmall noMarginTop={!!setIncludeContentLabel} />
      )}

      {hasCaptions && (
        <NewIdeaWrap>
          <NewIdeaButton
            size="xs"
            onClick={handleNewIdeaClick}
            text="Create new ideas"
          />
        </NewIdeaWrap>
      )}
    </>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > label {
    font-weight: 400;
    margin-top: 8px;
  }
`;

const CreateButton = styled(Button)`
  display: flex;
  margin-left: auto;
  position: relative;
  /* padding-top: 16px; */

  button {
    font-weight: 500 !important;
    height: 38px !important;
    width: 105px;
  }
`;

const NewIdeaWrap = styled.div`
  display: grid;
  place-items: center;
  margin: 72px 0;
`;
const NewIdeaButton = styled(Button)`
  button {
    font-weight: 500 !important;
    height: 38px !important;
    width: 170px;
  }
`;

const LoadingSpinnerStyled = styled(LoadingSpinner)<{ noMarginTop: boolean }>`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  max-height: auto;

  > div {
    position: relative;
    margin-top: ${({ noMarginTop }) => (noMarginTop ? "0" : "24px")};
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;

  > label {
    margin-bottom: 0;
  }
`;

const ContentLabelsWrap = styled.div`
  margin-top: 6px;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const ToggleLabel = styled.span`
  color: #454545;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
`;
