export const allowedSelectCoverPlatforms: string[] = ["TT", "IG", "FB"];
export const allowedUploadCoverPlatforms: string[] = ["IG", "FB"];
export const THUMBNAIL_WIDTH = 64;
export const THUMBNAIL_HEIGHT = 112;

export const allowedPlatformFullnameMapping: Record<string, string> = {
  TT: "Tiktok",
  IG: "Instagram",
  FB: "Facebook",
};
