import { useStockImages } from "components/MediaLibrary/SourceMedia/StocksFeed/StocksFeed";
import React, { useState } from "react";
import { colors } from "styles/color-tokens";
import { Title } from "ui/alert/alert";
import { LoadingCover } from "ui/cover";
import { CollectionFilesList } from "../../collection-files-list";
import { PickerProps } from "../media-picker";
import {
  EmptyAlertWrapper,
  TitleBar,
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
} from "../components/common";
import { stockImageToCollectionFile } from "../helper";
import styled from "styled-components";
import { Button } from "ui/ecosystems/forms";

export function StocksPicker(props: PickerProps) {
  const [query, setQuery] = useState("");
  const [searchResult, setSearchResult] = useState("");
  const { isLoading, images = [] } = useStockImages({
    searchResult,
    location: "picker",
  });
  const onSearch = () => {
    setSearchResult(query);
  };

  const files = images.map(stockImageToCollectionFile);

  return (
    <LoadingCover isLoading={isLoading}>
      <Toolbar>
        <ToolbarLeft></ToolbarLeft>

        <ToolbarRight>
          <SearchForm>
            <SearchInput
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search for assets..."
            />
            <Button
              primary
              onClick={onSearch}
              disabled={String(query).trim().length < 1}
            >
              Search
            </Button>
          </SearchForm>
        </ToolbarRight>
      </Toolbar>
      {isLoading ? (
        <EmptyAlertWrapper empty />
      ) : searchResult && images.length < 1 ? (
        <EmptyResult>{`No results for your search ‘${searchResult}’.`}</EmptyResult>
      ) : (
        <>
          <TitleBar>
            <Title>Stock images</Title>
          </TitleBar>
          <CollectionFilesList
            files={files}
            selectedMediaSet={props.selectedMediaFiles}
            selectedCollection={props.selectedCollection}
            onSelect={props.handleFileAdd}
            onRemove={props.handleFileRemove}
          />
        </>
      )}
    </LoadingCover>
  );
}

const EmptyResult = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${colors.black};
`;

const SearchForm = styled.div`
  display: flex;

  input {
    background: ${colors.white};
    border: 1px solid ${colors.neutral200};
    border-radius: 5px;
    padding: 0 10px;
    width: 240px;
    height: 38px;
    margin-right: 10px;
  }

  button {
    height: 38px;
    font-size: 12px;
  }
`;

const SearchInput = styled.input`
  font-size: 12px;
`;
