import { all } from "redux-saga/effects";
import {
  watchInitMediaLibraryPage,
  watchSelectCollection,
  watchAddCollection,
  watchDeleteCollection,
  watchUpdateCollection,
  watchDeleteCollectionUser,
  watchCreateOrUpdateCollectionUser,
  watchAddCollectionAssets,
  watchAddCollectionAssetsFromFeed,
  watchAddCollectionAssetsFromStock,
  watchDeleteCollectionAsset,
  watchDeleteCollectionAssets,
  watchUpdateCollectionAsset,
  watchShowCreatePost,
  watchFilterAssets,
  watchChangeAssetPagination,
  watchReloadTags,
  watchApplyBulkTags,
  watchBulkMoveAssets,
  watchActionsToTrackByType,
  watchGetAllCollectionAssets,
  watchReloadAssetCurrentPage,
  watchLoadCollection,
  watchDuplicateAssets,
} from "./mediaLibrarySagas";

import { contentLabelSagas } from "./contentLabelSagas";

import { inboxSagas } from "./inboxSagas";
import { conversationInboxSagas } from "./conversationInboxSagas";

import { watchGetAccounts } from "./skedCoreSagas";
import sourceMediaSagas from "./sourceMediaSagas";
import postsSagas from "./postsSagas";
import collaborationSagas from "./collaborationSagas";
import tagTemplatesSagas from "./tagTemplatesSagas";
import { postSaga } from "features/post";
import { planerPageSaga } from "pages/planner/__redux-model";
import { deepLinkPostSaga } from "features/post-deeplink";
import { postFormSagas } from "./postFormSagas";

export default function* rootSaga() {
  yield all([
    // Media Library Settings
    watchInitMediaLibraryPage(),
    watchLoadCollection(),
    watchSelectCollection(),
    watchAddCollection(),
    watchDeleteCollection(),
    watchUpdateCollection(),
    watchDeleteCollectionUser(),
    watchCreateOrUpdateCollectionUser(),
    watchAddCollectionAssets(),
    watchAddCollectionAssetsFromFeed(),
    watchAddCollectionAssetsFromStock(),
    watchDeleteCollectionAsset(),
    watchDeleteCollectionAssets(),
    watchUpdateCollectionAsset(),
    watchGetAllCollectionAssets(),
    watchReloadAssetCurrentPage(),
    watchShowCreatePost(),
    watchFilterAssets(),
    watchChangeAssetPagination(),
    watchReloadTags(),
    watchApplyBulkTags(),
    watchGetAccounts(),
    watchBulkMoveAssets(),
    watchDuplicateAssets(),
    watchActionsToTrackByType(),
    ...inboxSagas,
    ...conversationInboxSagas,
    sourceMediaSagas(),
    postsSagas(),
    collaborationSagas(),
    tagTemplatesSagas(),
    // features
    postSaga(),
    deepLinkPostSaga(),
    // pages
    planerPageSaga(),
    postFormSagas(),
    // content labels
    ...contentLabelSagas,
  ]);
}
