import {
  GET_MESSAGES_ROUTINE,
  GET_CONVERSATIONS_ROUTINE,
  CREATE_MESSAGE_ROUTINE,
  ADD_MESSAGE_ROUTINE,
  REMOVE_MESSAGE_ROUTINE,
  UPDATE_STAR_FILTER_ROUTINE,
  PATCH_CONVERSATION_ROUTINE,
  SELECT_CONVERSATION_ACCOUNTS_ROUTINE,
  UPDATE_CONVERSATION_CONFIG_ROUTINE,
  DELETE_CONVERSATION_ROUTINE,
  GET_CONVERSATIONS_COUNTER_ROUTINE,
  GET_CONVERSATION_ROUTINE,
  ADD_COLLABORATOR_TYPING_ROUTINE,
  REMOVE_COLLABORATOR_TYPING_ROUTINE,
  RESYNC_INTERLOCUTOR,
  STAR_CONV_ROUTINE,
  ASSIGN_USER_CONV_ROUTINE,
  ADD_MESSAGE_REACTION_ROUTINE,
  REMOVE_MESSAGE_REACTION_ROUTINE,
  SET_MESSAGE_REACTION_PROCESSING,
  CLOSE_ALL_CONVERSATIONS_ROUTINE,
  SYNC_MESSAGES_ROUTINE,
  SET_CONVERSATION_TYPES_FILTER_ROUTINE,
  SELECT_CONVERSATIONS_ROUTINE,
  PATCH_CONVERSATIONS_ROUTINE,
  SET_COLLABORATOR_TYPINGS_ROUTINE,
  INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE,
  INBOX_SET_MESSAGE_HIDDEN_ROUTINE,
  RESYNC_POST_CONTENT,
  INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE,
  NOTIFY_INBOX_URL_CHANGE_ROUTINE,
  SELECT_ACCOUNTS_WITH_CONV_TYPES,
  DELETE_MESSAGE_STATUS_ROUTINE,
} from "constants/ActionTypes";
import { createAction } from "@reduxjs/toolkit";
import {
  Status,
  Conversation,
  SyncedMessage,
  Message,
  MetaReaction,
  Pagination,
  ConversationType,
  TypingCollaborator,
} from "types/conversationInbox";
import { patchConversationsProps } from "api/conversations";

/** note: this action is not used by any Reducer/Saga, it will just
 * notify Store there is Inbox Url change, so angular-ui-router will pick it up
 * Ticket: [22280]
 */
export const notifyInboxUrlChange = createAction(
  NOTIFY_INBOX_URL_CHANGE_ROUTINE.TRIGGER
);

export const fetchConversations = createAction(
  GET_CONVERSATIONS_ROUTINE.TRIGGER
);

export const fetchConversation = createAction(
  GET_CONVERSATION_ROUTINE.TRIGGER,
  function preapre(conversationId: string) {
    return {
      payload: conversationId,
    };
  }
);

export type FetchMessagesAction = {
  payload: {
    conversationId: string;
    next?: string;
    previous?: string;
  };
};
export type FetchMessagesSuccessAction = {
  type: string;
  payload: {
    conversationId: string;
    data: Pagination<Message>;
    setMessagesOnly?: boolean;
  };
};

export const fetchMessages = createAction(
  GET_MESSAGES_ROUTINE.TRIGGER,
  function prepare(conversationId: string, next?: string): FetchMessagesAction {
    return {
      payload: {
        conversationId,
        next,
      },
    };
  }
);

export type SyncMessagesAction = {
  payload: {
    conversationId: string;
  };
};

export const syncMessages = createAction(
  SYNC_MESSAGES_ROUTINE.TRIGGER,
  function prepare(conversationId: string): SyncMessagesAction {
    return {
      payload: {
        conversationId,
      },
    };
  }
);

export type CreateMessageAction = {
  payload: {
    conversationId: string;
    isInternal: boolean;
    text: string;
    closeConversationAction?: "remove-conversation" | "keep-conversation";
    replyToId: string | undefined;
    fields?: Partial<Message>;
  };
};

export type RetryCreateMessageAction = {
  payload: {
    conversationId: string;
    messageId: string;
    isInternal: boolean;
    messageText: string;
  };
};

export type ProcessCreateMessageAction = {
  payload: CreateMessageAction["payload"] & {
    message: Partial<Message>;
  };
};

export type AddMessageAction = {
  payload: SyncedMessage;
};

export type ReactionPayload = AddReactionPayload | RemoveReactionPayload;
export interface AddReactionPayload {
  action: "added";
  conversationId: string;
  id: string;
  tempId?: string;
  messageId: string;
  reactee: string;
  reaction: MetaReaction;
  emoji?: string;
}

export interface RemoveReactionPayload {
  action: "removed";
  conversationId: string;
  id: string;
  messageId: string;
  reactee: string;
}

export type addMessageReactionPayload = Omit<
  AddReactionPayload,
  "action" | "id"
>;
export const addMessageReaction = createAction<addMessageReactionPayload>(
  ADD_MESSAGE_REACTION_ROUTINE.TRIGGER
);

export type removeMessageReactionPayload = Omit<
  RemoveReactionPayload,
  "action"
>;
export const removeMessageReaction = createAction<removeMessageReactionPayload>(
  REMOVE_MESSAGE_REACTION_ROUTINE.TRIGGER
);

export const addMessageReactionSuccess = createAction<AddReactionPayload>(
  ADD_MESSAGE_REACTION_ROUTINE.SUCCESS
);
export const removeMessageReactionSuccess = createAction<RemoveReactionPayload>(
  REMOVE_MESSAGE_REACTION_ROUTINE.SUCCESS
);

export interface setMessageReactionProcessingPayload {
  conversationId: string;
  messageId: string;
  isProcessing: boolean;
}
export const setMessageReactionProcessing =
  createAction<setMessageReactionProcessingPayload>(
    SET_MESSAGE_REACTION_PROCESSING
  );

export const createMessage = createAction(
  CREATE_MESSAGE_ROUTINE.TRIGGER,
  function prepare({
    closeConversationAction,
    conversationId,
    isInternal,
    text,
    replyToId,
    fields,
  }: {
    conversationId: string;
    text: string;
    isInternal: boolean;
    closeConversationAction: CreateMessageAction["payload"]["closeConversationAction"];
    replyToId: string | undefined;
    fields: Partial<Message> | undefined;
  }): CreateMessageAction {
    return {
      payload: {
        conversationId,
        text,
        isInternal,
        closeConversationAction,
        replyToId,
        fields,
      },
    };
  }
);

export const retryCreateMessage = createAction(
  CREATE_MESSAGE_ROUTINE.RETRY,
  function prepare(
    conversationId: string,
    isInternal: boolean,
    messageId: string,
    messageText: string
  ): RetryCreateMessageAction {
    return {
      payload: {
        conversationId,
        isInternal,
        messageId,
        messageText,
      },
    };
  }
);

export const addMessage = createAction(
  ADD_MESSAGE_ROUTINE.TRIGGER,
  function prepare(message: SyncedMessage): AddMessageAction {
    return {
      payload: message,
    };
  }
);

export type DeleteMessageStatusAction = {
  payload: {
    conversationId: string;
    messageId: string;
  };
};

export const deleteMessageStatus = createAction(
  DELETE_MESSAGE_STATUS_ROUTINE.TRIGGER,
  function prepare(
    message: DeleteMessageStatusAction["payload"]
  ): DeleteMessageStatusAction {
    return {
      payload: message,
    };
  }
);

export type RemoveMessageAction = {
  payload: {
    conversationId: string;
    messageId: string;
  };
};

export const removeMessage = createAction(
  REMOVE_MESSAGE_ROUTINE.TRIGGER,
  function prepare(
    message: RemoveMessageAction["payload"]
  ): RemoveMessageAction {
    return {
      payload: message,
    };
  }
);

export type setStarAction = {
  payload: {
    isStarred: boolean;
  };
};

export const setStarFilter = createAction(
  UPDATE_STAR_FILTER_ROUTINE.TRIGGER,
  function prepare(isStarred: boolean): setStarAction {
    return { payload: { isStarred } };
  }
);

export interface starConvAction {
  payload: {
    conversationId: string;
    isStarred: boolean;
  };
}

export interface starConversationsAction {
  payload: {
    conversationId: string;
    isStarred: boolean;
  };
}

export const starConv = createAction(
  STAR_CONV_ROUTINE.TRIGGER,
  function prepare(payload: starConvAction["payload"]) {
    return { payload: payload };
  }
);

export interface assignUserConvAction {
  payload: {
    conversationId: string;
    assignedTo: string | null;
  };
}
export const assignUserConv = createAction(
  ASSIGN_USER_CONV_ROUTINE.TRIGGER,
  function prepare(payload: assignUserConvAction["payload"]) {
    return { payload: payload };
  }
);

export type patchConversationAction = {
  payload: {
    conversation?: Conversation;
    conversationId: string;
    conversationIdIndex?: number;
    status?: Status.open | Status.closed;
    isStarred?: boolean;
    isMentioned?: boolean;
    assignedTo?: string | null;
    unreadCount?: number;
  };
};

export const patchConversation = createAction(
  PATCH_CONVERSATION_ROUTINE.TRIGGER,
  function prepare(
    payload: patchConversationAction["payload"]
  ): patchConversationAction {
    return { payload };
  }
);

export type patchConversationsAction = {
  payload: patchConversationsProps;
};

export const patchConversations = createAction(
  PATCH_CONVERSATIONS_ROUTINE.TRIGGER,
  function prepare(
    payload: patchConversationsAction["payload"]
  ): patchConversationsAction {
    return { payload };
  }
);

export type resyncInterlocutorAction = {
  payload: {
    conversationId: string;
    interlocutorId: string;
  };
};

export const resyncInterlocutor = createAction(
  RESYNC_INTERLOCUTOR.TRIGGER,
  function prepare(payload): resyncInterlocutorAction {
    return { payload };
  }
);

export type SelectConversationAccountsAction = {
  payload: string[];
};

export const selectConversationAccounts = createAction(
  SELECT_CONVERSATION_ACCOUNTS_ROUTINE.TRIGGER,
  function prepare(
    payload: SelectConversationAccountsAction["payload"]
  ): SelectConversationAccountsAction {
    return { payload };
  }
);

export type updateConversationConfigAction = {
  payload: {
    isStarred?: boolean;
    assignedTo?: string | null;
    type?: string | null;
    status?: string | null;
    sort?: string | null;
  };
};

export const updateConversationConfig = createAction(
  UPDATE_CONVERSATION_CONFIG_ROUTINE.TRIGGER,
  function prepare(
    payload: updateConversationConfigAction["payload"]
  ): updateConversationConfigAction {
    return { payload };
  }
);

export type deleteConversationAction = {
  payload: string;
};

export const deleteConversation = createAction(
  DELETE_CONVERSATION_ROUTINE.TRIGGER,
  function prepare(
    payload: deleteConversationAction["payload"]
  ): deleteConversationAction {
    return { payload };
  }
);

export const getConversationsCounter = createAction(
  GET_CONVERSATIONS_COUNTER_ROUTINE.TRIGGER
);

export type collaboratorTypingAction = {
  payload: Omit<TypingCollaborator, "createdAt"> & { enabled: boolean };
};

export const addCollaboratorTyping = createAction(
  ADD_COLLABORATOR_TYPING_ROUTINE.TRIGGER,
  function preapre(
    payload: collaboratorTypingAction["payload"]
  ): collaboratorTypingAction {
    return { payload };
  }
);

export const removeCollaboratorTyping = createAction(
  REMOVE_COLLABORATOR_TYPING_ROUTINE.TRIGGER,
  function preapre(
    payload: collaboratorTypingAction["payload"]
  ): collaboratorTypingAction {
    return { payload };
  }
);

export const setCollaboratorTypingsSuccess = createAction(
  SET_COLLABORATOR_TYPINGS_ROUTINE.SUCCESS,
  function preapre(payload: TypingCollaborator[]): {
    payload: TypingCollaborator[];
  } {
    return { payload };
  }
);

export interface closeAllConversationsPayload {
  accounts: string[];
}
export const closeAllConversations = createAction<closeAllConversationsPayload>(
  CLOSE_ALL_CONVERSATIONS_ROUTINE.TRIGGER
);

export interface setConversationTypesFilterAction {
  payload: {
    types: ConversationType[];
  };
}

export const setConversationTypesFilter = createAction<
  setConversationTypesFilterAction["payload"]
>(SET_CONVERSATION_TYPES_FILTER_ROUTINE.TRIGGER);

export interface selectConversationsPayload {
  conversationIds: string[];
  selected?: boolean;
}
export const selectConversations = createAction<selectConversationsPayload>(
  SELECT_CONVERSATIONS_ROUTINE.TRIGGER
);

export interface addMessageSuccessAction {
  type: string;
  payload: {
    conversationId: string;
    message: Message;
    ignoreIncreaseUnreadCount: boolean;
  };
}

export interface setMessageStatusRemovedAction {
  payload: {
    conversationId: string;
    id: string;
  };
}
export interface setMessageStatusEditingAction {
  payload: {
    message: Message;
    action: "enable" | "disable";
  };
}
export const setMessageStatusRemoved = createAction<
  setMessageStatusRemovedAction["payload"]
>(INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE.TRIGGER);
export interface setMessageHiddenAction {
  payload: {
    conversationId: string;
    id: string;
    hidden: boolean;
  };
}
export const setMessageHidden = createAction<setMessageHiddenAction["payload"]>(
  INBOX_SET_MESSAGE_HIDDEN_ROUTINE.TRIGGER
);
export const setMessageStatusEditing = createAction<
  setMessageStatusEditingAction["payload"]
>(INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE.TRIGGER);

export interface resyncPostContentAction {
  payload: {
    postId: string;
    conversationId: string;
  };
}
export const resyncPostContent = createAction<
  resyncPostContentAction["payload"]
>(RESYNC_POST_CONTENT.TRIGGER);

export interface selectAccountWithConvTypeAction {
  payload: {
    accountIds: string[];
    types: ConversationType[];
  };
}

export const selectAccountsWithConvTypes = createAction<
  selectAccountWithConvTypeAction["payload"]
>(SELECT_ACCOUNTS_WITH_CONV_TYPES.TRIGGER);
