import { updateUserPricingPlan } from "actions/user-pricing-plan";
import type { User } from "features/user";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  planBaseMaxAccounts,
  possibleSkedPlanCodeNames,
} from "./constants/plans";
import {
  BasePlanType,
  PlanPeriodUnit,
  SkedPlanCodeName,
  UpsellFeatureType,
  UpsellPlansType,
  UpsellPlanType,
} from "./types";
import ngDeps from "ng-react-directives/ngr-injector";
import { currentUserChanged } from "entities/current-user";
import { getUser } from "selectors/commonSelectors";
import {
  UPGRADE_APPROVAL_VIEWED,
  UPGRADE_COMPARISON_VIEWED,
  UPGRADE_COMPETITOR_VIEWED,
  UPGRADE_EXCEL_REPORTS_VIEWED,
  UPGRADE_FRONTIFY_VIEWED,
  UPGRADE_LABELS_PLANNING_VIEWED,
  UPGRADE_LABELS_REPORTING,
  UPGRADE_LIBRARY_VIEWED,
  UPGRADE_PAID_INSIGHTS_VIEWED,
  UPGRADE_PDF_REPORT_VIEWED,
  UPGRADE_REPORT_BUILDER_VIEWED,
  UPGRADE_TEAMWORK_VIEWED,
  UPGRADE_USERS_VIEWED,
} from "constants/Events";
import { setAccountsCounts } from "actions/skedCore";
import { socialAccountConnectEffectorRefs } from "features/social-account-connect/components/social-account-connect/model-ref";
import { isCollaborationEnabledForPlan } from "libs/account-permission-check";

export const mergeSortPlans = (plans: BasePlanType[]) => {
  const plansByPeriod = plans.reduce(
    (acum: UpsellPlansType, plan: BasePlanType) => {
      const skedPlan = plan.id.replace("-annual", "") as SkedPlanCodeName;

      if (!possibleSkedPlanCodeNames.includes(skedPlan)) {
        return acum;
      }

      const planObj: UpsellPlanType = {
        ...plan,
        planCodeName: skedPlan,
        planName: getPlanDisplayName(skedPlan),
        includedAccounts: plan.includedAccounts ?? 1,
      };

      if (planObj.period_unit === "month") {
        acum.month.push(planObj);
      } else {
        acum.year.push(planObj);
      }

      return acum;
    },
    { month: [], year: [] }
  );

  return plansByPeriod;
};

export const isUserPlan = (
  user: User | undefined,
  ...plans: SkedPlanCodeName[]
) => {
  return plans.some((plan) => user?.plan?.includes(plan));
};

export const isLegacyPlan = (user: User | undefined) => {
  return !user?.pricingVersion?.includes("2024");
};

export const getPlanDisplayName = (planCodeName: SkedPlanCodeName) => {
  return _.capitalize(planCodeName.replace("sked-", ""));
};
export const getPlanPeriodName = (period: PlanPeriodUnit) => {
  return period === "month" ? "Monthly" : "Yearly";
};

export const getDisplayFeatureName = (feature: UpsellFeatureType | "none") => {
  switch (feature) {
    case "approvals": {
      return "Content approvals workflow";
    }
    case "labels-planning": {
      return "Content pillar and campaign planning";
    }
    case "standard-pdf": {
      return "Standard PDF report";
    }
    case "competitor-analytics": {
      return "Competitor tracking and insights";
    }
    case "paid-ads": {
      return "Meta ads reporting";
    }
    case "excel": {
      return "Excel reports";
    }
    case "comparison-period": {
      return "Comparison periods";
    }
    case "labels-reporting": {
      return "Content pillar and campaign insights";
    }
    case "teamwork": {
      return "Teamwork";
    }
    case "user-generated-content": {
      return "User Generated Content";
    }
    case "add-account-user": {
      return "Add accounts & Add users";
    }
    case "ai-analytics": {
      return "AI Analytics";
    }
  }
  return "new features";
};

export const findPlanByPlanCodeName = (
  plans: UpsellPlansType,
  period: PlanPeriodUnit,
  name: SkedPlanCodeName
) => {
  const currentPlans = period === "month" ? plans.month : plans.year;
  return currentPlans.find((plan) => plan.planCodeName === name);
};

export const comparePlan = (
  planA: SkedPlanCodeName,
  planB: SkedPlanCodeName
) => {
  if (planA === planB) {
    return "current";
  }
  if (planIndexes[planA] <= planIndexes[planB]) {
    return "below";
  }
  return "above";
};

const planIndexes = {
  "sked-fundamentals": 0,
  "sked-essentials": 1,
  "sked-professional": 2,
  "sked-enterprise": 3,
} as const;

export const useUpdateUserPlan = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser) as User;
  /** need to update plan for Insights */
  return {
    updateUserPlanContext: ({
      numberOfAccounts,
      toPlan,
      subscription,
    }: {
      numberOfAccounts: number;
      toPlan: SkedPlanCodeName;
      subscription: User["subscription"];
    }) => {
      const isCollaborationEnabled =
        user.isCollaborationEnabled || isCollaborationEnabledForPlan(user.plan);
      /** notify effector, must notify effector before redux store :|  */
      currentUserChanged({
        ...user,
        plan: toPlan,
        subscription: subscription,
        isCollaborationEnabled,
      });
      /** notify angular */
      const { $rootScope } = ngDeps as unknown as {
        $rootScope: {
          user: User;
        };
      };
      if ($rootScope?.user && $rootScope.user.plan !== toPlan) {
        $rootScope.user = {
          ...$rootScope.user,
          plan: toPlan,
          subscription: subscription,
          isCollaborationEnabled,
        };
      }

      const basePlanAccountCount = planBaseMaxAccounts[toPlan] || 0;
      numberOfAccounts = Math.max(numberOfAccounts, basePlanAccountCount);
      console.log("updateMaxAllowed", basePlanAccountCount, numberOfAccounts);

      socialAccountConnectEffectorRefs.updateMaxAllowed?.(numberOfAccounts);

      /** calling in setTimeout, otherwise redux will clash with effector */
      setTimeout(() => {
        dispatch(updateUserPricingPlan(toPlan, numberOfAccounts, subscription));
        dispatch(
          setAccountsCounts({
            maxAllowed: numberOfAccounts,
          })
        );
        notifyPlanChangeToInsights(toPlan);
      }, 10);
    },
  };
};
export const notifyPlanChangeToInsights = (toPlan: SkedPlanCodeName) => {
  const insightsElement = document.querySelector(
    "#insights-iframe"
  ) as HTMLIFrameElement;
  if (insightsElement) {
    insightsElement.contentWindow?.postMessage(
      JSON.stringify({ type: "update-user-plan", plan: toPlan }),
      "*"
    );
  }
};

export const getTrackingEventByFeature = (feature: UpsellFeatureType) => {
  switch (feature) {
    case "approvals": {
      return UPGRADE_APPROVAL_VIEWED;
    }
    case "labels-planning": {
      return UPGRADE_LABELS_PLANNING_VIEWED;
    }
    case "standard-pdf": {
      return UPGRADE_PDF_REPORT_VIEWED;
    }
    case "competitor-analytics": {
      return UPGRADE_COMPETITOR_VIEWED;
    }
    case "paid-ads": {
      return UPGRADE_PAID_INSIGHTS_VIEWED;
    }
    case "excel": {
      return UPGRADE_EXCEL_REPORTS_VIEWED;
    }
    case "comparison-period": {
      return UPGRADE_COMPARISON_VIEWED;
    }
    case "labels-reporting": {
      return UPGRADE_LABELS_REPORTING;
    }
    case "teamwork": {
      return UPGRADE_TEAMWORK_VIEWED;
    }
    case "user-generated-content": {
      return UPGRADE_LIBRARY_VIEWED;
    }
    case "add-account-user": {
      return UPGRADE_USERS_VIEWED;
    }
    case "frontify-media": {
      return UPGRADE_FRONTIFY_VIEWED;
    }
    case "report-builder-enterprise":
    case "report-builder-professional": {
      return UPGRADE_REPORT_BUILDER_VIEWED;
    }
  }
  return "feature-upsell-viewed";
};
