import React from "react";
import styled from "styled-components";
import MultiPostSVG from "./icons/multi-post-indicator.svg";
import NotificationSVG from "./icons/notification.svg";
import IgStorySVG from "./icons/ig-story.svg";
import { aspectRatio } from "libs/styled-components-aspect-ratio";

const Icon = styled.div`
  width: 100%;
  height: 100%;
`;

export const MultiPostIcon = styled((props) => (
  <div className={props.className}>
    <Icon as={MultiPostSVG} />
  </div>
))`
  width: 10px;
  ${aspectRatio(1)};
`;

export const NotificationIcon = styled((props) => (
  <div className={props.className}>
    <Icon as={NotificationSVG} />
  </div>
))`
  width: 10px;
  ${aspectRatio(10 / 9)};
`;

export const IgStoryIcon = styled((props) => (
  <div className={props.className}>
    <Icon as={IgStorySVG} />
  </div>
))`
  width: 10px;
  ${aspectRatio(1)};
`;

export const IgRealIcon = styled.div`
  &:before {
    font-family: "Sked-React" !important;
    content: "\\e901";
  }
`;

export const IgIGTVIcon = styled.div`
  &:before {
    font-family: "Sked-React" !important;
    content: "\\e900";
  }
`;

export const PostTypeIcon = styled((props) => {
  const { post, className } = props;
  const IconComponent = (() => {
    switch (true) {
      case post.type === "multiMedia":
        return MultiPostIcon;
      case post.type === "story" &&
        (!post.storyType || post.storyType === "story"):
        return IgStoryIcon;
      case post.type === "story" && post.storyType === "reel":
        return IgRealIcon;
      case post.type === "story" && post.storyType === "igtv":
        return IgIGTVIcon;
      default:
        return null;
    }
  })();

  return IconComponent ? <IconComponent className={className} /> : null;
})``;
