import { User } from "features/user";

export const entitlementsMappings = {
  accounts: "accounts",
  "meta-ads": "meta-ads",
  "meta-ads-ai": "meta-ads-ai",
  "meta-ads-campaign": "meta-ads-campaign",
  "reports-full": "reports-full",
  "listening-keyword": "listening-keyword",
  "listening-competitors": "listening-competitors",
  "listening-sentiment": "listening-sentiment",
  "listening-audience": "listening-audience",
};

class EntitlementsPermission {
  hasEntitlements(entitlements: string[]): boolean {
    return entitlements.every((entitlement) =>
      Object.keys(entitlementsMappings).includes(entitlement)
    );
  }

  hasSocialListeningKeyword = (user: User) => {
    return user.preferences?.entitlements?.["listening-keyword"] === "true";
  };

  hasSocialListeningCompetitors = (user: User) => {
    return (
      Number(user.preferences?.entitlements?.["listening-competitors"]) > 0
    );
  };

  hasSocialListeningSentiment = (user: User) => {
    return user.preferences?.entitlements?.["listening-sentiment"] === "true";
  };

  hasSocialListeningAudience = (user: User) => {
    return user.preferences?.entitlements?.["listening-audience"] === "true";
  };

  hasSocialListening = (user: User) => {
    return (
      this.hasSocialListeningKeyword(user) ||
      this.hasSocialListeningAudience(user) ||
      this.hasSocialListeningSentiment(user) ||
      this.hasSocialListeningCompetitors(user)
    );
  };
}

export const entitlementsPermission = new EntitlementsPermission();
