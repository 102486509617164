import { CollectionFile } from "shared/types/files";
import { PostMediaFile } from "shared/types/post";

export function convertCollectionFileToPostMediaFile(
  collectionFile: CollectionFile
): PostMediaFile {
  // delete icc if it is present in the meta object
  // otherwise we can get 413 error when creating posts
  // because the meta object is too large - and icc is not
  // used by our backend. icc is usually a giant blob of binary
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { icc, ...meta } = collectionFile.meta;

  return {
    _id: collectionFile._id,
    thumbnailUrl: collectionFile.thumbnail,
    originalUrl: collectionFile.downloadUrl,
    url: collectionFile.storage.url,
    isVideo: collectionFile.meta.isVideo,
    fileName: collectionFile.name,
    type: collectionFile.meta.isVideo
      ? "video"
      : collectionFile.meta.isDocument
      ? "document"
      : "image",
    meta: meta,
    description: collectionFile.description,
    asset_id: collectionFile.asset_id,
  };
}
