import React, { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "../../../../CustomOverlayTrigger/CustomOverlayTrigger";
import { FormControl, SkedButton, Link } from "ui";
import { ExternalLink } from "ui/Link";
import { SELECT_NEW_HASHTAG_ROUTINE } from "constants/ActionTypes";
import { WEEKLY_HASHTAG_SEARCH_MAX } from "constants/MediaLibrary";
import { useSelector, useDispatch } from "react-redux";
import { getRoutinesLoading } from "selectors/commonSelectors";
import { showConfirmModal } from "actions/modal";
import { searchNewHashtag } from "actions/sourceMedia";
import { FormSize, FormThemeProvider } from "ui/ecosystems/forms";
import { TextInput } from "ui/ecosystems/forms/inputs";

const AddHashtag = ({ account, hashtags }) => {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [newHashtag, setNewHashtag] = useState("");
  const [error, setError] = useState("");
  const addingHashtag = useSelector((state) =>
    getRoutinesLoading(state, [SELECT_NEW_HASHTAG_ROUTINE])
  );
  const maxHashtagsTooltipJsx = (
    <Tooltip id="collection-delete">
      Instagram only allows 30 hashtags to be searched within a 7 day period,
      please wait to select additional hashtags or choose another Instagram
      account.
    </Tooltip>
  );

  if (hashtags.length >= WEEKLY_HASHTAG_SEARCH_MAX) {
    return (
      <div>
        <OverlayTrigger placement="top" overlay={maxHashtagsTooltipJsx}>
          <div className="tw-text-gray-400 tw-cursor-pointer tw-mb-4">
            {" "}
            + Add Hashtag
          </div>
        </OverlayTrigger>
      </div>
    );
  }

  const showNewHashtagConfirmModal = () => {
    setError("");

    let hashtag = newHashtag;

    if (hashtag.length === 0) {
      return;
    }

    // If they add the # in front let's remove it and let them proceed.
    if (hashtag[0] === "#") {
      hashtag = hashtag.substring(1);
    }

    const atWeeklyMax = hashtags.length === WEEKLY_HASHTAG_SEARCH_MAX;

    const confirmMessage = (
      <div>
        <div className="tw-mb-8">
          Instagram limits users to 30 hashtags in a rolling 7 day period, so
          please check that it&apos;s the right tag. You can{" "}
          <ExternalLink
            href={`https://www.instagram.com/explore/tags/${hashtag}/`}
            type="primary"
          >
            {" "}
            preview #{hashtag} on Instagram
          </ExternalLink>
        </div>
        {atWeeklyMax && (
          <div className="tw-text-red-500">
            You have reached Instagram&apos;s weekly max of{" "}
            {WEEKLY_HASHTAG_SEARCH_MAX} searches. You will need to wait for the
            next active hashtag to expire before you can activate this hashtag.
          </div>
        )}
        {!atWeeklyMax && (
          <div>
            Add{" "}
            <span className="tw-font-black tw-text-purple-400">#{hashtag}</span>{" "}
            to reactivate hashtag search for 7 days?
          </div>
        )}
      </div>
    );

    dispatch(
      showConfirmModal({
        title: "Add Active Hashtag",
        messageJsx: confirmMessage,
        confirmType: "primary",
        confirmText: `Add #${hashtag}`,
        handleConfirm: () => {
          dispatch(searchNewHashtag(account._id, hashtag.toLowerCase()));
          setNewHashtag("");
          setIsAdding(false);
        },
        disabled: atWeeklyMax,
        loadingRoutines: [SELECT_NEW_HASHTAG_ROUTINE],
      })
    );
  };

  return (
    <>
      <div className="tw-text-purple-400 tw-cursor-pointer tw-mb-4">
        <div
          onClick={() => {
            if (isAdding) {
              setNewHashtag("");
            }
            setIsAdding(!isAdding);
          }}
        >
          {!isAdding ? "+" : "-"} Add Hashtag
        </div>
      </div>
      {isAdding && (
        <FormThemeProvider size={FormSize.large}>
          <div className="tw-mb-4">
            <TextInput
              autoFocus
              className="tw-w-full"
              value={newHashtag}
              placeholder="Search for hashtag..."
              maxLength={50}
              onChange={(value) => setNewHashtag(value.trim())}
            />
          </div>
          {error && <div className="tw-text-red-400 tw-mb-4">{error}</div>}
          <div className="tw-text-right">
            <SkedButton
              name="Add Hashtag"
              type="primary"
              size="small"
              disabled={addingHashtag}
              onClick={showNewHashtagConfirmModal}
            />
          </div>
        </FormThemeProvider>
      )}
    </>
  );
};

AddHashtag.propTypes = {
  account: PropTypes.object,
  hashtags: PropTypes.array,
};

export default AddHashtag;
