//import contains from 'dom-helpers/query/contains';
import React, { cloneElement, PropsWithChildren } from "react";
import Overlay from "react-bootstrap/lib/Overlay";
import PortalWrapper from "./PortalWrapper";

function createChainedFunction(...funcs) {
  return funcs
    .filter((f) => f != null)
    .reduce((acc, f) => {
      if (typeof f !== "function") {
        throw new Error(
          "Invalid Argument Type, must only provide functions, undefined, or null."
        );
      }

      if (acc === null) {
        return f;
      }

      return function chainedFunction(...args) {
        acc.apply(this, args);
        f.apply(this, args);
      };
    }, null);
}

/**
 * Check if value one is inside or equal to the of value
 *
 * @param {string} one
 * @param {string|array} of
 * @returns {boolean}
 */
function isOneOf(one, of) {
  if (Array.isArray(of)) {
    return of.indexOf(one) >= 0;
  }
  return one === of;
}

class OverlayTrigger extends React.Component<
  {
    defaultOverlayShown?: boolean;
    trigger?: string[];
    placement: string;
    overlay: React.JSX.Element;
    delayHide?: number;
  } & PropsWithChildren
> {
  constructor(
    props = {
      defaultOverlayShown: false,
      trigger: ["hover", "focus"],
    },
    context
  ) {
    super(props, context);

    this.handleToggle = this.handleToggle.bind(this);
    this.handleDelayedShow = this.handleDelayedShow.bind(this);
    this.handleDelayedHide = this.handleDelayedHide.bind(this);
    this.handleHide = this.handleHide.bind(this);

    this.handleMouseOver = (e) => {
      this.handleMouseOverOut(this.handleDelayedShow, e, "toElement");
    };

    this.handleMouseOut = (e) =>
      this.handleMouseOverOut(this.handleDelayedHide, e, "toElement");

    //this._mountNode = null;

    this.state = {
      show: props.defaultOverlayShown,
    };
  }

  //   componentDidMount() {
  //     // this._mountNode = document.createElement("div");
  //     this.renderOverlay();
  //   }

  //   componentDidUpdate() {
  //     this.renderOverlay();
  //   }

  //   componentWillUnmount() {
  //     // ReactDOM.unmountComponentAtNode(this._mountNode);
  //     // this._mountNode = null;

  //     clearTimeout(this._hoverShowDelay);
  //     clearTimeout(this._hoverHideDelay);
  //   }

  handleDelayedHide() {
    if (this._hoverShowDelay != null) {
      clearTimeout(this._hoverShowDelay);
      this._hoverShowDelay = null;
      return;
    }

    if (!this.state.show || this._hoverHideDelay != null) {
      return;
    }

    const delay =
      this.props.delayHide != null ? this.props.delayHide : this.props.delay;

    if (!delay) {
      this.hide();
      return;
    }

    this._hoverHideDelay = setTimeout(() => {
      this._hoverHideDelay = null;
      this.hide();
    }, delay);
  }

  handleDelayedShow() {
    if (this._hoverHideDelay != null) {
      clearTimeout(this._hoverHideDelay);
      this._hoverHideDelay = null;
      return;
    }

    if (this.state.show || this._hoverShowDelay != null) {
      return;
    }

    const delay =
      this.props.delayShow != null ? this.props.delayShow : this.props.delay;

    if (!delay) {
      this.show();
      return;
    }

    this._hoverShowDelay = setTimeout(() => {
      this._hoverShowDelay = null;
      this.show();
    }, delay);
  }

  handleHide() {
    this.hide();
  }

  // Simple implementation of mouseEnter and mouseLeave.
  // React's built version is broken: https://github.com/facebook/react/issues/4251
  // for cases when the trigger is disabled and mouseOut/Over can cause flicker
  // moving from one child element to another.
  handleMouseOverOut(handler, e, relatedNative) {
    const target = e.currentTarget;
    const related = e.relatedTarget || e.nativeEvent[relatedNative];

    //    if ((!related || related !== target) && !contains(target, related)) {
    if ((!related || related !== target) && !target.contains(related)) {
      handler(e);
    }
  }

  handleToggle() {
    if (this.state.show) {
      this.hide();
    } else {
      this.show();
    }
  }

  hide() {
    this.setState({ show: false });
  }

  makeOverlay(overlay, props) {
    return (
      <PortalWrapper>
        <Overlay
          {...props}
          show={this.state.show}
          onHide={this.handleHide}
          target={this}
        >
          {overlay}
        </Overlay>
      </PortalWrapper>
    );
  }

  show() {
    this.setState({ show: true });
  }

  //   renderOverlay() {
  //     return (
  //       <>
  //         {cloneElement(child, triggerProps)}
  //         {this.makeOverlay(overlay, props)}
  //       </>
  //     );
  // ReactDOM.unstable_renderSubtreeIntoContainer(
  //   this,
  //   this._overlay,
  //   this._mountNode
  // );
  //}

  render() {
    const {
      trigger = ["hover", "focus"],
      overlay,
      children,
      onBlur,
      onClick,
      onFocus,
      onMouseOut,
      onMouseOver,
      ...props
    } = this.props;

    delete props.delay;
    delete props.delayShow;
    delete props.delayHide;
    delete props.defaultOverlayShown;

    const child = React.Children.only(children);
    const childProps = child.props;
    const triggerProps = {};

    if (this.state.show) {
      triggerProps["aria-describedby"] = overlay.props.id;
    }

    // FIXME: The logic here for passing through handlers on this component is
    // inconsistent. We shouldn't be passing any of these props through.

    triggerProps.onClick = createChainedFunction(childProps.onClick, onClick);

    if (isOneOf("click", trigger)) {
      triggerProps.onClick = createChainedFunction(
        triggerProps.onClick,
        this.handleToggle
      );
    }

    if (isOneOf("hover", trigger)) {
      triggerProps.onMouseOver = createChainedFunction(
        childProps.onMouseOver, // wrapped component
        onMouseOver, // this component on mouse over
        this.handleMouseOver
      );
      triggerProps.onMouseOut = createChainedFunction(
        childProps.onMouseOut,
        onMouseOut,
        this.handleMouseOut
      );
    }

    if (isOneOf("focus", trigger)) {
      triggerProps.onFocus = createChainedFunction(
        childProps.onFocus,
        onFocus,
        this.handleDelayedShow
      );
      triggerProps.onBlur = createChainedFunction(
        childProps.onBlur,
        onBlur,
        this.handleDelayedHide
      );
    }

    // this._overlay = this.makeOverlay(overlay, props);

    return (
      <>
        {cloneElement(child, triggerProps)}
        {this.makeOverlay(overlay, props)}
      </>
    );
  }
}

export default OverlayTrigger;
