import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

import { getHolidaysCalendars } from "selectors/calendarFtilterSelectors";
import { setLocalStorageSelectedCalendarsIds } from "libs/storage/adapters";
import { useTrackEvent } from "libs/analytics";
import { Button as UiButton, FormSize } from "ui/ecosystems/forms";

import CheckIcon from "assets/icons/check.svg";
import { ImportHoliday } from "./ImportHoliday";
import {
  deleteCountryHolidays,
  calendarFilterActions,
} from "../CalendarFilterModel";
import { NEW_CAL_FILTERS_EVENTS } from "constants/Events";

export const CalendarEvents: React.FC = () => {
  const dispatch = useDispatch();
  const { toggleCountryHolidays } = calendarFilterActions;
  const holidaysCalendars = useSelector(getHolidaysCalendars);
  const [showHolidayModal, setShowHolidayModal] = useState<boolean>(false);
  const [fromUrl, setFromUrl] = useState<boolean>(false);
  const trackEvent = useTrackEvent();

  useEffect(() => {
    setLocalStorageSelectedCalendarsIds(
      JSON.stringify(
        holidaysCalendars
          .filter((holidayCalendar) => holidayCalendar.checked)
          .map((holidayCalendar) => holidayCalendar._id)
      )
    );
    if (holidaysCalendars?.length) {
      trackEvent({
        eventName: NEW_CAL_FILTERS_EVENTS,
        metadata: {
          count: holidaysCalendars?.length,
        },
        useServices: ["AMP"],
      });
    }
  }, [holidaysCalendars]);

  const handleAddHolidays = () => {
    setFromUrl(false);
    setShowHolidayModal(true);
  };

  const handleAddViaUrl = () => {
    setFromUrl(true);
    setShowHolidayModal(true);
  };

  const handleRemoveCountry = (countryId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(deleteCountryHolidays(countryId));
  };

  const toggleCheckbox = (countryId: string) => {
    dispatch(toggleCountryHolidays(countryId));
  };

  return (
    <>
      <Events>
        <span>
          Show custom holiday or event markers by adding a calendar below.
        </span>
        <Actions>
          <AddHolidaysButton
            size={FormSize.small}
            primary
            onClick={handleAddHolidays}
          >
            Add holidays
          </AddHolidaysButton>
          <AddViaUrlButton
            size={FormSize.medium}
            text
            onClick={handleAddViaUrl}
          >
            or add via URL
          </AddViaUrlButton>
        </Actions>
      </Events>

      <HolidayFilter>
        <h4>Holiday Filters</h4>
        <ul>
          {holidaysCalendars.map((country) => (
            <li key={country._id} onClick={() => toggleCheckbox(country._id)}>
              <CounryCheckbox>
                {country.checked && <CheckMark />}
              </CounryCheckbox>
              {country.name}
              <RemoveCountry
                onClick={(event) => handleRemoveCountry(country._id, event)}
              >
                &times;
              </RemoveCountry>
            </li>
          ))}
        </ul>
      </HolidayFilter>

      {showHolidayModal && (
        <ImportHoliday
          fromUrl={fromUrl}
          setFromUrl={setFromUrl}
          setShowHolidayModal={setShowHolidayModal}
        />
      )}
    </>
  );
};

const Events = styled.div`
  min-height: 130px;
  padding: 10px 2rem 28px;
  border-bottom: 1px solid ${colors.neutral100};
  display: flex;
  flex-flow: column nowrap;

  & div > {
    display: flex;
    margin-top: auto;
    flex-wrap: wrap;
  }
`;

const AddViaUrlButton = styled(UiButton)`
  border: none;
  font-size: 15px;
  color: ${colors.primary500};
  text-decoration: none;
`;

const AddHolidaysButton = styled(UiButton)`
  color: ${colors.primary500};
  font-size: 15px;
  font-weight: 400;
  line-height: 34px;
  height: 34px;
  padding: 0 20px;
  background: transparent;
  &:not(:hover):active {
    color: ${colors.primary500};
  }
  &:hover {
    color: ${colors.white};
    background: ${colors.primary500};
  }
`;

const HolidayFilter = styled.div`
  padding: 0 1rem 0 2rem;

  & h4 {
    color: ${colors.neutral600};
    font-size: 1.4rem;
    font-weight: 300;
  }

  & ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column nowrap;
    list-style-type: none;
  }

  & li {
    position: relative;
    height: 2.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    & span:last-child {
      display: none;
    }

    &:hover {
      background-color: ${colors.neutral50};

      & span:last-child {
        display: inline-block;
      }
    }
  }
`;

const CounryCheckbox = styled.span`
  display: flex;
  width: 14px;
  height: 14px;
  background-color: ${colors.neutral400};
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`;

const CheckMark = styled(CheckIcon)`
  width: 12px;
  height: 12px;
  fill: ${colors.white};
`;

const RemoveCountry = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  font-size: 2.5rem;
  cursor: pointer;
`;
const Actions = styled.div`
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
  gap: 10px;
`;
