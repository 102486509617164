import { FIELDS } from "constants/PostFormFields";
import { POST_TYPES } from "constants/PostsView";

export const RULES = {
  LENGTH: "CHARACTER_LENGTH",
  HASHTAG_COUNT: "HASHTAG_COUNT",
  MENTION_COUNT: "MENTION_COUNT",
  SUPPORTED_POST_TYPE: "SUPPORTED_POST_TYPE",
  REQUIRED: "REQUIRED",
};

export const postPlatformsConfig = {
  IG: {
    fields: [
      FIELDS.CAPTION,
      FIELDS.FIRST_COMMENT,
      FIELDS.URL,
      FIELDS.LOCATION_TAG,
      FIELDS.USER_TAGS,
      FIELDS.PRODUCT_TAGS,
      FIELDS.STORY_TYPE,
      FIELDS.STORY_URL,
      FIELDS.STORY_LABEL,
      FIELDS.STORY_STICKER_HEIGHT,
      FIELDS.ALT_TEXT,
      FIELDS.COLLABORATOR_TAG,
    ],
    validation: {
      error: {
        [FIELDS.TYPE]: {
          [RULES.SUPPORTED_POST_TYPE]: [
            POST_TYPES.IMAGE,
            POST_TYPES.VIDEO,
            POST_TYPES.MULTI_MEDIA,
            POST_TYPES.STORY,
          ],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 2200],
          [RULES.HASHTAG_COUNT]: [0, 30],
        },
        [FIELDS.FIRST_COMMENT]: {
          [RULES.LENGTH]: [0, 2200],
          [RULES.HASHTAG_COUNT]: [0, 30],
        },
        [FIELDS.MEDIA]: {
          [RULES.LENGTH]: [0, 10],
        },
      },
      warn: {},
      types: [],
    },
  },
  TT: {
    fields: [
      FIELDS.CAPTION,
      FIELDS.URL,
      FIELDS.POST_IS_NOTIFICATION_ONLY,
      FIELDS.TT_ALLOW_COMMENTS,
      FIELDS.TT_ALLOW_STITCH,
      FIELDS.TT_ALLOW_DUET,
      FIELDS.TT_TITLE,
      FIELDS.TT_PRIVACY_LEVEL,
      FIELDS.TT_AUTO_ADD_MUSIC,
      FIELDS.TT_PHOTO_COVER_INDEX,
    ],
    validation: {
      error: {
        [FIELDS.TYPE]: {
          [RULES.SUPPORTED_POST_TYPE]: [
            POST_TYPES.VIDEO,
            POST_TYPES.IMAGE,
            POST_TYPES.MULTI_MEDIA,
          ],
        },
        [FIELDS.MEDIA]: {
          [RULES.LENGTH]: [0, 35],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 2200],
        },
        [FIELDS.TT_TITLE]: {
          [RULES.LENGTH]: [
            0,
            90,
            "Tiktok: Title - 90 characters are supported.",
          ],
        },
      },
      warn: {},
      types: [],
    },
  },
  SC: {
    fields: [FIELDS.CAPTION, FIELDS.URL, FIELDS.ALT_TEXT],
    validation: {
      error: {
        [FIELDS.TYPE]: {
          [RULES.SUPPORTED_POST_TYPE]: [
            POST_TYPES.IMAGE,
            POST_TYPES.VIDEO,
            POST_TYPES.MULTI_MEDIA,
            POST_TYPES.STORY,
            POST_TYPES.TEXT,
          ],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 10000],
        },
        [FIELDS.MEDIA]: {
          [RULES.LENGTH]: [0, 20],
        },
      },
      warn: {},
      types: [],
    },
  },
  TH: {
    fields: [
      FIELDS.CAPTION,
      FIELDS.URL,
      FIELDS.TH_REPLY_CONTROL,
      FIELDS.POST_IS_NOTIFICATION_ONLY,
    ],
    validation: {
      error: {
        [FIELDS.TYPE]: {
          [RULES.SUPPORTED_POST_TYPE]: [
            POST_TYPES.IMAGE,
            POST_TYPES.VIDEO,
            POST_TYPES.MULTI_MEDIA,
            POST_TYPES.TEXT,
          ],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 500],
        },
        [FIELDS.MEDIA]: {
          [RULES.LENGTH]: [0, 10],
        },
      },
      warn: {},
      types: [],
    },
  },
  OT: {
    fields: [FIELDS.CAPTION, FIELDS.URL, FIELDS.ALT_TEXT],
    validation: {
      error: {
        [FIELDS.TYPE]: {
          [RULES.SUPPORTED_POST_TYPE]: [
            POST_TYPES.IMAGE,
            POST_TYPES.VIDEO,
            POST_TYPES.MULTI_MEDIA,
            POST_TYPES.STORY,
            POST_TYPES.TEXT,
          ],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 10000],
        },
        [FIELDS.MEDIA]: {
          [RULES.LENGTH]: [0, 20],
        },
      },
      warn: {},
      types: [],
    },
  },
  FB: {
    fields: [FIELDS.CAPTION, FIELDS.URL, FIELDS.LOCATION_TAG, FIELDS.ALT_TEXT],
    validation: {
      error: {
        [FIELDS.TYPE]: {
          [RULES.SUPPORTED_POST_TYPE]: [
            POST_TYPES.TEXT,
            POST_TYPES.IMAGE,
            POST_TYPES.VIDEO,
            POST_TYPES.MULTI_MEDIA,
          ],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 63206],
          [RULES.HASHTAG_COUNT]: [0, 30],
        },
        [FIELDS.MEDIA]: {
          [RULES.LENGTH]: [0, 10],
        },
      },
    },
  },
  TW: {
    fields: [FIELDS.CAPTION, FIELDS.URL, FIELDS.ALT_TEXT],
    validation: {
      error: {
        [FIELDS.TYPE]: {
          [RULES.SUPPORTED_POST_TYPE]: [
            POST_TYPES.TEXT,
            POST_TYPES.IMAGE,
            POST_TYPES.VIDEO,
            POST_TYPES.MULTI_MEDIA,
          ],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 280],
          [RULES.HASHTAG_COUNT]: [0, 30],
        },
        [FIELDS.MEDIA]: {
          [RULES.LENGTH]: [0, 10],
        },
      },
      warn: {},
    },
  },
  LI: {
    fields: [FIELDS.CAPTION, FIELDS.FIRST_COMMENT, FIELDS.URL, FIELDS.ALT_TEXT],
    validation: {
      error: {
        [FIELDS.TYPE]: {
          [RULES.SUPPORTED_POST_TYPE]: [
            POST_TYPES.TEXT,
            POST_TYPES.IMAGE,
            POST_TYPES.MULTI_MEDIA,
            POST_TYPES.VIDEO,
            POST_TYPES.DOCUMENT,
          ],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 3000],
          [RULES.HASHTAG_COUNT]: [0, 30],
        },
        [FIELDS.MEDIA]: {
          [RULES.LENGTH]: [0, 10],
        },
      },
    },
    warn: {},
  },
  GMB: {
    fields: [
      FIELDS.CAPTION,
      FIELDS.GMB_TOPIC_TYPE,
      FIELDS.GMB_CTA,
      FIELDS.GMB_TITLE,
      FIELDS.GMB_START_DATE,
      FIELDS.GMB_END_DATE,
      FIELDS.URL,
      FIELDS.GMB_COUPON_CODE,
      FIELDS.GMB_TERMS,
    ],
    validation: {
      error: {
        [FIELDS.MEDIA]: {
          [RULES.LENGTH]: [0, 10],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 1500],
        },
      },
      warn: {},
    },
  },
  PI: {
    fields: [
      FIELDS.CAPTION,
      FIELDS.URL,
      FIELDS.PI_TITLE,
      FIELDS.PI_BOARDS,
      FIELDS.PI_SECTION_ID,
      FIELDS.ALT_TEXT,
    ],
    validation: {
      error: {
        [FIELDS.TYPE]: {
          [RULES.SUPPORTED_POST_TYPE]: [POST_TYPES.IMAGE, POST_TYPES.VIDEO],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 500],
        },
        [FIELDS.PI_TITLE]: {
          [RULES.LENGTH]: [0, 100],
        },
      },
      warn: {},
    },
  },
  YT: {
    fields: [
      FIELDS.CAPTION,
      FIELDS.YT_TITLE,
      FIELDS.YT_CATEGORY,
      FIELDS.YT_TAGS,
      FIELDS.YT_PRIVACY,
      FIELDS.URL,
    ],
    validation: {
      error: {
        [FIELDS.TYPE]: {
          [RULES.SUPPORTED_POST_TYPE]: [POST_TYPES.VIDEO],
        },
        [FIELDS.CAPTION]: {
          [RULES.LENGTH]: [0, 5500],
        },
        [FIELDS.YT_TITLE]: {
          [RULES.LENGTH]: [0, 100],
        },
      },
      warn: {},
    },
  },
};
