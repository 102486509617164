import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";
import { useGate, useStore } from "effector-react";

import { FileSelector } from "../../file-selector/file-selector";
import {
  $model,
  userFilesSelected,
  getCollectionsFx,
  filtersChanged,
  setPage,
  filtersReset,
  Gate,
  collectionSelected,
} from "features/media-library/components/media-picker/model";

import { CollectionSelector } from "features/media-library/components/collection-selector/";

import { MediaFilesFilters, useMediaFilesFilters } from "entities/media-files";
import { MLSortBy } from "features/media-library/components/ml-sort-by";
import { MLToggleView } from "features/media-library/components/ml-toggle-view";
import { LibraryPayWall } from "features/media-library/components/library-pay-wall";
import { isFullLibrary } from "features/media-library";
import { useUGCUpsellModal } from "features/plans-modal";
import { SmallPagination, Alert } from "ui";
import { AlertType } from "ui/alert";
import { Button, FormSize } from "ui/ecosystems/forms";
import { CollectionFilesList } from "features/media-library/components/collection-files-list";
import { CollectionFilesTable } from "features/media-library/components/files-table";
import { useCurrentUser } from "entities/current-user";
import { LoadingCover } from "ui/cover";
import { PickerProps } from "features/media-library/components/media-picker/media-picker";
import {
  FilterBar,
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
} from "../components/common";
import { MediaFilesSelectedFilters } from "entities/media-files/componets/filters/media-files-filters";

export function LibraryPicker(props: PickerProps) {
  const user = useCurrentUser();
  useGate(Gate);

  const { showUGCUpsellModal } = useUGCUpsellModal();

  useEffect(() => {
    getCollectionsFx(user);
  }, [user]);
  const [viewType, setViewType] = useState<"list" | "grid">("grid");

  const {
    // selectedMediaFiles,
    uploadingStatus,
    currentPage,
    pagesCount,
    currentCollection,
    collectionsTags,
    collections,
    media,
    filters,
    // selectedMediaSet,
  } = useStore($model);

  const [filtersProps] = useMediaFilesFilters({
    values: filters,
    onChange: filtersChanged,
    tags: collectionsTags,
    onClear: filtersReset,
  });

  return (
    <LoadingCover isLoading={!currentCollection}>
      <Toolbar>
        <ToolbarLeft>
          <LibraryPayWall>
            {({ isDisabled }) => (
              <CollectionSelector
                selectedCollection={currentCollection ?? null}
                collections={collections}
                onChange={collectionSelected}
                disabled={isDisabled}
              />
            )}
          </LibraryPayWall>
          <MediaFilesFilters
            {...filtersProps}
            availableFilters={[
              "name",
              "description",
              "sourceName",
              "tags",
              "favorite",
              "mediaType",
              "usage",
            ]}
          />
        </ToolbarLeft>
        <ToolbarRight>
          <Settings>
            <LibraryPayWall>
              {({ isDisabled }) => (
                <MLSortBy
                  disabled={isDisabled}
                  value={filters.sort}
                  onChange={(sort) =>
                    filtersChanged({
                      ...filters,
                      sort,
                    })
                  }
                />
              )}
            </LibraryPayWall>
            <MLToggleView value={viewType} onChange={setViewType} />
          </Settings>
        </ToolbarRight>
      </Toolbar>
      <FilterBar>
        <MediaFilesSelectedFilters {...filtersProps} />
      </FilterBar>
      <FileSelectorContainer>
        <FileSelector
          uploadingStatus={uploadingStatus}
          limit={props.limit}
          isDisabled={props.limit <= props.selectedMediaFiles.size}
          onSelect={userFilesSelected}
          title={`Upload into ${currentCollection?.name}`}
          renderOverlay={(props) => (
            <Overlay {...props}>
              <DropZoneTitle>Drop files to add to your post</DropZoneTitle>
            </Overlay>
          )}
        />
      </FileSelectorContainer>
      <Gallery>
        <Files>
          {React.createElement(views[viewType], {
            files: media,
            selectedMediaSet: props.selectedMediaFiles,
            selectedCollection: props.selectedCollection,
            onSelect: props.handleFileAdd,
            onRemove: props.handleFileRemove,
          })}
          {isFullLibrary(user) ? (
            <SmallPagination
              pagesCount={pagesCount}
              currentPage={currentPage}
              onChange={setPage}
            />
          ) : (
            <div css="margin-top: 12px;">
              <Alert type={AlertType.info}>
                Upgrade your plan to view, sort and create posts from all
                assets. Upgraded plans can quickly source and save user
                generated content from hashtag searches, other users and more.
              </Alert>
              <div css="margin-top: 4px; display: flex; justify-content: center;">
                <Button
                  size={FormSize.large}
                  primary
                  type="button"
                  onClick={() => showUGCUpsellModal()}
                >
                  Upgrade your plan
                </Button>
              </div>
            </div>
          )}
        </Files>
      </Gallery>
    </LoadingCover>
  );
}

const views = {
  grid: CollectionFilesList,
  list: CollectionFilesTable,
};

const Files = styled.div`
  ${SmallPagination} {
    margin-top: 20px;
  }
`;

const Gallery = styled.div``;

const FileSelectorContainer = styled.div``;

const DropZoneTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: ${colors.black};
`;

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${colors.neutral400};
  box-sizing: border-box;
  border-radius: 5px;

  &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${colors.white}E6;
    backdrop-filter: blur(10px);
    z-index: -1;
  }
`;

const Settings = styled.div`
  display: flex;
  align-items: center;
`;
