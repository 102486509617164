import { Tool, ExportFormat, ImageFormat } from "photoeditorsdk";

const tools = [
  Tool.TRANSFORM,
  Tool.FILTER,
  Tool.ADJUSTMENT,
  Tool.FOCUS,
  Tool.TEXT,
  Tool.TEXT_DESIGN,
  Tool.STICKER,
  Tool.BRUSH,
  Tool.FRAME,
  Tool.OVERLAY,
];
const customTransforms = [
  {
    identifier: "instagram",
    name: "Instagram",
    items: [
      {
        identifier: "portrait_ig",
        name: "Max Portrait (0.8:1/4:5)",
        ratio: 1080 / 1350,
      },
      {
        identifier: "square_ig",
        name: "Square",
        ratio: 1,
      },
      {
        identifier: "landscape_ig",
        name: "Max Landscape (1.91:1)",
        ratio: 1080 / 566,
      },
      {
        identifier: "story_ig",
        name: "IG Story",
        ratio: 1080 / 1920,
      },
    ],
  },
  {
    identifier: "pinterest",
    name: "Pinterest",
    items: [
      {
        identifier: "pinterest_recommended",
        name: "2:3 - recommended",
        ratio: 1000 / 1500,
      },
    ],
  },
  {
    identifier: "linkedin",
    name: "LinkedIn",
    items: [
      {
        identifier: "linkedin_recommended",
        name: "LI recommended",
        ratio: 1200 / 627,
      },
    ],
  },
];
const customFrames = [
  {
    identifier: "white-plain-frame",
    name: "White",
    layoutMode: "horizontal-inside",
    thumbnailURI: "items/simple/icon.png",
    tintable: false,
    imageGroups: {
      top: {
        mid: {
          image: "items/simple/simple_corner.png",
          mode: "repeat",
        },
      },
      left: {
        mid: {
          image: "items/simple/simple_corner.png",
          mode: "repeat",
        },
      },
      right: {
        mid: {
          image: "items/simple/simple_corner.png",
          mode: "repeat",
        },
      },
      bottom: {
        mid: {
          image: "items/simple/simple_corner.png",
          mode: "repeat",
        },
      },
    },
  },
  {
    identifier: "black-plain-frame",
    name: "Black",
    layoutMode: "horizontal-inside",
    thumbnailURI: "items/black/icon.png",
    tintable: false,
    imageGroups: {
      top: {
        mid: {
          image: "items/black/simple_corner.png",
          mode: "repeat",
        },
      },
      left: {
        mid: {
          image: "items/black/simple_corner.png",
          mode: "repeat",
        },
      },
      right: {
        mid: {
          image: "items/black/simple_corner.png",
          mode: "repeat",
        },
      },
      bottom: {
        mid: {
          image: "items/black/simple_corner.png",
          mode: "repeat",
        },
      },
    },
  },
];
export const config = {
  // yes weirdly the license is meant to be delivered as a string
  license:
    '{"api_token":"zggCndbLszjRTLAJQLDW6g","app_identifiers":["localhost","127.0.0.1","app.schedugr.am","staging.schedugr.am","staging.skedsocial.com","app.skedsocial.com","testdrive.skedsocial.com","old.skedsocial.com","staging.now.skedsocial.com","ebstaging.now.skedsocial.com","one.now.skedsocial.com","two.now.skedsocial.com","prod-n18.skedsocial.com","staging-n18.skedsocial.com"],"available_actions":[],"domains":["https://api.photoeditorsdk.com"],"enterprise_license":false,"expires_at":null,"features":["camera","library","export","customassets","whitelabel","adjustment","brush","filter","focus","frame","overlay","sticker","text","transform"],"issued_at":1739487143,"minimum_sdk_version":"1.0","owner":"Dialogue Consulting Pty Ltd","platform":"HTML5","products":["pesdk"],"version":"2.4","signature":"BmZPEfawMygW7v8lb73UbrPt+ZKyDzVDMre4giH+45Bik+iFu2iCwQ3uwNpeJ7hJ42CwukXyXbcb1ZsI60aSUUVr3BLmQrrSUTP+0b+y6gKVNO+2bCVadxmc1DdeYTG7WsumEkMyO+50dQz1Jbg6RN9Dhl6w4NLMxlKTIOhKAcXgPeHfaGr3u+s179sVxavLOj+qmVKNhPwbCS5kmUiCTwCpf4BgpxSUlLzhZLl2IzlfdQR+Ye9kVyaeLrXPtlB0UTzFHpsFlboeEWbiCZRnvVHCugcWxhoP5zX/GXcEQATj7Z0YexeEjDBVeNQC+XZLgwEJ+dgFUQgjvxKlYFAEYIX3qx9s6+lIgK63cYSqDr/bFPeDYltWEZK/EfjkdYfJmvPRiToJYxoDrIguLMYqknPjgHfWxG1FY/00egM7GDdwYTQ6S2lB1EN5mUSsRYynW/wpa31y6LJit0WbU1aCwbv95sZ3ewgjZaYPMUxwXsAgJA0XTIyDWIjFneCjyW7Q7jVTr4nUCAV7+Jyt4toJ7lpadj3YVPKDOZQtlOegg6a4p8csXVPA80BJW/bFuy72znehZWpa7W+rXwSQGc+j/H2OZZZP0zSGLkWttVuxVmW600p1wmMktOPqUEebxZK0QktG2ml2KMmlscXx4AKu0TvEZMqSJ/dBEM2uljPVmfo="}',
  assetBaseUrl: "/assets/resources/photoeditor",
  title: "Sked Image Editor",
  custom: {
    languages: {
      en: {
        mainCanvasActions: {
          buttonExport: "Save",
        },
      },
    },
  },
  responsive: true,
  tools,
  engine: {
    downscaleOptions: {
      maxMegaPixels: {
        desktop: 9,
        mobile: 5,
      },
    },
  },
  displayResizeWarning: true,
  export: {
    image: {
      format: ImageFormat.JPEG,
      exportType: ExportFormat.BLOB,
      enableDownload: false,
      quality: 1,
    },
  },
  // probably does nothing after migrating to v5
  library: {
    enableUpload: false,
    enableWebcam: false,
  },
  frame: {
    items: [
      ...customFrames,
      { identifier: "imgly_frame_dia" },
      { identifier: "imgly_frame_art_decor" },
      { identifier: "imgly_frame_black_passepartout" },
      { identifier: "imgly_frame_wood_passepartout" },
      { identifier: "imgly_frame_lowpoly_shadow" },
    ],
  },
  transform: {
    categories: [
      ...customTransforms,
      {
        identifier: "imgly_transforms_common",
      },
      {
        identifier: "imgly_transforms_facebook",
      },
      {
        identifier: "imgly_transforms_twitter",
      },
    ],
  },
  displayCloseButton: true,
};
