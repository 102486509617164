import CollaborationAvatar from "components/Collaboration/ChatUtils/CollaborationAvatar";
import React, { useEffect, useRef, useState } from "react";
import { SkedIcon } from "ui";
import styled from "styled-components";
import replaceUserTags from "utils/replaceUserTags";

import { useTrackEvent } from "libs/analytics";
import axios from "axios";

const MENTION_ITEM_HEIGHT = 30;

/**
 * component for textarea and send button behaviours in collaboration chat
 */
function CollaborationInputControls({
  collaborators,
  onMessageSend,
  editingComment,
  cancelEdit,
  disabled,
}) {
  const trackEvent = useTrackEvent();

  // values for mention dropdown
  const [availableCollaborators, setAvailableCollaborators] = useState([]);
  // highlighted user in dropdown
  const [selectedCollaborator, setSelectedCollaborator] = useState(null);
  // for tracking parameter
  const [selectedCollaboratorId, setSelectedCollaboratorId] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const mentionListRef = useRef(null);

  // detects mentions and filters collaborators in dropdown
  // also resizes textarea
  const onChange = ({ target: { value } }) => {
    setInputValue(value);
    // trigger mentions dropdown if needed
    const parsedMentionString = value.match(/@\w*$/);
    if (parsedMentionString) {
      const username = parsedMentionString[0].substring(1).toLowerCase();
      const newCollaborators = collaborators.filter((user) =>
        user.username?.toLowerCase().startsWith(username)
      );
      // if there weren't any collaborators previously and will appear now
      if (!availableCollaborators.length && newCollaborators.length) {
        setSelectedCollaborator(0);
      }
      // if has selectedCollaborator and list will be empty
      if (!newCollaborators.length && Number.isInteger(selectedCollaborator))
        setSelectedCollaborator(null);
      setAvailableCollaborators(newCollaborators);
      // if has selectedCollaborator and its out of range in newCollaborators
      if (
        Number.isInteger(selectedCollaborator) &&
        newCollaborators.length - 1 < selectedCollaborator
      ) {
        setSelectedCollaborator(newCollaborators.length - 1);
      }
    } else {
      if (availableCollaborators) {
        setSelectedCollaborator(null);
        setAvailableCollaborators([]);
      }
    }
    resizeTextarea();
  };

  // appends mentioned user to input area
  const applyMention = (user) => {
    if (user && user.username) {
      setSelectedCollaboratorId(user._id);
      const message = inputValue.replace(/@\w*$/, `@${user.username}, `);
      setInputValue(message);
      setAvailableCollaborators([]);
      setSelectedCollaborator(null);
      inputRef.current.focus();
      setTimeout(() => {
        resizeTextarea();
      }, 0);
    }
  };

  // on text change resizes textarea
  const resizeTextarea = () => {
    // text jumps without this hack
    setTimeout(() => {
      if (inputRef && inputRef.current && inputRef.current.style) {
        inputRef.current.style.height = "5px";
        inputRef.current.style.height = inputRef.current.scrollHeight + "px";
      }
    }, 0);
  };

  useEffect(() => {
    if (editingComment) {
      const { body } = editingComment;
      const message = body.trim().replace(/@\[\w*\]/gm, (id) => {
        const user = collaborators.find((user) => "@[" + user._id + "]" === id);
        if (!user) return "";
        return `@${user.username}`;
      });

      setInputValue(message);
    } else {
      setInputValue("");
    }
    resizeTextarea();
  }, [editingComment]);

  // on plane icon click or enter press
  const sendMessage = async () => {
    // remove trailing spaces and format mentions
    let message = replaceUserTags(inputValue, collaborators);

    if (!message.length) return;

    //if user tagged without any text, deleting comma
    if (
      message.length !== 1 &&
      message.substring(message.indexOf(",")).length === 1
    ) {
      message = message.substring(0, message.length - 1);
    }

    setInputValue("");
    // just in case ;)
    setAvailableCollaborators([]);
    resizeTextarea();
    onMessageSend(message);

    // tracking logic
    const {
      data: {
        data: { plans },
      },
    } = await axios("/plans");
    const currentPlan = plans.find((plan) => plan.currentPlan);
    trackEvent({
      eventName: "collab-send-internal-comment",
      metadata: {
        plan: currentPlan ? currentPlan.displayName : "Unknown",
      },
      useServices: ["AMP"],
    });

    try {
      window.hj("event", "collab-send-internal-comment");
      console.log("Hotjar trigger collab-send-internal-comment");
    } catch (e) {
      console.error("Hotjar fire event error:", e);
    }

    setSelectedCollaboratorId(null);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      return handleEnterPress();
    }
    // if has collaborators list open and pressed up or down
    const arrowPressed = [38, 40].includes(event.keyCode);
    if (arrowPressed && availableCollaborators.length > 0) {
      handleArrowsPress();
    }

    // if pressed tab or space with selectedcollaborator
    const tabOrSpacePressed = [9, 32].includes(event.keyCode);
    if (tabOrSpacePressed && Number.isInteger(selectedCollaborator)) {
      event.preventDefault();
      applyMention(availableCollaborators[selectedCollaborator]);
    }

    function handleEnterPress() {
      event.preventDefault();
      if (Number.isInteger(selectedCollaborator)) {
        applyMention(availableCollaborators[selectedCollaborator]);
      } else {
        sendMessage();
      }
    }

    //
    function handleArrowsPress() {
      let newIndex;
      switch (event.key) {
        case "ArrowUp":
          // if can go up
          if (selectedCollaborator > 0) {
            newIndex = selectedCollaborator - 1;
            setSelectedCollaborator(newIndex);
            // scroll view to selected item
            if (
              mentionListRef.current.scrollTop >
              newIndex * MENTION_ITEM_HEIGHT
            )
              mentionListRef.current.scrollTop -= MENTION_ITEM_HEIGHT;
          }
          break;
        case "ArrowDown":
          // if can go down
          if (selectedCollaborator < availableCollaborators.length - 1) {
            newIndex = selectedCollaborator + 1;
            setSelectedCollaborator(newIndex);
            // scroll view to selected item
            if (
              mentionListRef.current.scrollTop + 2 * MENTION_ITEM_HEIGHT <
              newIndex * MENTION_ITEM_HEIGHT
            )
              mentionListRef.current.scrollTop += MENTION_ITEM_HEIGHT;
          }
          break;
      }
      event.preventDefault();
    }
  };

  const handleEditCancel = () => {
    setInputValue("");
    resizeTextarea();
    cancelEdit();
  };

  return (
    <InputControlsWrap>
      {availableCollaborators.length > 0 && (
        <MentionList ref={mentionListRef}>
          {availableCollaborators.map((user, index) => (
            <MentionListItem
              isSelected={index === selectedCollaborator}
              key={user._id}
              onClick={() => applyMention(user)}
              onMouseEnter={() => setSelectedCollaborator(index)}
            >
              <CollaborationAvatar user={user} small={true} />{" "}
              <span>{user.username}</span>
            </MentionListItem>
          ))}
        </MentionList>
      )}
      <textarea
        onChange={onChange}
        ref={inputRef}
        value={inputValue}
        placeholder="Type a message..."
        onKeyDown={(e) => handleKeyDown(e)}
        disabled={disabled}
      ></textarea>
      <SendButton disabled={!inputValue.trim().length || disabled}>
        <SkedIcon
          icon={"send-plane"}
          color="purple"
          size="26"
          onClick={() => sendMessage()}
        />
      </SendButton>
      {editingComment && (
        <CancelEditText onClick={() => handleEditCancel()}>
          Cancel Edit
        </CancelEditText>
      )}
    </InputControlsWrap>
  );
}

// styling
const InputControlsWrap = styled.div`
  display: flex;
  position: relative;
  margin: 0 24px;
  border-top: 1px #eee solid;
  padding-top: 12px;
  align-items: flex-end;
  justify-content: space-between;

  textarea {
    border: 1px solid #eeeeee;
    border-radius: 5px;
    flex: 1;
    margin-right: 14px;
    resize: none;
    color: #454545;
    padding: 12px;
    max-height: 120px;
    min-height: 44px;
    height: 44px;
    line-height: 16px;
  }
`;

const MentionList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  min-width: 200px;
  position: absolute;
  transform: translate(0px, -100%);
  top: 12px;
  border-radius: 5px;
  overflow: auto;
  max-height: 90px;
  background: #000;
  cursor: pointer;
`;
const MentionListItem = styled.li`
  margin: 0;
  padding: 0;
  list-style-type: none;
  min-width: 200px;
  background: #000;
  color: #fff;
  height: 30px;
  padding: 8px;
  border: 1px solid #000;
  display: flex;

  ${({ isSelected }) =>
    isSelected &&
    `
    background: #333;
    border: 1px solid #454545;
    border-radius: 5px;
  `}
  span {
    order: 1;
    font-size: 12px;
    line-height: 14px;
  }
`;

const SendButton = styled.div`
  margin-bottom: 6px;

  svg path {
    fill-opacity: ${({ disabled }) => (disabled ? "0.44" : "1")};
  }
`;

const CancelEditText = styled.p`
  font-size: 10px;
  line-height: 10px;
  text-decoration-line: underline;
  color: #333333;
  transform: translate(0, 100%);
  position: absolute;
  right: 40px;
  margin: 0;
  bottom: -4px;
  cursor: pointer;
`;

export default CollaborationInputControls;
