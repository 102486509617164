export const HREF_TO_STATE = {
  "/dashboard": "root.home",
  "/dashboard/groups": "root.groups",
  "/dashboard/accounts": "root.accounts",
  "/dashboard/library": "root.library",
  "/dashboard/profile": "root.settings.profile",
  "/dashboard/calendar": "root.calendar",
  "/dashboard/inbox": "root.inbox",
  "/dashboard/workshop": "root.workshop",
  "/dashboard/template-manager": "root.templateManager",
  "/dashboard/planner": "root.planner",
  "/dashboard/schedules/dashboard/active": "root.schedules.active",
  "/dashboard/schedules/dashboard/inactive": "root.schedules.archive",
  "/dashboard/schedules/dashboard/drafts": "root.schedules.drafts",
  "/dashboard/insights": "root.insights",
  "/dashboard/social-listening": "root.socialListening",
  "/dashboard/upload": "root.index",
  "/dashboard/users": "root.users",
  "/dashboard/contact": "root.contact",
  "/dashboard/subscription": "root.settings.subscription",
  "/dashboard/collaborate": "root.settings.collaborate",
  "/dashboard/change-password": "root.settings.password",
  "/dashboard/referral": "root.settings.referral",
  "/dashboard/profile/2faRegistration": "root.settings.2fa",
};
