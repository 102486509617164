import { useStoreMap } from "effector-react";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { $path } from "./model";
import ngDeps from "ng-react-directives/ngr-injector";
import { HREF_TO_STATE } from "constants/Router";

interface LinkProps {
  children: React.ReactNode;
  className?: string;
  to?: string;
  id?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  checkActiveFunc?: (path: string) => boolean;
  target?: string;
}

export function Link({
  to,
  className,
  children,
  checkActiveFunc,
  ...props
}: LinkProps): JSX.Element {
  const navigate = useNavigate();
  const isActive = useStoreMap({
    store: $path,
    keys: [to],
    fn: (path, [to]) => {
      if (checkActiveFunc) {
        return checkActiveFunc(path);
      }
      // Remove qury param if exist
      return to && path.includes(to.split("?")[0]);
    },
  });

  const loadBeforeNavigate = async (e: any) => {
    if (props.onClick) {
      props.onClick(e);
    }

    if ((props.target && props.target === "_blank") || !to) return;

    e.preventDefault();

    await navigateWithAngular(navigate, to);
  };

  return (
    <a
      href={to}
      {...props}
      onClick={loadBeforeNavigate}
      className={`${className} ${isActive ? "active" : ""}`}
    >
      {children}
    </a>
  );
}

export async function navigateWithAngular(
  navigate: NavigateFunction,
  to: string
) {
  const { $rootScope } = ngDeps;
  // There should only be known routes passed in here
  const trimmedTo: keyof typeof HREF_TO_STATE = (
    to.includes("?") ? to.substring(0, to.indexOf("?")) : to
  ) as keyof typeof HREF_TO_STATE;
  const goToValue = HREF_TO_STATE[trimmedTo];
  try {
    // NOTE: avoid white page
    // this may throw error `Error: transition superseded`
    await $rootScope.go(goToValue || "root.none");
  } catch (e) {
    console.log("error while navigating in angular router", e);
  }

  navigate(to);
}
