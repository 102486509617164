import React from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";
import { TooltipWrapper } from "../TooltipWrapper";

interface Props {
  className?: string;
  children: React.ReactNode;
}

function QuestionTooltipComponent(props: Props): React.ReactNode {
  return (
    <TooltipWrapper
      className={props.className}
      tooltip={
        <QuestionTooltipContent>{props.children}</QuestionTooltipContent>
      }
    >
      <Icon />
    </TooltipWrapper>
  );
}

export const QuestionTooltip = styled(QuestionTooltipComponent)`
  display: inline-block;
`;

const QuestionTooltipContent = styled.div`
  padding: 4px;
  text-align: left;
`;

const Icon = styled.div.attrs({
  children: "?",
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  background: ${colors.neutral700};
  border-radius: 50%;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  cursor: pointer;

  color: ${colors.white};
`;
