import React from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

import SkedIcon from "../SkedIcon/SkedIcon";

interface Props {
  children: React.ReactNode;
  onEdit?(): void;
  onDelete(): void;
  className?: string;
}

function EditAbleMediaComponent(props: Props) {
  return (
    <EditAbleMediaRoot className={props.className}>
      {props.children}
      <Buttons>
        {props.onEdit && <EditButton onClick={props.onEdit} />}
        <DeleteButton onClick={props.onDelete} />
      </Buttons>
    </EditAbleMediaRoot>
  );
}

const Button = styled(SkedIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const DeleteButton = styled(Button).attrs({
  icon: "trash",
  color: "red",
})`
  svg {
    width: 14px !important;
    height: 14px !important;
  }

  :hover {
    background-color: ${colors.error50};
  }
`;

const EditButton = styled(Button).attrs({
  icon: "icon-pencil",
  color: "purple",
})`
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background-color: ${colors.primary50};
  }
`;

const Buttons = styled.div`
  width: 35px;
  background: ${colors.white};
  border: 1px solid ${colors.primary100};
  border-left-width: 0;
  box-sizing: border-box;
  border-radius: 0 5px 5px 0;
  overflow: hidden;
  flex-direction: column;

  ${EditButton}, ${DeleteButton} {
    width: 100%;
    height: 50%;
  }

  ${DeleteButton} {
    :only-child {
      height: 100%;
    }
  }
`;

const EditAbleMediaRoot = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-block;

  ${Buttons} {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 100%;
  }

  &:hover {
    ${Buttons} {
      display: inherit;
    }
  }
`;

export const EditAbleMedia = styled(EditAbleMediaComponent)``;
