class YoutubeIconUtils {
  resolvePlayIcon(platformName: string) {
    return platformName === "youtube" ? "play" : platformName;
  }

  resolveYtRedIcon(platformName: string) {
    return platformName === "youtube" ? "youtubeRedPlay" : platformName;
  }
}

export default new YoutubeIconUtils();
