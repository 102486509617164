import React from "react";
import styled from "styled-components";
import { FIELDS } from "constants/PostFormFields";
import StoryType from "../StoryType/StoryType";
import { flexColumnMb, flexColumn } from "./flex-columns";

export function StyledStoryType({
  platformPost,
  selectedPlatform,
  platforms,
  updateField,
  errors,
}) {
  return (
    <StoryTypeContainer>
      <StoryTypeStyled
        key={`storytype-${selectedPlatform}`}
        post={platformPost}
        selectedPlatform={selectedPlatform}
        platforms={platforms}
        onChange={(val) => {
          if (val !== "story") {
            // non-stories must always be published manually
            // so show this warning
            // NOTE 3 argument use debounce or not
            updateField(FIELDS.PUBLISH_STORY_MANUALLY, true);
          }

          return updateField(FIELDS.STORY_TYPE, val);
        }}
        errors={errors}
      />
    </StoryTypeContainer>
  );
}
const StoryTypeContainer = styled.div`
  ${flexColumnMb};
`;
const StoryTypeStyled = styled(StoryType)`
  ${flexColumn};
  min-width: 200px;
`;
