import React, { useContext } from "react";
import styled from "styled-components";
import OverlayTrigger from "../../../../components/CustomOverlayTrigger/CustomOverlayTrigger";
import Tooltip from "react-bootstrap/lib/Tooltip";

import { AccountType } from "shared/types/accounts";
import { getAccountDisplayName } from "../..";
import { AccountContext } from "./account-context";

interface Props {
  account: AccountType;
  children: React.ReactNode;
  maxLength?: number;
  weight?: number;
}

export const AccountName = styled((props: Props) => {
  const account = props.account || useContext(AccountContext);
  // show a tooltip if it is truncated beyond maxLength
  if (
    props.maxLength &&
    getAccountDisplayName(account)?.length > props.maxLength
  ) {
    const accountTooltip = (
      <Tooltip id="account-name-tooltip">
        {getAccountDisplayName(account)}
      </Tooltip>
    );
    return (
      <OverlayTrigger placement="top" overlay={accountTooltip}>
        <div {...props}>
          {getAccountDisplayName(account).substring(0, props.maxLength) + "…"}
        </div>
      </OverlayTrigger>
    );
  }
  return <div {...props}>{getAccountDisplayName(account)}</div>;
})`
  font-weight: ${(props) => (props.weight ? props.weight : 500)};
  font-size: 14px;
  line-height: 17px;
`;
