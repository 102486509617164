import { CSSObjectWithLabel, GroupBase, StylesConfig } from "react-select";
import { OptionBrandedType } from "../types";

interface stylesProps {
  minHeight?: string;
  isSearchable?: boolean;
}

export const getStyles = ({
  minHeight,
  isSearchable,
}: stylesProps): StylesConfig<
  OptionBrandedType,
  boolean,
  GroupBase<OptionBrandedType>
> => ({
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    minHeight: minHeight || "50px",
    display: "flex",
    alignItems: "center",
  }),
  input: (base: CSSObjectWithLabel) => ({
    ...base,

    display: "inline-block",
  }),
  option: (base: CSSObjectWithLabel) => ({
    ...base,
  }),
  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    minHeight: minHeight || "50px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginLeft: isSearchable ? "6px" : 0,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    maxWidth: "calc(100% - 8px)",
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    display: "flex",
    minHeight: "30px",
    padding: "4px",
  }),
  multiValue: (base: CSSObjectWithLabel) => ({
    ...base,
    minHeight: "38px",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F6F5FF",
    borderRadius: "5px",
    border: "1px solid #DEE7FF",
  }),
  multiValueRemove: (base: CSSObjectWithLabel) => ({
    ...base,
    minHeight: "38px",
  }),
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    zIndex: 50,
  }),
  group: (base: CSSObjectWithLabel) => ({
    ...base,
    // display: "flex",
    borderBottom: "1px solid #e5e5e5",
  }),
});
