import { useMemo, useCallback, useState, useRef, MouseEvent } from "react";
import styled from "styled-components";

import Select, { components, CSSObjectWithLabel } from "react-select";
import { Checkbox, FormSize } from "ui/ecosystems/forms";
import { AccountType } from "shared/types/accounts";
import { TooltipWrapper } from "ui/TooltipWrapper";
import { ReactSelectComponentStyles } from "ui/ecosystems/forms/select/select";
import { useClickOutside } from "libs/use-click-outside";

import { getAccountNameParts } from "utils/accounts";
import MovingToGroup from "assets/icons/sked-home/moving-to-group.svg";
import { ClampTextWithTooltip } from "ui/clamped-text";

import { PickerType } from "../types";
import { SelectOptionLabel } from "./components/select-option-label";
import { AccountsGroupValueContainer } from "./components/accounts-group-value-container";

interface ItemProps {
  isSelected: boolean;
  data: {
    disabledTooltip?: boolean;
    account: AccountType;
  };
  currentGroupName?: string;
}

const preventEvents = (event: MouseEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

const Option = (props: ItemProps) => {
  const {
    isSelected,
    data: { disabledTooltip, account },
    currentGroupName,
  } = props;

  const [groupName, accountName] = getAccountNameParts(account);

  const isNotInCurrentGroup =
    isSelected && groupName && currentGroupName !== groupName;

  return (
    <div onClick={preventEvents}>
      <TooltipWrapper tooltip={disabledTooltip}>
        <components.Option {...props}>
          <OptionWrapper
            data-testid={`accountPickerForGroup-${accountName}`}
            isSelected={isSelected}
          >
            <Checkbox checked={isSelected} size={FormSize.large} />
            <Label>
              <SelectOptionLabel account={account} />
            </Label>
            <GroupName>
              -&nbsp;
              <ClampTextWithTooltipStyled>
                {groupName || "Ungrouped"}
              </ClampTextWithTooltipStyled>
            </GroupName>
            {isNotInCurrentGroup && (
              <>
                <TooltipWrapper
                  tooltip={
                    <>
                      You are moving this account
                      <br /> from group “{groupName}”
                    </>
                  }
                >
                  <StyledMovingToGroup />
                </TooltipWrapper>
              </>
            )}
          </OptionWrapper>
        </components.Option>
      </TooltipWrapper>
    </div>
  );
};

interface SelectState {
  // aliased for consumers
  focus(): void;
  blur(): void;
  state: {
    menuIsOpen: boolean;
  };
}

const styles = {
  ...ReactSelectComponentStyles,
  valueContainer: (provided: CSSObjectWithLabel) => {
    const styles: CSSObjectWithLabel = {
      // works for multi - old
      //   ...provided,
      //   flexWrap: "nowrap",
      //   minHeight: 40,
      //   padding: "7px 8px",
      //   overflow: "visible !important",
      // works for single
      ...provided,
      flexWrap: "nowrap",
      minHeight: 40,
      display: "flex",
      padding: "7px 8px",
    };

    return styles;
  },
  option: (base: CSSObjectWithLabel) => ({
    ...base,
    background: "transparent",
  }),
};

export const AccountsPickerForGroup = (props: PickerType): JSX.Element => {
  const {
    accounts,
    selectedAccountIds = [],
    onChange = null,
    groupName,
  } = props;

  const [isMenuClosed, setIsMenuClosed] = useState<boolean>(false);
  const selectState = useRef<SelectState>();

  const closeMenu = () => {
    setIsMenuClosed(false);
  };
  const toggleMenu = () => {
    setIsMenuClosed(!isMenuClosed);
  };

  useClickOutside(selectState, closeMenu);

  const options = useMemo(
    () => accounts.map((account) => ({ account, value: account._id })),
    [accounts]
  );

  const value = useMemo(() => {
    return accounts
      .filter((account) => selectedAccountIds.includes(account._id))
      .map((account: AccountType) => ({ account, value: account._id }));
  }, [accounts, selectedAccountIds]);

  const handleChange = useCallback(
    (selected: any) => {
      const clear = selected === null || selected?.length === 0;

      if (onChange) {
        if (clear) {
          return onChange([]);
        }

        return onChange(
          selected.map(({ account }: { account: AccountType }) => account)
        );
      }
    },
    [onChange]
  );

  const ValueContainer = useCallback(
    (props: any) => {
      const placeHolderText = "Please select account";

      return (
        <div>
          <AccountsGroupValueContainer
            valueContainerProps={props}
            selectedOptions={value}
            placeholder="Select accounts"
            selectedPlaceholder={placeHolderText}
            currentGroupName={groupName}
          />
        </div>
      );
    },
    [groupName, value]
  );

  const customSelectComponents = {
    Option: (props) => <Option {...props} currentGroupName={groupName} />,
    ValueContainer: ValueContainer,
  };

  return (
    <div onClick={toggleMenu} ref={selectState}>
      <Select
        autoFocus={false}
        components={customSelectComponents}
        closeMenuOnSelect={false}
        placeholder={`Select accounts`}
        isMulti={true}
        menuIsOpen={isMenuClosed}
        isSearchable={false}
        options={options}
        value={value}
        onChange={handleChange}
        hideSelectedOptions={false}
        styles={styles}
      />
    </div>
  );
};

const OptionWrapper = styled.div<{ isSelected?: boolean }>`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  grid-auto-columns: min-content;
  color: ${({ isSelected }) => (isSelected ? "black" : "#666666")};
`;

const Label = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledMovingToGroup = styled(MovingToGroup)`
  width: 16px;
  height: 16px;
  margin-top: 5px;
`;

const GroupName = styled.div`
  display: inherit;
  color: #808080;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const ClampTextWithTooltipStyled = styled(ClampTextWithTooltip)`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default AccountsPickerForGroup;
