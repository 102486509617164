import React, { useState, useEffect } from "react";
import moment from "moment";

import { SkedIcon } from "ui";
import { POST_STATES } from "constants/PostsView";
import { FIELDS } from "constants/PostFormFields";
import { DATE_FORMAT } from "constants/common";
import { getPostState } from "utils/posts";
import DateTimePicker from "components/DateTimePicker/DateTimePicker";
import ngDeps from "ng-react-directives/ngr-injector";

import { dateInThePast } from "utils/dates";
import { QuestionIconWithTooltip, TooltipWrapper } from "ui";
import { ExternalLink } from "ui/Link";
import { Select } from "ui/ecosystems/forms";
import styled from "styled-components";

const POST_NOW = `${POST_STATES.SCHEDULED}-NOW`;

const PostFormActions = ({
  post,
  samePostState,
  initialPostState,
  timePreference,
  onChange,
  disabled = false,
  canQueuePosts = true,
  isSummary,
}) => {
  const { $rootScope } = ngDeps;
  const timezone = $rootScope.user.timezone;
  const [selected, setSelected] = useState(getPostState(post));

  const [scheduledFor, setScheduledFor] = useState(getDefaultScheduledFor());
  const [inThePast, setInThePast] = useState(false);

  function onDateChange(newDateTime) {
    setScheduledFor(newDateTime);
    setDatePickerVisible(false);
    setInThePast(dateInThePast(newDateTime));
    onChange && onChange({ [FIELDS.WHEN]: newDateTime });
  }

  function getDefaultScheduledFor() {
    if (post.when && !moment(post.when).isValid()) {
      throw new Error(
        `The selected date of ${post.when} is not valid. Please try again or contact support if the issue persists.`
      );
    }

    return (
      post.when ? moment(post.when) : moment().tz(timezone).add(5, "minutes")
    ).format(DATE_FORMAT);
  }

  useEffect(() => {
    if (initialPostState) {
      setSelected(initialPostState);
    }
  }, [initialPostState]);
  useEffect(() => {
    if (selected === POST_STATES.SCHEDULED) {
      const time = getDefaultScheduledFor();
      setScheduledFor(time);
      if (!post.when) {
        onChange({ [FIELDS.WHEN]: time });
      }
      setInThePast(dateInThePast(time));
    }
  }, [selected]);

  const queueTooltip = (
    <div>
      Use your recurring posting schedule. Click for more (in a new window).
    </div>
  );
  const disabledQueueTooltip = (
    <div>
      Set up Queue for all your selected accounts to post on a recurring
      schedule. Click for more (in a new window).
    </div>
  );

  let config = [
    {
      value: POST_STATES.DRAFT,
      title: (
        <StateOption className="tw-flex tw-items-center">
          <SkedIcon icon="icon-send-draft" className="tw-mr-2" />
          Draft
        </StateOption>
      ),
      label: (
        <StateLabel className="tw-flex tw-items-center">
          <SkedIcon icon="icon-send-draft" className="tw-mr-2" />
          Draft
        </StateLabel>
      ),
    },
    {
      value: POST_STATES.QUEUED,
      title: (
        <StateOption
          className={`tw-flex tw-items-center ${
            canQueuePosts ? `` : `tw-text-gray-600 tw-cursor-not-allowed`
          }`}
        >
          <SkedIcon icon="icon-queue" className="tw-mr-2" />
          Queue post&nbsp;
          <ExternalLink
            href={`https://help.skedsocial.com/en/articles/3811779-use-the-queue-function`}
          >
            <QuestionIconWithTooltip
              tooltip={canQueuePosts ? queueTooltip : disabledQueueTooltip}
            />
          </ExternalLink>
        </StateOption>
      ),
      // don't show tooltip when displayed in the input
      label: (
        <StateLabel className="tw-flex tw-items-center">
          <SkedIcon icon="icon-queue" className="tw-mr-2" />
          Queue post
        </StateLabel>
      ),
      isDisabled: !canQueuePosts,
    },
    {
      value: POST_STATES.SCHEDULED,
      title: (
        <StateOption className="tw-flex tw-items-center">
          <SkedIcon icon="icon-upcoming" className="tw-mr-2" />
          Schedule for
        </StateOption>
      ),
      label: (
        <StateLabel className="tw-flex tw-items-center">
          <SkedIcon icon="icon-upcoming" className="tw-mr-2" />
          Schedule for
        </StateLabel>
      ),
    },
    {
      value: POST_NOW,
      title: (
        <StateOption className="tw-flex tw-items-center">
          <SkedIcon icon="icon-upcoming" className="tw-mr-2" />
          Post Now
        </StateOption>
      ),
      label: (
        <StateLabel className="tw-flex tw-items-center">
          <SkedIcon icon="icon-upcoming" className="tw-mr-2" />
          Post Now
        </StateLabel>
      ),
    },
  ];
  // add an archived option here for when user uses 'post again'
  if (selected === POST_STATES.ARCHIVED) {
    config.push({
      id: 3,
      value: POST_STATES.ARCHIVED,
      title: (
        <StateOption className="tw-flex tw-items-center">
          Archived - choose one…
        </StateOption>
      ),
      label: (
        <StateLabel className="tw-flex tw-items-center">
          Archived - choose one…
        </StateLabel>
      ),
      selected: selected === POST_STATES.ARCHIVED,
      isDisabled: true,
    });
  }

  const handlePostStateChange = (value) => {
    let key = value;
    let postNow = false;

    if (key === POST_NOW) {
      key = POST_STATES.SCHEDULED;
      postNow = true;
      // leave selected as POST_NOW - so that it displays correctly
      setSelected(POST_NOW);
    } else {
      setSelected(key);
    }
    onChange &&
      onChange({ [FIELDS.POST_STATE]: key, [FIELDS.IS_NOW]: postNow });
  };
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const displayDateFormat =
    timePreference === "12h" ? "ddd D MMM h:mma" : "ddd D MMM HH:mm";

  const scheduledClasses = "tw-bg-primary-200 tw-text-black";
  const scheduledErrorClasses = "tw-bg-red-100 tw-text-red-500";

  return (
    <div
      className="tw-flex tw-whitespace-no-wrap"
      data-testid="postCreateTypeSelectWrapper"
    >
      {!isSummary && (
        <ActionSelect
          className={"tw-mr-4"}
          options={config}
          defaultValue={config[0]}
          value={selected}
          closeMenuOnSelect
          isMulti={false}
          isSearchable={false}
          onChange={handlePostStateChange}
          renderOption={(option) => option.title}
          renderLabel={(option) => option.label}
          placeholder={"Select one"}
          menuPlacement={"auto"}
        />
      )}
      {selected === POST_STATES.ARCHIVED && (
        <DisplayBox className="tw-text-black tw-bg-red-200">
          Select draft/schedule/queue for this post.
        </DisplayBox>
      )}
      {selected === POST_STATES.DRAFT && (
        <DisplayBox className="tw-text-black tw-bg-primary-200">
          {samePostState
            ? "This post is a draft"
            : "This post will be sent to drafts"}
        </DisplayBox>
      )}
      {selected === POST_STATES.QUEUED && (
        <DisplayBox className="tw-text-black tw-bg-primary-200">
          {samePostState
            ? "This post is queued"
            : "This post will be sent to the queue"}
        </DisplayBox>
      )}
      {selected === POST_STATES.SCHEDULED && !isSummary && (
        <DisplayBox
          className={`tw-relative tw-text-black ${
            inThePast ? scheduledErrorClasses : scheduledClasses
          }`}
        >
          Scheduled for:
          <span
            className={`tw-underline tw-cursor-pointer tw-ml-4 ${
              inThePast ? "tw-text-red-400" : "tw-text-primary-500"
            }`}
            onClick={() =>
              !disabled && setDatePickerVisible(!datePickerVisible)
            }
          >
            {moment(scheduledFor).format(displayDateFormat)}
          </span>
          {datePickerVisible && (
            <div
              className="tw-absolute"
              style={{ transform: "translate(-35%, -70%)", zIndex: 1000 }}
            >
              <DateTimePicker
                selectedDate={scheduledFor}
                onChange={onDateChange}
                timePreference={timePreference}
                onClose={() => setDatePickerVisible(false)}
              />
            </div>
          )}
        </DisplayBox>
      )}
      {selected === `${POST_STATES.SCHEDULED}-NOW` && (
        <DisplayBox className="tw-bg-primary-200 tw-text-black">
          Scheduled to post ASAP
        </DisplayBox>
      )}
      {selected === POST_STATES.SCHEDULED && isSummary && (
        <DisplayBoxSuccess>
          Posted: {moment(scheduledFor).format(displayDateFormat)}
        </DisplayBoxSuccess>
      )}
    </div>
  );
};

const DisplayBox = ({ children, className = "" }) => {
  return (
    <div
      className={`tw-flex tw-items-center tw-rounded-lg tw-font-medium tw-px-4 tw-flex-wrap ${className}`}
    >
      {children}
    </div>
  );
};
const DisplayBoxSuccess = styled.div`
  height: 48px;
  background: #f0f8e7;
  color: #66b913;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 5px;
`;
const ActionSelect = styled(Select)`
  font-size: 14px;
  line-height: 38px;
  min-width: 190px;
`;
const StateOption = styled.div`
  font-size: 14px;
`;
const StateLabel = styled.div`
  font-size: 14px;
  line-height: 38px;
`;

export default PostFormActions;
