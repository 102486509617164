import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Icon, Tooltip, Button } from "@dialogueconsulting/sked-ui";
import { FIELDS } from "constants/PostFormFields";
import { SmallLoadingSpinner } from "ui/LoadingSpinner/LoadingSpinner";
import { PlatformIcon } from "entities/accounts";
import { useTrackEvent } from "libs/analytics";
import { COVER_IMAGE_SELECTED } from "constants/Events";
import { toast } from "react-toastify";
import { MediaModal } from "features/media-library";
import { useOpenableState } from "libs/use-openable-state";
import { VideoCoverSelectorProps } from "./types";
import {
  allowedPlatformFullnameMapping,
  allowedSelectCoverPlatforms,
  allowedUploadCoverPlatforms,
} from "./const";
import {
  getVideoPreviewImage,
  isSafariLikeUserAgent,
  resolveToastPlatformName,
} from "./utils";
import { Alert } from "ui";
import { AlertType } from "ui/alert";
import { VideoFrameSelector } from "./FrameSelector";

export const VideoCoverSelector = ({
  videoUrl,
  isLoading: isLoadingVideo,
  videoMedia,
  thumbnails,
  updateField,
  videoCoverImageTimestamp,
  videoCoverPreview = "",
  originalCover = "",
  thumbnailUrl,
  selectedPlatform,
  platforms,
  disabled,
  videoLoadingError,
}: VideoCoverSelectorProps) => {
  const trackEvent = useTrackEvent();
  const [isLoadingThumb, setIsLoadingThumb] = useState(false);
  const [cover, setCover] = useState(videoCoverPreview);
  const [isEditing, setIsEditing] = useState(false);

  const isLoading = isLoadingVideo || isLoadingThumb;
  const allowUpload = useMemo(() => {
    if (videoLoadingError) {
      return true;
    }

    if (!videoUrl) {
      return false;
    }

    if (allowedUploadCoverPlatforms.includes(selectedPlatform)) {
      return true;
    }

    return platforms
      .filter((platform) => allowedSelectCoverPlatforms.includes(platform.TYPE))
      .every((platform) => allowedUploadCoverPlatforms.includes(platform.TYPE));
  }, [videoLoadingError, videoUrl, selectedPlatform, platforms]);

  useEffect(() => {
    if (isLoadingVideo) {
      return;
    }

    if (!videoUrl) {
      if (originalCover) {
        setCover("");
        updateField(FIELDS.VIDEO_COVER_ORIGINAL, "");
        updateField(FIELDS.VIDEO_COVER_PREVIEW, "");
        updateField(FIELDS.VIDEO_COVER_IMAGE_TIMESTAMP, 0);
      }

      return;
    }

    const getOriginalCover = async () => {
      setIsLoadingThumb(true);
      try {
        let thumb;

        if (videoCoverPreview) {
          thumb = videoCoverPreview;
          // FOR IG upload cover
        } else if (thumbnailUrl && videoCoverImageTimestamp === -1) {
          thumb = thumbnailUrl;
        } else {
          thumb = await getVideoPreviewImage(
            videoUrl,
            (videoCoverImageTimestamp || 0) / 1000
          );
        }

        setCover(thumb);
        updateField(FIELDS.VIDEO_COVER_ORIGINAL, thumb);
        updateField(FIELDS.VIDEO_COVER_PREVIEW, thumb);
      } catch (error) {
        console.log(error);

        toast.error(
          <ToastWrap>
            <Icon name="x-circle" size={18} color="#FA4F38" />
            <span>{`There was an issue selecting your cover, please try again or contact Support.`}</span>
          </ToastWrap>
        );
      } finally {
        setIsLoadingThumb(false);
      }
    };

    if (!originalCover) {
      getOriginalCover();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    originalCover,
    videoUrl,
    isLoadingVideo,
    thumbnailUrl,
    videoCoverImageTimestamp,
  ]);

  const onCoverChanged = (
    cover: string,
    timestampInMs: number,
    message?: string
  ) => {
    setCover(cover);
    updateField(FIELDS.VIDEO_COVER_PREVIEW, cover);
    updateField(FIELDS.VIDEO_COVER_IMAGE_TIMESTAMP, timestampInMs);

    if (message) {
      toast.success(
        <ToastWrap>
          <Icon name="check-circle-broken" size={18} color="#fff" />
          <span>{message}</span>
        </ToastWrap>
      );
    }
    trackEvent({
      eventName: COVER_IMAGE_SELECTED,
      metadata: {
        platforms,
        selected: "Frame", //TODO: Can be Frame or Upload in the future
      },
      useServices: ["AMP"],
    });
  };

  const onCoverUploaded = (url: string) => {
    setCover(url);
    updateField(FIELDS.VIDEO_COVER_PREVIEW, url);
    updateField(FIELDS.THUMBNAILURL, url);
    // FOR IG upload cover
    updateField(FIELDS.VIDEO_COVER_IMAGE_TIMESTAMP, -1);
    trackEvent({
      eventName: COVER_IMAGE_SELECTED,
      metadata: {
        platforms,
        selected: "Upload", //TODO: Can be Frame or Upload in the future
      },
      useServices: ["AMP"],
    });
  };

  const displayPlatforms = platforms.filter((platform) =>
    allowedSelectCoverPlatforms.includes(platform.TYPE)
  );

  const platformFullname =
    allowedPlatformFullnameMapping[selectedPlatform] || "";

  return (
    <Wrap>
      {isSafariLikeUserAgent() && (
        <Alert
          type={AlertType.warning}
          icon={true}
          isExpandable={true}
          title="Safari Compatibility Issue"
          defaultExpanded={false}
        >
          Looks like you are using Safari. The cover selection might not work
          properly due to technical limitations. Please use a different browser
          e.g. Google Chrome or Firefox. Contact support if you have any
          questions.
        </Alert>
      )}
      <Head>
        <Title>Cover</Title>
        {!isEditing &&
          selectedPlatform === "LINKED" &&
          displayPlatforms.map((platform) => (
            <PlatformIcon
              key={platform.TYPE}
              type={platform.NAME}
              css="width: 14px; height: 14px; margin-left: 5px;"
            />
          ))}
        {isEditing && (
          <Tooltip
            text="Drag or click on a thumbnail and click 'done' to finalize your selection."
            side="right"
            textSize="12px"
            contentWidth="450px"
            container={
              document.querySelector(
                ".modal-giselle.modal-dialog"
              ) as HTMLElement
            }
          >
            <div style={{ position: "relative" }}>
              <Icon name="alert-circle" color="#333333" size={16} />
            </div>
          </Tooltip>
        )}
      </Head>

      {isEditing && (
        <VideoFrameSelector
          videoUrl={videoUrl}
          thumbnails={thumbnails}
          media={videoMedia}
          isLoading={isLoading}
          onClose={() => setIsEditing(false)}
          onChange={(cover: string, timestamp: number) =>
            onCoverChanged(
              cover,
              timestamp,
              `${platformFullname} cover updated.`
            )
          }
        />
      )}

      {!isEditing && (
        <Content>
          {isLoading ? (
            <Cover>
              <SmallLoadingSpinner />
            </Cover>
          ) : (
            <Cover>
              {cover || originalCover ? (
                <img src={cover || originalCover} alt="video-cover" />
              ) : (
                <Icon name="image-01" color="#333333" size={16} />
              )}
            </Cover>
          )}
          {!disabled && (
            <Actions>
              {videoMedia && (
                <Button
                  hierarchy="secondary"
                  onClick={() => setIsEditing(true)}
                  text="Select cover"
                />
              )}
              {allowUpload && (
                <VideoCoverUploadButton
                  onUpload={onCoverUploaded}
                  platformName={resolveToastPlatformName(selectedPlatform)}
                />
              )}
              {cover && cover !== originalCover && (
                <Button
                  hierarchy="tertiary"
                  onClick={() => {
                    onCoverChanged(
                      originalCover,
                      videoCoverImageTimestamp || 0,
                      `The ${platformFullname} video cover has been reverted to original.`
                    );
                  }}
                  text="Revert to original"
                />
              )}
            </Actions>
          )}
        </Content>
      )}
    </Wrap>
  );
};

function VideoCoverUploadButton({
  onUpload,
  platformName,
}: {
  onUpload: (url: string) => void;
  platformName: string;
}) {
  const MediaLibraryModal = useOpenableState();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelect = ([media]: any[]) => {
    if (media.type !== "image") {
      toast.error("Selected media is not an image.");
      return;
    }

    if (media?.meta?.width < 1080) {
      toast.error(
        "Selected image has width less than Instagram's recommended size, they may be pixelated."
      );
      return;
    }

    onUpload(media?.url);

    toast.success(`The ${platformName} cover has been successfully updated.`);
    MediaLibraryModal.close();
  };

  return (
    <>
      <MediaModal
        show={MediaLibraryModal.isOpened}
        onHide={MediaLibraryModal.close}
        limit={1}
        onSelect={onSelect}
        selectedAccountIds={[]}
      />
      <Button
        hierarchy="tertiary"
        onClick={MediaLibraryModal.open}
        text="Upload cover"
      />
    </>
  );
}

const Wrap = styled.div`
margin: 16px 0;
}
`;
const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const Title = styled.div`
  color: var(--Text-T01, #333);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  margin-right: 8px;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
`;
const Cover = styled.div`
  width: 64px;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--Neutral-N200, #c4c4c4);
  background: var(--Neutral-N50, #f2f2f2);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Actions = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ToastWrap = styled.div`
  display: flex;
  span {
    margin-left: 10px;
  }
`;
