import React, { useMemo } from "react";
import styled from "styled-components";

import { getAccountNameParts, getPlatformNameType } from "utils/accounts";
import AccountAvatar from "components/AccountAvatar/AccountAvatar";
import { isPausedAccount } from "entities/accounts/libs/get-account-statuses";
import WarningAlertIcon from "entities/accounts/components/accounts-group-template/warningAlert.svg";
import { AccountType } from "shared/types/accounts";
import {
  doesAccountNeedApiReconnect,
  isAccountPostingAndInsightsEnabled,
} from "entities/accounts";

export const SelectOptionLabel = ({
  account,
}: {
  account: AccountType;
}): JSX.Element => {
  const [, name] = getAccountNameParts(account);

  const { platformType, platformName } = getPlatformNameType(account);

  const needReconnection = !(
    doesAccountNeedApiReconnect(account) ||
    isAccountPostingAndInsightsEnabled(account)
  );

  const disconnected = useMemo(() => {
    return (
      !isPausedAccount(account) &&
      (account?.status === "failed" || account?.promptApiReconnect)
    );
  }, [account]);

  return (
    <Container>
      <AccountAvatarStyled
        profilePictureUrl={account.profilePictureUrl}
        platformType={platformType}
        color={account.color}
        size={"small"}
        placement={"top"}
        tooltip={platformName}
        disconnected={disconnected}
        useYtRedPlayIcon
      />
      {needReconnection && <DisconnectWarningIcon />}
      <AccountName>{name}</AccountName>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100% !important;
  position: relative;
`;

const DisconnectWarningIcon = styled(WarningAlertIcon)`
  color: #fa4f38;
  position: absolute;
  top: -3px;
  left: -3px;
  height: 13px;
  width: 13px;
`;

const AccountAvatarStyled = styled(AccountAvatar)`
  margin-right: 0.5rem;
`;
const AccountName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
