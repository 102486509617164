import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

import { openModal, closeModal } from "libs/modals";
import { useTrackEvent } from "libs/analytics";
import {
  Button,
  FormLine,
  TextInput,
  FormSize,
  FormThemeProvider,
} from "ui/ecosystems/forms";

import ArrowIcon from "assets/icons/ico-arrow-white.svg";

import { ADD_USER_CLICKED } from "constants/Events";
import { PostFieldError } from "components/Posts/PostForm";

import { EDIT_USER_MODAL, ADD_USER_MODAL } from "../../UserModals";
import { selectAllUsers } from "features/collaboration/users-model";
import { deleteSearchParamWhenCloseModal } from "libs/modals/init";

export const AddUserModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const users = useSelector(selectAllUsers);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState({});
  const disabled = !(email && firstName && lastName);

  function addUser(event: MouseEvent) {
    // prevent html form submit
    event.preventDefault();
    event.stopPropagation();
    const usedEmail =
      users.length > 0 && users.find((user) => user.email === email);

    if (usedEmail) {
      const saveError = {};
      saveError["SAVE_ERROR"] = {
        errors: ["This email is already invited."],
      };
      setErrors(saveError);
    } else {
      trackEvent({ eventName: ADD_USER_CLICKED });

      dispatch(closeModal({ type: ADD_USER_MODAL }));
      dispatch(
        openModal({
          type: EDIT_USER_MODAL,
          props: {
            userInfo: {
              email,
              firstName,
              lastName,
            },
          },
        })
      );
    }
  }

  function closeAddModal() {
    deleteSearchParamWhenCloseModal(ADD_USER_MODAL);
    dispatch(closeModal({ type: ADD_USER_MODAL }));
  }

  return (
    <div>
      <Modal
        data-testid="add-user-modal"
        show
        onHide={closeAddModal}
        animation={false}
        // @ts-ignore
        bsStyle="giselle"
        // @ts-ignore
        bsSize="md-reasonable"
      >
        <Form name="inviteForm">
          <div className="modal-header">
            <button type="button" className="close" onClick={closeAddModal}>
              &times;
            </button>
            <h4>Add a user</h4>
          </div>
          <div className="modal-body">
            <p>
              Add team member to access the Sked dashboard. In the next step,
              you’ll be able to choose which accounts they will have access to.
            </p>
            <FormThemeProvider size={FormSize.large}>
              <FormLineComponent label="Email">
                <TextInput
                  onChange={setEmail}
                  required
                  autoFocus
                  placeholder="quirky@skedsocial.com"
                />
                <PostFieldErrorStyled fields={["SAVE_ERROR"]} errors={errors} />
              </FormLineComponent>
              <FormLineComponent label="First Name">
                <TextInput
                  onChange={setFirstName}
                  required
                  placeholder="Jane"
                />
              </FormLineComponent>
              <FormLineComponent label="Last Name">
                <TextInput
                  onChange={setLastName}
                  required
                  placeholder="Austen"
                />
              </FormLineComponent>
            </FormThemeProvider>
          </div>

          <Actions>
            <Cancel size={FormSize.large} text onClick={closeAddModal}>
              Cancel
            </Cancel>

            <Submit
              type="submit"
              primary
              size={FormSize.large}
              disabled={disabled}
              onClick={addUser}
            >
              Add user and set permission
              <ArrowIcon />
            </Submit>
          </Actions>
          <Steps>
            <Step active />
            <Step />
          </Steps>
        </Form>
      </Modal>
    </div>
  );
};

const Cancel = styled(Button)`
  color: ${colors.primary600};
  font-weight: 300;
  text-decoration: none;
  &:not(:disabled):hover {
    color: ${colors.primary700};
    font-weight: 500;
  }
`;
const Submit = styled(Button)`
  border-radius: 10px;
  column-gap: 10px;
  :disabled {
    svg {
      fill: ${colors.neutral700};
    }
  }
`;

const FormLineComponent = styled(FormLine)`
  margin-bottom: 10px;
`;

const Form = styled.form`
  padding-bottom: 24px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  margin-top: 20px;
`;

const Steps = styled.div`
  display: flex;
  margin-top: 54px;
  justify-content: center;
  column-gap: 4px;
`;

const Step = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${colors.neutral400};
  border-radius: 50%;
  ${(props: { active?: boolean }) =>
    props.active &&
    `
    background-color: ${colors.primary500};
  `}
`;

const PostFieldErrorStyled = styled(PostFieldError)`
  margin-top: 5px;
`;
