import { searchNewHashtag, selectExistingHashtag } from "actions/sourceMedia";
import {
  DEFAULT_HASHTAG_FILTER,
  PAGES,
  WEEKLY_HASHTAG_SEARCH_MAX,
} from "constants/MediaLibrary";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "styles/color-tokens";

import { getSelectedIgAccount } from "selectors/hashtagFeedSelectors";
import styled from "styled-components";
import { CollectionFilesList } from "features/media-library/components/collection-files-list";
import { HashtagSelector } from "features/media-library/components/media-picker/hashtag/hashtag-selector";
import { PickerProps } from "features/media-library/components/media-picker/media-picker";
import { CollectionFile } from "shared/types/files";
import { LoadingCover } from "ui/cover";
import moment from "moment";
import {
  EmptyAlertWrapper,
  Title,
  Toolbar,
  ToolbarLeft,
  ToolbarRight,
} from "../components/common";
import { Hashtag } from "../types";
import { feedMediaToCollectionFile } from "../helper";
import { AccountSelector } from "../components/account-selector";
import { Modal } from "react-bootstrap";
import { SkedButton, TooltipWrapper } from "ui";
import { BUTTON_TYPES } from "constants/SkedButton";
import InfoIcon from "assets/icons/question-dark.svg";
import ShareIcon from "assets/icons/share.svg";
import { LoadMore } from "../components/load-more";
import { useHashtagFeed } from "components/MediaLibrary/SourceMedia/HashtagFeed/HashtagFeed";
import { useHashtags } from "components/MediaLibrary/LibrarySidebar/SourceMediaSidebar/HashtagSidebar/HashtagSidebar";
import { UpsellWrap, UpsellUGCCTA } from "../creator/creator-picker";
import { getUser } from "selectors/commonSelectors";

export function HashtagPicker(props: PickerProps) {
  const dispatch = useDispatch();
  const { feedMedia, selectedIgAccount, loading } = useHashtagFeed();
  const { activeHashtags, expiredHashtags, selectedHashtag, loadingHashtags } =
    useHashtags();
  const [showExpiredListModal, setShowExpiredListModal] = useState(false);

  const files: CollectionFile[] = (feedMedia || []).map(
    feedMediaToCollectionFile
  );

  const onHashtagChanged = React.useCallback(
    (hashtagId) => {
      if (!selectedIgAccount?._id) {
        return;
      }

      dispatch(
        selectExistingHashtag(selectedIgAccount._id, hashtagId, {
          ...DEFAULT_HASHTAG_FILTER,
          excludeAccounts: [selectedIgAccount._id],
        })
      );
    },
    [selectedIgAccount?._id]
  );

  React.useEffect(() => {
    if (
      !loading &&
      !loadingHashtags &&
      !selectedHashtag &&
      activeHashtags?.length > 0
    ) {
      onHashtagChanged(activeHashtags[0]?.hashtag_id);
    }
  }, [
    loading,
    loadingHashtags,
    selectedHashtag,
    activeHashtags,
    onHashtagChanged,
  ]);

  const user = useSelector(getUser);
  const upsell = Boolean(user?.plan?.includes("fundamentals"));

  if (upsell) {
    return (
      <UpsellWrap>
        <strong>1. Add the hashtag you want to track</strong>
        <p>You can track up to 30 hashtags at a time</p>

        <strong>2. Check your results</strong>
        <p>
          See all public posts with the hashtag, and sort by recent or top posts
        </p>

        <strong>3. Select the posts you want to add</strong>
        <p>
          Add one or multiple posts to a Library Collection. Your images are
          automatically tagged by Al so you can find them later
        </p>

        <strong>4. Post your new UGC</strong>
        <p>
          Schedule your UGC content to Instagram, Pinterest, Facebook, Twitter
          Linkedln, or Google Business Profile. Automatically saved metadata
          makes sure you're acknowledging the original creator
        </p>
        <UpsellUGCCTA />
      </UpsellWrap>
    );
  }

  return (
    <LoadingCover isLoading={loading || loadingHashtags}>
      <Toolbar>
        <ToolbarLeft>
          <AccountSelector type="hashtag" />
          <HashtagInfo>
            {`${activeHashtags.length}/${WEEKLY_HASHTAG_SEARCH_MAX}`}
          </HashtagInfo>
          <TooltipWrapper
            tooltip="30 results per Instagram account in a 7 day rolling period"
            placement="bottom"
          >
            <InfoIconStyled />
          </TooltipWrapper>
        </ToolbarLeft>

        <ToolbarRight>
          <HashtagSelector
            hashtags={activeHashtags}
            account={selectedIgAccount}
            openExpiredListModal={() => setShowExpiredListModal(true)}
            showExpiredListModal={expiredHashtags.length > 0}
            onHashtagChanged={onHashtagChanged}
          />
        </ToolbarRight>
        {expiredHashtags.length > 0 && (
          <ExpiredHashtagsListModal
            show={showExpiredListModal}
            onHide={() => setShowExpiredListModal(false)}
            data-testid="expired-hashtags-model"
            bsStyle="giselle"
          >
            <ExpiredHashtagsListModalHeader closeButton>
              <ExpiredHashtagsListModaTitle>
                Expired hashtags
              </ExpiredHashtagsListModaTitle>
            </ExpiredHashtagsListModalHeader>
            <ExpiredHashtagsListModalBody>
              <p>
                Instagram limits users to 30 hashtags on a rolling 7 day period.
                Click the preview icon to make sure that you’re choosing the
                right tag before reactivating.
              </p>
              <ExpiredHashtagsList
                hashtags={expiredHashtags}
                onChange={() => setShowExpiredListModal(false)}
              />
              <ExpiredHashtagsListModalButton
                size="small"
                type={BUTTON_TYPES.ALERT_OUTLINE}
                onClick={() => setShowExpiredListModal(false)}
              >
                Cancel
              </ExpiredHashtagsListModalButton>
            </ExpiredHashtagsListModalBody>
          </ExpiredHashtagsListModal>
        )}
      </Toolbar>

      {!selectedHashtag ? (
        expiredHashtags.length > 0 ? (
          <ExpiredHashtagsAlertWrapper>
            <h3>Search for hashtags</h3>
            <p>
              Search for a new hashtag or select from a previously used hashatg.
              30 hashtags can be searched. and each will expire after 7 days.
            </p>
            <ExpiredHashtagsList hashtags={expiredHashtags} />
          </ExpiredHashtagsAlertWrapper>
        ) : (
          <EmptyAlert />
        )
      ) : (
        <>
          <Title>
            {`#${selectedHashtag.name}`}{" "}
            <span>Results from the last 24 hours. Expires in 7 days</span>
          </Title>
          <CollectionFilesList
            files={files}
            selectedMediaSet={props.selectedMediaFiles}
            selectedCollection={props.selectedCollection}
            onSelect={props.handleFileAdd}
            onRemove={props.handleFileRemove}
          />
          {files.length > 0 && <LoadMore page={PAGES.HASHTAGS} />}
        </>
      )}
    </LoadingCover>
  );
}

function EmptyAlert() {
  return (
    <EmptyAlertWrapper>
      <h3>Search your brand’s hashtags.</h3>
      <p>
        Have a hashtag that your followers use to get your attention? Search a
        hashtag to source content directly from Instagram into your posts.
      </p>
      <p>
        You can search up to 30 hashtags at one time and each hashtag will
        expire after 7 days. Only posts within the last 24 hours will appear.
      </p>
    </EmptyAlertWrapper>
  );
}

function ExpiredHashtagsList({
  hashtags,
  onChange,
}: {
  hashtags: Hashtag[];
  onChange?: () => void;
}) {
  const dispatch = useDispatch();
  const account = useSelector(getSelectedIgAccount);

  const onReactivate = (hashtag: Hashtag) => {
    dispatch(
      searchNewHashtag(account._id, hashtag.name.toLowerCase(), "picker")
    );
    onChange?.();
  };
  return (
    <ExpiredHashtagsListWrapper>
      {(hashtags || []).map((hashtag) => {
        const expiredDate = hashtag.search_date
          ? moment().to(moment(hashtag.search_date).add(7, "days"), true)
          : "Unknown";

        return (
          <HashtagRow key={hashtag.hashtag_id}>
            <HashtagRowName>#{hashtag.name}</HashtagRowName>
            <HashtagRowDate>{`Expired ${expiredDate} ago`}</HashtagRowDate>
            <HashtagRowAction>
              <OpenHashtag
                target="_blank"
                rel="norefferrer"
                href={`https://www.instagram.com/explore/tags/${hashtag.name}`}
              >
                <ShareIcon />
              </OpenHashtag>
              <ReactivateButton onClick={() => onReactivate(hashtag)}>
                Reactivate
              </ReactivateButton>
            </HashtagRowAction>
          </HashtagRow>
        );
      })}
    </ExpiredHashtagsListWrapper>
  );
}

const HashtagRow = styled.div`
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 8px 0;

  > div {
    flex-basis: calc(100% / 3);
  }
`;
const HashtagRowName = styled.div`
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #333333;
  width: 135px;
  overflow: hidden;
`;
const HashtagRowDate = styled.div`
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #c4c4c4;
`;
const HashtagRowAction = styled.div`
  display: flex;
  justify-content: end;
`;
const ReactivateButton = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 450;
  text-decoration: underline;
  display: flex;
  align-items: center;
  text-align: right;
  color: #4f31ff;
  cursor: pointer;
`;

const OpenHashtag = styled.a`
  margin-right: 10px;
  margin-top: 3px;
`;

const ExpiredHashtagsAlertWrapper = styled.div`
  width: 436px;
  min-height: 215px;
  margin: 100px auto;

  h3 {
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    margin-top: 20px;
  }
`;

const ExpiredHashtagsListWrapper = styled.div``;

const HashtagInfo = styled.b`
  margin-left: 10px;
`;

const ExpiredHashtagsListModal = styled(Modal)`
  margin-top: 100px;
`;

const ExpiredHashtagsListModalHeader = styled(Modal.Header)`
  background: #fff;
  button {
    color: #333333;
  }
`;

const ExpiredHashtagsListModaTitle = styled(Modal.Title)`
  color: #343434;
`;

const ExpiredHashtagsListModalBody = styled(Modal.Body)`
  p {
    font-size: 14px;
    line-height: 17px;
    color: ${colors.neutral700};
  }
`;
const ExpiredHashtagsListModalButton = styled(SkedButton)`
  margin-top: 20px;
`;

const InfoIconStyled = styled(InfoIcon)`
  position: relative;
  top: 1px;
  margin-left: 10px;
`;
