/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";
import { Post } from "shared/types/post";
import { getAllContentLabels } from "api/content-labels";
import { getGroups } from "api/accountGroups";
import { ContentLabel } from "@workshop/types";
import { getUser } from "selectors/commonSelectors";
import { useSelector } from "react-redux";

export const useLabels = ({ postFormValue }: { postFormValue: Post }) => {
  const [isFetchingAccountGroups, setIsFetchingAccountGroups] = useState(true);
  const cachedAccountGroupsRef = useRef<AccountGroup[]>();
  const cachedAllLabelsRef = useRef<ContentLabel[]>();

  const user = useSelector(getUser);

  const isContentLabelFeatureEnabled =
    user.plan !== "sked-fundamentals" &&
    user.plan !== "sked-fundamentals-annual" &&
    user.plan !== "sked-essentials" &&
    user.plan !== "sked-essentials-annual";

  function getSelectedGroupId() {
    return filterGroupsByAccounts(
      cachedAccountGroupsRef.current || [],
      postFormValue?.accountIds || []
    )?.[0]?._id;
  }

  async function fetchGroups() {
    cachedAccountGroupsRef.current =
      cachedAccountGroupsRef.current ||
      (await asyncDataMemoryCache.get("accountGroups", () => getGroups())).data
        .items;
  }
  async function fetchAllLabels() {
    cachedAllLabelsRef.current =
      cachedAllLabelsRef.current ||
      (await asyncDataMemoryCache.get("labels", () => getAllContentLabels()))
        .data;
  }
  useEffect(() => {
    async function handleAccountsChange() {
      try {
        setIsFetchingAccountGroups(true);
        if (!postFormValue || postFormValue.accountIds.length === 0) {
          return;
        }

        await Promise.all([fetchGroups(), fetchAllLabels()]);
      } catch (e) {
        console.error(e);
      } finally {
        setIsFetchingAccountGroups(false);
      }
    }

    handleAccountsChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postFormValue && postFormValue.accounts]);

  const selectedAccountGroupId = getSelectedGroupId();
  const contentLabelOptions = (cachedAllLabelsRef.current || [])
    .filter(
      (d) =>
        d.status === "ACTIVE" &&
        d.groupId === selectedAccountGroupId &&
        selectedAccountGroupId
    )
    .map((d) => ({
      value: d._id || "",
      label: d.name,
      isCampaign: d.type === "CAMPAIGN",
      emoji: d.emoji,
      color: d.color,
    }));
  return {
    isFetchingAccountGroups,
    selectedAccountGroupId,
    selectedContentLabel: (postFormValue?.labelIds || [])
      .map((c) => contentLabelOptions.find((d) => d.value === c))
      .filter(Boolean),
    contentLabelOptions,
    isContentLabelFeatureEnabled,
  };
};

interface AccountGroup {
  accountIds: string[];
  _id: string;
}

function filterGroupsByAccounts(
  groupData: AccountGroup[],
  accountIds: string[]
) {
  return groupData.filter((group) =>
    group.accountIds.some((accountId) => accountIds.includes(accountId))
  );
}

interface CacheData {
  data: any;
  key: string;
  timestamp: number;
}
class AsyncDataMemoryCache {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public cacheTimeout = 1000 * 60; //60 seconds
  private runningPromises = new Map<string, Promise<any>>();
  private cacheData = new Map<string, CacheData>();
  public async get<T>(key: string, func: () => Promise<T>): Promise<T> {
    const cachedData = this.cacheData.get(key);
    if (cachedData && Date.now() - cachedData.timestamp < this.cacheTimeout) {
      return cachedData.data;
    }

    const result = await this.withinSinglePromise(key, func);
    this.cacheData.set(key, {
      data: result,
      key,
      timestamp: Date.now(),
    });
    return result;
  }
  private async withinSinglePromise<T>(
    key: string,
    func: () => Promise<T>
  ): Promise<T> {
    const runningPromise = this.runningPromises.get(key);
    if (runningPromise) {
      return await runningPromise;
    }

    try {
      const promise = func();
      this.runningPromises.set(key, promise);
      const result = await promise;
      return result;
    } finally {
      this.runningPromises.delete(key);
    }
  }

  public clearCache() {
    this.cacheData.clear();
  }
}

export const asyncDataMemoryCache = new AsyncDataMemoryCache();
