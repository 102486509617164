export function resolveToastPlatformName(platform: string) {
  switch (platform) {
    case "TT":
      return "Tiktok";
    case "IG":
      return "Instagram Reel";
    default:
      return "";
  }
}

export function isSafariLikeUserAgent() {
  return (
    navigator.userAgent.includes("Safari") &&
    !navigator.userAgent.includes("Chrome")
  );
}

export function getVideoPreviewImage(
  videoUrl: string,
  timestamp: number
): Promise<string> {
  return new Promise((resolve, reject) => {
    // Dynamically create a video element
    const videoElement = document.createElement("video");
    videoElement.style.display = "none"; // Hide the video element
    videoElement.crossOrigin = "Anonymous";
    document.body.appendChild(videoElement); // Append it to the body to ensure it can load and play

    // Dynamically create a canvas element
    const canvasElement = document.createElement("canvas");
    const context = canvasElement.getContext("2d");

    videoElement.addEventListener("loadedmetadata", () => {
      // Seek to the specified timestamp once the video metadata is loaded
      videoElement.currentTime = Math.min(timestamp, videoElement.duration);
    });

    videoElement.addEventListener("seeked", () => {
      // Ensure the canvas size matches the video frame size
      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;

      // Draw the video frame to the canvas
      context?.drawImage(
        videoElement,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );

      // Convert the canvas to a data URL and resolve the promise with it
      resolve(canvasElement.toDataURL());

      // Clean up by removing the video and canvas elements
      videoElement.remove();
      canvasElement.remove();
    });

    videoElement.addEventListener("error", () => {
      // Clean up in case of error
      videoElement.remove();
      canvasElement.remove();
      reject(new Error("Error loading video"));
    });

    // Set the video source to trigger loading
    videoElement.src = videoUrl;
    videoElement.load(); // Explicitly load the video
  });
}

export const convertVideoUrlToBlob = async (url: string) => {
  try {
    // Fetch the video from the URL
    const response = await fetch(url);
    // Ensure the fetch was successful
    if (!response.ok) {
      throw new Error(`Failed to fetch video: ${response.statusText}`);
    }
    // Convert the video data to a Blob object
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error("Error converting video URL to Blob:", error);
    return Promise.resolve(undefined);
  }
};
