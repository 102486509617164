import styled, { css } from "styled-components";
import { colors } from "styles/color-tokens";

interface ItemProps {
  disabled?: boolean;
  selected?: boolean;
}

export const Item = styled.div<ItemProps>`
  min-height: 29px;
  word-break: keep-all;
  white-space: nowrap;
  padding: 8px 10px;
  line-height: 1.6rem;
  cursor: pointer;
  :hover {
    background-color: ${colors.neutral100};
  }
  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.3;
      cursor: not-allowed;
    `}

  ${(props) => {
    return props.selected
      ? css`
          background-color: ${colors.primary500};
          color: ${colors.white};

          &:hover {
            background-color: ${colors.primary500};
            color: ${colors.white};
          }
        `
      : null;
  }}
`;
