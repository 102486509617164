import React from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

import { Button as SkedButton } from "@dialogueconsulting/sked-ui";
import { showUpsellModal } from "actions/typed-modal";
import { useDispatch } from "react-redux";
import { useTrackEvent } from "libs/analytics";
import { UPGRADE_LIBRARY_VIEWED } from "constants/Events";

export function SMSplash({
  children,
  title,
  subtitle,
}: {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
}) {
  return (
    <Root>
      <Inner>
        <Title>{title}</Title>
        {subtitle && (
          <Subtitle css="margin-top: var(--spacing);">{subtitle}</Subtitle>
        )}
        <Content css="margin-top: var(--spacing);">{children}</Content>
      </Inner>
    </Root>
  );
}

const Root = styled.div`
  --spacing: 12px;
  flex-grow: 1;
  padding: calc(var(--spacing) * 2);
  overflow: auto;
`;

const Inner = styled.div`
  min-height: 100%;
  border: 1px dashed ${colors.primary500};
  background: ${colors.primary50};
  border-radius: 5px;
  padding: calc(var(--spacing) * 2);
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 24px;
  line-height: 1.31;
  color: ${colors.neutral800};
  margin: 0;
  text-align: center;
`;

const Subtitle = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 131%;
  color: ${colors.neutral700};
  margin: 0;
  text-align: center;
`;

const Content = styled.div`
  font-size: 16px;
  line-height: 1.31;
  color: ${colors.neutral700};
  font-weight: 300;

  li,
  p {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    font-weight: inherit;
    margin: 0;
  }

  b {
    color: ${colors.neutral800};
    font-weight: 900;
  }
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const List = styled.ul`
  list-style: symbols;
  padding-left: calc(var(--spacing) * 2);

  li {
    ::marker {
      font-weight: 700;
      font-size: inherit;
      line-height: inherit;
    }

    &:not(:first-child) {
      margin-top: calc(var(--spacing) * 1.2);
    }

    p:not(:first-child) {
      margin-top: calc(var(--spacing) * 0.5);
    }
  }
`;

export const AddLibraryButton = () => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  return (
    <UpsellButtonStyled
      text="Add Library to your plan"
      endIcon="upsell-rocket-white-bg"
      onClick={() => {
        dispatch(showUpsellModal("upsell", "user-generated-content"));
        trackEvent({
          eventName: UPGRADE_LIBRARY_VIEWED,
          useServices: ["AMP", "AC", "HS"],
        });
      }}
      size="md"
      useDefaultIconColor
    />
  );
};

const UpsellButtonStyled = styled(SkedButton)`
  margin: 10px auto;

  p {
    margin-bottom: 0;
    margin-left: 0;
    color: ${colors.white};
  }
`;
