import { ContentLabel } from "@workshop/types";
import axios from "axios";
import { getLocalStorageSkedApiToken } from "libs/storage/adapters";
import { AccountPlatformType } from "shared/types/accounts";
import {
  BrandSummaryApiResponse,
  buildPeriodPair,
  RequestAccount,
} from "./insights-api-helper";
import moment from "moment";
import { ReportBuilderPermission } from "features/collaboration/components/edit-user/edit-user";
import { InsightAccount } from "./types/insights";
import { SLRequestParams, SLSummaryResponse } from "./types/social-listening";

export const insightsAxios = axios.create();

const INSIGHTS_DATE_REQUEST_FORMAT = "YYYY-MM-DDTHH:mm:ss";

class InsightsApi {
  public skedApiToken: string | undefined;
  public insightsApiToken: string | undefined;
  private lastInsightsApiTokenPromise: Promise<InsightsApiAuthData> | undefined;
  private lastInsightAccounts: InsightAccount[] = [];

  async fetchSLSummary({
    accountIds,
    startDate,
    endDate,
    includeCompetitor,
  }: {
    accountIds: string[];
    startDate: Date;
    endDate: Date;
    includeCompetitor: boolean;
  }) {
    await this.fetchInsisghtsApiToken();

    const dateRange = buildPeriodPair(startDate, endDate);
    function unixToDateString(unix: number) {
      return moment.unix(unix).format(INSIGHTS_DATE_REQUEST_FORMAT);
    }
    const request: SLRequestParams = {
      accounts: this.lastInsightAccounts.filter((c) =>
        accountIds.includes(c.accountId)
      ),
      period: {
        since: unixToDateString(dateRange.thisPeriod.since),
        until: unixToDateString(dateRange.thisPeriod.until),
      },
      prevPeriod: {
        since: unixToDateString(dateRange.previousPeriod.since),
        until: unixToDateString(dateRange.previousPeriod.until),
      },
      results: {
        includeCompetitor,
      },
    };
    const url = `${this.getBaseUrl()}/api/3.0/social-listening/summary/get`;
    const result = await insightsAxios.post<SLSummaryResponse>(url, request, {
      headers: await this.getHeaders(),
    });
    console.log("SL Summary", result.data);

    return result.data;
  }

  async getOnboardingCaptions({
    nCaptions,
    accounts,
  }: {
    nCaptions: number;
    accounts: {
      _id: string;
      platformType?: AccountPlatformType;
      platformId?: string;
    }[];
  }) {
    const result = await insightsAxios.post<{ data: { captions: string[] } }>(
      `${this.getBaseUrl()}/api/3.0/captivate/captions/onboarding`,
      {
        nCaptions,
        accounts,
      },
      {
        headers: await this.getHeaders(),
      }
    );

    return result?.data?.data?.captions || [];
  }

  async getContentLabelsOnboardingCaptivate({
    accounts,
  }: {
    accounts: {
      _id: string;
      platformType?: AccountPlatformType;
      platformId?: string;
    }[];
  }) {
    console.log(
      "API, fetching labels for",
      accounts.map((c) => c._id)
    );
    const result = await insightsAxios.post<{ data: ContentLabel[] }>(
      `${this.getBaseUrl()}/api/3.0/captivate/content-labels/onboarding`,
      {
        accounts,
      },
      {
        headers: await this.getHeaders(),
      }
    );

    return result.data.data;
  }

  async getContentLabelsSuggestionsCaptivate({
    groupDescription,
    labelType,
    userInput,
  }: {
    groupDescription: string;
    labelType: "CONTENT_PILLAR" | "CAMPAIGN";
    userInput: string;
  }) {
    console.log(
      "API, fetching suggestions for",
      groupDescription,
      labelType,
      userInput
    );
    const result = await insightsAxios.post<{ data: ContentLabel[] }>(
      `${this.getBaseUrl()}/api/3.0/captivate/content-labels/suggestions`,
      {
        groupDescription,
        labelType,
        userInput,
      },
      {
        headers: await this.getHeaders(),
      }
    );

    return result.data.data;
  }

  async fetchBrandSummary({
    accounts,
    timezone,
    args,
    startDate,
    endDate,
  }: {
    accounts: RequestAccount[];
    timezone: string;
    startDate: Date;
    endDate: Date;
    args?: string;
  }) {
    const dateRange = buildPeriodPair(startDate, endDate);

    const thisRange = {
      since: `"${moment
        .unix(dateRange.thisPeriod.since)
        .format(INSIGHTS_DATE_REQUEST_FORMAT)}"`,
      until: `"${moment
        .unix(dateRange.thisPeriod.until)
        .format(INSIGHTS_DATE_REQUEST_FORMAT)}"`,
    };

    const lastRange = {
      since: `"${moment
        .unix(dateRange.previousPeriod.since)
        .format(INSIGHTS_DATE_REQUEST_FORMAT)}"`,
      until: `"${moment
        .unix(dateRange.previousPeriod.until)
        .format(INSIGHTS_DATE_REQUEST_FORMAT)}"`,
    };

    const range = `ranges=%5B%7B%22since%22%3A${
      thisRange.since
    }%2C%22until%22%3A${thisRange.until}%7D%2C%7B%22since%22%3A${
      lastRange.since
    }%2C%22until%22%3A${lastRange.until}%7D%5D&timezoneLocale=${timezone}${
      args || ""
    }`;

    const url = `${this.getBaseUrl()}/api/3.0/brands/summary?${range}`;
    const result = await insightsAxios.post<BrandSummaryApiResponse>(
      url,
      { accounts },
      {
        headers: await this.getHeaders(),
      }
    );

    return result.data;
  }

  async getSuggestionsCaptions({
    nCaptions,
    accounts,
    userInput,
    labelIds,
    groupId,
    includeEmoji,
  }: {
    nCaptions: number;
    accounts: {
      _id: string;
      platformId: string;
      platformType: string;
    }[];
    userInput: string;
    labelIds: string[];
    groupId: string;
    includeEmoji: boolean;
  }) {
    const result = await insightsAxios.post(
      `${this.getBaseUrl()}/api/3.0/captivate/captions/suggestions`,
      {
        nCaptions,
        accounts,
        userInput,
        labelIds,
        groupId,
        includeEmoji,
      },
      {
        headers: await this.getHeaders(),
      }
    );

    return result.data.data;
  }

  async getCaptivateTopicSuggestion({
    groupDescription,
    labelId,
    labelDescription,
    labelType,
  }: {
    groupDescription: string;
    labelId: string;
    labelDescription: string;
    labelType: "content-pillar" | "campaign";
  }) {
    console.log(
      "API, fetching topic suggestion for",
      groupDescription,
      labelId,
      labelDescription,
      labelType
    );
    const result = await insightsAxios.post<{
      answer: string;
    }>(
      `${this.getBaseUrl()}/api/3.0/captivate/captions/topic-suggestion`,
      {
        groupDescription,
        labelId,
        labelDescription,
        labelType,
      },
      {
        headers: await this.getHeaders(),
      }
    );

    return result.data;
  }

  async getReportBuilderNotificationCounts() {
    const { token, permission } = await this.fetchReportBuilderAuthInfo();
    const result = await insightsAxios.get<{
      newReportCount: number;
      allReportCount: number;
    }>(`${this.getBaseUrl()}/api/3.0/report-builder/pdf-reports/counts`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      ...result.data,
      permission,
    };
  }

  private async fetchReportBuilderAuthInfo() {
    const {
      data: { redirectTo },
    } = await insightsAxios.get<{ redirectTo?: string }>("/insights-sso");

    const insightsSSOToken = redirectTo?.split("?")[1].replace("token=", "");

    const result = await insightsAxios.get<{
      data: { token: string; permission: ReportBuilderPermission };
    }>(
      `${this.getBaseUrl()}/api/3.0/report-builder/auth?token=${insightsSSOToken}`
    );

    return result.data.data;
  }

  private async fetchInsisghtsApiToken() {
    if (!this.lastInsightsApiTokenPromise) {
      let skedApiToken = this.skedApiToken || getLocalStorageSkedApiToken();
      if (!skedApiToken) {
        /** wait a bit and try again */
        await new Promise((r) => setTimeout(r, 2000));
      }
      skedApiToken = this.skedApiToken || getLocalStorageSkedApiToken();
      if (!skedApiToken) {
        throw new Error("No Sked API token found");
      }

      this.lastInsightsApiTokenPromise = insightsAxios
        .get<{ data: InsightsApiAuthData }>(
          `${this.getBaseUrl()}/api/3.0/auth-by-skedapi?token=${skedApiToken}&accountDetails=true`
        )
        .then((c) => c.data.data);
    }
    try {
      const result = await this.lastInsightsApiTokenPromise;
      this.insightsApiToken = result.token;
      this.lastInsightAccounts = result.accountList;

      return this.insightsApiToken;
    } catch (e) {
      this.lastInsightsApiTokenPromise = undefined;
      throw e;
    }
  }

  private async getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${await this.fetchInsisghtsApiToken()}`,
    };
  }

  private getBaseUrl() {
    // here, uncomment this to run locally
    // return "https://api-insights-staging.skedsocial.com";

    if (process.env.NODE_ENV === "staging") {
      return "https://api-insights-staging.skedsocial.com";
    }
    if (process.env.NODE_ENV === "test" || process.env.NODE_ENV === "dev") {
      return "http://localhost:8032";
    }

    return "https://api-insights-2.skedsocial.com";
  }
}

interface InsightsApiAuthData {
  token: string;
  accountList: InsightAccount[];
}

export const insightsApi = new InsightsApi();
