import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { closeModal } from "libs/modals";

import { DELETE_USER_MODAL, EDIT_USER_MODAL } from "../../UserModals";

import { deleteUser } from "../../users-model";
import { ConfirmWithText } from "ui/modals/ConfirmWithText";

interface DeleteUserTypes {
  email: string;
  accountName: string;
}

const ConfirmText = "delete";

export const DeleteUserModal = (props: DeleteUserTypes) => {
  const { accountName, email } = props;
  const dispatch = useDispatch();

  const closeDeleteModal = () => {
    dispatch(closeModal({ type: DELETE_USER_MODAL }));
  };

  const submit = () => {
    dispatch(deleteUser({ email }));
    dispatch(closeModal({ type: EDIT_USER_MODAL }));
    dispatch(closeModal({ type: DELETE_USER_MODAL }));
  };

  return (
    <ConfirmWithText
      closeModal={closeDeleteModal}
      title={`Delete [${accountName}] User`}
      subTitle={`Are you sure you want to delete [${accountName}] from Sked?`}
      defaultConfirmText={ConfirmText}
      confirmButtonText={"Delete"}
      onSubmit={submit}
    >
      <>
        <Details>
          To confirm, enter <i>&lsquo;delete&rsquo;</i> in the field below.
        </Details>
      </>
    </ConfirmWithText>
  );
};

const Text = styled.p`
  font-size: 14px;
  line-height: 17px;
  font-weight: 300;
`;

const Details = styled(Text)`
  margin-top: 26px;
`;
