import React from "react";
import styled from "styled-components";

import { getAccountNameParts } from "utils/accounts";
import { AccountAvatar } from "entities/accounts";
import { AccountType } from "shared/types/accounts";

enum AvatarSize {
  xsmall = "xsmall",
}

export const SelectOptionLabel = ({
  account,
}: {
  account: AccountType;
}): JSX.Element => {
  const [, name] = getAccountNameParts(account);

  return (
    <Container>
      <AccountAvatar
        key={account._id}
        size={AvatarSize.xsmall}
        account={account}
        useYtRedPlayIcon
      />
      <AccountName>{name}</AccountName>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100% !important;
  position: relative;
  gap: 10px;
`;

const AccountName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  color: #333333;
`;
