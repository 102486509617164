import axios from "axios";
import {
  getLocalStorageSkedApiToken,
  setLocalStorageSkedApiToken,
} from "libs/storage/adapters";

// TODO: Change later
export let baseUrl = "https://api-inbox.skedsocial.com";

// for when you're testing the API locally
let useLocal = false;

if (process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "dev") {
  if (useLocal) {
    baseUrl = "http://localhost:5001";
  } else {
    baseUrl = "https://api-inbox-staging.skedsocial.com";
  }
  console.log("env", process.env.NODE_ENV, baseUrl);
}

const defaultOptions = {
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
};

// Create instance
export function axiosCall() {
  return axios.get("/api-jwt").then((response) => response.data.jwt);
}

export async function getToken() {
  let token = getLocalStorageSkedApiToken();
  if (token) {
    return token;
  } else {
    // gets token from Sked Core
    token = await axiosCall();
    setLocalStorageSkedApiToken(token);
    return token;
  }
}

export const skedInboxApi = axios.create(defaultOptions);
window.skedInboxApi = skedInboxApi;

skedInboxApi.interceptors.request.use(async (config) => {
  let token = getLocalStorageSkedApiToken();
  if (token) {
    // // use JWT in all calls to the external API
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.log("No localstorage token");
    // gets token from Sked Core
    token = await axiosCall();
    setLocalStorageSkedApiToken(token);
    // use JWT in all calls to the external API
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  }
  // return config;
}, null);

skedInboxApi.interceptors.response.use(null, async (error) => {
  if (error && error.response && error.response.status === 401) {
    // only remove token when 401
    localStorage.removeItem("skedApiToken");

    const token = await axiosCall();
    setLocalStorageSkedApiToken(token);

    return skedInboxApi(error.config);
  } else {
    return Promise.reject(error);
  }
});
