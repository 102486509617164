import React from "react";
import { DEFAULT_HASHTAG_FILTER, PAGES } from "constants/MediaLibrary";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "../../../../../components/CustomOverlayTrigger/CustomOverlayTrigger";

import { LOAD_MORE_IG_ASSETS_ROUTINE } from "constants/ActionTypes";

import { SkedButton } from "ui";
import { getAllMediaLoaded } from "selectors/hashtagFeedSelectors";
import { loadMoreIgAssets } from "actions/sourceMedia";
import { getRoutinesLoading } from "selectors/commonSelectors";
import styled from "styled-components";

interface LoadMoreProps {
  filterState?: typeof DEFAULT_HASHTAG_FILTER;
  page: typeof PAGES[keyof typeof PAGES];
}

export function LoadMore({ filterState, page }: LoadMoreProps) {
  const dispatch = useDispatch();
  const isLoadingMore = useSelector((state) =>
    getRoutinesLoading(state, [LOAD_MORE_IG_ASSETS_ROUTINE])
  );
  const allMediaLoaded = useSelector((state) => getAllMediaLoaded(state, page));

  const tooltipJsx = (
    <Tooltip id="all-media-loaded">
      Showing all posts from within the last 24 hours.
    </Tooltip>
  );

  return (
    <Wrapper>
      {allMediaLoaded && (
        <OverlayTrigger placement="bottom" overlay={tooltipJsx}>
          <div className="tw-mb-4 tw-text-gray-400 tw-cursor-default">
            All posts have been loaded
          </div>
        </OverlayTrigger>
      )}
      <SkedButton
        type="primary-outline"
        name="Load More"
        size="small"
        onClick={() => {
          if (!isLoadingMore) {
            dispatch(loadMoreIgAssets(filterState));
          }
        }}
        loading={isLoadingMore}
        disabled={isLoadingMore}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 25px auto 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
