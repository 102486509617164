/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from "lodash";
import { toast } from "react-toastify";
import {
  CREATE_MESSAGE_ROUTINE,
  ADD_MESSAGE_ROUTINE,
  REMOVE_MESSAGE_ROUTINE,
  DELETE_CONVERSATION_ROUTINE,
  GET_CONVERSATIONS_ROUTINE,
  GET_MESSAGES_ROUTINE,
  PATCH_CONVERSATION_ROUTINE,
  SELECT_CONVERSATION_ACCOUNTS_ROUTINE,
  UPDATE_CONVERSATION_CONFIG_ROUTINE,
  UPDATE_STAR_FILTER_ROUTINE,
  GET_CONVERSATIONS_COUNTER_ROUTINE,
  ADD_CONVERSATION_ROUTINE,
  REMOVE_CONVERSATION_ROUTINE,
  GET_CONVERSATION_ROUTINE,
  ADD_COLLABORATOR_TYPING_ROUTINE,
  REMOVE_COLLABORATOR_TYPING_ROUTINE,
  RESYNC_INTERLOCUTOR,
  STAR_CONV_ROUTINE,
  ASSIGN_USER_CONV_ROUTINE,
  ADD_MESSAGE_REACTION_ROUTINE,
  REMOVE_MESSAGE_REACTION_ROUTINE,
  SET_MESSAGE_REACTION_PROCESSING,
  CLOSE_ALL_CONVERSATIONS_ROUTINE,
  SYNC_MESSAGES_ROUTINE,
  SET_CONVERSATION_TYPES_FILTER_ROUTINE,
  SELECT_CONVERSATIONS_ROUTINE,
  PATCH_CONVERSATIONS_ROUTINE,
  GET_CONVERSATIONS_COUNTER_LATEST_ROUTINE,
  INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE,
  INBOX_SET_MESSAGE_HIDDEN_ROUTINE,
  RESYNC_POST_CONTENT,
  INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE,
  NOTIFY_INBOX_URL_CHANGE_ROUTINE,
  SELECT_ACCOUNTS_WITH_CONV_TYPES,
  DELETE_MESSAGE_STATUS_ROUTINE,
} from "constants/ActionTypes";
import {
  put,
  call,
  takeEvery,
  select,
  delay,
  takeLatest,
  fork,
  debounce,
} from "redux-saga/effects";
import {
  getMessages,
  createMessage,
  setMessageDeletedStatus,
  setMessageHidden,
} from "api/messages";
import {
  deleteConversation,
  resyncInterlocutor,
  getConversation as getSingleConversation,
  getConversations,
  getConversationsCounter,
  patchConversation,
  closeAllConversation,
  patchConversations,
  resyncPostContent,
} from "api/conversations";

import {
  CreateMessageAction,
  AddMessageAction,
  RemoveMessageAction,
  deleteConversationAction,
  FetchMessagesAction,
  patchConversationAction,
  SelectConversationAccountsAction,
  setStarAction,
  updateConversationConfigAction,
  collaboratorTypingAction,
  RetryCreateMessageAction,
  ProcessCreateMessageAction,
  resyncInterlocutorAction,
  starConvAction,
  assignUserConvAction,
  AddReactionPayload,
  RemoveReactionPayload,
  addMessageReactionPayload,
  removeMessageReactionPayload,
  setMessageReactionProcessingPayload,
  closeAllConversationsPayload,
  SyncMessagesAction,
  FetchMessagesSuccessAction,
  setConversationTypesFilterAction,
  selectConversationsPayload,
  patchConversationsAction,
  addMessageSuccessAction,
  setMessageHiddenAction,
  setMessageStatusRemovedAction,
  resyncPostContentAction,
  setMessageStatusEditingAction,
  selectAccountWithConvTypeAction,
  DeleteMessageStatusAction,
} from "actions/conversationInbox";
import {
  getConversation,
  getConversations as getConversationsSelector,
  getConversationAssignedTo,
  getConversationFilterConfig,
  getConversationNext,
  getConversationSort,
  getConversationStarred,
  getConversationMentioned,
  getConversationStatus,
  getConversationTypes,
  getConversationUnassigned,
  getMessages as getMessagesSelector,
  getMessagesNext,
  getSelectedConversationAccounts,
  isMessageReactionProcessing,
  getAccountsByIds,
} from "selectors/conversationInboxSelectors";

import {
  Conversations,
  Pagination,
  Message,
  Conversation,
  ConversationsFilters,
  Status,
  ReactionType,
  MessageStatus,
  MessageType,
  SyncedMessage,
  ConversationsResponse,
  ConversationType,
  ConversationPost,
} from "types/conversationInbox";
import {
  deleteMessageReaction,
  setMessageReaction,
} from "api/message-reaction";
import { getUser } from "selectors/commonSelectors";
import { User } from "features/user";
import { ConversationFilterConfig } from "reducers/conversationInboxReducer";
import { AccountType } from "shared/types/accounts";
import { filterValidInboxAccounts } from "@inbox/entities/conversations/ui/conversation-account-selector/inbox-valid-accounts-filter";
import { AxiosError } from "axios";
import { setLocalStorageSelectedAccounts } from "libs/storage/adapters";
import { resolveMessageReactions } from "@inbox/entities/conversations/ui/conversation-messages/components/messages-layout/messages-layout-common";

export const InboxSagaConstants = {
  openConversationOnNewMessageWaitTime: 2000,
  delayUntilRemoveConversation: 500,
  delayProcessingMessageFromSked: 2000,
  getConversationsCounterDebounceTime: 500,
};

type ConversationSagaProp = {
  reset?: boolean;
};

/** include MENTION_V2 if TAGGED_POST included */
function includeMentionV2(types: ConversationType[]): ConversationType[] {
  if (types.includes("TAGGED_POST") && !types.includes("MENTION_V2")) {
    return [...types, "MENTION_V2"];
  }

  return types;
}

/**
 * NOTE: Normally we should not call this function to refresh converations status, because current list
 * of conversations might be loaded with Pagination.
 * for now this function should only be called in 4 places:
 * - When Account selection changed
 * - When conversation filters changed
 * - When loading more conversations (Pagination)
 * - When reload starred filter conversations
 */
function putGetConversationsSaga(params: ConversationSagaProp) {
  return put({ ...params, type: GET_CONVERSATIONS_ROUTINE.TRIGGER });
}

function putGetConversationsCounterSaga() {
  return put({ type: GET_CONVERSATIONS_COUNTER_ROUTINE.TRIGGER });
}

/** we need to filter inbox valid account here also because inbox selected account ids are shared
 * with other pages
 */
export function* getValidSelectedInboxAccounts() {
  const selectedAccountIds: string[] = yield select(
    getSelectedConversationAccounts
  );
  const selectedAccounts: AccountType[] = yield select((s) =>
    getAccountsByIds(s, selectedAccountIds)
  );
  return filterValidInboxAccounts(selectedAccounts).map((c) => c._id);
}

export function* getConversationsSaga({ reset = false }: ConversationSagaProp) {
  try {
    /** remove all conversation first */
    if (reset) {
      yield put({
        type: GET_CONVERSATIONS_ROUTINE.SUCCESS,
        payload: {
          data: [],
          reset: true,
        },
      });
    }

    yield put({ type: GET_CONVERSATIONS_ROUTINE.REQUEST });
    const next: string = yield select(getConversationNext);
    const isStarred: boolean = yield select(getConversationStarred);
    const isMentioned: boolean = yield select(getConversationMentioned);
    const sort: string = yield select(getConversationSort);
    const accounts: string[] = yield getValidSelectedInboxAccounts();
    const assignedTo: string | null = yield select(getConversationAssignedTo);
    let types: ConversationType[] = yield select(getConversationTypes);
    types = includeMentionV2(types);
    let status: Status | null = yield select(getConversationStatus);
    const unassigned: boolean | null = yield select(getConversationUnassigned);

    const ignoredAccountsComments: string[] = yield getIgnoredAccountsComments(
      true,
      accounts
    );

    if (!status) {
      status = Status.open;
    }

    const returnData: { data: ConversationsResponse } = yield call(
      getConversations,
      {
        ...(reset ? {} : { next }),
        isStarred,
        isMentioned,
        sort,
        accounts,
        assignedTo,
        types,
        status,
        unassigned,
        ignoredAccountsComments,
      }
    );

    yield put({
      type: GET_CONVERSATIONS_ROUTINE.SUCCESS,
      payload: {
        data: returnData.data,
        reset: reset,
      },
    });
  } catch (error) {
    yield put({
      type: GET_CONVERSATIONS_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* getConversationSaga(action: { payload: string }) {
  try {
    const returnData: { data: Conversation } = yield call(
      getSingleConversation,
      {
        conversationId: action.payload,
      }
    );
    yield put({
      type: GET_CONVERSATION_ROUTINE.SUCCESS,
      payload: { data: returnData.data },
    });
  } catch (error) {
    yield put({
      type: GET_CONVERSATION_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* syncMessagesSaga(action: SyncMessagesAction) {
  try {
    const conv: Conversation | null = yield select((s) =>
      getConversation(s, action.payload.conversationId)
    );
    if (!conv) {
      return;
    }

    yield put({
      type: SYNC_MESSAGES_ROUTINE.REQUEST,
      payload: action.payload,
    });

    const conversationId: string = action.payload.conversationId;
    const returnData: { data: Pagination<Message> } = yield call(getMessages, {
      conversationId,
    });

    yield put<FetchMessagesSuccessAction>({
      type: GET_MESSAGES_ROUTINE.SUCCESS,
      payload: {
        conversationId,
        data: returnData.data,
        setMessagesOnly: true,
      },
    });
  } catch (error) {
    /** ignore errors */
    console.warn(error);
  }
}

export function* getMessagesSaga(action: FetchMessagesAction) {
  try {
    yield put({ type: GET_MESSAGES_ROUTINE.REQUEST });
    const conversationId: string = action.payload.conversationId;
    const next: string | null = yield select((state) =>
      getMessagesNext(state, conversationId)
    );

    const returnData: { data: Pagination<Message> } = yield call(getMessages, {
      conversationId,
      next,
    });
    yield put<FetchMessagesSuccessAction>({
      type: GET_MESSAGES_ROUTINE.SUCCESS,
      payload: {
        conversationId,
        data: returnData.data,
      },
    });
  } catch (error) {
    yield put({
      type: GET_MESSAGES_ROUTINE.FAILURE,
      error: true,
      payload: {
        error: error,
        conversationId: action.payload.conversationId,
      },
    });
  }
}

export function* processCreateMessage(action: ProcessCreateMessageAction) {
  const { message, ...payload } = action.payload;
  const conversations: Conversation[] = yield select(getConversationsSelector);
  const convIdx = conversations.findIndex(
    (c) => c.id === payload.conversationId
  );

  const shouldRemoveConv =
    payload.closeConversationAction === "remove-conversation" &&
    !payload.isInternal;
  try {
    // 1. Trigger CREATE_MESSAGE_ROUTINE.REQUEST: append the message data immediately
    yield put({
      type: CREATE_MESSAGE_ROUTINE.REQUEST,
      payload: {
        message,
        conversationId: payload.conversationId,
      },
    });

    yield put({
      type: INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE.REQUEST,
      payload: {
        message,
        action: "disable",
      },
    });

    if (shouldRemoveConv) {
      yield fork(showCompleteAndRemoveConversationSaga, {
        payload: action.payload.conversationId,
      });
    }

    const returnData: { data: Message } = yield call(createMessage, payload);

    // 2. Trigger CREATE_MESSAGE_ROUTINE.SUCCESS: Update the temporary message with real data
    yield put({
      type: CREATE_MESSAGE_ROUTINE.SUCCESS,
      payload: {
        temporaryId: message.id,
        message: returnData.data,
        conversationId: payload.conversationId,
      },
    });

    if (shouldRemoveConv) {
      yield putGetConversationsCounterSaga();
    }

    const targetStatus = payload.closeConversationAction
      ? Status.closed
      : Status.open;
    yield fork(checkToRemoveConversationByStatus, {
      conversationId: action.payload.conversationId,
      message: returnData.data,
      messageConvStatus: targetStatus,
    });
  } catch (error) {
    handleServerError(error, "Unable to send a message");

    if (shouldRemoveConv && convIdx !== -1) {
      yield put({
        type: PATCH_CONVERSATION_ROUTINE.FAILURE,
        payload: {
          staus: Status.open,
          conversationIdIndex: convIdx,
          conversation: conversations[convIdx],
        },
      });
    }

    // 3. Trigger CREATE_MESSAGE_ROUTINE.FAILURE: mark temporary message as failed
    yield put({
      type: CREATE_MESSAGE_ROUTINE.FAILURE,
      payload: {
        message,
        conversationId: payload.conversationId,
      },
    });
  }
}

export function* notifyInboxUrlChangeSaga() {
  yield put({ type: NOTIFY_INBOX_URL_CHANGE_ROUTINE.REQUEST });
}

export function* createMessageSaga(action: CreateMessageAction) {
  const user: User = yield select(getUser);
  const { fields: moreFields, ...baseFields } = action.payload;
  const message: Message = {
    ...baseFields,
    ...moreFields,
    type: baseFields.isInternal
      ? MessageType.internal
      : moreFields?.type || MessageType.messageCreating,
    id: _.uniqueId(`message_${Date.now()}`),
    createdAt: new Date(),
    status: MessageStatus.pending,
    sender: {
      platform: "SK",
      id: user.userId,
      name: user.userName,
    },
    reactions: [],
    unread: false,
    sentFromSked: true,
    lastCreatedRequestPalyload: action.payload,
  };

  yield call(processCreateMessage, {
    payload: {
      ...action.payload,
      message,
    },
  });

  // TODO: refactor
  // BE returns pusher event after message is create, so maybe it will be better to do it there
  // only getCounters when BE/pusher event send mentionee
  // yield putGetConversationsCounterSaga();
}

export function* retryCreateMessageSaga(action: RetryCreateMessageAction) {
  const { conversationId, messageId } = action.payload;

  const messages: Message[] = yield select((state) =>
    getMessagesSelector(state, action.payload.conversationId)
  );
  const message = messages.find(
    (msg: Message) => msg.id === action.payload.messageId
  );

  if (!message || !message.lastCreatedRequestPalyload) {
    return;
  }

  yield call(removeMessage, {
    payload: {
      messageId,
      conversationId,
    },
  });

  yield call(createMessageSaga, {
    payload: message.lastCreatedRequestPalyload,
  });
}

function shouldIgnoreMessageFromSked(
  message: SyncedMessage,
  conversation: Conversation | null
) {
  if (!conversation) {
    return true;
  }

  if (conversation.messages?.some((c) => c.id === message.id)) {
    return true;
  }

  /** handle the case when message is sent from Sked Inbox,
   * there will be a race condition between Message from Pusher and response from creating new message
   */
  const pendingMessages = conversation.messages?.filter(
    (c) => c.status === MessageStatus.pending
  );
  /** for now, we asume if there is any pending messages with the same text' */
  return !!pendingMessages?.some((c) => c.text === message.text);
}

export function* addMessageSaga(action: AddMessageAction) {
  try {
    const user: User = yield select(getUser);

    const { conversation: incomingConv, sender } = action.payload;
    const isMessageFromSked =
      sender && (user.userId === sender.id || user.userName === sender.name);

    if (isMessageFromSked) {
      /** delay to reduce impact of race condition between response from server and pusher when
       * a new message is sent
       */
      yield delay(InboxSagaConstants.delayProcessingMessageFromSked);
    }

    yield fork(checkToRemoveConversationByStatus, {
      conversationId: action.payload.conversationId,
      message: action.payload,
      messageConvStatus: action.payload.conversation?.status || Status.open,
      shouldWait: true,
    });

    const conversation: Conversation = yield select((state) =>
      getConversation(state, action.payload.conversationId)
    );

    if (incomingConv) {
      const { account } = incomingConv;
      const accounts: string[] = yield getValidSelectedInboxAccounts();
      if (!accounts.includes(account)) {
        return;
      }

      const filterConfig: ConversationFilterConfig = yield select(
        getConversationFilterConfig
      );

      let convTypes: ConversationType[] = yield select(getConversationTypes);
      convTypes = includeMentionV2(convTypes);
      if (
        !conversation &&
        (!isConversationMatchedFilter(
          incomingConv,
          filterConfig,
          user.userId
        ) ||
          !convTypes.includes(incomingConv.type))
      ) {
        return;
      }
    }

    if (
      isMessageFromSked &&
      shouldIgnoreMessageFromSked(action.payload, conversation)
    ) {
      return;
    }

    // Only update if it is a new message
    if (
      conversation?.messages?.find(
        (message: Message) => message.id === action.payload.id
      ) === undefined
    ) {
      /** we don't want to increase unread count as conversation is just loaded from server */
      const ignoreIncreaseUnreadCount = !conversation;
      if (!conversation) {
        yield call(getConversationSaga, {
          payload: action.payload.conversationId,
        });
      }

      yield put<addMessageSuccessAction>({
        type: ADD_MESSAGE_ROUTINE.SUCCESS,
        payload: {
          conversationId: action.payload.conversationId,
          message: _.omit(action.payload, "conversationId", "conversation"),
          ignoreIncreaseUnreadCount,
        },
      });
      yield putGetConversationsCounterSaga();
    } else {
      console.log("existing message");
    }
  } catch (error) {
    console.error("error", error);
    yield put({
      type: ADD_MESSAGE_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* removeMessage(action: RemoveMessageAction) {
  try {
    const conversation: Conversation = yield select((state) =>
      getConversation(state, action.payload.conversationId)
    );
    if (!conversation) {
      yield call(getConversationSaga, {
        payload: action.payload.conversationId,
      });
    }
    yield put({
      type: REMOVE_MESSAGE_ROUTINE.SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: REMOVE_MESSAGE_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* removeMessageSaga(action: AddMessageAction) {
  try {
    const conversation: Conversation = yield select((state) =>
      getConversation(state, action.payload.conversationId)
    );
    if (!conversation) {
      return;
    }

    yield put({
      type: REMOVE_MESSAGE_ROUTINE.SUCCESS,
      payload: action.payload,
    });

    yield putGetConversationsCounterSaga();
  } catch (error) {
    yield put({
      type: REMOVE_MESSAGE_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* deleteMessageStatusSaga(action: DeleteMessageStatusAction) {
  try {
    const conversation: Conversation = yield select((state) =>
      getConversation(state, action.payload.conversationId)
    );
    if (!conversation) {
      return;
    }

    yield put({
      type: DELETE_MESSAGE_STATUS_ROUTINE.SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: DELETE_MESSAGE_STATUS_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* updateStarFilterSaga(action: setStarAction) {
  try {
    yield put({
      type: UPDATE_STAR_FILTER_ROUTINE.REQUEST,
      payload: { isStarred: action.payload.isStarred },
    });
    yield putGetConversationsSaga({ reset: true });
    yield put({
      type: UPDATE_STAR_FILTER_ROUTINE.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: UPDATE_STAR_FILTER_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* starConvSaga(action: starConvAction) {
  const { payload } = action;
  const conversation: Conversation = yield select((state) =>
    getConversation(state, payload.conversationId)
  );

  if (!conversation) {
    return;
  }

  try {
    yield put({
      type: PATCH_CONVERSATION_ROUTINE.REQUEST,
      payload: action.payload,
    });

    yield call(patchConversation, {
      conversationId: payload.conversationId,
      isStarred: payload.isStarred,
    });

    const isStarredFilter: boolean = yield select((s) =>
      getConversationStarred(s)
    );

    if (isStarredFilter && !payload.isStarred) {
      yield put({
        type: DELETE_CONVERSATION_ROUTINE.SUCCESS,
        payload: payload.conversationId,
      });
    }
    yield putGetConversationsCounterSaga();
  } catch (error) {
    console.error(error);
    const msgAction = payload.isStarred ? "star" : "unstar";
    toast.error(`Unable to ${msgAction} conversation`);
    yield put({
      type: PATCH_CONVERSATION_ROUTINE.REQUEST,
      payload: {
        ...action.payload,
        isStarred: conversation.isStarred,
      },
    });
  }
}

export function* assignUserConvSaga(action: assignUserConvAction) {
  const { payload } = action;
  const conversation: Conversation = yield select((state) =>
    getConversation(state, payload.conversationId)
  );

  if (!conversation) {
    return;
  }

  try {
    yield put({
      type: PATCH_CONVERSATION_ROUTINE.REQUEST,
      payload: payload,
    });

    yield call(patchConversation, {
      conversationId: payload.conversationId,
      assignedTo: payload.assignedTo,
    });

    const assignedTo: string | null = yield select((s) =>
      getConversationAssignedTo(s)
    );

    const unassigned: boolean | null = yield select((s) =>
      getConversationUnassigned(s)
    );

    const shouldRemoveConv =
      (unassigned && payload.assignedTo) ||
      (assignedTo && payload.assignedTo !== assignedTo);

    if (shouldRemoveConv) {
      yield put({
        type: DELETE_CONVERSATION_ROUTINE.SUCCESS,
        payload: payload.conversationId,
      });
    }
    yield putGetConversationsCounterSaga();
  } catch (error) {
    console.error(error);
    const msgAction = payload.assignedTo ? "assign" : "unassign";
    toast.error(`Unable to ${msgAction} conversation`);
    yield put({
      type: PATCH_CONVERSATION_ROUTINE.REQUEST,
      payload: {
        ...action.payload,
        assignedTo: conversation.assignedTo,
      },
    });
  }
}

export function* patchConversationSaga(action: patchConversationAction) {
  const conversation: Conversation = yield select((state) =>
    getConversation(state, action.payload.conversationId)
  );
  const conversations: Conversation[] = yield select((state) =>
    getConversationsSelector(state)
  );
  const conversationIdIndex: number = conversations.findIndex(
    (conv: Conversation) => conv.id === conversation.id
  );

  try {
    yield put({
      type: PATCH_CONVERSATION_ROUTINE.REQUEST,
      payload: action.payload,
    });

    if (action.payload.status) {
      yield call(showCompleteAndRemoveConversationSaga, {
        payload: action.payload.conversationId,
      });
    }

    const returnData: { data: Conversation } = yield call(
      patchConversation,
      action.payload
    );

    yield putGetConversationsCounterSaga();

    yield put({
      type: PATCH_CONVERSATION_ROUTINE.SUCCESS,
      payload: {
        data: returnData.data,
      },
    });
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 400) {
      toast.error(
        (error as AxiosError)?.response?.data?.message ||
          "Unable to send a message"
      );
    }

    if (action.payload.status) {
      yield put({
        type: ADD_CONVERSATION_ROUTINE.REQUEST,
        payload: action.payload,
      });
    }

    // Trigger PATCH_CONVERSATION_ROUTINE.FAILURE and revert data
    yield put({
      type: PATCH_CONVERSATION_ROUTINE.FAILURE,
      payload: {
        ...action.payload,
        conversationIdIndex,
        conversation,
      },
    });
  }
}

export function* patchConversationsSaga(action: patchConversationsAction) {
  const assignedTo: string | null = yield select((s) =>
    getConversationAssignedTo(s)
  );

  const unassigned: boolean | null = yield select((s) =>
    getConversationUnassigned(s)
  );

  const conversations: Conversation[] = yield select((state) =>
    getConversationsSelector(state)
  );

  const shouldRemoveConversations =
    (unassigned && action.payload.assignedTo) ||
    (assignedTo && action.payload.assignedTo !== assignedTo);

  try {
    yield put({
      type: PATCH_CONVERSATIONS_ROUTINE.REQUEST,
      payload: action.payload,
    });

    if (action.payload.status) {
      yield call(showCompleteAndRemoveConversationSaga, {
        payload: action.payload.conversationIds,
      });
    } else if (shouldRemoveConversations) {
      yield put({
        type: DELETE_CONVERSATION_ROUTINE.SUCCESS,
        payload: action.payload.conversationIds,
      });
    }

    const returnData: { data: Conversation } = yield call(
      patchConversations,
      action.payload
    );

    yield putGetConversationsCounterSaga();

    yield put({
      type: PATCH_CONVERSATIONS_ROUTINE.SUCCESS,
      payload: {
        data: returnData.data,
      },
    });
  } catch {
    yield put({
      type: PATCH_CONVERSATIONS_ROUTINE.FAILURE,
      payload: {
        conversations,
      },
    });
  }
}

export function* resyncInterlocutorSaga(action: resyncInterlocutorAction) {
  try {
    yield put({ type: RESYNC_INTERLOCUTOR.REQUEST });
    const { data = {} } = yield call(resyncInterlocutor, action.payload);
    yield put({
      type: RESYNC_INTERLOCUTOR.SUCCESS,
      payload: { ...data, conversationId: action.payload.conversationId },
    });
  } catch (error) {
    yield put({
      type: RESYNC_INTERLOCUTOR.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* closeAllConversationsSaga({
  payload,
}: {
  payload: closeAllConversationsPayload;
}) {
  try {
    yield put({ type: CLOSE_ALL_CONVERSATIONS_ROUTINE.REQUEST });
    yield call(closeAllConversation, payload.accounts);
    yield put({ type: CLOSE_ALL_CONVERSATIONS_ROUTINE.SUCCESS });
    yield putGetConversationsSaga({ reset: true });
    yield putGetConversationsCounterSaga();
  } catch (error) {
    console.error(error);
    toast.error("Unable to close all conversations");
    yield put({ type: CLOSE_ALL_CONVERSATIONS_ROUTINE.FAILURE });
    yield putGetConversationsSaga({ reset: true });
    yield putGetConversationsCounterSaga();
  }
}

function* selectConversationAccountsRequest(accountIds: string[]) {
  yield put<{
    type: string;
    payload: SelectConversationAccountsAction["payload"];
  }>({
    type: SELECT_CONVERSATION_ACCOUNTS_ROUTINE.REQUEST,
    payload: accountIds,
  });

  setLocalStorageSelectedAccounts(JSON.stringify(accountIds));
  // TODO: need update home selected group
  // setLocalStorageHomeSelectedGroupName(groupName);
}

export function* selectConversationAccountsSaga(
  action: SelectConversationAccountsAction
) {
  try {
    yield selectConversationAccountsRequest(action.payload);

    yield putGetConversationsCounterSaga();
    yield putGetConversationsSaga({ reset: true });
    yield put({
      type: SELECT_CONVERSATION_ACCOUNTS_ROUTINE.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: SELECT_CONVERSATION_ACCOUNTS_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* updateConversationConfigSaga(
  action: updateConversationConfigAction
) {
  try {
    yield put({
      type: UPDATE_CONVERSATION_CONFIG_ROUTINE.REQUEST,
      payload: action.payload,
    });
    yield putGetConversationsSaga({ reset: true });
    yield put({
      type: UPDATE_CONVERSATION_CONFIG_ROUTINE.SUCCESS,
    });
  } catch (error) {
    yield put({
      type: UPDATE_CONVERSATION_CONFIG_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* deleteConversationSaga(action: deleteConversationAction) {
  try {
    yield put({ type: DELETE_CONVERSATION_ROUTINE.REQUEST });
    yield call(deleteConversation, action.payload);
    yield put({
      type: DELETE_CONVERSATION_ROUTINE.SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: DELETE_CONVERSATION_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* getConversationsCounterDebouncedSaga() {
  yield put({ type: GET_CONVERSATIONS_COUNTER_LATEST_ROUTINE.TRIGGER });
}

export function* getConversationsCounterSaga() {
  try {
    let types: ConversationType[] = yield select(getConversationTypes);
    types = includeMentionV2(types);
    yield put({ type: GET_CONVERSATIONS_COUNTER_ROUTINE.REQUEST });
    const accounts: string[] = yield getValidSelectedInboxAccounts();
    const ignoredAccountsComments: string[] = yield getIgnoredAccountsComments(
      true,
      accounts
    );
    const returnData: { data: ConversationsFilters } = yield call(
      getConversationsCounter,
      {
        accounts,
        types,
        ignoredAccountsComments,
      }
    );
    yield put({
      type: GET_CONVERSATIONS_COUNTER_ROUTINE.SUCCESS,
      payload: {
        ...returnData.data,
        // we want to display all as converstaions without closed or archived counts
        all: (returnData.data?.all || 0) - (returnData.data?.closed || 0),
      },
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_CONVERSATIONS_COUNTER_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

type removeConversationAction = {
  payload: string | string[];
};
export function* showCompleteAndRemoveConversationSaga(
  action: removeConversationAction
) {
  const payload = Array.isArray(action.payload)
    ? action.payload
    : [action.payload];
  try {
    yield put({
      type: REMOVE_CONVERSATION_ROUTINE.REQUEST,
      payload,
    });
    // After 0.5 seconds, dispatch success that will remove conversation from reducer
    yield delay(InboxSagaConstants.delayUntilRemoveConversation);
    yield put({
      type: REMOVE_CONVERSATION_ROUTINE.SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: REMOVE_CONVERSATION_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* addMessageReactionSaga(action: {
  payload: addMessageReactionPayload;
}) {
  const { payload } = action;
  const isProcessing: boolean = yield select((s) =>
    isMessageReactionProcessing(s, payload.conversationId, payload.messageId)
  );

  if (isProcessing) {
    return;
  }

  function setReactionProcessing(isProcessing: boolean) {
    return put<{ type: string; payload: setMessageReactionProcessingPayload }>({
      type: SET_MESSAGE_REACTION_PROCESSING,
      payload: {
        conversationId: payload.conversationId,
        isProcessing: isProcessing,
        messageId: payload.messageId,
      },
    });
  }

  const tempReaction: AddReactionPayload = {
    action: "added",
    conversationId: payload.conversationId,
    id: `temp-id-${Math.random()}`,
    messageId: payload.messageId,
    reactee: payload.reactee,
    reaction: payload.reaction,
  };

  try {
    yield put({
      type: ADD_MESSAGE_REACTION_ROUTINE.SUCCESS,
      payload: tempReaction,
    });

    yield setReactionProcessing(true);

    const returnData: { data: ReactionType } = yield call(setMessageReaction, {
      messageId: payload.messageId,
      reaction: payload.reaction,
    });

    const addReaction: AddReactionPayload = {
      action: "added",
      conversationId: payload.conversationId,
      id: returnData.data.id,
      tempId: tempReaction.id,
      messageId: payload.messageId,
      reactee: returnData.data.reactee,
      reaction: returnData.data.reaction,
      emoji: returnData.data.emoji,
    };

    yield put({
      type: ADD_MESSAGE_REACTION_ROUTINE.SUCCESS,
      payload: addReaction,
    });
  } catch (error) {
    handleServerError(error, "Unable to add a reaction to message");

    const removeReaction: RemoveReactionPayload = {
      action: "removed",
      conversationId: payload.conversationId,
      id: tempReaction.id,
      messageId: payload.messageId,
      reactee: payload.reactee,
    };

    yield put({
      type: REMOVE_MESSAGE_REACTION_ROUTINE.SUCCESS,
      payload: removeReaction,
    });
  } finally {
    yield setReactionProcessing(false);
  }
}

export function* removeMessageReactionSaga(action: {
  payload: removeMessageReactionPayload;
}) {
  const { payload } = action;
  const isProcessing: boolean = yield select((s) =>
    isMessageReactionProcessing(s, payload.conversationId, payload.messageId)
  );
  if (isProcessing) {
    console.log("reaction processing, ignore request!!");
    return;
  }

  function setReactionProcessing(isProcessing: boolean) {
    return put<{ type: string; payload: setMessageReactionProcessingPayload }>({
      type: SET_MESSAGE_REACTION_PROCESSING,
      payload: {
        conversationId: payload.conversationId,
        isProcessing: isProcessing,
        messageId: payload.messageId,
      },
    });
  }

  const removeReaction: RemoveReactionPayload = {
    action: "removed",
    conversationId: payload.conversationId,
    id: payload.id,
    messageId: payload.messageId,
    reactee: payload.reactee,
  };
  const messages: Message[] = yield select((state) =>
    getMessagesSelector(state, payload.conversationId)
  );
  const foundMessage = messages.find((c) => c.id === payload.messageId);
  const foundReaction =
    foundMessage?.type === MessageType.ttThreadedComment
      ? resolveMessageReactions(foundMessage)[0]
      : foundMessage?.reactions?.find((c) => c.id === payload.id);
  if (!foundMessage || !foundReaction) {
    return;
  }

  try {
    yield put({
      type: REMOVE_MESSAGE_REACTION_ROUTINE.SUCCESS,
      payload: removeReaction,
    });
    yield setReactionProcessing(true);
    yield call(deleteMessageReaction, {
      messageId: payload.messageId,
      reactionId: payload.id,
    });
  } catch (error) {
    handleServerError(error, "Unable to remove a reaction to message");

    const addReaction: AddReactionPayload = {
      action: "added",
      conversationId: payload.conversationId,
      id: foundReaction.id,
      messageId: payload.messageId,
      reactee: foundReaction.reactee,
      reaction: foundReaction.reaction,
      emoji: foundReaction.emoji,
    };
    yield put({
      type: ADD_MESSAGE_REACTION_ROUTINE.SUCCESS,
      payload: addReaction,
    });
  } finally {
    yield setReactionProcessing(false);
  }
}

export function* removeCollaboratorSaga(action: collaboratorTypingAction) {
  try {
    yield put({
      type: REMOVE_COLLABORATOR_TYPING_ROUTINE.SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: REMOVE_COLLABORATOR_TYPING_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

export function* addCollaboratorSaga(action: collaboratorTypingAction) {
  try {
    yield put({
      type: ADD_COLLABORATOR_TYPING_ROUTINE.SUCCESS,
      payload: action.payload,
    });
  } catch (error) {
    yield put({
      type: ADD_COLLABORATOR_TYPING_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

interface CheckToRemoveConversationByStatusParams {
  conversationId: string;
  message: Pick<Message, "type" | "isInternal">;
  messageConvStatus: Status;
  shouldWait?: boolean;
}
export function* checkToRemoveConversationByStatus({
  conversationId,
  message,
  messageConvStatus,
  shouldWait,
}: CheckToRemoveConversationByStatusParams) {
  if ([MessageType.system, MessageType.typing].includes(message.type)) {
    return;
  }
  if (message.isInternal) {
    return;
  }

  const config: ConversationFilterConfig = yield select(
    getConversationFilterConfig
  );
  const conversations: Conversations["conversations"] = yield select(
    getConversationsSelector
  );

  if (!conversations?.find((c) => c.id === conversationId)) {
    return;
  }

  const inClosedOrArchivedFilter =
    !!config.status && config.status !== Status.open;
  const isMessageConvClosedStatus = messageConvStatus === Status.closed;

  if (inClosedOrArchivedFilter === isMessageConvClosedStatus) {
    return;
  }

  if (shouldWait) {
    yield delay(InboxSagaConstants.openConversationOnNewMessageWaitTime);
  }

  yield put({
    type: DELETE_CONVERSATION_ROUTINE.SUCCESS,
    payload: conversationId,
  });

  yield putGetConversationsCounterSaga();
}

function* setConversationTypesFilterRequest(types: ConversationType[]) {
  yield put<{
    type: string;
    payload: setConversationTypesFilterAction["payload"];
  }>({
    type: SET_CONVERSATION_TYPES_FILTER_ROUTINE.REQUEST,
    payload: { types },
  });
}

export function* setConversationTypesFilterSaga(
  action: setConversationTypesFilterAction
) {
  try {
    yield setConversationTypesFilterRequest(action.payload.types);
    yield putGetConversationsSaga({ reset: true });
    yield putGetConversationsCounterSaga();
    yield put({
      type: SET_CONVERSATION_TYPES_FILTER_ROUTINE.SUCCESS,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: SET_CONVERSATION_TYPES_FILTER_ROUTINE.FAILURE,
    });
  }
}

export function* selectConversationsSaga({
  payload,
}: {
  payload: selectConversationsPayload;
}) {
  yield put({
    type: SELECT_CONVERSATIONS_ROUTINE.REQUEST,
    payload,
  });
}

function* setMessageStatusRemovedSaga({
  payload,
}: setMessageStatusRemovedAction) {
  try {
    yield put({
      type: INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE.REQUEST,
      payload,
    });

    yield setMessageDeletedStatus({ id: payload.id });
    yield put({
      type: INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE.SUCCESS,
      payload,
    });
  } catch (error) {
    handleServerError(error, "Unable to remove message");
    yield put({
      type: INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE.FAILURE,
      payload,
    });
  }
}

function* setMessageStatusEditingSaga({
  payload,
}: setMessageStatusEditingAction) {
  try {
    yield put({
      type: INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE.REQUEST,
      payload,
    });
  } catch (error) {
    handleServerError(error, "Unable to remove message");
    yield put({
      type: INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE.FAILURE,
      payload,
    });
  }
}

function* setMessageHiddenSaga({ payload }: setMessageHiddenAction) {
  try {
    yield put({
      type: INBOX_SET_MESSAGE_HIDDEN_ROUTINE.REQUEST,
      payload,
    });
    yield setMessageHidden({ id: payload.id, hidden: payload.hidden });
    yield put({
      type: INBOX_SET_MESSAGE_HIDDEN_ROUTINE.SUCCESS,
      payload,
    });
  } catch (error) {
    handleServerError(error, "Unable to set message hidden");
    yield put({
      type: INBOX_SET_MESSAGE_HIDDEN_ROUTINE.FAILURE,
      payload,
    });
  }
}
export function* resyncPostContentSaga({ payload }: resyncPostContentAction) {
  try {
    const { conversationId, postId } = payload;
    const convs: Conversation[] = yield select(getConversationsSelector) || [];

    if (
      convs.some(
        (c) =>
          c.post?.id === postId && (c.post.resyncing || c.post.resyncRequested)
      )
    ) {
      console.log("ignore as post is in resync process or resynced requested");
      return;
    }

    yield put({
      type: RESYNC_POST_CONTENT.REQUEST,
      payload,
    });
    const { data: post }: { data: ConversationPost } = yield call(
      resyncPostContent,
      {
        postId,
        conversationId,
      }
    );
    yield put({
      type: RESYNC_POST_CONTENT.SUCCESS,
      payload: post,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: RESYNC_POST_CONTENT.FAILURE,
      payload,
    });
  }
}

export function* selectAccountsWithConvTypesSaga({
  payload,
}: selectAccountWithConvTypeAction) {
  try {
    yield setConversationTypesFilterRequest(payload.types);
    yield selectConversationAccountsRequest(payload.accountIds);

    yield putGetConversationsSaga({ reset: true });
    yield putGetConversationsCounterSaga();

    yield put({
      type: SET_CONVERSATION_TYPES_FILTER_ROUTINE.SUCCESS,
    });
    yield put({
      type: SELECT_CONVERSATION_ACCOUNTS_ROUTINE.SUCCESS,
    });
  } catch (error) {
    console.error(error);
    yield put({
      type: SET_CONVERSATION_TYPES_FILTER_ROUTINE.FAILURE,
    });
    yield put({
      type: SELECT_CONVERSATION_ACCOUNTS_ROUTINE.FAILURE,
      error: true,
      payload: error,
    });
  }
}

/// WATCHERS
export const conversationInboxSagas = [
  takeEvery(NOTIFY_INBOX_URL_CHANGE_ROUTINE.TRIGGER, notifyInboxUrlChangeSaga),
  takeEvery(CREATE_MESSAGE_ROUTINE.TRIGGER, createMessageSaga),
  takeEvery(CREATE_MESSAGE_ROUTINE.RETRY, retryCreateMessageSaga),
  takeEvery(ADD_MESSAGE_ROUTINE.TRIGGER, addMessageSaga),
  takeEvery(REMOVE_MESSAGE_ROUTINE.TRIGGER, removeMessageSaga),
  takeEvery(DELETE_MESSAGE_STATUS_ROUTINE.TRIGGER, deleteMessageStatusSaga),
  takeLatest(GET_CONVERSATIONS_ROUTINE.TRIGGER, getConversationsSaga),
  takeEvery(GET_CONVERSATION_ROUTINE.TRIGGER, getConversationSaga),
  takeEvery(GET_MESSAGES_ROUTINE.TRIGGER, getMessagesSaga),
  takeEvery(UPDATE_STAR_FILTER_ROUTINE.TRIGGER, updateStarFilterSaga),
  takeEvery(PATCH_CONVERSATION_ROUTINE.TRIGGER, patchConversationSaga),
  takeEvery(PATCH_CONVERSATIONS_ROUTINE.TRIGGER, patchConversationsSaga),
  takeEvery(STAR_CONV_ROUTINE.TRIGGER, starConvSaga),
  takeEvery(ASSIGN_USER_CONV_ROUTINE.TRIGGER, assignUserConvSaga),

  takeEvery(RESYNC_INTERLOCUTOR.TRIGGER, resyncInterlocutorSaga),
  takeEvery(
    SELECT_CONVERSATION_ACCOUNTS_ROUTINE.TRIGGER,
    selectConversationAccountsSaga
  ),
  takeEvery(
    UPDATE_CONVERSATION_CONFIG_ROUTINE.TRIGGER,
    updateConversationConfigSaga
  ),
  debounce(
    InboxSagaConstants.getConversationsCounterDebounceTime,
    GET_CONVERSATIONS_COUNTER_ROUTINE.TRIGGER,
    getConversationsCounterDebouncedSaga
  ),
  takeLatest(
    GET_CONVERSATIONS_COUNTER_LATEST_ROUTINE.TRIGGER,
    getConversationsCounterSaga
  ),
  takeEvery(
    REMOVE_CONVERSATION_ROUTINE.TRIGGER,
    showCompleteAndRemoveConversationSaga
  ),
  takeEvery(ADD_MESSAGE_REACTION_ROUTINE.TRIGGER, addMessageReactionSaga),
  takeEvery(REMOVE_MESSAGE_REACTION_ROUTINE.TRIGGER, removeMessageReactionSaga),
  takeEvery(ADD_COLLABORATOR_TYPING_ROUTINE.TRIGGER, addCollaboratorSaga),
  takeEvery(REMOVE_COLLABORATOR_TYPING_ROUTINE.TRIGGER, removeCollaboratorSaga),
  takeEvery(CLOSE_ALL_CONVERSATIONS_ROUTINE.TRIGGER, closeAllConversationsSaga),
  takeEvery(SYNC_MESSAGES_ROUTINE.TRIGGER, syncMessagesSaga),
  takeLatest(
    SET_CONVERSATION_TYPES_FILTER_ROUTINE.TRIGGER,
    setConversationTypesFilterSaga
  ),
  takeEvery(SELECT_CONVERSATIONS_ROUTINE.TRIGGER, selectConversationsSaga),
  takeEvery(
    INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE.TRIGGER,
    setMessageStatusRemovedSaga
  ),
  takeEvery(
    INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE.TRIGGER,
    setMessageStatusEditingSaga
  ),
  takeEvery(INBOX_SET_MESSAGE_HIDDEN_ROUTINE.TRIGGER, setMessageHiddenSaga),
  takeEvery(RESYNC_POST_CONTENT.TRIGGER, resyncPostContentSaga),
  takeEvery(
    SELECT_ACCOUNTS_WITH_CONV_TYPES.TRIGGER,
    selectAccountsWithConvTypesSaga
  ),
];

export function isConversationMatchedFilter(
  conv: AddMessageAction["payload"]["conversation"],
  filterConfig: ConversationFilterConfig,
  userId: string
): boolean {
  if (!conv) {
    return false;
  }

  if (
    (!filterConfig.status || filterConfig.status === Status.open) &&
    conv.status === Status.closed
  ) {
    return false;
  }

  if (filterConfig.unassigned && conv.assignedTo) {
    return false;
  }

  if (filterConfig.assignedTo && filterConfig.assignedTo !== conv.assignedTo) {
    return false;
  }

  if (filterConfig.type && conv.type !== filterConfig.type) {
    return false;
  }

  if (filterConfig.status && conv.status !== filterConfig.status) {
    return false;
  }

  if (filterConfig.isStarred && !!conv.isStarred !== !!filterConfig.isStarred) {
    return false;
  }

  if (filterConfig.isMentioned && !(conv.mentionees || []).includes(userId)) {
    return false;
  }

  return true;
}

function* getIgnoredAccountsComments(
  showInboxComments: boolean,
  accountIds: string[]
) {
  if (showInboxComments) {
    return [];
  }

  const selectedAccounts: AccountType[] = yield select((state) =>
    getAccountsByIds(state, accountIds)
  );
  return selectedAccounts
    .filter((c) => !c.platformType || c.platformType === "IG")
    .map((c) => c._id.toString());
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleServerError(err: any, commonMessage: string) {
  console.error(err);
  let details = "";
  if (isSocialApiError(err)) {
    details = `, Social API Error: ${err.response?.data?.error.message}`;
    console.error(
      "Social API error",
      JSON.stringify(err.response?.data, null, 2)
    );
  }
  toast.error(`${commonMessage}${details}`);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isSocialApiError(err: any): err is AxiosError<ApiErrorEntity> {
  const axiosError = err as AxiosError | undefined;
  return (
    !!axiosError?.isAxiosError && !!axiosError?.response?.data?.socialApiError
  );
}
interface ApiErrorEntity {
  error: { message: string };
  socialApiError: true;
}
