import React, { useContext } from "react";
import { styled } from "styled-components";
import { AccountContext } from "./account-context";
import { PlatformIcon } from "../plaform-icon/plaform-icon";
import { getPlatformName } from "../../../../utils/accounts";
import {
  platformAccountNameMap,
  PlatformName,
} from "../../../../shared/types/accounts";
import { PlatformNameToPlatformTypeMap } from "../../../../features/post/format-post";
import youtubeIconUtils from "../../../../utils/youtube-icon-utils";

export const AccountPlatformIcon = styled((props) => {
  const account = props.account || useContext(AccountContext);

  let platformType = getPlatformName(account?.platformType);

  const hasPlatform = Boolean(account?.platformId);
  const otherTypesWithConnectionOptions = [
    PlatformNameToPlatformTypeMap.tiktok,
    PlatformNameToPlatformTypeMap.threads,
  ];
  const isOtherTypeWithConnectionOption =
    otherTypesWithConnectionOptions.includes(account?.platformType);

  if (isOtherTypeWithConnectionOption && !hasPlatform) {
    platformType = platformAccountNameMap.OT;
  }

  if (props.useYtRedPlayIcon && platformType === "youtube") {
    platformType = youtubeIconUtils.resolveYtRedIcon(
      platformType
    ) as PlatformName;
  }

  return <PlatformIcon className={props.className} type={platformType} />;
})``;
