import { theme } from "@dialogueconsulting/sked-ui";
import React, { useMemo } from "react";
import { ThemeProvider, css, ThemeContext } from "styled-components";

export enum FormSize {
  default = "medium",
  large = "large",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  medium = "medium",
  small = "small",
}
interface FormThemeProps {
  size: FormSize;
  children: React.ReactNode;
}

interface ThemeProps {
  theme: {
    form?: {
      size?: FormSize;
    };
  };
  size?: FormSize;
  [key: string]: any;
}

export function FormThemeProvider(props: FormThemeProps) {
  let currentTheme = React.useContext(ThemeContext);

  if (!currentTheme) {
    currentTheme = theme;
  }
  const formTheme = useMemo(
    () => ({
      ...currentTheme,
      form: {
        size: props.size ?? FormSize.default,
      },
    }),
    [currentTheme, props.size]
  );

  return <ThemeProvider theme={formTheme}>{props.children}</ThemeProvider>;
}

function checkFormSize(props: ThemeProps, size: FormSize) {
  return (
    (props.theme.form?.size === size && !props.size) ||
    props.size === size ||
    props[size]
  );
}

export function formSizeStyles(size: FormSize, code: any) {
  return css`
    ${(props: ThemeProps) =>
      checkFormSize(props, size) &&
      css`
        ${code}
      `};
  `;
}

export function formSizeAttributes(
  size: FormSize,
  props: ThemeProps,
  attrs: any
) {
  return checkFormSize(props, size) ? attrs : {};
}
