import React, { useState } from "react";
import moment from "moment";
import { colors } from "styles/color-tokens";
import { searchNewHashtag } from "actions/sourceMedia";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Hashtag } from "../types";
import { AccountType } from "shared/types/accounts";
import { components, MenuListComponentProps, OptionProps } from "react-select";
import {
  CreatableSelect,
  CreatableSelectOnSelectValue,
} from "../components/creatable-select";

export function HashtagSelector({
  hashtags,
  account,
  openExpiredListModal,
  showExpiredListModal,
  onHashtagChanged,
}: {
  hashtags: Hashtag[];
  account: AccountType;
  openExpiredListModal: () => void;
  showExpiredListModal: boolean;
  onHashtagChanged: (hashtagId: string) => void;
}) {
  const dispatch = useDispatch();
  const [hashtag, setHashtag] = useState("");
  const [customHashtag, setCustomHashtag] = useState("");

  const onChange = ({
    label,
    value,
    __isNew__,
  }: CreatableSelectOnSelectValue<string>) => {
    if (__isNew__) {
      setCustomHashtag(value);
    } else {
      setCustomHashtag("");
      setHashtag(label);
      onHashtagChanged && onHashtagChanged(value);
    }
  };

  const onCustomValueChange = (val: string) => {
    setCustomHashtag("");
    setHashtag(val);
  };

  const createNewHashtag = () => {
    dispatch(
      searchNewHashtag(account._id, customHashtag.toLowerCase(), "picker")
    );
  };

  const hashtagsOptions = React.useMemo(
    () =>
      hashtags.map((hashtag) => ({
        label: hashtag.name,
        value: hashtag.hashtag_id,
      })),
    [hashtags]
  );

  return (
    <>
      <CreatableSelect
        name="hashtag"
        customValue={hashtag}
        options={hashtagsOptions}
        placeholder="Search for hashtag..."
        noOptionsMessage="No hashtag created yet."
        onChange={onChange}
        onCustomValueChange={onCustomValueChange}
        value={null}
        components={{
          MenuList: function MenuList(
            props: MenuListComponentProps<Hashtag[], false>
          ) {
            return (
              <components.MenuList {...props}>
                {props.children}
                {showExpiredListModal && (
                  <ExpiredListModalTrigger
                    data-testid="expired-hashtag-model-trigger"
                    onClick={openExpiredListModal}
                  >
                    Expired hashtags
                  </ExpiredListModalTrigger>
                )}
              </components.MenuList>
            );
          },
          Option: function Option(props: OptionProps<Hashtag[], false>) {
            const hashtag = hashtags.find(
              (item) => item.hashtag_id === props.data.value
            );
            if (!hashtag) {
              return (
                <components.Option {...props}>
                  {props.children}
                </components.Option>
              );
            }
            const expiredDate = hashtag.search_date
              ? moment().to(moment(hashtag.search_date).add(7, "days"), true)
              : "Unknown";
            return (
              <components.Option {...props}>
                <HashtagOption data-testid="hashtag-option">
                  <HashtagName>{`#${hashtag.name}`}</HashtagName>
                  <HashtagDate>{expiredDate}</HashtagDate>
                </HashtagOption>
              </components.Option>
            );
          },
        }}
      />
      <Button disabled={!customHashtag} onClick={createNewHashtag}>
        + Add
      </Button>
    </>
  );
}

const ExpiredListModalTrigger = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  text-decoration-line: underline;
  color: ${colors.primary500};
  cursor: pointer;
`;

const Button = styled.button`
  background: #4f31ff;
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  color: ${colors.white};
  border: none;
  height: 38px;
  line-height: 38px;
  margin-left: 10px;
  width: 94px;

  &:disabled {
    background: ${colors.neutral100} !important;
    color: ${colors.neutral400} !important;
  }
`;

const HashtagOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HashtagName = styled.div`
  font-size: 12px;
  color: ${colors.neutral800};
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const HashtagDate = styled.div`
  font-size: 12px;
  color: ${colors.neutral400};
`;
