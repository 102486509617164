/** @format */
import ngRedux from "ng-redux";
import ouibounce from "ouibounce";

import {ModalOnExit} from "../../react-src/ui/";
import {initAPI} from "../../react-src/api/_api";
import angular from "angular";
import Bugsnag from "@bugsnag/js";
import {openSupportChat} from '../../react-src/libs/support/open-support-chat'

initAPI();
var app = angular
  .module("sign", [
    "ngRedux",
    "ui.router",
    "ui.bootstrap",
    "ngAnimate",
    "toaster",
    "angular-google-analytics",
    "angular-timezone-selector",
    "react",
    "ngCookies",
  ])
  .value("ngrModalOnExit", ModalOnExit);

app
  .config([
    "$stateProvider",
    "$urlRouterProvider",
    "$httpProvider",
    "$locationProvider",
    function (
      $stateProvider,
      $urlRouterProvider,
      $httpProvider,
      $locationProvider
    ) {
      $locationProvider.html5Mode(true);
      $urlRouterProvider.when("/signup?email", "/signup/step-1?email");
      $urlRouterProvider.when("/signup?plan", "/signup/step-1?plan");

      $stateProvider
        .state("login", {
          url: "/signin?token",
          templateUrl: "/assets/sign-up/login.html",
          controller: "loginController",
          params: {title: "Sign in to Sked"},
        })
        .state("signUpCollab", {
          url: "/signup/collab",
          templateUrl: "/assets/sign-up/sign-up-step-1.html",
          controller: "collabSignupController",
          params: {
            title: "Collaborator Sign Up",
          },
        })
        .state("signUp", {
          url: "/signup",
          template: "<section ui-view></section>",
          controller: "signUpController",
          params: {
            title: "Sign Up for Sked",
            user: null,
          },
          abstract: true,
        })
        .state("signUp.1", {
          url: "/step-1",
          templateUrl: "/assets/sign-up/sign-up-step-1.html",
          params: {
            title: "Sign up for Sked: Step 1",
          },
        })
        .state("signUp.2", {
          url: "/step-2",
          templateUrl: "/assets/sign-up/sign-up-step-2.html",
          params: {
            title: "Sign up for Sked: Step 2",
          },
        });
    },
  ])
  .config([
    "AnalyticsProvider",
    function (AnalyticsProvider) {
      var object = {
        tracker: "UA-46264715-2",
        crossDomainLinker: true,
        crossLinkDomains: [
          "schedugr.am",
          "skedsocial.com",
          "app.schedugr.am",
          "app.skedsocial.com",
        ],
      };
      AnalyticsProvider.setAccount(object);
      AnalyticsProvider.trackPrefix("signup");
      // don't track URL params as otherwise it drops email into GA console due to ?email=blah param
      AnalyticsProvider.trackUrlParams(false);
      AnalyticsProvider.setPageEvent("$stateChangeSuccess");
    },
  ])
  .run([
    "Analytics",
    "$rootScope",
    "$state",
    "$stateParams",
    function (Analytics, $rootScope, $state, $stateParams) {
      $rootScope.$state = $state;
      $rootScope.$stateParams = $stateParams;
    },
  ]);
angular
  .module("exceptionOverride", [])
  .factory("$exceptionHandler", function () {
    return function (exception, cause) {
      Bugsnag.notify(exception,  function (event) {
          event.addMetadata("angular", {cause: cause});
        },
      );
    };
  });
app.controller("signUpController", [
  "$q",
  "$scope",
  "$http",
  "$state",
  "$window",
  "Analytics",
  "$timeout",
  "$cookies",
  "toaster",
  "$uibModal",
  "$location",
  function (
    $q,
    $scope,
    $http,
    $state,
    $window,
    Analytics,
    $timeout,
    $cookies,
    toaster,
    $modal,
    $location
  ) {
    $timeout(function () {
      $window.initMailChck();
      $window.initAll();
      if ($window.fbq) $window.fbq("track", "AddToCart");
    }, 1000);

    $scope.readyElement = function () {
      $timeout(function () {
        ouibounce(document.getElementById("modalOnExit"), {aggressive: true});
      }, 0);
    };
    $scope.$on("$stateChangeSuccess", function () {
      $window.initAll();
      if ($window.pintrk) {
        $window.pintrk("track", "pagevisit");
      }
      if ($window._hsq) {
        $window._hsq.push(['trackPageView']);
      }
      console.log("user", window.__user, $scope.fprom);
    });
    $scope.passwordInputType = "password";
    $scope.showHidePassword = function () {
      $scope.passwordInputType =
        $scope.passwordInputType === "password" ? "text" : "password";
    };

    console.log("user", window.__user);
    var user = ($scope.user = $state.params.user || window.__user || {});
    $scope.fprom = $cookies.get("_fprom_track");
    $scope.fbc = $cookies.get("_fbc");
    $scope.fbp = $cookies.get("_fbp");
    $window.ga(function (tracker) {
      $scope.cid = tracker.get("clientId");
      console.log("ga-cid2", $scope.cid);
    });

    user.invited = user.role === "collaborator";

    var invite = window.__invite;
    if (invite) {
      user.email = user.email || invite.email;
      user.company = invite.company;
      user.token = invite.token;
      user.invited = true;
    }
    $scope.$watch("user.timezone", function (dest) {
      if (dest && dest.includes("Australia")) {
        $scope.user.currency = "aud";
      }
    });
    $scope.currencies = [
      {
        id: "usd",
        label: "Pay in US Dollars (USD)",
      },
      {
        id: "aud",
        label: "Pay in Australian dollars (AUD)",
      },
      {
        id: "eur",
        label: "Pay in Euros (EUR)",
      },
      {
        id: "gbp",
        label: "Pay in Pounds (GBP)",
      },
    ];
    $scope.plans = [
      {
        id: "sked-fundamentals",
        label: "Fundamentals (month-to-month)",
      },
      {
        id: "sked-fundamentals-annual",
        label: "Fundamentals (annual)",
      },
      {
        id: "sked-essentials",
        label: "Essentials (month-to-month)",
      },
      {
        id: "sked-essentials-annual",
        label: "Essentials (annual)",
      },
      {
        id: "sked-professional",
        label: "Professional (month-to-month)",
      },
      {
        id: "sked-professional-annual",
        label: "Professional (annual)",
      },
      {
        id: "sked-enterprise",
        label: "Enterprise (month-to-month)",
      },
      {
        id: "sked-enterprise-annual",
        label: "Enterprise (annual)",
      },
    ];
    $scope.toggleReferrer = function () {
      $scope.showReferrer = !$scope.showReferrer;
      $window.initAll();
    };

    $scope.register = function (user, form) {
      // shims the passwordRepeat field - no longer included in signup
      var signUp = {
        email: user.email,
        password: user.password,
        passwordRepeat: user.password,
        token: user.token,
        agreedTos: user.agreedTos,
        signupCid: $scope.cid,
        fbData: {
          fbc: $scope.fbc,
          fbp: $scope.fbp,
        },
      };
      //{"status":"error","message":{"login":"A user with this login already exists. Please login with your existing credentials."}}
      //{"status":"error","message":{"passwordRepeat":"Your passwords are not equal"}}
      if ($window.pix) {
        $window.pix("Lead", {email: user.email || invite.email});
      }
      if ($window.fbq) {
        $window.fbq("track", "Lead");
      }
      if ($window.pintrk) $window.pintrk("track", "lead")
      // Ortto identification
      if ($window.ap3c && $window.ap3c.track) {
        $window.ap3c.track({
          "v": 0,
          "email": user.email,
          "first": user.firstName,
          "last": user.lastName,
          "ei": user._id
        });
      }
      if ($window._hsq) {
        $window._hsq.push(["identify", {id: user._id, email: user.email}]);
      }
      delete $scope.error;
      $q.when(window.axios
        .post("/signup", signUp))
        .then(
          function (res) {
            var newUser = (res.data && res.data.user) || {};
            if (newUser._id) Analytics.set("&uid", newUser._id);
            if (newUser._id) Analytics.set("userId", newUser._id);
            if (newUser._id && $window.amplitude)
              $window.amplitude.setUserId(newUser._id);
            $scope.user._id = newUser._id;
            $scope.user.role = newUser.role;
            $scope.user.currency = "usd";
            $scope.user.plan = newUser.plan || "sked-fundamentals";
            $scope.showReferrer = false;
            $scope.showCoupon = false;
            $state.go("signUp.2");
          },
          function ({response: res}) {
            $scope.error = res.data;
          }
        )
        .finally(function () {
          form.$setPristine();
        });
    };
    $scope.showIntercom = function (message) {
      openSupportChat(message, 'onboarding')
    };
    $scope.openWebinarLink = function () {
      $window.open("https://skedsocial.com/webinar", "_blank");
    };
    $scope.toggleCoupon = function () {
      $scope.showCoupon = !$scope.showCoupon;
    };

    function parseName(input) {
      input = input.trim();
      var fullName = input || "";
      var result = {};

      if (fullName.length > 0) {
        var nameTokens =
          fullName.match(
            /[A-ZÁ-ÚÑÜ][a-zá-úñü]+|([aeodlsz]+\s+)+[A-ZÁ-ÚÑÜ][a-zá-úñü]+/g
          ) || [];
        if (nameTokens.length === 1) {
          result = {firstName: nameTokens[0], lastName: ""};
        } else if (nameTokens.length === 0) {
          nameTokens = input.split(" ");
          if (nameTokens.length > 2) {
            var firstName = nameTokens[0];
            nameTokens.shift();
            result = {firstName: firstName, lastName: nameTokens.join(" ")};
          } else {
            result = {
              firstName: nameTokens[0],
              lastName: nameTokens.length > 1 ? nameTokens[1] : "",
            };
          }
        } else if (nameTokens.length > 3) {
          result.firstName = nameTokens.slice(0, 2).join(" ");
          result.lastName = nameTokens.join(" ");
        } else if (nameTokens.length > 2) {
          result.firstName = nameTokens[0];
          result.lastName =
            nameTokens.slice(-2, -1).join(" ") +
            " " +
            nameTokens.slice(-1).join(" ");
        } else {
          result.firstName = nameTokens[0];
          result.lastName = nameTokens.slice(-1).join(" ");
        }
      }
      console.log("input", input, result);
      console.log("nameTokens", nameTokens);
      return result;
    }

    $scope.updateDetails = function (userForm, form) {
      delete $scope.error;
      var user = $scope.user;
      var name = parseName(user.fullName);
      // override this as it doesn't make sense otherwise
      if (user.timezone && user.timezone.includes("Australia")) {
        user.currency = "aud";
      }
      $q.when(window.axios
        .post("/signupDetails", {
          firstName: name.firstName,
          lastName: name.firstName === name.lastName ? "" : name.lastName || "",
          company: user.company,
          currency: user.currency,
          referredBy: user.referredBy,
          timezone: user.timezone,
          plan: user.plan || "sked-fundamentals",
          tid: $scope.fprom,
          coupon: user.coupon || null,
        }))
        .then(
          function (resp) {
            console.log("signupDetails", resp.data);
            if (resp.data && resp.data.data && resp.data.data.user) {
              $scope.user = Object.assign($scope.user, resp.data.data.user);
              if ($window.amplitude)
                $window.amplitude.setUserId($scope.user._id);
            }
            if ($window.pix) {
              $window.pix("SignUp", {email: user.email || invite.email});
            }
            if (user.role === "collaborator") {
              location.href = "/";
            } else {
              if ($window.fbq) {
                $window.fbq("track", "InitiateCheckout", {
                  currency: user.currency,
                });
              }
              if ($window.pintrk) $window.pintrk("track", "addtocart", {
                currency: user.currency,
                product_id: user.plan,
              })
              $modal.open({
                templateUrl: "pick-trial-option.html",
                size: "lg",
                scope: $scope,
                backdrop: "static",
              });
              // start polling for check to see if user can sign up without cc
              $scope.pollForUserRecord();
            }
          },
          function ({response: res}) {
            $scope.error = res.data;
          }
        )
        .finally(function () {
          form.$setPristine();
        });
    };
    $scope.pollForUserRecord = function () {
      var loadTime = 5000, //Load the data every second
        errorCount = 0, //Counter for the server errors
        loadPromise; //Pointer to the promise created by the Angular $timout service
      var getData = function () {
        $q.when(window.axios
          .get("/signup/me"))

          .then(function (res) {
            console.log(res.data);
            if (res && res.data && res.data.user) {
              $scope.user = res.data.user;
            }

            errorCount = 0;
            nextLoad();
          })
          .catch(function (res) {
            console.error(res);
            nextLoad(++errorCount * 2 * loadTime);
          });
      };
      var cancelNextLoad = function () {
        $timeout.cancel(loadPromise);
      };
      var nextLoad = function (mill) {
        mill = mill || loadTime;
        //Always make sure the last timeout is cleared before starting a new one
        cancelNextLoad();
        loadPromise = $timeout(getData, mill);
      };
      //Start polling the data from the server
      getData();
      //Always clear the timeout when the view is destroyed, otherwise it will keep polling and leak memory
      $scope.$on("$destroy", function () {
        cancelNextLoad();
      });
    };
    $scope.startTrialWithoutCC = function (user) {
      $q.when(window.axios
        .put("/signup/createCustomerInTrialWithoutCC"))
        .then(function (res) {
          if (!$window.uetq) {
            $window.uetq = [];
          }
          $window.uetq.push("event", "signup-completed", {
            event_category: "signup",
            event_label: "signup-completed",
          });
          if ($window.qp) {
            $window.qp("track", "CompleteRegistration");
          }
          if ($window.fbq) {
            $window.fbq("track", "StartTrial", {subscription_id: user._id});
          }
          if ($window.pintrk) $window.pintrk("track", "signup")
          if ($window.gtag) {
            $window.gtag("event", "conversion", {
              send_to: "AW-992177465/ZcAhCPGU34gBELnajdkD",
              event_callback: function () {
                location.href = "/dashboard/accounts";
              },
            });
            return false;
          } else {
            location.href = "/dashboard/accounts";
          }
        })
        .catch(function (err) {
          console.error(err);
          err = err.data ? err.data : err;
          toaster.error({title: err.message ? err.message : err});
        });
    };
    $scope.addCreditCardForUser = function (user, fromSignup) {
      var body = {currency: user.currency || null, plan: user.plan || null};
      if (fromSignup) {
        body.source = "signup";
      }
      /* Initialize a Chargebee instance after mounting the root component. */
      var chargebeeInstance = Chargebee.init({
        site: getChargebeeSite(user.currency),
      }).openCheckout({
        hostedPage: function () {
          // We will discuss on how to implement this end point in the next step.
          return $q.when(window.axios
            .post("/signup/getHostedCCPage", body))
            .then(function (response) {
              return response.data;
            });
        },
        success: function (hostedPageId) {
          // success callback
          console.log("done", hostedPageId);

          $q.when(window.axios
            .post("/signup/acknowledgeHostedPage", {pageId: hostedPageId}))
            .then(function (response) {
              console.log("ackHostedPageResp", response);
              if (response && response.data && response.data.action === "ok") {
                if (!$window.uetq) {
                  $window.uetq = [];
                }
                $window.uetq.push("event", "signup-completed", {
                  event_category: "signup",
                  event_label: "signup-completed",
                });
                if ($window.qp) {
                  $window.qp("track", "CompleteRegistration");
                }
                if ($window.fbq) {
                  $window.fbq("track", "StartTrial");
                }
                if ($window.pintrk) $window.pintrk("track", "checkout", {
                  product_id: user.plan,
                })
                if ($window.gtag) {
                  $window.gtag("event", "conversion", {
                    send_to: "AW-992177465/ZcAhCPGU34gBELnajdkD",
                    event_callback: function () {
                      location.href = "/dashboard/accounts";
                    },
                  });
                  return false;
                } else {
                  location.href = "/dashboard/accounts";
                }
              } else {
                if (response && response.data) {
                  toaster.error({
                    title:
                      "There was an error creating your subscription. Please try again or contact support. " +
                      response.data,
                  });
                } else {
                  toaster.error({
                    title:
                      "There was an error creating your subscription. Please try again or contact support. ",
                  });
                }
              }
            })
            .catch(function (err) {
              console.error(err);
              // TODO send to raven
              err = err.data ? err.data : err;
              toaster.error({title: err.message ? err.message : err});
            });
        },
        loaded: function () {
          // Optional
          // will be called once checkout page is loaded
        },
        error: function (error) {
          // Optional
          // will be called if the promise passed causes an error
          console.log("err", error);
        },
        step: function (step) {
          // Optional
          // will be called for each step involved in the checkout process
          console.log("step", step);
        },
        close: function () {
          // Optional
          // will be called when the user closes the checkout modal box
          console.log("User cancelled checkout");
        },
      });
    };

    $scope.fixTimezone = function () {
      // prevent jstimezonedetect from using the Intl API
      if (window.Intl) {
        var dateTimeFormat = window.Intl.DateTimeFormat;
        window.Intl.DateTimeFormat = undefined;
        setTimeout(function () {
          window.Intl.DateTimeFormat = dateTimeFormat;
        }, 2000);
      }
    };
  },
]);

app.controller("loginController", [
  "$q",
  "$scope",
  "$http",
  "$uibModal",
  "$stateParams",
  "$rootScope",
  "$state",
  "$window",
  "$timeout",
  "toaster",
  "Analytics",
  "$location",
  "$cookies",
  function (
    $q,
    $scope,
    $http,
    $modal,
    $stateParams,
    $rootScope,
    $state,
    $window,
    $timeout,
    toaster,
    Analytics,
    $location,
    $cookies
  ) {
    $timeout(function () {
      $window.initAll();
    }, 1000);
    $scope.login = {};

    if ($stateParams.token) {
      //reset password
      openResetPassword($stateParams.token);
    }

    $scope.passwordInputType = "password";
    $scope.showHidePassword = function () {
      $scope.passwordInputType =
        $scope.passwordInputType === "password" ? "text" : "password";
    };

    $scope.signIn = function (credentials, form) {
      delete $scope.login.error;

      $q.when(window.axios.post("/signin", credentials)).then(
        function (res) {
          // remove skedApiToken in case this user has just logged out not via menu - will be replaced with /api-jwt
          $window.localStorage.removeItem('skedApiToken');
          handleLoginResponse(res, form);
        },
        function ({response: res}) {
          $scope.login.error = res.data;
          form.$setPristine();
        }
      );
    };
    $http
      .get(
        "https://api-us.storyblok.com/v2/cdn/stories/login-page?cv=1668468307&token=HWGQblG117q1oqXDape7Swtt&version=published"
      )
      .then(function (res) {
        console.log(res);
        if (res && res.data && res.data.story && res.data.story.content) {
          var item = res.data.story.content;
          item.url =
            item.url +
            "?utm_source=web-app&utm_medium=dashboard&utm_campaign=dashboard";
          $rootScope.featuredPost = {
            title: item.title,
            url: item.url,
            imageUrl: item.image.filename,
            description: item.description,
            buttonLabel: item.button_label
          };
        }
      });

    function handleLoginResponse(res, form) {
      res = res.data || {};
      // clear API token at login - temporary
      console.log("handleLoginResponse", res);
      if (!res.status) {
        console.error("Not status present, trying refresh.");
        $window.location.reload();
      }
      switch (res.status) {
        case "redirect":
          location.href = res.url;
          break;
        case "error":
          $scope.login.error = res;
          break;
        case "reactivate":
          showReactivate(res);
          break;
        case "reactivateFromSignup":
          showReactivateFromSignup(res);
          break;
        case "reactivateFromPaused":
          showReactivateFromPause(res);
          break;
        case "activateCollab":
          $state.go("signUp.2", {user: res.data.user});
          break;
        case "totp":
          show2Fa();
      }
      if (form) form.$setPristine();
    }

    function show2Fa() {
      $scope.totp = {};
      $modal.open({templateUrl: "/assets/sign-up/2fa.html", scope: $scope});
    }

    function showReactivateFromPause(res) {
      console.log("showReactivateFromPause", res);
      $scope.user = res.data.user;
      $scope.submitReactivation = false;
      $scope.reactivateMessage = {};

      var modal = $modal.open({
        templateUrl: "reactivate-account-from-pause.html",
        scope: $scope,
        controller: [
          "$scope",
          "$window",
          function ($scope, $window) {
            $window.initAll();
            $scope.submitReactivation = false;
            $scope.reactivateAccountFromPause = function (user, form) {
              $scope.submitReactivation = true;
              $q.when(window.axios
                .post("/reactivateAccountFromPause", {
                  userId: user._id,
                }))
                .then(
                  function (res) {
                    res = res.data;
                    if (res.status === "ok") {
                      $scope.reactivateMessage.success = res;
                      $scope.submitReactivation = false;
                      $scope.loginForm.$submitted = false;
                      modal.close();
                    } else if (res.status == "error") {
                      $scope.reactivateMessage.error = res;
                      $scope.submitReactivation = false;
                    }
                  },
                  function ({response: res}) {
                    $scope.reactivateMessage.error = res.data;
                    $scope.submitReactivation = false;
                  }
                )
                .finally(function () {
                  $scope.submitReactivation = false;
                  form.$setPristine();
                });
            };
          },
        ],
      });
    }

    function showReactivateFromSignup(res) {
      console.log("showReactivateFromPause", res);
      $scope.user = res.data.user;
      $scope.submitReactivation = false;
      $scope.reactivateMessage = {};

      var modal = $modal.open({
        templateUrl: "activate-account-from-signup.html",
        scope: $scope,
        controller: [
          "$scope",
          "$window",
          "$location",
          function ($scope, $window, $location) {
            $window.initAll();
            $scope.submitReactivation = false;

            $scope.showIntercom = function (message) {
                openSupportChat(message)
            };
            $scope.activateAccountFromSignup = function (user, form) {
              $scope.submitReactivation = true;
              if (
                user &&
                user.preferences &&
                user.preferences.allowSignupWithoutCc
              ) {
                $q.when(window.axios
                  .post("/signup/createCustomerInTrialWithoutCC"))
                  .then(function (resp) {
                    if (resp && resp.data && resp.data.status === "ok") {
                      $scope.reactivateMessage.success = {
                        data:
                          "Your subscription was successfully reactivated. Login using the form below.",
                      };
                      $scope.submitReactivation = false;
                      modal.close();
                      if ($window.qp) {
                        $window.qp("track", "CompleteRegistration");
                      }
                      if (!$window.uetq) {
                        $window.uetq = [];
                      }
                      $window.uetq.push("event", "signup-completed", {
                        event_category: "signup",
                        event_label: "signup-completed",
                      });
                      if ($window.fbq) {
                        $window.fbq("track", "StartTrial");
                      }
                      if ($window.pintrk) $window.pintrk("track", "signup")
                      if ($window.gtag) {
                        $window.gtag("event", "conversion", {
                          send_to: "AW-992177465/ZcAhCPGU34gBELnajdkD",
                        });
                      }
                    } else {
                      console.log(resp);
                      var err = resp;
                      err = err.data ? err.data : err;
                      toaster.error({title: err.message ? err.message : err});
                      $scope.submitReactivation = false;
                    }
                  })
                  .catch(function (err) {
                    console.error(err);
                    // TODO send to raven
                    err = err.data ? err.data : err;
                    toaster.error({title: err.message ? err.message : err});
                    $scope.submitReactivation = false;
                  });
              } else {
                /* Initialize a Chargebee instance after mounting the root component. */
                var chargebeeInstance = Chargebee.init({
                  site: getChargebeeSite(user.currency),
                }).openCheckout({
                  hostedPage: function () {
                    return $q.when(window.axios
                      .post("/signup/getHostedCCPage", {
                        source: "reactivate",
                        currency: user.currency || null,
                        plan: user.plan || null,
                      }))
                      .then(function (response) {
                        return response.data;
                      });
                  },
                  success: function (hostedPageId) {
                    // success callback
                    console.log("done", hostedPageId);

                    $q.when(window.axios
                      .post("/signup/acknowledgeHostedPage", {
                        pageId: hostedPageId,
                      }))
                      .then(function (response) {
                        console.log("ackHostedPageResp", response);
                        if (
                          response &&
                          response.data &&
                          response.data.action === "ok"
                        ) {
                          $scope.reactivateMessage.success = {
                            data:
                              "Your subscription was successfully reactivated. Login using the form below.",
                          };
                          $scope.submitReactivation = false;
                          $scope.loginForm.$submitted = false;
                          modal.close();
                          if (!$window.uetq) {
                            $window.uetq = [];
                          }
                          $window.uetq.push("event", "signup-completed", {
                            event_category: "signup",
                            event_label: "signup-completed",
                          });
                          if ($window.qp) {
                            $window.qp("track", "CompleteRegistration");
                          }
                          if ($window.fbq) {
                            $window.fbq("track", "StartTrial");
                          }
                          if ($window.pintrk) $window.pintrk("track", "checkout", {
                            product_id: user.plan,
                          })
                          if ($window.gtag) {
                            $window.gtag("event", "conversion", {
                              send_to: "AW-992177465/ZcAhCPGU34gBELnajdkD",
                            });
                          }
                        } else {
                          if (response && response.data) {
                            toaster.error({
                              title:
                                "There was an error reactivating your subscription. Please try again or contact support. " +
                                response.data,
                            });
                          } else {
                            toaster.error({
                              title:
                                "There was an error reactivating your subscription. Please try again or contact support. ",
                            });
                          }
                          $scope.reactivateMessage.error = response;
                          $scope.submitReactivation = false;
                        }
                      })
                      .catch(function (err) {
                        console.error(err);
                        // TODO send to raven
                        err = err.data ? err.data : err;
                        toaster.error({
                          title: err.message ? err.message : err,
                        });
                        $scope.submitReactivation = false;
                      });
                  },
                  loaded: function () {
                    // optional
                  },
                  error: function (error) {
                    // Optional
                    // will be called if the promise passed causes an error
                    console.log("err", error);
                    $scope.submitReactivation = false;
                  },
                  step: function (step) {
                    // Optional
                    // will be called for each step involved in the checkout process
                    console.log("step", step);
                  },
                  close: function () {
                    // Optional
                    // will be called when the user closes the checkout modal box
                    console.log("User cancelled checkout");
                    $scope.submitReactivation = false;
                  },
                });
              }
            };
          },
        ],
      });
    }

    function showReactivate(res) {
      console.log("showReactivate", res);
      $scope.user = res.data.user;
      $scope.submitReactivation = false;
      $scope.reactivateMessage = {};

      var modal = $modal.open({
        templateUrl: "reactivate-account.html",
        scope: $scope,
        controller: [
          "$scope",
          "$window",
          function ($scope, $window) {
            $scope.reactivateAccount = function (user, payment, form) {
              /* Initialize a Chargebee instance after mounting the root component. */
              var chargebeeInstance = Chargebee.init({
                site: getChargebeeSite(user.currency),
              }).openCheckout({
                hostedPage: function () {
                  // We will discuss on how to implement this end point in the next step.
                  return $q.when(window.axios
                    .post("/signup/getHostedCCPageReactivate", {
                      currency: user.currency || null,
                      plan: user.plan || null,
                    }))
                    .then(function (response) {
                      return response.data;
                    });
                },
                success: function (hostedPageId) {
                  // success callback
                  console.log("done", hostedPageId);

                  $q.when(window.axios
                    .post("/signup/acknowledgeHostedPage", {
                      pageId: hostedPageId,
                    }))
                    .then(function (response) {
                      console.log("ackHostedPageResp", response);
                      if (
                        response &&
                        response.data &&
                        response.data.action === "ok"
                      ) {
                        console.log("OK, reactivated - refreshing");
                        $scope.reactivateMessage.success = {
                          data:
                            "Your subscription was successfully reactivated. Login using the form below.",
                        };
                        $scope.submitReactivation = false;
                        $scope.loginForm.$submitted = false;
                        modal.close();
                      } else {
                        console.error("NOT OK", response);
                        if (response && response.data) {
                          toaster.error({
                            title:
                              "There was an error reactivating your subscription. Please try again or contact support. " +
                              response.data,
                          });
                        } else {
                          toaster.error({
                            title:
                              "There was an error reactivating your subscription. Please try again or contact support. ",
                          });
                        }
                        $scope.reactivateMessage.error = response;
                        $scope.submitReactivation = false;
                      }
                    })
                    .catch(function (err) {
                      console.error(err);
                      // TODO send to raven
                      err = err.data ? err.data : err;
                      toaster.error({title: err.message ? err.message : err});
                    });
                },
                loaded: function () {
                  // optional
                },
                error: function (error) {
                  // Optional
                  // will be called if the promise passed causes an error
                  console.log("err", error);
                },
                step: function (step) {
                  // Optional
                  // will be called for each step involved in the checkout process
                  console.log("step", step);
                },
                close: function () {
                  // Optional
                  // will be called when the user closes the checkout modal box
                  console.log("User cancelled checkout");
                },
              });
            };
          },
        ],
      });
    }

    $scope.signInTotp = function (auth, form) {
      delete $scope.totp.error;
      $q.when(window.axios.post("/signinTotp", auth)).then(
        function (res) {
          handleLoginResponse(res, form);
        },
        function ({response: res}) {
          $scope.totp.error = res.data;
          form.$setPristine();
        }
      );
    };

    $scope.saveForm = function (url, data) {
      $q.when(window.axios
        .post(url, data))
        .then(
          function (res) {
            res = res.data;
            if (res.status === "ok") {
              $scope.forgotPassword = {success: res};
            }
            modal.close();
          },
          function ({response: res}) {
            $scope.forgotPassword.error = res.data;
          }
        )
        .finally(function () {
          form.$setPristine();
        });
    };

    $scope.openForgotPassword = function () {
      $scope.forgotPassword = {};
      var modal = $modal.open({
        templateUrl: "forgot-password.html",
        size: "md",
        scope: $scope,
        controller: [
          "$scope",
          "$window",
          function (scope, $window) {
            // jquery input
            $window.initAll();

            scope.showIntercom = function (message) {
               openSupportChat(message)
            };

            scope.resetPassword = function (password, form) {
              $q.when(window.axios
                .post("/profile/forgotpassword", password))
                .then(
                  function (res) {
                    res = res.data;
                    if (res.status === "ok") {
                      $scope.forgotPassword = {success: res};
                    }
                    modal.close();
                  },
                  function ({response: res}) {
                    $scope.forgotPassword.error = res.data;
                  }
                )
                .finally(function () {
                  form.$setPristine();
                });
            };
          },
        ],
      });
    };

    function openResetPassword(token) {
      $scope.resetPassword = {};
      var modal = $modal.open({
        templateUrl: "reset-password.html",
        scope: $scope,
        controller: [
          "$scope",
          function (scope) {
            scope.saveNewPassword = function (password, form) {
              password.token = token;
              $q.when(window.axios
                .post("/profile/reset", password))
                .then(
                  function (res) {
                    res = res.data;
                    if (res.status === "ok") {
                      $scope.resetPassword = {success: res};
                    }
                    modal.close();
                  },
                  function ({response: res}) {
                    $scope.resetPassword.error = res.data;
                  }
                )
                .finally(function () {
                  form.$setPristine();
                });
            };
          },
        ],
      });
    }

    // init all jquery features
    $window.initAll();
  },
]);

app.controller("2faController", [
  "$q",
  "$scope",
  "$uibModal",
  "$http",
  function ($q, $scope, $modal, $http) {
    $scope.signIn = function (auth, form) {
      $q.when(window.axios.post("/signinTotp", auth)).then();
    };
  },
]);

app.controller("collabSignupController", [
  "$q",
  "$scope",
  "$http",
  "$window",
  "$state",
  "Analytics",
  "$timeout",
  function ($q, $scope, $http, $window, $state, Analytics, $timeout) {
    $timeout(function () {
      $window.initAll();
    }, 1000);

    var invite = $window.__invite;
    $scope.passwordInputType = "password";
    $scope.showHidePassword = function () {
      $scope.passwordInputType =
        $scope.passwordInputType === "password" ? "text" : "password";
    };
    if (!invite) {
      return $state.go("signUp.1");
    }
    $scope.invite = {inviter: invite.inviter};
    $scope.user = {email: invite.email, token: invite.token};

    $scope.collabSignup = true;
    // clear API token at signup - temporary
    localStorage.setItem("skedApiToken", null);
    $scope.register = function (user, form) {
      var signUp = {
        email: user.email,
        password: user.password,
        passwordRepeat: user.password,
        token: user.token,
        firstName: user.firstName,
        lastName: user.lastName,
        agreedTos: user.agreedTos,
      };

      delete $scope.error;

      $q.when(window.axios
        .post("/signup/collab", signUp))
        .then(
          function (res) {
            Analytics.trackEvent("signup", "signup-collab");
            location.href = "/";
          },
          function ({response: res}) {
            $scope.error = res.data;
          }
        )
        .finally(function () {
          form.$setPristine();
        });
    };
  },
]);

app
  .directive("select2", function () {
    return {
      require: "ngModel",
      scope: {
        ngModel: "=",
      },
      link: function (scope, el) {
        el.select2({
          dropdownAutoWidth: true,
          minimumResultsForSearch: 4,
        });
        setTimeout(function () {
          el.change();
        });
      },
    };
  })
  .directive("modalDismiss", function () {
    return {
      link: function (scope, el) {
        el.on("click", function () {
          scope.$dismiss();
        });
      },
    };
  })
  .directive("modalClose", function () {
    return {
      link: function (scope, el) {
        el.on("click", function () {
          scope.$close();
        });
      },
    };
  })
  .directive("freshdeskTrigger", function () {
    return {
      link: function (scope, el) {
        el.on("click", function (e) {
          angular.element("#freshwidget-button a")[0].click(e.originalEvent);
        });
      },
    };
  })
  .filter("trust", [
    "$sce",
    function ($sce) {
      return function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
      };
    },
  ]);

function getChargebeeSite(selectedCurrency) {
  const testEnv = ["dev", "staging"].includes(process.env.NODE_ENV);

  return testEnv
    ? "schedugram-usd-test"
    : (() => {
      switch (selectedCurrency) {
        case "aud":
          return "schedugram";
        case "eur":
          return "schedugram-eur";
        case "gbp":
          return "schedugram-gbp";
        default:
          return "schedugram-usd";
      }
    })();
}

// used for when customer has recurrent csrf issues
function resetCookies(noalert) {
  var cookies = document.cookie.split(";");
  var cookieNames = [];
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    cookieNames.push(name);
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
  var message = "Cookies Cleared:\n" + cookieNames.join("\n");
  console.log(message);
  if (!noalert) alert(message);
  window.location.href = "/signin";
}
