export type HubSpotHelpChatTopic =
  | "generic"
  | "accounts"
  | "plans"
  | "approvals"
  | "onboarding"
  | "upsell";

export function openSupportChat(
  message = "",
  helpChatTopic: HubSpotHelpChatTopic = "generic"
) {
  console.log("Opening HubSpot support chat", {
    message,
    query: helpChatTopic,
  });

  try {
    const searchParams = new URLSearchParams(window.location.search);
    const currentQuery = searchParams.get("hs-sked-chat");

    if (currentQuery === helpChatTopic) {
      window.HubSpotConversations.widget.open();
      return;
    }
    window.history.pushState({}, "", `?hs-sked-chat=${helpChatTopic}`);

    //refreshing the wiget will automatically close the widget
    //need to reopen after it refreshes
    window.HubSpotConversations.widget.refresh({ openToNewThread: true });
    setTimeout(() => {
      window.HubSpotConversations.widget.open();
    }, 2000);
  } catch {
    return alert(
      "There was a problem opening live chat (please check your ad blocker is disabled). Please click the chat icon in the corner or email hi@skedsocial.com."
    );
  }
}
