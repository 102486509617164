import {
  generateVideoThumbnails,
  importFileandPreview,
} from "@rajesh896/video-thumbnails-generator";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { extractMediaUrls } from "../../utils";
import { allowedSelectCoverPlatforms } from "./const";
import { UseVidoeCoverProps } from "./types";
import { convertVideoUrlToBlob } from "./utils";

export const useVideoCover = ({
  platformPost,
  selectedPlatform,
  platforms,
}: UseVidoeCoverProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [videoMedia, setVideoMedia] = useState<File | null>(null);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [videoLoadingError, setVideoLoadingError] = useState(false);

  const mediaFiles = useMemo(() => {
    const { mediaFiles, isVideo, url } = platformPost;

    if (Array.isArray(mediaFiles) && mediaFiles.length > 0) {
      return mediaFiles;
    }

    if (isVideo && url) {
      return [
        {
          isVideo,
          url,
        },
      ];
    }

    return [];
  }, [platformPost]);

  const isVideoPost =
    mediaFiles.length === 1 && mediaFiles[0]?.isVideo === true;

  const showVideoSelector = useMemo(() => {
    const { isStory, storyType } = platformPost;
    const mediaUrls = extractMediaUrls(platformPost);
    if (
      mediaUrls.length > 1 || // for more than 1 assets
      (mediaUrls.length > 0 && !isVideoPost) || // for image assets
      (isStory && storyType !== "reel")
    ) {
      return false;
    }

    if (selectedPlatform === "LINKED") {
      return platforms.some((platform) =>
        allowedSelectCoverPlatforms.includes(platform.TYPE)
      );
    }

    return allowedSelectCoverPlatforms.includes(selectedPlatform);
  }, [platformPost, selectedPlatform, platforms, isVideoPost]);

  const { url: mediaUrl, thumbnailUrl } = mediaFiles?.[0] || {};

  useEffect(() => {
    if (!mediaUrl || !showVideoSelector) {
      if (videoUrl) {
        setVideoUrl("");
        setThumbnails([]);
        setVideoMedia(null);
        setVideoLoadingError(false);
      }
      return;
    }

    const init = async () => {
      setIsLoading(true);

      try {
        const video = await convertVideoUrlToBlob(mediaUrl);

        if (!video) {
          toast.error(
            "Fail to load the video, please remove the video and try again."
          );
          setIsLoading(false);
          setVideoLoadingError(true);
          return;
        }

        const thumbs = await generateVideoThumbnails(video as File, 10, "jpg");
        const res = await importFileandPreview(video as File);

        setVideoMedia(video as File);
        setThumbnails(thumbs);
        setVideoUrl(res);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaUrl, showVideoSelector]);

  return {
    showVideoSelector,
    isLoading,
    videoMedia,
    videoUrl,
    thumbnails,
    thumbnailUrl,
    videoLoadingError,
  };
};
