import { PlatformTypes, Post } from "./types";
import { PostMediaFile } from "../media-library/types";
import { parseThreadsData, parseTikTokData } from "./format-post";
import { allowedSelectCoverPlatforms } from "components/Posts/PostForm/components/VideoCoverSelector/const";

export type PostApiInput = PostMediaFile & {
  caption: string;
  mediaFiles: PostMediaFile[];
  location: unknown;
  // instagram field
  firstcomment?: string;
  tags?: unknown[];
  productsTags?: [];
  collaboratingIgUsers?: [];
  altText?: string;
  fileName?: string;
  meta?: object;
};

export function getPostDataFromTypedPost(post: Post): PostApiInput {
  const data = {
    _id: post._id,
    accountIds: post.accountIds,
    postType: post.state,
    channel: post.channel,
    caption: post.caption || "",
    labelIds: post.labelIds || [],
    firstcomment: post.firstcomment || "", // required for some reason
    ...parseMediaFileToPostRootData(post),
    mediaFiles: post.mediaFiles,
    type: post.type,
    when: post.when,
    // ensure isNow is set to false for drafts or queued posts
    // as they can't be published 'now'
    isNow:
      post.state === "draft" || post.state === "queue" ? false : post.isNow,
    redirectUrl: post.redirectUrl,
    postStatusKey: post.postStatusKey,

    ...(post.platformType === PlatformTypes.instagram
      ? {
          tags: post.tags,
          firstcomment: post.firstcomment || "",
          productTags: post.productTags,
          isStory: post.isStory,
          location: post.location,
          storyType: post.storyType,
          storyUrl: post.storyUrl,
          storyLabel: post.storyLabel || null,
          storyStickerHeight: post.storyUrl
            ? post.storyStickerHeight || 0.8
            : null,
          publishStoryManually: post.publishStoryManually,
          collaboratingIgUsers: post.collaboratingIgUsers || [],
        }
      : {}),

    ...(post.platformType === PlatformTypes.facebook
      ? {
          location: post.location,
        }
      : {}),

    ...(post.platformType === PlatformTypes.googlemybusiness
      ? {
          gmbTopicType: post.gmbTopicType,
          gmbEvent: post.gmbEvent,
          gmbOffer: post.gmbOffer,
          gmbCallToAction: post.gmbCallToAction,
        }
      : {}),

    ...(post.platformType === PlatformTypes.linkedin
      ? {
          liTargetEntities: post.liTargetEntities,
          fileName: post.fileName || null,
          meta: post.meta || {},
        }
      : {}),

    ...(post.platformType === PlatformTypes.pinterest
      ? {
          piTitle: post.piTitle,
          piSectionId: post.piSectionId,
          piBoards: post.piBoards,
        }
      : {}),
    ...(post.platformType === PlatformTypes.tiktok
      ? parseTikTokData(post)
      : {}),
    ...(post.platformType === PlatformTypes.threads
      ? parseThreadsData(post)
      : {}),
    ...(post.platformType === PlatformTypes.youtube
      ? {
          ytTitle: post.ytTitle,
          ytPrivacy: post.ytPrivacy,
          ytCategoryId: post.ytCategoryId,
          ytTags: post.ytTags || null,
        }
      : {}),

    ...(post.platformType === PlatformTypes.cluster
      ? {
          tags: post.tags,
          productTags: post.productTags,
          location: post.location,
          gmbTopicType: post.gmbTopicType,
          gmbEvent: post.gmbEvent,
          gmbOffer: post.gmbOffer,
          gmbCallToAction: post.gmbCallToAction,
          piTitle: post.piTitle,
          piSectionId: post.piSectionId,
          piBoards: post.piBoards,
          fileName: post.fileName || null,
          meta: post.meta || {},
          thumbnailUrl: post.thumbnailUrl,
          videoCoverImageTimestamp: post?.videoCoverImageTimestamp,
          collaboratingIgUsers: post.collaboratingIgUsers || [],
        }
      : {}),
  };

  if (post.platforms && post.platforms.length > 1) {
    data.platforms = post.platforms;
  } else if (post.platforms) {
    data.platformType = post.platforms[0];
  }

  if (
    post.platforms.some((platform: string) =>
      allowedSelectCoverPlatforms.includes(platform)
    )
  ) {
    const { videoCoverImageTimestamp, thumbnailUrl } = post;

    data.videoCoverImageTimestamp = videoCoverImageTimestamp;

    if (thumbnailUrl) {
      data.thumbnailUrl = thumbnailUrl;

      if (
        Array.isArray(data.mediaFiles) &&
        data.mediaFiles.length > 0 &&
        data.mediaFiles[0].thumbnailUrl
      ) {
        data.mediaFiles[0].thumbnailUrl = thumbnailUrl;
      }
    }
  }

  return data;
}

function parseMediaFileToPostRootData(post: Post) {
  if (!post.mediaFiles.length) {
    if (post.url) {
      return {
        url: post.url,
        originalUrl: post.originalUrl,
        thumbnailUrl: post.thumbnailUrl,
        fileName: post.fileName,
        isVideo: post.isVideo,
        meta: post.meta || {},
        altText: post.altText,
      };
    }
    return {};
  }

  const { url, originalUrl, thumbnailUrl, fileName, isVideo, meta, altText } =
    post.mediaFiles[0];

  return {
    url,
    originalUrl,
    origUrl: originalUrl,
    thumbnailUrl,
    fileName,
    isVideo,
    meta,
    altText,
  };
}

export function normalizePlatformType(platformType: string): number {
  if (typeof platformType === "number") {
    return platformType;
  }

  switch (platformType) {
    case "IG":
      return PlatformTypes.instagram;

    case "FB":
      return PlatformTypes.facebook;

    case "TW":
      return PlatformTypes.twitter;

    case "LI":
      return PlatformTypes.linkedin;

    case "GMB":
      return PlatformTypes.googlemybusiness;

    case "YT":
      return PlatformTypes.youtube;

    case "PI":
      return PlatformTypes.pinterest;

    case "TT":
      return PlatformTypes.tiktok;

    case "TH":
      return PlatformTypes.threads;

    case "SC":
      return PlatformTypes.snapchat;

    case "OT":
      return PlatformTypes.other;

    default:
      return PlatformTypes.instagram;
  }
}
