import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FIELDS, FIELDS_DEFAULT } from "constants/PostFormFields";
import { SwitchField } from "../components/SwitchField";
import { get } from "lodash";
import { PostFieldLabel } from "../PostFieldLabel/PostFieldLabel";
import PlatformUsage from "../PlatformUsage/PlatformUsage";
import { Flex } from "ui/commonStyled/layout";
import { AccountPlatformType } from "shared/types/accounts";
import { TextInput } from "ui/ecosystems/forms/inputs";
import { useDebouncedCallback } from "use-debounce";
import { MediaUrlInfo } from "shared/types/post";
import { getMediaType } from "../postFormValidator";
import { Select } from "ui/ecosystems/forms";
import { TiktokPrivacyLevel } from "features/post/types";
import { Icon, Tooltip } from "@dialogueconsulting/sked-ui";
import PostFieldError from "components/Posts/PostForm/PostFieldError/PostFieldError";
import { extractMediaUrls } from "../utils";

interface FieldInfo {
  label: string;
  field: string;
}

const allowCommentField = {
  label: "Allow Comments",
  field: FIELDS.TT_ALLOW_COMMENTS,
} as const;

const videoPostAllowFields = [
  allowCommentField,
  {
    label: "Allow Stitch",
    field: FIELDS.TT_ALLOW_STITCH,
  },
  {
    label: "Allow Duet",
    field: FIELDS.TT_ALLOW_DUET,
  },
] as const;
const autoAddMusicField = {
  label: "Auto Add Music",
  field: FIELDS.TT_AUTO_ADD_MUSIC,
};
const titleField = {
  label: "Title",
  field: FIELDS.TT_TITLE,
};
const privacyLevelField = {
  label: "Privacy Level",
  field: FIELDS.TT_PRIVACY_LEVEL,
};

const postPrivacyConfig: { value: TiktokPrivacyLevel; label: string }[] = [
  {
    value: "PUBLIC_TO_EVERYONE",
    label: "Visible to all TikTok users",
  },
  {
    value: "MUTUAL_FOLLOW_FRIENDS",
    label: "Only visible to mutual followers",
  },
  {
    value: "FOLLOWER_OF_CREATOR",
    label: "Only visible to followers of the account",
  },
  {
    value: "SELF_ONLY",
    label: "Private, only visible to the account itself",
  },
];
interface PlatformInfo {
  LABEL: string;
  NAME: string;
  ORDER: number;
  TYPE: AccountPlatformType;
}
export const TiktokSwitchAllows = ({
  postFormValue,
  updateField,
  allDisabled,
  platforms,
  selectedPlatform,
  errors,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  postFormValue: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateField: (field: string, value: any) => void;
  allDisabled: false;
  platforms?: PlatformInfo[];
  selectedPlatform?: PlatformInfo;
  errors: string[];
}) => {
  console.log(
    "TiktokSwitchAllows",
    postFormValue,
    allDisabled,
    platforms,
    selectedPlatform
  );
  const [titleValue, setValue] = useState("");
  const containerRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (get(postFormValue, FIELDS.TT_TITLE)) {
      setValue(get(postFormValue, FIELDS.TT_TITLE));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bounce = useDebouncedCallback((value) => {
    const title = get(postFormValue, FIELDS.TT_TITLE);
    if (value != title) {
      updateField(FIELDS.TT_TITLE, value);
    }
  }, 200);

  if (!postFormValue) {
    return null;
  }

  const media = extractMediaUrls(postFormValue);
  const photoMedia = media.filter((item) => getMediaType(item) === "image");
  const isVideo =
    !!media.length && media.every((item) => getMediaType(item) === "video");
  const isPhoto =
    !!media.length && media.every((item) => getMediaType(item) === "image");

  const onSwitchChange = (field: string) => {
    const prevValue = get(postFormValue, field, true);
    updateField(field, !prevValue);
  };
  const renderPlatformUsage = (item: FieldInfo) =>
    platforms &&
    selectedPlatform && (
      <PlatformUsage
        platforms={platforms}
        field={item.field}
        selectedPlatform={selectedPlatform}
      />
    );
  const renderSwitchField = (item: FieldInfo) => (
    <SwitchField
      label={item.label}
      labelIsAfter
      defaultChecked={get(postFormValue, item.field, true)}
      onChange={() => onSwitchChange(item.field)}
      disabled={allDisabled}
    />
  );

  return (
    <TiktokSwitchAllowsWrapper ref={containerRef}>
      {isVideo && (
        <>
          <LabelStyled>Other post information</LabelStyled>
          {videoPostAllowFields.map((item, index) => {
            return (
              <>
                <Flex marginBottom="10" align="center" key={index}>
                  {renderPlatformUsage(item)}
                  {renderSwitchField(item)}
                </Flex>
              </>
            );
          })}
        </>
      )}
      {isPhoto && (
        <>
          {photoMedia.length > 1 && (
            <>
              <LabelStyled>
                Cover {renderPlatformUsage(privacyLevelField)}
              </LabelStyled>
              <div style={{ marginBottom: 10 }}>
                <Select
                  options={photoMedia.map((_, index) => ({
                    value: index,
                    label: `Photo ${index + 1}`,
                  }))}
                  placeholder={"Select Photo Cover Index"}
                  value={Math.min(
                    get(postFormValue, FIELDS.TT_PHOTO_COVER_INDEX) ||
                      FIELDS_DEFAULT.TT_PHOTO_COVER_INDEX,
                    photoMedia.length - 1
                  )}
                  onChange={(value) => {
                    updateField(FIELDS.TT_PHOTO_COVER_INDEX, value);
                  }}
                  disabled={allDisabled}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  renderLabel={renderCoverIndex(photoMedia) as any}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  renderOption={renderCoverIndex(photoMedia) as any}
                />
              </div>
            </>
          )}
          <LabelStyled>Title {renderPlatformUsage(titleField)}</LabelStyled>
          <Flex marginBottom="10" align="center">
            <TextInput
              data-testid="title-input"
              value={titleValue}
              disabled={allDisabled}
              placeholder="The title of your photos"
              onChange={(value: string) => {
                setValue(value);
                bounce(value);
              }}
            />
          </Flex>
          <PostFieldError
            className="tw-mt-4"
            fields={[FIELDS.TT_TITLE]}
            errors={errors}
          />
          <LabelStyled>
            Privacy level {renderPlatformUsage(privacyLevelField)}
          </LabelStyled>
          <div style={{ marginBottom: 10 }}>
            <Select
              options={postPrivacyConfig}
              placeholder={"Select a post privacy level"}
              value={
                get(postFormValue, FIELDS.TT_PRIVACY_LEVEL) ||
                FIELDS_DEFAULT.TT_PRIVACY_LEVEL
              }
              onChange={(value) => {
                updateField(FIELDS.TT_PRIVACY_LEVEL, value);
              }}
              disabled={allDisabled}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              renderLabel={renderPrivacyLevel as any}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              renderOption={renderPrivacyLevel as any}
            />
          </div>

          <LabelStyled>Other post information</LabelStyled>
          <Flex marginBottom="10" align="center">
            {renderPlatformUsage(allowCommentField)}
            {renderSwitchField(allowCommentField)}
          </Flex>
          <Flex marginBottom="10" align="center">
            {renderPlatformUsage(autoAddMusicField)}
            {renderSwitchField(autoAddMusicField)}
            <Tooltip
              text={
                "Automatically add recommended music to the post, you can change the music later in the TikTok app."
              }
              contentWidth="240px"
              textAlign="center"
              textSize="12px"
              side="right"
              container={containerRef.current || undefined}
            >
              <div>
                <Icon name="info-circle" color="#808080" size={16} />
              </div>
            </Tooltip>
          </Flex>
        </>
      )}
    </TiktokSwitchAllowsWrapper>
  );
};

const renderPrivacyLevel = (data: { value: string; label: string }) => {
  return (
    <PrivacyLevelDisplay
      value={data.value as TiktokPrivacyLevel}
      label={data.label}
    />
  );
};

const PrivacyLevelDisplay = ({
  value,
  label,
}: {
  value: TiktokPrivacyLevel;
  label: string;
}) => {
  return (
    <ItemDisplayContainer>
      {resolvePrivacyLevelIcon(value)}
      <PrivacyLabel>{label}</PrivacyLabel>
    </ItemDisplayContainer>
  );
};

const PrivacyLabel = styled.div`
  margin-left: 8px;
`;

const resolvePrivacyLevelIcon = (value: TiktokPrivacyLevel) => {
  switch (value) {
    case "PUBLIC_TO_EVERYONE":
      return <Icon name="globe-04" size={20} />;
    case "MUTUAL_FOLLOW_FRIENDS":
      return <Icon name="switch-horizontal-01" size={20} />;
    case "FOLLOWER_OF_CREATOR":
      return <Icon name="user-check-01" size={20} />;
    case "SELF_ONLY":
      return <Icon name="lock-01" size={20} />;
    default:
      return null;
  }
};

const renderCoverIndex = (mediaFiles: MediaUrlInfo[]) => {
  // eslint-disable-next-line react/display-name
  return (data: { value: number }) => {
    const media = mediaFiles[data.value];
    return (
      <CoverIndexDisplay
        index={data.value}
        src={media.thumbnailUrl || media.url}
      />
    );
  };
};
const CoverIndexDisplay = ({ index, src }: { index: number; src: string }) => {
  return (
    <ItemDisplayContainer>
      <NumberIcon value={index} />
      <CoverPreivewImg src={src} />
    </ItemDisplayContainer>
  );
};

const NumberIcon = ({ value }: { value: number }) => {
  return <NumberIconBox>{value}</NumberIconBox>;
};

const ItemDisplayContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CoverPreivewImg = styled.img`
  width: 24px;
  height: 24px;
`;

const NumberIconBox = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #4f31ff;
  border-radius: 4px;
  color: #4f31ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const LabelStyled = styled(PostFieldLabel)`
  margin-bottom: 1rem;
  display: flex;
  white-space: pre;
`;

const TiktokSwitchAllowsWrapper = styled.div`
  margin-bottom: 1rem;
`;
