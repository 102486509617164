import moment, { Moment } from "moment";
import { AccountType } from "shared/types/accounts";
import { IPlatformType } from "./types/insights";

export interface RequestAccount {
  id: string;
  dbAccountId: string;
  platformType: IPlatformType;
  pageId?: string;
  orgId?: string;
}

export interface BrandSummaryApiResponse {
  current?: PeriodSummary;
  previous?: PeriodSummary;
}
export interface PeriodSummary {
  since?: string;
  until?: string;
  timezone?: string;
  accounts?: AccountSummary[];
}
export interface AccountSummary {
  id: string;
  dbAccountId: string;
  platformType: IPlatformType;
  interactions: number;
  newFollowers: number;
  reach: number;
  impressions: number;
  mediaCount: number;
  labelPosts?: LabelPostMetric[];
}
export interface LabelPostMetric {
  _id: string;
  impression: number;
  reach: number;
  interactions: number;
  platformPostId: string;
  platformPermalink?: string;
  labels: {
    name: string;
    emoji: string;
    color: string;
    type: "CONTENT_PILLAR" | "CAMPAIGN";
    _id: string;
  }[];
}

export interface BrandSummaryMetrics {
  impressions: number;
  reach: number;
  interactions: number;
  mediaCount: number;
  newFollowers: number;
}

export const getMetricsSum = (period?: PeriodSummary) => {
  if (!period) {
    return {
      impressions: 0,
      reach: 0,
      interactions: 0,
      mediaCount: 0,
      newFollowers: 0,
    };
  }
  const metricKeys = [
    "impressions",
    "reach",
    "interactions",
    "mediaCount",
    "newFollowers",
  ];

  return (period?.accounts || []).reduce(
    (acc, account) => {
      metricKeys.forEach((key) => {
        acc[key] += account[key];
      });

      return acc;
    },
    {
      impressions: 0,
      reach: 0,
      interactions: 0,
      mediaCount: 0,
      newFollowers: 0,
    }
  );
};

export interface TopAccount {
  current?: AccountSummary;
  previous?: AccountSummary;
  account?: AccountType;
}

export const getTopAccount = (
  brandSummary: BrandSummaryApiResponse | null,
  accounts?: AccountType[]
) => {
  const currentAccounts = brandSummary?.current?.accounts || [];
  const previousAccounts = brandSummary?.previous?.accounts || [];

  let newFollowers: TopAccount = {};
  let reach: TopAccount = {};
  let interactions: TopAccount = {};

  currentAccounts.forEach((account) => {
    const previousAccount = previousAccounts.find(
      (acc) => acc.id === account.id
    );

    const originalAccount = (accounts || []).find(
      (acc) => acc._id === account.dbAccountId
    );

    const currentAccountObject: TopAccount = {
      current: account,
      previous: previousAccount,
      account: originalAccount,
    };

    if (account.newFollowers >= (newFollowers?.current?.newFollowers || 0)) {
      newFollowers = currentAccountObject;
    }
    if (account.reach >= (reach?.current?.reach || 0)) {
      reach = currentAccountObject;
    }
    if (account.interactions >= (interactions?.current?.interactions || 0)) {
      interactions = currentAccountObject;
    }
  });

  return { newFollowers, reach, interactions };
};

export const buildPeriodPair = (
  since: Date | undefined,
  until: Date | undefined
) => {
  const startDate = moment(since).startOf("day");
  const endDate = moment(until).endOf("day");

  const startOfMonth = startDate.clone().startOf("month");
  const endOfMonth = endDate.clone().endOf("month");

  let previousPeriodSince: Moment;
  let previousPeriodUntil: Moment;

  if (startDate.isSame(startOfMonth) && endDate.isSame(endOfMonth)) {
    const differenceInMonths = Math.abs(endDate.diff(startDate, "month") + 1);
    previousPeriodSince = startOfMonth
      .clone()
      .subtract(differenceInMonths, "month");
    previousPeriodUntil = endOfMonth
      .clone()
      .subtract(differenceInMonths, "month")
      .endOf("month");
  } else {
    const differenceInDays = Math.abs(endDate.diff(startDate, "days") + 1);
    previousPeriodSince = startDate.clone().subtract(differenceInDays, "day");
    previousPeriodUntil = endDate.clone().subtract(differenceInDays, "day");
  }

  const thisPeriod = {
    since: startDate.unix(),
    until: endDate.unix(),
  };
  const previousPeriod = {
    since: previousPeriodSince.unix(),
    until: previousPeriodUntil.unix(),
  };
  return { thisPeriod, previousPeriod };
};

export const insightsValueFormatter = (number: number | undefined | null) => {
  if (number === null || number === undefined) {
    return "N/A";
  }
  number = Number(number);
  const value = beutifyText(number);
  if (value === "NaN") return "N/A";
  return value;
};

export const beutifyText = (number: number) => {
  const SYMBOLS = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i: number;
  for (i = SYMBOLS.length - 1; i > 0; i--) {
    if (number >= SYMBOLS[i].value) {
      break;
    }
  }
  const value =
    (number / SYMBOLS[i].value).toFixed(1).replace(regex, "$1") +
    SYMBOLS[i].symbol;
  return value;
};

function getId(account: AccountType) {
  switch (account.platformName) {
    case "instagram":
      return account.platformId;
    case "facebook":
      return account._id;
    case "linkedin":
      return account._id;
    case "googlemybusiness":
      return account._id;
    case "tiktok":
      return account._id;
  }
  return account._id;
}

export const formatRequestAccounts = (account: AccountType) => {
  const userId = account.platformId;
  const id = getId(account) as string;
  const platformType = account.platformName?.toUpperCase() as IPlatformType;
  switch (account.platformName) {
    case "instagram": {
      return {
        id,
        platformType,
        dbAccountId: account._id,
      };
    }
    case "facebook": {
      return {
        id,
        pageId: userId,
        platformType,
        dbAccountId: account._id,
      };
    }
    case "linkedin": {
      return {
        id,
        orgId: userId,
        platformType,
        dbAccountId: account._id,
      };
    }
    case "googlemybusiness":
      return {
        id,
        platformType,
        dbAccountId: account._id,
      };
    case "tiktok": {
      return {
        id,
        platformType,
        dbAccountId: account._id,
      };
    }
    default:
      return {
        id,
        platformType,
        dbAccountId: account._id,
      };
  }
};
