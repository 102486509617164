import React from "react";
import { createEvent, createStore } from "effector";
import { useStore } from "effector-react";

import { UpgradeLibraryDialog } from "./upgrade-library-dialog";
import { useDispatch } from "react-redux";
import { showUpsellModal } from "actions/typed-modal";
import { UPGRADE_LIBRARY_VIEWED } from "constants/Events";
import { useTrackEvent } from "libs/analytics";

const onHide = createEvent();
const openUpgradeLibraryDialog = createEvent();

const $show = createStore(false)
  .on(onHide, () => false)
  .on(openUpgradeLibraryDialog, () => true);

export function UpgradeLibraryDialogController() {
  const show = useStore($show);

  return (
    <UpgradeLibraryDialog show={show} onHide={onHide} onComplete={onHide} />
  );
}

export function useUGCUpsellModal() {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  return {
    showUGCUpsellModal: () => {
      dispatch(showUpsellModal("upsell", "user-generated-content"));
      trackEvent({
        eventName: UPGRADE_LIBRARY_VIEWED,
        useServices: ["AMP", "AC", "HS"],
      });
    },
  };
}
