import React from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { LoadingSpinner, TooltipWrapper } from "ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  InvalidLabel,
  MessageBlock,
  MessageBlockProps,
  MessageContainer,
} from "../../message/styled";
import { Attachment } from "types/conversationInbox";
import { MediaTempalte } from "./media-template";
import { MediaLocation } from "./media-location";
import { MediaShare } from "./media-share";
import { MediaFile } from "./media-file";

export enum MediaTypes {
  storyMention = "story_mention",
  image = "image",
  audio = "audio",
  share = "share",
  template = "template",
  video = "video",
  file = "file",
  location = "location",
  gif = "gif",
  sticker = "sticker",
  fallback = "fallback",
}

type UnsupportedMediaProps = {
  message?: string;
  radius?: boolean;
} & MessageBlockProps;

export type MultiMediaProps = {
  media: Attachment;
  onError: () => void;
  showInvalid: boolean;
  isReady: boolean;
  setIsReady: () => void;
  portraitMedia?: boolean;
} & UnsupportedMediaProps;

export const UnsupportedMedia = ({
  message,
  ...props
}: UnsupportedMediaProps): JSX.Element => {
  return (
    <MessageContainer left={props.left}>
      <MessageBlock {...props}>
        <UnsupportedMessageContent message={message} />
      </MessageBlock>
    </MessageContainer>
  );
};

export const UnsupportedMessageContent = ({
  message,
}: {
  message: React.ReactNode;
}) => (
  <UnsupportedMediaWrapper>
    Shared an attachment
    <TooltipWrapper
      tooltip={message || "We’re unable to display this attachment."}
      placement="bottom"
    >
      <span data-testid="shared-an-attachment-tootlip">?</span>
    </TooltipWrapper>
  </UnsupportedMediaWrapper>
);

export const resolveMediaType = (media: Attachment): MediaTypes | string => {
  if (!media || !media.type) {
    return MediaTypes.fallback;
  }

  if (media.type.includes("image")) {
    return MediaTypes.image;
  }

  if (media.type.includes("video")) {
    return MediaTypes.video;
  }

  if (media.type.includes("audio")) {
    return MediaTypes.audio;
  }

  if (media.type === MediaTypes.fallback && media?.payload?.url) {
    return MediaTypes.share;
  }

  return media.type;
};

export function MultiMedia(props: MultiMediaProps): JSX.Element {
  const { media, onError, showInvalid, isReady, setIsReady } = props;

  if (showInvalid) {
    return (
      <MediaUnavailable portraitMedia={!!props.portraitMedia}>
        <InvalidIcon data-testid="invalid-icon" icon={faClock} />
        <InvalidLabel>No longer available in Sked</InvalidLabel>
      </MediaUnavailable>
    );
  }

  const mediaType = resolveMediaType(media);
  let mediaContent;

  switch (mediaType) {
    case MediaTypes.image: {
      mediaContent = (
        <img
          data-testid="multimedia-image"
          src={media.payload.url}
          width="100%"
          onError={onError}
          onLoad={setIsReady}
        />
      );
      break;
    }

    case MediaTypes.video: {
      mediaContent = (
        <ReactPlayer
          url={media.payload.url}
          width="100%"
          height="auto"
          controls
          onError={onError}
          onReady={setIsReady}
        />
      );
      break;
    }

    case MediaTypes.audio: {
      mediaContent = (
        <ReactPlayer
          url={media.payload.url}
          width="100%"
          height="40px"
          controls
          onError={onError}
          onReady={setIsReady}
        />
      );
      break;
    }

    case MediaTypes.share: {
      mediaContent = <MediaShare {...props} />;
      break;
    }

    case MediaTypes.template: {
      mediaContent = <MediaTempalte {...props} />;
      break;
    }

    case MediaTypes.location: {
      mediaContent = <MediaLocation {...props} />;
      break;
    }

    case MediaTypes.file: {
      mediaContent = <MediaFile {...props} />;
      break;
    }

    default:
      mediaContent = null;
      break;
  }

  if (!mediaContent) {
    return (
      <div style={{ display: "block" }}>
        <UnsupportedMedia {...props} removeMargin />
      </div>
    );
  }

  return (
    <>
      {!isReady && (
        <LoadingSpinner isSmall isCentered wrapperAbsolutePosition />
      )}
      <HideUntilReady isReady={isReady}>{mediaContent}</HideUntilReady>
    </>
  );
}

interface HideUntilReadyProps {
  isReady: boolean;
}

export const HideUntilReady = styled.div<HideUntilReadyProps>`
  display: none;

  ${(props) =>
    props.isReady &&
    `
    display: block
  `}
`;

const InvalidIcon = styled(FontAwesomeIcon)`
  color: #454545;
  font-size: 34px;
  width: 100% !important;
`;

const MediaUnavailable = styled.div<{ portraitMedia: boolean }>`
  ${(p) => (p.portraitMedia ? `margin: 115px auto;` : `margin: 45px auto;`)}
`;

export const UnsupportedMediaWrapper = styled.div`
  display: flex;
  span {
    display: inline-flex;
    margin-left: 10px;
    width: 19px;
    height: 19px;
    background: #454545;
    font-size: 12px;
    color: #ffffff;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
  }
  a {
    display: inline-block;
    margin-left: 5px;
  }
`;
