import React from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

import { UploadingStatus } from "api/media-library";
import { UploadingFile } from "features/media-library/uploading-files.model";

export function UploadingProgressIndicator({
  uploadingFile,
}: {
  uploadingFile: UploadingFile;
}) {
  const { progress, error } = uploadingFile;
  if (
    progress.status === UploadingStatus.uploading ||
    progress.status === UploadingStatus.transcoding
  ) {
    return <UploadingProgressBar key={progress.status} progress={progress} />;
  } else {
    return <UploadingStatusChip status={progress.status} error={error} />;
  }
}

function UploadingProgressBar({
  className,
  progress,
}: {
  className?: string;
  progress: UploadingFile["progress"];
}) {
  return (
    <div className={className}>
      <UploadingStatusChip status={progress.status} />
      <Progress
        value={progress.value}
        color={(() => {
          switch (progress.status) {
            case UploadingStatus.uploading:
              return colors.primary500;
            case UploadingStatus.transcoding:
              return colors.success500;
            default:
              return undefined;
          }
        })()}
      />
    </div>
  );
}

function ProgressComponent({
  className,
}: {
  className?: string;
  value: number;
  color?: string;
}) {
  return (
    <div className={className}>
      <div className="value"></div>
    </div>
  );
}

const Progress = styled(ProgressComponent)`
  width: 100%;
  height: 8px;
  background: ${colors.neutral100};
  border-radius: 4px;

  .value {
    width: ${(props) => props.value}%;
    height: 100%;
    background: ${(props) => props.color ?? colors.error500};
    border-radius: 4px;
    transition: width 0.2s linear;
  }
`;

function UploadingStatusChipComponent({
  className,
  status,
  error,
}: {
  className?: string;
  status: UploadingStatus;
  error?: any;
}) {
  const text = (() => {
    if (error || status === UploadingStatus.error) {
      return `Failed! ${error ? error : "Error uploading."}`;
    }
    switch (status) {
      case UploadingStatus.pending:
        return "Initializing...";
      case UploadingStatus.uploading:
        return "Uploading...";
      case UploadingStatus.transcoding:
        return "Transcoding...";
      case UploadingStatus.success:
        return "Successfully uploaded!";
    }
  })();

  return <p className={className}>{text}</p>;
}

const UploadingStatusChip = styled(UploadingStatusChipComponent)`
  margin: 0;
  font-size: 10px;
  line-height: 1.44;
  color: ${({ status }) => {
    switch (status) {
      case UploadingStatus.success:
        return colors.success500;
      case UploadingStatus.error:
        return colors.error500;
      default:
        return colors.neutral400;
    }
  }};
`;
