// states

export enum PostStates {
  draft = "draft",
  schedule = "schedule",
  queue = "queue",
  archived = "archived",
}

type ScheduledPost = {
  state: PostStates.schedule;
  when: string | null;
};

type QueuedPost = {
  state: PostStates.queue;
  when: string | null;
};

type ArchivedPost = {
  state: PostStates.archived;
  when: string | null;
};

type DraftedPost = {
  state: PostStates.draft;
};

type PostState = ScheduledPost | QueuedPost | DraftedPost | ArchivedPost;

export enum PlatformTypes {
  instagram,
  facebook,
  twitter,
  linkedin,
  googlemybusiness,
  cluster,
  youtube,
  pinterest,
  snapchat,
  tiktok,
  other,
  threads,
}

export type InstagramPost = {
  platformType: PlatformTypes.instagram;
  firstcomment?: string;
  location?: unknown;
  tags: unknown[];
  productTags: unknown[];
  isStory: boolean;
  storyType?: string | null;
  storyUrl?: string;
  storyLabel?: string;
  storyStickerHeight?: number;
  publishStoryManually?: boolean;
};

export type FacebookPost = {
  platformType: PlatformTypes.facebook;
  location?: string;
};

export type TwitterPost = {
  platformType: PlatformTypes.twitter;
};

export type SnapchatPost = {
  platformType: PlatformTypes.snapchat;
};

export type OtherPost = {
  platformType: PlatformTypes.other;
};

export type LinkedInPost = {
  platformType: PlatformTypes.linkedin;
  liTargetEntities?: {
    [key: string]: { label: string }[];
  };
};

export type YoutubePost = {
  platformType: PlatformTypes.youtube;
  ytTitle?: string;
  ytPrivacy?: string;
  ytCategoryId?: number | string;
  ytTags?: string | null;
};

export type TikTokPost = {
  thumbnailUrl: string;
  videoCoverImageTimestamp?: number;
  videoCoverPreview: string;
  platformType: PlatformTypes.tiktok;
  postOptions: TTPostOptions;
};

export interface TTPostOptions {
  isNotification: boolean;
  allowComments?: boolean;
  allowStitch?: boolean;
  allowDuet?: boolean;
  title?: string;
  autoAddMusic?: boolean;
  privacyLevel?: TiktokPrivacyLevel;
  photoCoverIndex?: number;
}

export type TiktokPrivacyLevel =
  | "PUBLIC_TO_EVERYONE"
  | "MUTUAL_FOLLOW_FRIENDS"
  | "FOLLOWER_OF_CREATOR"
  | "SELF_ONLY";

export type ThreadsPost = {
  platformType: PlatformTypes.threads;
  postOptions: {
    replyControl: string;
  };
};
export type GoogleMyBusinessPost = {
  platformType: PlatformTypes.googlemybusiness;
  gmbTopicType?: string;
  gmbEvent?: {
    title?: string;
    startDate?: Date;
    endDate?: Date;
  };
  gmbOffer?: object;
  gmbCallToAction?: object;
};

export type PinterestPost = {
  platformType: PlatformTypes.pinterest;
  piBoards?: any[];
  piSectionId?: string;
  piTitle?: string;
};

export type ClusterPost = {
  platformType: PlatformTypes.cluster;
  firstcomment: string;
  location?: string;
  tags?: unknown[];
  productTags?: unknown[];
  gmbTopicType?: string;
  gmbEvent?: object;
  gmbOffer?: object;
  gmbCallToAction?: object;
  piBoards?: any[];
  piSectionId?: string;
  piTitle?: string;
};

export type PostByPlatformType =
  | InstagramPost
  | FacebookPost
  | TwitterPost
  | LinkedInPost
  | GoogleMyBusinessPost
  | ClusterPost
  | YoutubePost
  | PinterestPost
  | TikTokPost
  | SnapchatPost
  | ThreadsPost
  | OtherPost;

export type PostType =
  | "image"
  | "video"
  | "text"
  | "multiMedia"
  | "story"
  | "document"
  | "reel";

export type PostOptions = TTPostOptions;

export type Post = {
  _id: string;
  accountId?: string;
  accountIds: string[];
  labelIds: string[];
  mediaFiles: MediaFile[];
  type: PostType;
  caption?: string;
  channel?: string;
  fileName?: string;
  redirectUrl?: string;
  url?: string;
  isVideo?: boolean;
  queued?: boolean;
  postStatusKey?: string;
  postedBy?: string;
  createdBy?: string;
  createdAt?: string;
  originalUrl?: string;
  origUrl?: string;
  tags?: unknown[];
  productTags?: unknown[];
  accounts?: unknown[];
  updatedBy?: string;
  updatedAt?: string;
  thumbnailUrl?: string;
  meta?: {
    height?: number;
    width?: number;
    size?: number;
    pages?: number;
  };
  altText?: string;
  when?: string;
  firstcomment?: string;
  isNow?: boolean;
  isStory?: boolean;
  storyUrl?: any;
  storyLabel?: string;
  storyStickerHeight?: number;
  location?: object;
  storyType?: string;
  newPost?: boolean;
  nextRunAt?: Date;
  scheduledFor?: Date;
  postType?: string;
  pushNotificationMeta?: NotificationPerson[];
  isPosting?: boolean;
  isQueued?: boolean;
  isSuccess?: boolean;
  isCanceled?: boolean;
  isPotentialError?: boolean;
  postOptions?: PostOptions;
  videoCoverImageTimestamp?: number;
  collaboratingIgUsers?: IgCollabPerson[];
} & PostState &
  PostByPlatformType;

export type PlatformPost<P extends { platformType: PlatformTypes }> = Post & P;
export type StatePost<S extends { state: PostStates }> = Post & S;
export type DefinedPost<
  P extends { platformType: PlatformTypes },
  S extends { state: PostStates }
> = Post & P & S;

type MediaFile = {
  isVideo?: boolean;
  originalUrl?: string;
  origUrl?: string;
  url: string;
  thumbnailUrl: string;
  fileName?: string;
  type?: string;
  meta?: object;
  altText?: string;
};

export type NotificationPerson = {
  _id?: string;
  opened?: boolean;
  delivered?: boolean;
  notified?: "email" | "mobile";
  published?: boolean;
  name?: string;
};

export type IgCollabPerson = {
  username: string;
  profilePictureUrl: string;
  _id?: string;
};
