import {
  initCreatorFeed,
  initHashtagFeed,
  initTaggedInFeed,
  selectIgAccount,
} from "actions/sourceMedia";
import { AccountPickerDropdown } from "components/AccountPicker";
import { LIBRARY_SEARCH_TAGGED_IN } from "constants/Events";
import { useTrackEvent } from "libs/analytics";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedIgAccount } from "selectors/hashtagFeedSelectors";
import { getAccountsForPlatform } from "selectors/skedCoreSelectors";
import { AccountType } from "shared/types/accounts";

export function useInitMediaFeed() {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  const initMediaFeed = (type: string, account: AccountType) => {
    switch (type) {
      case "hashtag":
        dispatch(initHashtagFeed(account._id));
        break;
      case "tagged-in":
        trackEvent({
          eventName: LIBRARY_SEARCH_TAGGED_IN,
          useServices: ["AMP"],
          metadata: { location: "picker" },
        });
        dispatch(initTaggedInFeed(account._id));
        break;
      case "creator":
        dispatch(initCreatorFeed({ accountId: account._id }));
        break;
      default:
        console.warn("no type matched");
    }
  };

  return initMediaFeed;
}

export function AccountSelector({ type }: { type: string }) {
  const dispatch = useDispatch();
  let accounts = useSelector((state) => getAccountsForPlatform(state, "IG"));

  if (type === "tagged-in") {
    accounts = accounts.filter((account) => {
      return (account.session?.scopes || []).includes(
        "instagram_manage_comments"
      );
    });
  }

  const initMediaFeed = useInitMediaFeed();
  const selectedIgAccount: AccountType = useSelector(getSelectedIgAccount);

  const onAccountSelect = (account: AccountType) => {
    dispatch(selectIgAccount(account._id));
    initMediaFeed(type, account);
  };

  return (
    <AccountPickerDropdown
      accounts={accounts}
      onChange={onAccountSelect}
      selectedAccountIds={selectedIgAccount ? [selectedIgAccount._id] : []}
    />
  );
}
