import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// @ts-ignore
import { escapeRegExp } from "lodash";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

import { getTagTemplates as getTemplates } from "actions/tagTemplates";
import { TagTemplate } from "features/user/types";
import { getTagTemplates } from "selectors/tagTemplatesSelectors";
import { useClickOutside } from "libs/use-click-outside";
import { TextInput } from "ui";
import { shuffleTemplateTags } from "../services/shuffleTemplateTags";
// @ts-ignore
import PreviewIcon from "../icons/preview.svg";
import { TagsList } from "./tags-list";
// @ts-ignore
import TemplateIcon from "../icons/templates.svg";
import { TooltipWrapper } from "ui";
interface TagProps {
  name: string;
  template: string;
}

interface TagTemplateSelectorProps {
  className?: string;
  onSelect: (templateText: string) => void;
}

function TemplatesSelectorComponent(props: TagTemplateSelectorProps) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [template, setTemplate] = useState("");
  const [toolTipTop, setToolTipTop] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const templates = useSelector<TagTemplate[]>(getTagTemplates);
  const [tags, setTags] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any>([]);
  useEffect(() => {
    dispatch(getTemplates());
  }, []);

  useEffect(() => {
    setTags(templates);
    const currentSearch = escapeRegExp(search);
    setFilteredItems(
      currentSearch
        ? tags.filter((tag: TagProps) => {
            const currentSearchReg = new RegExp(currentSearch, "i");
            if (
              currentSearchReg.test(tag.template) ||
              currentSearchReg.test(tag.name)
            ) {
              return tag;
            }
          })
        : tags
    );
  }, [templates, search]);
  function handleTemplateSelected(tag: TagProps) {
    props.onSelect(shuffleTemplateTags(tag.template));
  }

  function mouseHover(template: string, event: any) {
    // @ts-ignore
    const parrentPos = scrollerRef.current.getBoundingClientRect();
    const currentPos = event.target.getBoundingClientRect();
    setToolTipTop(currentPos.y - parrentPos.y - 60);
    setTemplate(template);
  }

  function mouseLeave() {
    setTemplate("");
  }

  function renderItem(tag: any) {
    const mouseEnter = (event: React.MouseEvent) =>
      mouseHover(tag.template, event);

    return (
      <TemplateItem>
        {tag.name}
        <Preview onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <PreviewIconStyled />
        </Preview>
      </TemplateItem>
    );
  }

  function changeTag(value: string) {
    setSearch(value);
  }

  function clearSearch() {
    setSearch("");
  }

  useClickOutside(wrapperRef, () => setIsOpen(false));

  const togglePicker = () => setIsOpen(!isOpen);

  return (
    <TemplateButtonWrapper ref={wrapperRef} className={props.className}>
      <TooltipWrapper
        tooltip={isOpen ? null : "Post templates"}
        placement={"top"}
      >
        <TemplateButton isActive={isOpen} type="button" onClick={togglePicker}>
          <TemplateIcon />
        </TemplateButton>
      </TooltipWrapper>
      {isOpen && templates && (
        <TemplateWrapper ref={scrollerRef}>
          <Title>Your templates</Title>
          <SearchWrapper>
            <Search placeholder="Search" value={search} onChange={changeTag} />
            {search ? <SearchIcon onClick={clearSearch} /> : <SearchIcon />}
          </SearchWrapper>

          <TagsList
            onSelect={handleTemplateSelected}
            listItems={filteredItems}
            renderListItem={renderItem}
          />
          {template && (
            <Tooltip top={toolTipTop}>
              <TooltipContent>{template}</TooltipContent>
            </Tooltip>
          )}
        </TemplateWrapper>
      )}
    </TemplateButtonWrapper>
  );
}

export const TemplatesSelector = styled(TemplatesSelectorComponent)``;

const TemplateButton = styled.button<{ isActive: boolean }>`
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0;
  appearance: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 4px ${colors.black}33;
  border: 1px solid
    ${(props) => (props.isActive ? colors.primary500 : colors.primary100)};
  border-radius: 5px;
  background: ${(props) =>
    props.isActive ? colors.primary500 : colors.primary50};
  svg {
    fill: ${(props) => (props.isActive ? colors.white : colors.primary300)};
  }
`;

const TemplateButtonWrapper = styled.div`
  display: flex;
  height: 24px;
`;

const TemplateWrapper = styled.div`
  position: absolute;
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-flow: column nowrap;
  transform: translate(calc(-100% + 25px), calc(-100% - 5px));
  height: 185px;
  width: 220px;
  border: 1px solid ${colors.neutral100};
  box-sizing: border-box;
  box-shadow: 0 0 10px ${colors.black}33;
  border-radius: 5px;
  background: ${colors.white};
`;

const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 5px;
`;

const Title = styled.div`
  padding: 10px 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

const Search = styled(TextInput)<{ placeholder: string }>`
  margin-left: 5px;
  width: 194px;
`;

const SearchIcon = styled.button`
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 20px;
  height: 100%;
  appearance: none;
  border: none;
  background: transparent;
  outline: none;
  &:before {
    font-family: "icomoon3";
    content: "\\e988";
    position: absolute;
    top: 15px;
    right: 15px;
    color: lightgray;
    font-size: 14px;
  }
`;

const TemplateItem = styled.div`
  padding: 8px;
  padding-right: 35px;
  font-size: 12px;
  color: ${colors.black};
`;

const Tooltip = styled.div<{ top: number }>`
  position: fixed;
  right: -10px;
  top: ${(props) => props.top}px;
  width: 160px;
  height: 58px;
  padding: 4px 8px;
  border-radius: 5px;
  background: ${colors.neutral800};
  color: ${colors.white};
  pointer-events: none;
  z-index: 999;
  font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 10px;
  &:before {
    content: "";
    position: absolute;
    right: 39px;
    bottom: -4px;
    height: 10px;
    width: 10px;
    transform: rotate(45deg);
    background: ${colors.neutral800};
  }
`;

const TooltipContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.6em;
  height: 45px;
  word-break: break-all;
`;

const PreviewIconStyled = styled(PreviewIcon)``;

const Preview = styled.div`
  position: absolute;
  right: 9px;
  top: 5px;
  padding: 5px;
  font-size: 12px;
  color: ${colors.black};
  &:hover {
    ${PreviewIconStyled} {
      fill: ${colors.primary500};
    }
  }
  ${PreviewIconStyled} {
    pointer-events: none;
  }
`;
