// wrap react-select for apply ui-kit styles
import styled, { css } from "styled-components";
import { ReactSelectWithTestId } from "./react-select-with-test-id";
import { InputWrapperBaseCss } from "./_input-base-wrapper";
import { FormSize, formSizeStyles } from "./form-theme-provider";

export const ReactSelectStyled = styled(ReactSelectWithTestId).attrs({
  classNamePrefix: "select",
})`
  .select__control {
    ${InputWrapperBaseCss};
    height: auto;
    min-height: var(--inputHeight);
    line-height: var(--inputHeight);
  }

  .select__value-container {
    display: flex; /* the new react select uses a grid layout, so we need to override it */
  }
  .select__placeholder {
    height: auto;
    line-height: var(--inputHeight);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .select__menu {
    ${formSizeStyles(
      FormSize.small,
      css`
        --inputHeight: 32px;
        font-size: 12px;
        line-height: 14px;
      `
    )}

    ${formSizeStyles(
      FormSize.medium,
      css`
        --inputHeight: 38px;
        font-size: 12px;
        line-height: 14px;
      `
    )}

    ${formSizeStyles(
      FormSize.large,
      css`
        --inputHeight: 49px;
        font-size: 14px;
        line-height: 17px;
      `
    )}
    min-width: 100%;
    width: auto;
  }

  ${formSizeStyles(
    FormSize.small,
    css`
      .select__indicator {
        padding: 3px;

        svg {
          height: 17px;
        }
      }
    `
  )}

  ${formSizeStyles(
    FormSize.medium,
    css`
      .select__multi-value {
        font-size: 14px;
        line-height: 16px;
      }
    `
  )};
  ${formSizeStyles(
    FormSize.large,
    css`
      .select__multi-value {
        font-size: 16px;
        line-height: 18px;
      }
    `
  )};
`;
