import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import _ from "lodash";

import {
  getLocalStorageSelectedPostContentTypes,
  useStorageSelectedPostContentTypes,
} from "libs/storage/adapters";
import { filterPostContentType } from "actions/postsView";
import { useTrackEvent } from "libs/analytics";
import { useSelectedAccounts } from "entities/accounts";
import { getPostContentTypes } from "selectors/postsPageSelectors";
import { getPage } from "selectors/commonSelectors";
import { PostFilterValueContainer } from "ui/ecosystems/dropdown";
import { Checkbox, FormSize, Select } from "ui/ecosystems/forms";
import {
  postsContentAllOption,
  postsContentOptions,
} from "constants/PostsFliters";
import { PostContentType } from "types/postsFilters";
import { POST_CONTENT_TYPE_FILTER } from "constants/Events";

interface OptionLabelProps {
  isSelected: boolean;
}

const PostContentTypeFilter = (): JSX.Element => {
  const dispatch = useDispatch();
  const accounts = useSelectedAccounts();
  const selectedPostContentTypes = useSelector(getPostContentTypes);
  const page = useSelector(getPage);
  const trackEvent = useTrackEvent();

  const [selected, setSelectedToStorage] = useStorageSelectedPostContentTypes(
    selectedPostContentTypes.length
      ? selectedPostContentTypes
      : [postsContentAllOption, ...postsContentOptions].map(
          (item) => item.value
        )
  );

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<PostContentType[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<PostContentType[]>([]);

  const getInitialTypes = (types: string[]) => {
    const allOptions = [postsContentAllOption, ...postsContentOptions];

    const result = !types?.find((item: string) => item === "*")
      ? types
          ?.map((type: string) =>
            allOptions.find((item) => item.value === type)
          )
          // remove empty values
          .filter((item) => item !== undefined)
      : allOptions;

    return result;
  };

  useEffect(() => {
    const typesFromStorage = getLocalStorageSelectedPostContentTypes();
    const typesParsed = typesFromStorage && JSON.parse(typesFromStorage);
    const initialTypes = getInitialTypes(typesParsed);
    const types = initialTypes?.length
      ? initialTypes
      : [postsContentAllOption, ...postsContentOptions];

    const typesValues = types.map((item: PostContentType) => item.value);
    setSelectedOptions(types);
    setSelectedToStorage(typesValues);
    dispatch(filterPostContentType(typesValues));
    setFilteredOptions([...postsContentOptions]);
    setIsInitialized(!isInitialized);
  }, []);

  useEffect(() => {
    if (!isInitialized) return;

    const initialTypes = getInitialTypes(selectedPostContentTypes);
    const typesValues = initialTypes.map((item: PostContentType) => item.value);
    setSelectedOptions(initialTypes);
    setSelectedToStorage(typesValues);
  }, [selectedPostContentTypes]);

  useEffect(() => {
    if (accounts.length) {
      const platformsTypes: string[] = _.uniq(
        accounts.map((account) => {
          if (account.platformType) {
            return account.platformType;
          } else {
            return "IG";
          }
        })
      );
      const options = postsContentOptions.filter((option: PostContentType) => {
        return option.relatedTo?.some((platform: string) =>
          platformsTypes.includes(platform)
        );
      });
      setFilteredOptions(options);

      //NOTE - if after change accounts some selected option not related need delete it or select all if no any another
      const filteredSelectedOptions = selectedOptions.filter(
        (option: PostContentType) => {
          return option.relatedTo?.some((platform: string) =>
            platformsTypes.includes(platform)
          );
        }
      );

      if (filteredSelectedOptions.length < selectedOptions.length) {
        const types = filteredSelectedOptions?.length
          ? filteredSelectedOptions
          : [postsContentAllOption, ...postsContentOptions];

        const typesValues = types.map((item: PostContentType) => item.value);
        setSelectedOptions(types);
        updateStateValue(typesValues);
      }
    } else {
      setFilteredOptions([...postsContentOptions]);
    }
  }, [accounts]);

  const updateStateValue = async (updatedSelected: any[]) => {
    await setSelectedToStorage(updatedSelected);
    await dispatch(filterPostContentType(updatedSelected));
  };

  useEffect(() => {
    if (filteredOptions.length) {
      const updatedSelectedTypes: PostContentType[] = [];

      filteredOptions.forEach((option) => {
        if (selected?.includes(option.value)) {
          updatedSelectedTypes.push(option);
        }
      });

      if (filteredOptions.length === updatedSelectedTypes.length) {
        updatedSelectedTypes.push(postsContentAllOption);
      }

      const updatedSelected = updatedSelectedTypes.map(
        (item: PostContentType) => item.value
      );
      if (!_.isEqual(updatedSelected, selected) || !selectedOptions.length) {
        setSelectedOptions(updatedSelectedTypes);
        updateStateValue(updatedSelected);
      }
    }
  }, [filteredOptions]);

  const onChange = (selectedOptions: PostContentType[]) => {
    const isAllSelected = selectedOptions.some((item) => item.value === "*");

    const wasAllSelected = selected?.some((item: string) => item === "*");

    const selectedWithoutAll = selectedOptions.filter(
      (item) => item.value !== "*"
    );

    let updatedOptions = selectedWithoutAll;

    if (!isAllSelected && wasAllSelected) {
      updatedOptions = [];
    } else if (
      selectedWithoutAll.length === filteredOptions.length ||
      (isAllSelected && !wasAllSelected)
    ) {
      updatedOptions = [postsContentAllOption, ...filteredOptions];
    }

    setSelectedOptions(updatedOptions);
    const updatedSelected = updatedOptions.map(
      (item: PostContentType) => item.value
    );
    updateStateValue(updatedSelected);

    if (page) {
      trackEvent({
        eventName: POST_CONTENT_TYPE_FILTER,
        metadata: {
          page: page.toLocaleLowerCase(),
        },
      });
    }
  };

  const renderOption = (option: any) => {
    const { label } = option.data;

    return (
      <OptionWrapper>
        <Checkbox checked={option.isSelected} size={FormSize.large} />
        <OptionLabel isSelected={option.isSelected}>{label}</OptionLabel>
      </OptionWrapper>
    );
  };

  const ValueContainer = (props: any) => {
    const isSelectAll = selectedOptions.some((item) => item.value === "*");

    const placeHolderText = isSelectAll
      ? "All post types selected"
      : `${selectedOptions?.length} post ${
          selectedOptions?.length > 1 ? "types" : "type"
        } selected...`;

    return (
      <PostFilterValueContainer
        valueContainerProps={props}
        selectedOptions={selectedOptions}
        placeholder="Select post type"
        selectedPlaceholder={placeHolderText}
      />
    );
  };

  return (
    <>
      <Select
        placeholder="Select post type"
        options={[postsContentAllOption, ...filteredOptions]}
        value={selectedOptions}
        onChange={onChange}
        isSearchable={false}
        isMulti={true}
        hideSelectedOptions={false}
        renderOption={renderOption}
        components={{ ValueContainer }}
        useNative={true}
      />
    </>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const OptionLabel = styled.div<OptionLabelProps>`
  margin-left: 10px;
  color: ${({ isSelected }) => (isSelected ? "#666666" : "#949494")};
`;

export default PostContentTypeFilter;
