import React from "react";
import AccountAvatar from "components/AccountAvatar/AccountAvatar";
import { getAccountNameParts, getPlatformNameType } from "utils/accounts";

function PostAccount({ account }) {
  const [brand, login] = getAccountNameParts(account);

  const { platformType } = getPlatformNameType(account);

  return (
    <div className={`tw-flex tw-items-center`}>
      <div className="PostAccount-Avatar">
        <AccountAvatar
          useYtRedPlayIcon
          profilePictureUrl={account.profilePictureUrl}
          platformType={platformType}
          color={account.color}
          size="medium"
          placement="top"
        />
      </div>
      <div className="tw-mx-2 tw-flex tw-flex-col tw-justify-center">
        {brand && (
          <div className="tw-text-lg tw-text-gray-400 tw-font-medium tw-italic">
            {brand}
          </div>
        )}
        <div>{login}</div>
      </div>
    </div>
  );
}

function start_and_end(str) {
  const maxAccountNameLength = 25;
  const showCharLeft = 12;
  const showCharRight = 13;
  if (str.length > maxAccountNameLength) {
    return (
      str.substr(0, showCharLeft) +
      "..." +
      str.substr(str.length - showCharRight, str.length)
    );
  }
  return str;
}

export default PostAccount;
