import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const isTestEnv = process.env.NODE_ENV === "test";

if (!isTestEnv) {
  Bugsnag.start({
    apiKey: "8f449d48646c0c521405dbb8b900217d",
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: ["staging", "production"],
  });
}

const BugsnagReactReports = isTestEnv
  ? React.Fragment
  : Bugsnag.getPlugin("react").createErrorBoundary(React);

export default BugsnagReactReports;
