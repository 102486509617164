import React, { useRef, useState } from "react";
import { Overlay } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { colors } from "styles/color-tokens";
import useOnClickOutside from "use-onclickoutside";

import { ConfirmRequired } from "ui/confirm-required";
import { CrossFadeReplace } from "ui/cross-fade-replace";
import { deleteCollection, toggleSelectAll } from "actions/mediaLibrary";
import { UPDATE_COLLECTION_ROUTINE } from "constants/ActionTypes";
import { TooltipWrapper } from "ui";
import { AppConfig } from "app-config";
import { MediaCollection } from "api/media-library";

import { CollectionColor } from "../collection-color";
import CaretIcon from "./caret.svg";
import { CollectionColorForm } from "./collection-color-form";
import { CollectionNameForm } from "./collection-name-form";
import { Icon, Tooltip } from "@dialogueconsulting/sked-ui";
import { isManagedLibraryCollection } from "components/MediaLibrary/Utils";

export function CollectionIndicator({
  collection,
}: {
  collection: MediaCollection;
}): JSX.Element {
  const dispatch = useDispatch();

  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dropdown, setDropdown] = useState<"menu" | "color" | "name" | null>(
    null
  );
  useOnClickOutside(containerRef, () => setDropdown(null));

  const collectionNonDeletable = getCollectionNonDeletable(collection);
  const { isManagedCollection, platformName, PlatformIcon } =
    isManagedLibraryCollection(collection);

  function onCollectionUpdated(collection: MediaCollection) {
    dispatch({
      type: UPDATE_COLLECTION_ROUTINE.SUCCESS,
      payload: {
        collection,
      },
    });
    setDropdown(null);
  }
  // no logic, for some reason onHide is required
  const onHide = () => {};

  return (
    <ConfirmRequired
      message="Are you sure you want to delete this collection?"
      title={`Delete collection - ${collection.name}`}
      confirmText="Delete"
      onConfirm={() => {
        dispatch(deleteCollection(collection._id));
      }}
    >
      {({ onClick: onClickDelete }) => (
        <Container ref={containerRef}>
          <Trigger
            ref={triggerRef}
            onClick={() => setDropdown((d) => (d ? null : "menu"))}
          >
            {isManagedCollection && PlatformIcon ? (
              <span style={{ marginRight: "8px", height: "24px" }}>
                <PlatformIcon />
              </span>
            ) : (
              <CollectionColor className="tw-mr-4" collection={collection} />
            )}
            <p>{collection.name}</p>
            {isManagedCollection && (
              <Tooltip
                text={`This collection is managed through an integration. To edit or delete it, please go to ${platformName}. You can also disconnect it from Sked on our Settings page.`}
                side="right"
                textSize="12px"
                contentWidth="250px"
              >
                <div style={{ position: "relative", marginLeft: "8px" }}>
                  <Icon
                    name="info-circle"
                    color={colors.neutral500}
                    size={16}
                  />
                </div>
              </Tooltip>
            )}
            <Caret className="tw-ml-4" />
          </Trigger>
          <Overlay
            rootClose={true}
            target={() => triggerRef.current}
            container={() => containerRef.current}
            show={Boolean(dropdown)}
            placement="bottom"
            onHide={onHide}
          >
            <Dropdown>
              <CrossFadeReplace>
                {(() => {
                  switch (dropdown) {
                    case "color":
                      return (
                        <CollectionColorForm
                          collection={collection}
                          onCollectionUpdated={onCollectionUpdated}
                        />
                      );
                    case "name":
                      return (
                        <CollectionNameForm
                          collection={collection}
                          onCollectionUpdated={onCollectionUpdated}
                        />
                      );
                    default:
                      return (
                        <Menu key="menu">
                          <MenuItem
                            onClick={() => {
                              dispatch(toggleSelectAll());
                              setDropdown(null);
                            }}
                            disabled={collection.asset_count === 0}
                          >
                            Select all assets in <b>{collection.name}</b>
                          </MenuItem>
                          <MenuItem
                            onClick={() => setDropdown("name")}
                            disabled={isManagedCollection}
                          >
                            Change collection name
                          </MenuItem>
                          {!isManagedCollection && (
                            <MenuItem onClick={() => setDropdown("color")}>
                              Change collection color
                            </MenuItem>
                          )}
                          {collectionNonDeletable ? (
                            <TooltipWrapper
                              tooltip={collectionNonDeletable}
                              placement="bottom"
                            >
                              <MenuItem disabled>Delete collection</MenuItem>
                            </TooltipWrapper>
                          ) : (
                            <MenuItem
                              css="color: red !important;"
                              onClick={() => {
                                onClickDelete();
                                setDropdown(null);
                              }}
                            >
                              Delete collection
                            </MenuItem>
                          )}
                        </Menu>
                      );
                  }
                })()}
              </CrossFadeReplace>
            </Dropdown>
          </Overlay>
        </Container>
      )}
    </ConfirmRequired>
  );
}

const Caret = styled(CaretIcon)`
  width: 12px;
  height: 7px;
`;

const Trigger = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0;
    font-size: 2.25rem;
    line-height: 1.2;
    font-weight: 900;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const MenuItem = styled.button`
  width: 100%;
  background: transparent;
  border: none;
  padding: 6px 8px;
  text-align: left;
  transition: background-color 0.3s;
  border-radius: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:disabled {
    background: transparent !important;
    opacity: 0.5;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: ${AppConfig.ZIndex.Dropdown};
  background: ${colors.white};
  padding: 6px 0;
  width: 300px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 8px 16px;
`;

function getCollectionNonDeletable(collection: MediaCollection): string | void {
  if (collection.isSystemInbox) {
    return "This is a system collection, so it cannot be deleted.";
  } else if (collection.asset_count !== 0) {
    return "To delete this collection all assets must be either moved to another collection or deleted.";
  }
}

const Container = styled.div`
  position: relative;
`;
