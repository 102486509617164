import { Button } from "@dialogueconsulting/sked-ui";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { SmallLoadingSpinner } from "ui/LoadingSpinner/LoadingSpinner";
import { THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from "./const";
import { getVideoPreviewImage } from "./utils";

export const VideoFrameSelector = ({
  media,
  isLoading,
  onChange,
  onClose,
  videoUrl,
  thumbnails,
}: {
  media: File | null;
  isLoading: boolean;
  onChange: (cover: string, timestampInMs: number) => void;
  onClose: () => void;
  videoUrl: string;
  thumbnails: string[];
}) => {
  const [, setVideo] = useState<File | null>(null);
  const [videoThumb, setVideoThumb] = useState<string>("");
  const [sliderX, setSliderX] = useState<number | null>(null);
  const [duration, setDuration] = useState<number>(0); // in second;
  const [videoDuration, setVideoDuration] = useState<number>(0);

  const sliderRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (media) {
      setFile(media);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startDrag = (e: React.MouseEvent | React.TouchEvent): void => {
    isDragging.current = true;
    updateValue(e);
  };

  const stopDrag = async (): Promise<void> => {
    isDragging.current = false;
    if (videoUrl && videoDuration) {
      const thumb = await getVideoPreviewImage(videoUrl, videoDuration);
      setVideoThumb(thumb);
    }
  };

  const updateValue = (e: React.MouseEvent | React.TouchEvent): void => {
    const { left, width } = sliderRef?.current?.getBoundingClientRect() || {};

    if (!isDragging.current || !left || !width) return;

    let clientX = 0;
    if ("touches" in e) {
      clientX = e.touches[0].clientX;
    } else {
      clientX = e.clientX;
    }

    if (clientX - left + 2 - THUMBNAIL_WIDTH / 2 <= 0) {
      setVideoDuration(0);
      return;
    }
    if (clientX - left - 2 + THUMBNAIL_WIDTH / 2 >= width) {
      setVideoDuration(duration);
      return;
    }

    const newValue = Math.max(0, Math.min(1, (clientX - left) / width)) * 100;
    setVideoDuration((newValue * duration) / 100);
    setSliderX(newValue);
  };

  const setFile = async (file: File) => {
    setVideo(null);
    setVideoThumb("");
    setSliderX(null);
    setVideoDuration(0);

    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      setDuration(video.duration);
    };

    video.src = URL.createObjectURL(file);
    videoRef.current = video;

    setVideo(file);
  };

  const onCoverSelect = () => {
    onChange?.(videoThumb, Math.floor(videoDuration * 1000));
    onClose?.();
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = videoDuration;
    }
  }, [videoDuration]);

  if (isLoading || thumbnails.length < 1) {
    return <SmallLoadingSpinner />;
  }

  return (
    <VideoFrameSelectorWrap>
      <FrameWrap>
        <IndicatorWrap
          ref={sliderRef}
          onMouseDown={startDrag}
          onMouseMove={updateValue}
          onMouseUp={stopDrag}
          onMouseLeave={stopDrag}
          onTouchStart={startDrag}
          onTouchMove={updateValue}
          onTouchEnd={stopDrag}
        >
          <Indicator
            style={{
              left: sliderX ? `${sliderX}%` : `${THUMBNAIL_WIDTH / 2}px`,
            }}
          >
            <video
              src={videoUrl}
              height={THUMBNAIL_HEIGHT}
              ref={(el) => (videoRef.current = el)}
            />
          </Indicator>
        </IndicatorWrap>
        <Thumbnails>
          {thumbnails.map((item, key) => {
            return (
              <Thumbnail key={key}>
                <img
                  src={item}
                  onClick={() => {
                    setVideoThumb(item);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                />
              </Thumbnail>
            );
          })}
        </Thumbnails>
      </FrameWrap>
      <FrameActionWrap>
        <Button
          hierarchy="primary"
          onClick={onCoverSelect}
          text="Done"
          disabled={videoThumb === ""}
        />
        <Button hierarchy="tertiary" onClick={onClose} text="Cancel" />
      </FrameActionWrap>
    </VideoFrameSelectorWrap>
  );
};

const VideoFrameSelectorWrap = styled.div`
  display: flex;
`;

const FrameWrap = styled.div`
  position: relative;
  width: ${THUMBNAIL_WIDTH * 10}px;
`;

const FrameActionWrap = styled.div`
display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
`;

const Thumbnails = styled.div`
  width: ${THUMBNAIL_WIDTH * 10}px;
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid var(--Neutral-N100, #e6e6e6);
  height: ${THUMBNAIL_HEIGHT}px;
  overflow: hidden;
`;

const Thumbnail = styled.div`
  width: ${THUMBNAIL_WIDTH}px;
  height: ${THUMBNAIL_HEIGHT}px;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: ${THUMBNAIL_HEIGHT}px;
    object-fit: cover;
  }
`;

const IndicatorWrap = styled.div`
  width: 100%;
  height: ${THUMBNAIL_HEIGHT}px;
  position: absolute;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
`;

const Indicator = styled.div`
  width: ${THUMBNAIL_WIDTH}px;
  height: ${THUMBNAIL_HEIGHT}px;
  background: transparent;
  border: 3px solid #ddd;
  position: relative;
  left: 0;
  cursor: pointer;
  background: blue;
  transform: translateX(-50%);
  border-radius: 4.125px;
  border: 4px solid var(--Primary-P500, #4f31ff);
  background: var(--Text-T01, #333);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
