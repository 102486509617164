import { openSupportChat } from "libs/support";
import React from "react";
import { AccountGroup } from "shared/types/accounts";
import styled from "styled-components";
import { TooltipWrapper } from "ui";
import youtubeIconUtils from "utils/youtube-icon-utils";

export interface AccountGroupOptionAccountsType {
  _id: string;
  name: string;
  avatar?: string;
  platform?: string;
  color?: string;
}

export interface AccountGroupOptionType {
  value: string;
  label: string;
  accounts: AccountGroupOptionAccountsType[];
}

export const generateAccountGroupOptions = (
  accountGroups: AccountGroup[]
): AccountGroupOptionType[] => {
  return accountGroups.map((accountGroup) => ({
    value: accountGroup.title,
    label: accountGroup.title,
    accounts: accountGroup.accounts.map((account) => ({
      _id: account._id,
      name: account.loginAlias || account.login,
      avatar: account.profilePictureUrl,
      platform: youtubeIconUtils.resolvePlayIcon(
        account.platformName as string
      ),
      color: account.color,
    })),
  }));
};

export const openSupportForEnableApprovals = () => {
  openSupportChat(
    "Hey, I'd like to set up the approvals process for my account.."
  );
};

const TooltipContainer = styled.div`
  max-width: 245px;
`;

const OpenSupportChatLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const ApprovalTooltipElement = (
  <TooltipContainer>
    Please contact{" "}
    <OpenSupportChatLink onClick={() => openSupportForEnableApprovals()}>
      customer support
    </OpenSupportChatLink>{" "}
    to activate Approvals.
  </TooltipContainer>
);

export const ApprovalTooltipWrapper = ({
  show,
  children,
}: {
  children: React.ReactElement;
  show: boolean;
}) => {
  return (
    <TooltipWrapper
      tooltip={show ? ApprovalTooltipElement : undefined}
      placement={"top"}
    >
      {children}
    </TooltipWrapper>
  );
};
