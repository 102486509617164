import React, { useMemo, forwardRef } from "react";
import ReactSelect, { components as reactSelectComponents } from "react-select";

export const addDataTestIdToReactSelectComponent =
  (Component: JSX.ElementType, dataTestId: string) => (props: any) =>
    (
      <Component
        {...props}
        innerProps={Object.assign({}, props.innerProps, {
          "data-testid": dataTestId,
        })}
      />
    );

const ReactSelectWithTestIdComponent = (props: any, ref: any) => {
  const customSelectComponents = useMemo(() => {
    let defaultComponents = {};

    if (props.components) {
      defaultComponents = props.components;
    }

    if (process.env.NODE_ENV === "dev" || process.env.NODE_ENV === "test") {
      return {
        ClearIndicator: addDataTestIdToReactSelectComponent(
          reactSelectComponents.ClearIndicator,
          "uiReactSelectClearIndicator"
        ),
        SelectContainer: addDataTestIdToReactSelectComponent(
          reactSelectComponents.SelectContainer,
          "uiReactSelectContainer"
        ),
        ...defaultComponents,
      };
    }

    return defaultComponents;
  }, [props.components]);

  return (
    <ReactSelect ref={ref} {...props} components={customSelectComponents} />
  );
};

export const ReactSelectWithTestId = forwardRef(ReactSelectWithTestIdComponent);
