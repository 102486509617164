import React from "react";
import styled from "styled-components";

import { getPlatformTypes } from "utils/posts";
import { getPlatformName } from "utils/accounts";
import { PlatformIcon } from "entities/accounts";

const PostPlatformTypes = ({ post, accounts, style = {} }) => {
  const platforms = getPlatformTypes(post, accounts);
  const platformColor = getPlatformColor(platforms);

  return (
    <StyledPlatforms
      className="tw-px-4 tw-rounded-l-lg tw-flex tw-flex-col tw-justify-center tw-items-center"
      color={platformColor}
      style={style}
    >
      {platforms.map((platformType) => {
        const platformName = getPlatformName(platformType);
        return (
          <PlatformIcon
            key={platformType}
            className="tw-mb-4"
            css="width: 30px; height: 30px;"
            type={platformName}
          />
        );
      })}
    </StyledPlatforms>
  );
};

const StyledPlatforms = styled.div`
  background: ${({ color }) => (color ? color : "black")};
  margin-top: ${({ style }) =>
    style && style.marginTop ? style.marginTop : "0px"};
`;

const getPlatformColor = (platforms) => {
  if (platforms.length > 1) {
    return "#DEDAFE";
  }

  switch (platforms[0]) {
    case "IG":
      return "#F9F0F7";
    case "FB":
      return "#E3ECF3";
    case "TW":
      return "#E2F7FD";
    case "LI":
      return "#F3F6F8";
    case "GMB":
      return "#8ecdff";
    case "YT":
      return "#FBBFBB";
    case "PI":
      return "#F5D1D1";
    case "TT":
      return "#d7d7d7";
    case "TT":
      return "#757575";
    case "SC":
      return "#fdf6df";
    case "OT":
      return "#dbd1f5";
    default:
      return "black"; // throw error?
  }
};

export default PostPlatformTypes;
