import React, { MouseEventHandler, useState } from "react";
import styled, { css } from "styled-components";
import BootstrapTooltip from "react-bootstrap/lib/Tooltip";

import StarFilled from "assets/icons/star-filled.svg";
import StarEmpty from "assets/icons/star-empty.svg";
import { TooltipWrapper } from "ui";

import "./GridMediaCard.less";

export interface GridMediaCardProps {
  id: string;
  selected?: boolean;
  selecting?: boolean;
  assetUrl: string;
  videoPreviewUrl?: string;
  hoverProps?: JSX.Element | null;
  canSelect?: boolean;
  favorited?: boolean;
  canFavorite?: boolean;
  isVideo?: boolean;
  className?: string;
  onCardClick?: MouseEventHandler<HTMLDivElement> | undefined;
  onFavoriteClick?: MouseEventHandler<HTMLDivElement> | undefined;
  showCollectionTooltip?: boolean;
  order?: number;
}

const GridMediaCard = ({
  id = "",
  selected = false,
  selecting = false,
  assetUrl = "",
  videoPreviewUrl = "",
  hoverProps = null,
  canSelect = true,
  favorited = false,
  canFavorite = true,
  isVideo = false,
  className = "",
  onCardClick,
  onFavoriteClick = undefined,
  showCollectionTooltip = false,
  order = 1,
}: GridMediaCardProps) => {
  const tooltipPlacement =
    order % 5 === 4 || order % 5 === 0 ? "left" : "right";

  return (
    <GridMediaCardRoot
      className={`GridMediaCard-Card ${className}`}
      $isVideo={isVideo}
      data-testid="assetContainer"
    >
      {showCollectionTooltip && (
        <Tooltip placement={tooltipPlacement}>
          Please select a Collection
        </Tooltip>
      )}
      <div
        className={`GridMediaCard-Asset tw-cursor-pointer tw-rounded ${
          selected ? "GridMediaCard-Asset_selected" : ""
        }`}
        onClick={onCardClick}
      >
        {canFavorite && (
          <FavoriteButton
            data-testid="favourite-button"
            onClick={onFavoriteClick}
          >
            {favorited ? (
              <TooltipWrapper tooltip={"Un-star asset"}>
                <StarFilled />
              </TooltipWrapper>
            ) : (
              <TooltipWrapper tooltip="Star asset">
                <StarEmpty />
              </TooltipWrapper>
            )}
          </FavoriteButton>
        )}
        {canSelect && (
          <div
            className={`GridMediaCard-CheckboxBackground ${
              selecting ? "GridMediaCard-CheckboxBackground_selecting" : ""
            }`}
          >
            <div className="checked" data-testid="select-button">
              <svg
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 5.5L4 8.5L11.5 1"
                  stroke="#4F31FF"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        )}
        <Preview
          data-testid="gridMediaCardImage"
          src={assetUrl}
          alt={`Media asset ${id}`}
        />
        {isVideo && (
          <>
            <VideoPreview
              src={
                videoPreviewUrl || assetUrl.replace("thumbnail.jpg", "gif.gif")
              }
              fallbackSrc={assetUrl}
            />
            <PlayVideoButton />
          </>
        )}

        {hoverProps && (
          <div
            className="GridMediaCard-Hover"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {hoverProps}
          </div>
        )}
      </div>
    </GridMediaCardRoot>
  );
};

const FavoriteButton = styled.div`
  position: absolute;
  opacity: 0.8;
  background: none;
`;

const Preview = styled.img``;

const VideoPreview = styled(
  ({ fallbackSrc, ...props }: { fallbackSrc: string; src: string }) => {
    const [src, setSrc] = useState(props.src);
    return <img {...props} src={src} onError={() => setSrc(fallbackSrc)} />;
  }
)``;

const PlayVideoButton = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 0 13px 20px;
  border-color: transparent transparent transparent #ffffff;
`;

interface GridMediaCardRootProps {
  $isVideo: boolean;
}

const GridMediaCardRoot = styled.div<GridMediaCardRootProps>`
  ${FavoriteButton} {
    display: none;
    position: absolute;
    z-index: 1;
    left: 8%;
    right: 80%;
    top: 8%;
    bottom: 80%;
  }

  :hover {
    ${FavoriteButton} {
      display: block;
    }
  }

  ${VideoPreview} {
    display: none;
  }

  ${(props) =>
    props.$isVideo &&
    css`
      :hover {
        ${Preview}, ${PlayVideoButton} {
          display: none;
        }
        ${VideoPreview} {
          display: block;
        }
      }
    `}

  ${PlayVideoButton} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Tooltip = styled(BootstrapTooltip)`
  opacity: 1;
  width: 195px;

  ${(props) =>
    props.placement === "left"
      ? css`
          right: 100%;
        `
      : css`
          left: 100%;
        `}

  .tooltip-inner {
    width: 100%;
  }
`;

export default GridMediaCard;
