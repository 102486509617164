import React, { useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/color-tokens";
import { DropzoneRootProps, FileRejection, useDropzone } from "react-dropzone";

import { selectFileFromDropBox } from "libs/dropbox";
import { selectFileFromCanva } from "libs/canva";
import { selectFileFromGoogleDrive } from "libs/google-drive";
import { getFileTypeByName, loadBlob } from "libs/files";
import { useIsWindowDragActive } from "libs/drag-and-drop";
import { SkedButton } from "ui";

import DropBoxIcon from "./icons/dropbox-icon.svg";
import GoogleDriveIcon from "./icons/google-drive-icon.svg";
import CanvaIcon from "./icons/canva-logo.svg";
import LocalFile from "./icons/cloud-upload.svg";
import FrontifyIcon from "./icons/frontify-icon.svg";
import { openModal } from "libs/modals";
import { ProgressBar } from "ui/progress-bar/progress-bar";
import { FileToUpload } from "api/media-library";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ADD_FRONTIFY_MODAL } from "libs/frontify";
import { isUserPlan } from "features/upsell/plan-utils";
import { getUser } from "selectors/commonSelectors";
import { FRONTIFY_WIDGET_CLICKED } from "constants/Events";
import { useTrackEvent } from "libs/analytics";
import { Icon } from "@dialogueconsulting/sked-ui";
import { showUpsellModal } from "actions/typed-modal";

interface Props {
  className?: string;
  title: string;
  limit: number;
  uploadingStatus: string | null;
  isDisabled: boolean;

  onSelect(files: FileToUpload[]): void;

  renderOverlay(props: DropzoneRootProps): React.ReactNode;
}

const Selector: React.FC<Props> = ({
  className,
  title,
  onSelect,
  renderOverlay,
  limit,
  uploadingStatus,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [
        ".jpg",
        ".jpeg",
        ".png",
        ".gif",
        ".webp",
        ".heic",
        ".heif",
        ".bmp",
        ".tif",
        ".tiff",
      ],
      "video/*": [".mp4", ".mov", ".avi", ".webm"],
      "application/pdf": [".pdf"],
    },
    onDrop,
    noClick: true,
    disabled: isDisabled,
    onError: (error) => {
      console.error(error);
      toast.error(error?.message || "An error occurred");
    },
  });
  const isWindowDragActive = useIsWindowDragActive();

  async function onDrop(
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) {
    try {
      fileRejections.forEach(({ file, errors }) => {
        toast.error(
          `${file.name} was rejected: ${errors
            .map((e) => e.message)
            .join(", ")}`
        );
      });

      const files = await Promise.all(
        acceptedFiles
          .slice(0, limit)
          .map(async (file): Promise<FileToUpload> => {
            // TODO: 'file' is actually already a Blob, so we most probably
            // don't need this loadBlob call moreover loadBlob currently
            // does not do any useful work
            const blob = await loadBlob(file);
            return {
              contentType: "blob",
              name: file.name,
              blob,
              type: getFileTypeByName(file.name),
            };
          })
      );

      onSelect(files);
    } catch (e: any) {
      toast.error(e && e.message ? e.message : e);
    }
  }

  async function handleDropBoxClick() {
    try {
      setIsCanva(false);
      const dropBoxFiles = await selectFileFromDropBox();
      const files = dropBoxFiles.slice(0, limit).map(
        (dropBoxFile): FileToUpload => ({
          contentType: "url",
          name: dropBoxFile.name,
          url: dropBoxFile.link,
          thumbnail: dropBoxFile.thumbnailLink,
          type: getFileTypeByName(dropBoxFile.name),
        })
      );
      onSelect(files);
    } catch (e: any) {
      toast.error(e && e.message ? e.message : e);
    }
  }

  async function handleGoogleDriveClick() {
    try {
      setIsCanva(false);
      const gDriveFiles = await selectFileFromGoogleDrive();
      const files = gDriveFiles.map(
        (file): FileToUpload => ({
          contentType: "getter",
          name: file.name,
          getImage: file.getImage,
          type: getFileTypeByName(file.name),
        })
      );

      onSelect(files);
    } catch (e: any) {
      toast.error(e && e.message ? e.message : e);
    }
  }

  async function handleCanvaTypeClick(type: string) {
    try {
      const canvaFile = await selectFileFromCanva(type);
      onSelect([
        {
          contentType: "url",
          name: `${canvaFile.designTitle}.jpg`,
          url: canvaFile.exportUrl,
          thumbnail: canvaFile.exportUrl,
          // Canva button embed only currently supports
          // exporting image formats. TODO when it supports video/gif, support here
          type: "image",
        } as FileToUpload,
      ]);
    } catch (e: any) {
      toast.error(e && e.message ? e.message : e);
    }
  }

  const [isCanva, setIsCanva] = useState(false);

  const user = useSelector(getUser);

  async function handleCanvaClick() {
    setIsCanva(!isCanva);
  }

  const handleFrontifyClick = () => {
    trackEvent({
      eventName: FRONTIFY_WIDGET_CLICKED,
      useServices: ["AMP", "AC"],
    });
    try {
      setIsCanva(false);
      dispatch(
        openModal({
          type: ADD_FRONTIFY_MODAL,
          props: {
            limit: limit,
            onSelect: onSelect,
          },
        })
      );
    } catch (err: any) {
      toast.error(err?.message || "An error occurred");
    }
  };

  if (uploadingStatus !== null) {
    return (
      <Uploading>
        <Title>{title}</Title>
        <ProgressBar>{uploadingStatus}</ProgressBar>
      </Uploading>
    );
  }

  // will use this later
  const frontifyEnabled = isUserPlan(user, "sked-enterprise");

  return (
    <>
      <SelectorRoot className={className} {...getRootProps()}>
        <Title>{title}</Title>
        <LocalFileBlock>
          <LocalFileLogo alt="Local file" />
          <DropText>Drag and drop files</DropText>
          <SelectLocalFileButton disabled={isDisabled}>
            <input {...getInputProps()} disabled={isDisabled} />
            Select files
          </SelectLocalFileButton>
        </LocalFileBlock>
        <PlatformButtons>
          <PlatformButton
            data-testid="platformDropboxButton"
            disabled={isDisabled}
            onClick={handleDropBoxClick}
            Icon={DropBoxIcon}
            tooltip="Select from Dropbox"
          />
          <PlatformButton
            data-testid="platformGoogleDriveButton"
            disabled={isDisabled}
            onClick={handleGoogleDriveClick}
            Icon={GoogleDriveIcon}
            tooltip="Select from Google Drive"
          />
          <PlatformButton
            data-testid="platformCanvaButton"
            disabled={isDisabled}
            onClick={handleCanvaClick}
            $isCanva={isCanva}
            Icon={CanvaIcon}
            tooltip="Select or create from Canva"
          />
          {frontifyEnabled ? (
            <PlatformButton
              data-testid="platformFrontifyButton"
              disabled={isDisabled}
              onClick={handleFrontifyClick}
              Icon={FrontifyIcon}
              tooltip="Select from Frontify"
            />
          ) : (
            <UpsellPlatform>
              <Icon name="upsell-rocket-blue-bg" size={16} />
              <PlatformButton
                data-testid="platformFrontifyButton"
                disabled={isDisabled}
                onClick={() => {
                  dispatch(showUpsellModal("upsell", "frontify-media"));
                  trackEvent({
                    eventName: "Upgrade-frontify-viewed",
                    useServices: ["AMP", "AC", "HS"],
                  });
                }}
                Icon={FrontifyIcon}
                tooltip="Select from Frontify"
              />
            </UpsellPlatform>
          )}
        </PlatformButtons>
        {isCanva && !isDisabled && (
          <CanvaWrapper>
            <CanvaButtons>
              <CanvaButton
                type="primary"
                onClick={() => handleCanvaTypeClick("SocialMedia")}
              >
                Generic Social Media
              </CanvaButton>
              <CanvaButton
                type="primary"
                onClick={() => handleCanvaTypeClick("InstagramPost")}
              >
                Instagram Post
              </CanvaButton>
              <CanvaButton
                type="primary"
                onClick={() => handleCanvaTypeClick("InstagramStory")}
              >
                Instagram Story
              </CanvaButton>
            </CanvaButtons>
            <CanvaButtons>
              <CanvaButton
                type="primary"
                onClick={() => handleCanvaTypeClick("FacebookPost")}
              >
                Facebook Post
              </CanvaButton>
              <CanvaButton
                type="primary"
                onClick={() => handleCanvaTypeClick("TwitterPost")}
              >
                Twitter Post
              </CanvaButton>
            </CanvaButtons>
          </CanvaWrapper>
        )}
      </SelectorRoot>
      {isWindowDragActive && renderOverlay(getRootProps())}
    </>
  );
};

export const FileSelector = styled(Selector)``;

const Title = styled.div`
  margin-top: 15px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
`;

const SelectLocalFileButton = styled.label<{ disabled: boolean }>`
  display: flex;
  color: ${colors.primary500};
  margin: 0;
  border-radius: 5px;
  padding: 0;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.primary500};
  width: 155px;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
  font-size: 10px;
  background: ${colors.white};
  ${(props) =>
    props.disabled &&
    css`
      cursor: pointer;
      background: ${colors.neutral400} !important;
      border-color: ${colors.neutral400} !important;
    `}
  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;

      &:hover {
        background: ${colors.primary700};
        color: ${colors.white};
      }
    `}
`;

const PlatformButton = styled(
  ({
    Icon,
    $isCanva = false,
    ...props
  }: {
    Icon: React.FC;
    $isCanva?: boolean;
    disabled: boolean;
    onClick: () => void;
    tooltip: string;
  }) => (
    <button {...props}>
      <Icon />
    </button>
  )
)`
  display: flex;
  background: ${colors.white};
  border-radius: 5px;
  width: 34px;
  height: 34px;
  padding: 0;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${(props) => (props.$isCanva ? colors.primary500 : colors.neutral100)};

  &:hover {
    border-color: ${colors.primary500};
  }

  > * {
    width: 28px;
    height: 28px;
  }
`;

const UpsellPlatform = styled.div`
  position: relative;
  > div:first-of-type {
    position: absolute;
    top: -4px;
    right: -4px;
    z-index: 2;
  }
`;

const PlatformButtons = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(4, 34px);
  grid-gap: 17px;
  margin: 15px 0 20px;
`;

const SelectorRoot = styled.div<{ isDragActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.neutral50};
  border-radius: 5px;
  min-height: 175px;
  max-height: 348px;
  margin-bottom: 20px;
`;

const Uploading = styled(SelectorRoot)`
  justify-content: center;

  ${Title} {
    margin-bottom: 10px;
  }

  ${ProgressBar} {
    width: 80%;
  }
`;

const DropText = styled.p`
  text-align: center;
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 8px;
`;

const CanvaButton = styled(SkedButton)`
  margin: 4px;

  button {
    font-weight: 400 !important;
    height: 30px !important;
    line-height: 30px !important;
    font-size: 10px !important;
  }
`;

const LocalFileLogo = styled(LocalFile)`
  mix-blend-mode: luminosity;
  height: 32px;
  margin: 18px auto 13px;
  display: block;
`;

const LocalFileBlock = styled.div``;

const CanvaWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  position: relative;
  flex-direction: column;
  margin-bottom: 14px;
`;
const CanvaButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;
