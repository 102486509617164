"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.semanticColors = exports.colors = exports.lessVariables = void 0;
// Less variables for color tokens
exports.lessVariables = `
@colors-white: #FFFFFF;
@colors-black: #000000;

@colors-primary25: #FCFAFF;
@colors-primary37: #F4F2FF;
@colors-primary50: #EDEAFF;
@colors-primary100: #DCD6FF;
@colors-primary200: #BFB3FF;
@colors-primary300: #9C8AFF;
@colors-primary400: #7A5DFF;
@colors-primary500: #4F31FF;
@colors-primary600: #3F27CC;
@colors-primary700: #2F1D99;
@colors-primary800: #201466;
@colors-primary900: #100A33;

@colors-error25: #FFF8F8;
@colors-error50: #FFE6E6;
@colors-error100: #FFCCCC;
@colors-error200: #FF9999;
@colors-error300: #FF6666;
@colors-error400: #FF3333;
@colors-error500: #FF0000;
@colors-error600: #CC0000;
@colors-error700: #990000;
@colors-error800: #642016;
@colors-error900: #321008;

@colors-neutral25: #F9F9F9;
@colors-neutral50: #F2F2F2;
@colors-neutral100: #E6E6E6;
@colors-neutral200: #C4C4C4;
@colors-neutral300: #B3B3B3;
@colors-neutral400: #999999;
@colors-neutral500: #808080;
@colors-neutral600: #666666;
@colors-neutral700: #424242;
@colors-neutral800: #333333;
@colors-neutral900: #1A1A1A;

@colors-success25: #F5FFF9;
@colors-success50: #E7FFF2;
@colors-success100: #D1FFE6;
@colors-success200: #9FF3CC;
@colors-success300: #6DE7B3;
@colors-success400: #3BDB99;
@colors-success500: #10D081;
@colors-success600: #0CA46D;
@colors-success700: #0D8F4D;
@colors-success800: #054A34;
@colors-success900: #022519;

@colors-warning25: #FFFDF5;
@colors-warning50: #FFF8E6;
@colors-warning100: #FFF2C7;
@colors-warning200: #FFE699;
@colors-warning300: #FFD966;
@colors-warning400: #FFBF34;
@colors-warning500: #F59E0B;
@colors-warning600: #D97706;
@colors-warning700: #B45309;
@colors-warning800: #92400E;
@colors-warning900: #7C2D12;

@colors-blurple: #4F31FF;
@colors-grape: #642A73;
@colors-cosmo: #ED207C;
@colors-spice: #FF852C;
@colors-lime: #D5F870;

@colors-miami600: #B84FAD;
@colors-miami400: #EF72E2;
@colors-miami200: #FFC8F9;

@colors-spice700: #AC581B;
@colors-spice500: #FF6B2B;
@colors-spice200: #FDC399;

@colors-lime700: #586C1F;
@colors-lime500: #D5F870;
@colors-lime200: #F1F8DA;

// Semantic Colors
@semantic-text-primary: @colors-neutral800;
@semantic-text-secondary: @colors-neutral600;
@semantic-text-disabled-dropdown: @colors-neutral500;
@semantic-text-brand1: @colors-primary500;
@semantic-text-brand2: @colors-primary700;

@semantic-border-card: @colors-neutral100;
@semantic-border-modal: @colors-neutral100;
@semantic-border-focus: @colors-primary500;
`;
exports.colors = {
    // Shades
    white: "#FFFFFF",
    black: "#000000",
    // Primary/Blue (Blurple)
    primary25: "#FCFAFF",
    primary37: "#F4F2FF", // Used for sidebar background
    primary50: "#EDEAFF",
    primary100: "#DCD6FF",
    primary200: "#BFB3FF",
    primary300: "#9C8AFF",
    primary400: "#7A5DFF",
    primary500: "#4F31FF",
    primary600: "#3F27CC",
    primary700: "#2F1D99",
    primary800: "#201466",
    primary900: "#100A33",
    // Error/Red
    error25: "#FFF8F8",
    error50: "#FFE6E6",
    error100: "#FFCCCC",
    error200: "#FF9999",
    error300: "#FF6666",
    error400: "#FF3333",
    error500: "#FF0000",
    error600: "#CC0000",
    error700: "#990000",
    error800: "#642016",
    error900: "#321008",
    // Neutral (Obsidian)
    neutral25: "#F9F9F9",
    neutral50: "#F2F2F2",
    neutral100: "#E6E6E6",
    neutral200: "#C4C4C4",
    neutral300: "#B3B3B3",
    neutral400: "#999999",
    neutral500: "#808080",
    neutral600: "#666666",
    neutral700: "#424242",
    neutral800: "#333333",
    neutral900: "#1A1A1A",
    // Success/Green
    success25: "#F5FFF9",
    success50: "#E7FFF2",
    success100: "#D1FFE6",
    success200: "#9FF3CC",
    success300: "#6DE7B3",
    success400: "#3BDB99",
    success500: "#10D081",
    success600: "#0CA46D",
    success700: "#0D8F4D",
    success800: "#054A34",
    success900: "#022519",
    // Warning/Yellow
    warning25: "#FFFDF5",
    warning50: "#FFF8E6",
    warning100: "#FFF2C7",
    warning200: "#FFE699",
    warning300: "#FFD966",
    warning400: "#FFBF34",
    warning500: "#F59E0B",
    warning600: "#D97706",
    warning700: "#B45309",
    warning800: "#92400E",
    warning900: "#7C2D12",
    // Brand Colors
    blurple: "#4F31FF",
    grape: "#642A73",
    cosmo: "#ED207C",
    spice: "#FF852C",
    lime: "#D5F870",
    // Extended Spectrum - Miami
    miami600: "#B84FAD",
    miami400: "#EF72E2",
    miami200: "#FFC8F9",
    // Extended Spectrum - Spice
    spice700: "#AC581B",
    spice500: "#FF6B2B",
    spice200: "#FDC399",
    // Extended Spectrum - Lime
    lime700: "#586C1F",
    lime500: "#D5F870",
    lime200: "#F1F8DA",
};
exports.semanticColors = {
    // Text Colors
    textPrimary: exports.colors.neutral800,
    textSecondary: exports.colors.neutral600,
    textDisabledDropdown: exports.colors.neutral500,
    textBrand1: exports.colors.primary500,
    textBrand2: exports.colors.primary700,
    // Border Colors
    borderCard: exports.colors.neutral100,
    borderModal: exports.colors.neutral100,
    borderFocus: exports.colors.primary500,
};
