import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

import { closeModal } from "libs/modals";
import { Button, FormSize } from "ui/ecosystems/forms";

import { INVITATION_USER_MODAL } from "../../UserModals";

export const InvitationUserModal = (props: {
  userName: string;
}): JSX.Element => {
  const dispatch = useDispatch();

  function closeInvitationModal() {
    dispatch(closeModal({ type: INVITATION_USER_MODAL }));
  }

  return (
    <div>
      <Modal
        show
        onHide={closeInvitationModal}
        animation={true}
        bsStyle="giselle"
        // @ts-ignore
        bsSize="md-reasonable"
      >
        <ModalContainer>
          <Header>Invitation has been sent!</Header>
          <Body>
            <p>
              Once {props.userName} has accepted, you will receive an email with
              links to update their access to library, post statuses & ability
              to add accounts.
            </p>
          </Body>
          <Actions>
            <Done
              type="button"
              primary
              size={FormSize.large}
              onClick={closeInvitationModal}
            >
              Done
            </Done>
          </Actions>
        </ModalContainer>
      </Modal>
    </div>
  );
};

const ModalContainer = styled.div`
  padding: 25px;
`;
const Actions = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 38px;
`;
const Header = styled.h4`
  margin: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
`;

const Body = styled.div`
  margin-top: 17px;
  p {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
`;

const Done = styled(Button)`
  padding: 0 40px;
  border-radius: 10px;
`;
