import { registerModal } from "libs/modals";
import { AddUserModal } from "./components/add-user";
import { EditUserModal } from "./components/edit-user";
import { InvitationUserModal } from "./components/invitation-user";
import { UpdateAvatar } from "./components/update-avatar";
import { DeleteUserModal } from "./components/delete-user";
import { MODAL_NAMES } from "constants/Modals";

// ADD USER MODEL
export const ADD_USER_MODAL = MODAL_NAMES.ADD_USER_MODAL;
registerModal(ADD_USER_MODAL, AddUserModal);

// EDIT USER MODEL
export const EDIT_USER_MODAL = "EDIT_USER_MODAL";
registerModal(EDIT_USER_MODAL, EditUserModal);

// INVITATION USER MODEL
export const INVITATION_USER_MODAL = "INVITATION_USER_MODAL";
registerModal(INVITATION_USER_MODAL, InvitationUserModal);

// Delete USER MODEL
export const DELETE_USER_MODAL = "DELETE_USER_MODAL";
registerModal(DELETE_USER_MODAL, DeleteUserModal);

// ADD USER AVATAR MODEL
export const ADD_USER_AVATAR_MODAL = "ADD_USER_AVATAR_MODAL";
registerModal(ADD_USER_AVATAR_MODAL, UpdateAvatar);
