import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { createGlobalStyle } from "styled-components";
import { Modal } from "react-bootstrap";
import { cloneDeep, isEmpty } from "lodash";
import { ToastContainer } from "react-toastify";

//components
import { LoadingSpinner } from "ui";
import PostForm from "components/Posts/PostForm/PostForm";

//actions
import { hideModal, showConfirmModal } from "actions/modal";
import { checkInstagramPostOverlapping, savePostForm } from "actions/postForm";

//selectors
import { getLoading, getUser } from "selectors/commonSelectors";
import { getFirstForm } from "selectors/postFormSelectors";

//utils
import { getNewPostObj } from "utils/posts";
import { closeCreatePostModal, closePostForm } from "features/post";
import { usePostForm } from "components/Posts/PostForm/use-post-form";
import { POST_STATES } from "constants/PostsView";
import { useTrackEvent } from "libs/analytics";
import { reloadAssetCurrentPage, reloadTags } from "actions/mediaLibrary";

// apis
import collabApi from "api/collaboration.ts";

import { plannerPageScheduleClusterPost } from "pages/planner/__redux-model";
import {
  CREATE_POST_YT,
  CREATE_SINGLE_POST,
  CREATE_POST_LI,
} from "constants/Events";

// if assets exists, make sure we set the keys on the parent object
// correctly so that it will show the appropriate type and files
const formatAssetsToPost = (assets) => {
  if (assets && assets.length) {
    // Not 100% sure if the url fields are required? Type definitely is
    return {
      url: assets[0].url,
      originalUrl: assets[0].originalUrl || assets[0].origUrl || assets[0].url,
      origUrl: assets[0].origUrl || assets[0].originalUrl || assets[0].url,
      thumbnailUrl: assets[0].thumbnailUrl || assets[0].url,
    };
  }
  return {};
};

export const CreatePostModal = ({
  post,
  accounts,
  caption,
  assets = [],
  accountEditingIsDisabled,
  isHidden,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const newPost = getNewPostObj(post);
  const form = useSelector(getFirstForm);
  const [isFormValueChanged, setIsFormValueChanged] = useState(false);
  const trackEvent = useTrackEvent();
  let postObject = {
    ...newPost,
    mediaFiles: !isEmpty(newPost.mediaFiles) ? newPost.mediaFiles : assets,
    ...formatAssetsToPost(assets),
    accountIds:
      accounts && accounts.length > 0 ? accounts.map(({ _id }) => _id) : [],
  };

  if (caption) {
    postObject.caption = caption;
  }

  if (postObject.mediaFiles?.length > 0 && postObject.type !== "story") {
    postObject.type = "multiMedia";
  } else if (postObject.type === "multiMedia") {
    // this post should not be multiMedia anymore as no mediaFiles included…
    // should not have any text posts here as no way to convert from multiMedia to text, so assume either video or image
    if (postObject.url.includes(".mp4")) {
      postObject.type = "video";
    } else {
      postObject.type = "image";
    }
  } else {
    if (
      postObject.type === "story" ||
      postObject.type === "image" ||
      postObject.type === "video" ||
      // by default post comes with a text value, but when we create the post from the library
      // we should recheck post type according to the existing assets
      (postObject.type === "text" && !postObject.mediaFiles?.length)
    ) {
      // do nothing - this is fine - multiMedia is handled above
    } else {
      // draft has an invalid post type. Guess it from the file URL as this will be a single image/video
      if (postObject.type && !postObject.url) {
        if (
          postObject.type.includes("image") &&
          !postObject.type.includes("gif")
        ) {
          postObject.type = "image";
        } else if (
          postObject.type.includes("document") ||
          postObject.type.includes("pdf")
        ) {
          postObject.type === "document";
        } else {
          postObject.type = "video";
        }
      } else {
        if (postObject.url) {
          if (
            postObject.url.includes(".jpg") ||
            postObject.url.includes(".jpeg") ||
            postObject.url.includes(".png")
          ) {
            postObject.type = "image";
          } else if (
            postObject.url.includes(".mp4") ||
            postObject.url.includes(".gif")
          ) {
            postObject.type = "video";
          } else if (postObject.url.includes(".pdf")) {
            postObject.type = "document";
          }
        } else {
          postObject.type = "text";
        }
      }
    }
  }

  const trackEventYT = (post) => {
    const isVideoPostWithYouTube =
      post.type === "video" &&
      post.accounts.some((el) => el.platformType === "YT");

    if (isVideoPostWithYouTube && post.meta?.format?.duration) {
      const duration = +(+post.meta.format.duration).toFixed();
      if (duration <= 60) {
        trackEvent({
          eventName: CREATE_POST_YT,
          metadata: { duration: "< 1 min" },
        });
      } else if (duration <= 900) {
        trackEvent({
          eventName: CREATE_POST_YT,
          metadata: { duration: "1 to 15 min" },
        });
      } else if (duration > 900) {
        trackEvent({
          eventName: CREATE_POST_YT,
          metadata: { duration: "over 15 min" },
        });
      }
    }
  };

  const trackEventLI = (post) => {
    if (post._id === "NEW_POST") {
      post.accounts.forEach((account) => {
        if (account.platformType === "LI") {
          trackEvent({
            eventName: CREATE_POST_LI,
            metadata: {
              pageType: account.linkedinUrn.includes("person")
                ? "Personal Page"
                : "Company Page",
            },
          });
        }
      });
    }
  };

  const postForm = usePostForm(postObject, {
    async onSave(data) {
      const { post: postForm } = data;
      await new Promise((resolve) =>
        dispatch(
          checkInstagramPostOverlapping({
            postId: postForm._id,
            accounts: postForm.accounts,
            isNow: postForm.isNow,
            when: postForm.when,
            done: resolve,
          })
        )
      );

      trackEvent({ eventName: CREATE_SINGLE_POST });
      trackEventYT(postForm);
      trackEventLI(postForm);
      dispatch(savePostForm(data));
    },
    onSuccess() {
      closeModal();
    },
  });

  const cancelWithoutConfirm = () => {
    dispatch(closeCreatePostModal());
    dispatch(reloadAssetCurrentPage());
    dispatch(reloadTags());
  };

  function handleCancel() {
    dispatch(
      showConfirmModal({
        title: "Cancel creating post",
        message: `Are you sure you want to cancel editing this post? Any unsaved work will be lost.`,
        confirmType: "primary_outline",
        confirmText: "Continue creating",
        cancelType: "alert",
        cancelText: "Cancel & lose post",
        handleConfirm() {
          // continue editing, so hide only confirm modal
          dispatch(hideModal(false, "CONFIRM_MODAL"));
        },
        handleCancel() {
          // should hide all modals present because this is lose changes option
          dispatch(hideModal(true));
          closeModal();
        },
      })
    );
  }

  function closeModal() {
    dispatch(closeCreatePostModal());
    dispatch(reloadAssetCurrentPage());
    dispatch(reloadTags());
  }

  return (
    <PostModal
      title="Create Post"
      onCancel={isFormValueChanged ? handleCancel : cancelWithoutConfirm}
      isLoading={loading}
      isHidden={isHidden}
    >
      {form && (
        <PostForm
          {...postForm}
          setIsFormValueChanged={setIsFormValueChanged}
          onCancel={isFormValueChanged ? handleCancel : cancelWithoutConfirm}
          accountEditingIsDisabled={accountEditingIsDisabled}
        />
      )}
    </PostModal>
  );
};

export function EditPostModal({ post, accountEditingIsDisabled, isHidden }) {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const [isFormValueChanged, setIsFormValueChanged] = useState(false);

  useEffect(() => {
    collabApi.subscribeToGeneralChannel(user.adminId, user.userId, dispatch);

    return () => {
      collabApi.unsubscribeFromGeneralChannel(user.adminId);
    };
  }, []);

  let postForm = usePostForm(post, {
    onSuccess() {},
    onSave(data) {
      const isDraft = data.newPostState === POST_STATES.DRAFT;
      const hasMultipleAccs = data.post.accounts.length >= 2;
      // if saving modal not from planner
      // or user saves draft
      // or single account
      if (!data.plannerAccount || isDraft || !hasMultipleAccs) {
        dispatch(closePostForm(data));
      } else {
        handlePlannerMultipost(data);
      }
    },
  });

  function handleCancelEdit() {
    dispatch(hideModal(false, "CONFIRM_MODAL"));
    dispatch(closePostForm());
    dispatch(reloadAssetCurrentPage());
    dispatch(reloadTags());
  }

  const cancelWithoutConfirm = () => {
    dispatch(closePostForm());
    dispatch(reloadAssetCurrentPage());
    dispatch(reloadTags());
  };

  function handleCancelClick() {
    if (!post.past) {
      dispatch(
        showConfirmModal({
          title: "Cancel edit post",
          message: `Are you sure you want to cancel editing this post? Any unsaved work will be lost.`,
          confirmType: "primary_outline",
          confirmText: "Continue editing",
          cancelType: "alert",
          cancelText: "Cancel & undo changes",
          handleConfirm() {
            // continue editing, so hide only confirm modal
            dispatch(hideModal(false, "CONFIRM_MODAL"));
          },
          handleCancel() {
            handleCancelEdit();
          },
        })
      );
    } else {
      handleCancelEdit();
    }
  }

  // TODO: move to /pages/planner/model.ts
  const savePostFromPlanner = (data) => {
    const { plannerAccount } = data;
    dispatch(hideModal(false, "CONFIRM_MODAL"));
    const scheduledPostData = cloneDeep(data);
    const initial = cloneDeep(data);
    // scheduled post will be created as new post
    scheduledPostData.post.accounts = [plannerAccount];
    scheduledPostData.post.accountIds = [plannerAccount._id];
    scheduledPostData.post.newPost = true;
    // remaining clusterpost without account that was selected in planner
    // will be updated
    initial.newPostState = initial.originalPostState;
    initial.post.accounts = initial.post.accounts.filter(
      (acc) => acc._id !== plannerAccount._id
    );
    initial.post.accountIds = initial.post.accountIds.filter(
      (accId) => accId !== plannerAccount._id
    );
    // save initial changes
    dispatch(
      plannerPageScheduleClusterPost({
        initialPost: initial,
        scheduledPost: scheduledPostData,
      })
    );
  };

  // TODO: move to /pages/planner/model.ts
  const handlePlannerMultipost = (data) => {
    // show modal, alerting that only 1 account will be scheduled/queued
    // the one selected in planner
    const [title, action] = {
      [POST_STATES.QUEUED]: ["Queue Post", "queued"],
      [POST_STATES.SCHEDULED]: ["Schedule Post", "scheduled"],
    }[data.newPostState];
    // show modal
    dispatch(
      showConfirmModal({
        title,
        message: `This draft post will be ${action} for the ${data.plannerAccount.login} account only.`,
        confirmType: "primary_outline",
        confirmText: "OK",
        cancelType: "alert",
        cancelText: "Cancel",
        handleConfirm() {
          savePostFromPlanner(data);
        },
        handleCancel() {
          dispatch(hideModal(true));
        },
      })
    );
  };

  const getPostedState = (post) => {
    let postState = null;
    if (post.isSuccess && !post.isPotentialError && post.past) {
      postState = "success";
    } else if (
      !post.isSuccess &&
      post.past &&
      !post.isPosting &&
      !post.isQueued
    ) {
      postState = "failed";
    }

    return postState;
  };

  if (getPostedState(post) === "failed") {
    postForm = {
      ...postForm,
      post: {
        ...postForm.post,
        past: false,
      },
    };
  } else if (getPostedState(post) === "success") {
    postForm = {
      ...postForm,
      post: {
        ...postForm.post,
        past: true,
      },
      allDisabled: true,
      errors: undefined,
      isSummary: true,
    };
  }

  return (
    <PostModal
      isHidden={isHidden}
      title={
        getPostedState(post) === "success"
          ? "Post Summary"
          : getPostedState(post) === "failed"
          ? "Post Again"
          : "Edit Post"
      }
      onCancel={isFormValueChanged ? handleCancelClick : cancelWithoutConfirm}
      isLoading={false}
    >
      <PostForm
        {...postForm}
        onCancel={isFormValueChanged ? handleCancelClick : cancelWithoutConfirm}
        setIsFormValueChanged={setIsFormValueChanged}
        accountEditingIsDisabled={accountEditingIsDisabled}
      />
    </PostModal>
  );
}

function PostModal(props) {
  const bodyRef = useRef();
  const [eventClickStart] = useState({});
  const handleMouseDown = (event) => {
    eventClickStart.target = event.target;
  };

  const handleMouseUp = (eventClickEnd) => {
    if (
      bodyRef.current.contains(eventClickStart.target) &&
      !bodyRef.current.contains(eventClickEnd.target)
    ) {
      eventClickEnd.stopPropagation();
      eventClickEnd.preventDefault();
    }
  };

  useLayoutEffect(() => {
    const modal = document.querySelector('[role="dialog"] .in.modal');
    if (modal) {
      modal.addEventListener("mousedown", handleMouseDown);
      modal.addEventListener("mouseup", handleMouseUp);
      modal.addEventListener("click", handleMouseUp);
    }
    return () => {
      if (modal) {
        modal.removeEventListener("mousedown", handleMouseDown);
        modal.removeEventListener("mouseup", handleMouseUp);
        modal.removeEventListener("click", handleMouseUp);
      }
    };
  }, [bodyRef.current]);

  return (
    <CreatePostModalRoot
      show={!props.isHidden}
      onHide={props.onCancel}
      animation={false}
      bsStyle="giselle"
    >
      <div data-testid="create-post-modal" ref={bodyRef}>
        <ToastContainer position="top-center" theme="colored" />
        <ChildrenModalFixScrollStyles />
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.isLoading && <LoadingSpinner />}
          {props.children}
        </Modal.Body>
      </div>
    </CreatePostModalRoot>
  );
}

const CreatePostModalRoot = styled(Modal)`
  // fixes for cross angular/react modals
  overflow-y: auto;
  z-index: 1040 !important;

  .modal-giselle {
    width: auto !important;
    max-width: 1400px;
  }
`;

const ChildrenModalFixScrollStyles = createGlobalStyle`
  .modal {
    overflow-y: auto;
  }
`;
