import React from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

import { ModalHeader, ModalActions, ModalContent } from "ui/giselle-modal";
import { SubmitButton, FormSize } from "ui/ecosystems/forms";
import { Link } from "ui/Link";
import { useUserCurrency } from "entities/current-user";
import { openSupportChat } from "libs/support";

import { HeaderCell, IconContainer, Icon } from "./plan-selection";
import { Dots } from "./dots";
import {
  formatPriceLong,
  formatPriceShort,
  getTotalFromIntention,
  getAmountDueFromEstimate,
} from "../libs/plan-price-helpers";
import { PlanChangeIntention } from "../PlanChangeIntention.type";

interface PlanConfirmProps {
  className?: string;
  isChangingPlan: boolean;
  intention: PlanChangeIntention;
  onConfirm(): void;
  onBack(): void;
}

export const PlanConfirm = ({
  className,
  intention,
  isChangingPlan,
  onConfirm,
  onBack,
}: PlanConfirmProps) => {
  const user_currency_code = useUserCurrency();

  const credit_note_estimate = intention.estimate.credit_note_estimates[0];

  return (
    <Root className={className}>
      <ModalHeader
        title={
          intention.ugcLibraryAddon
            ? `Confirm adding Library to your ${getPlanDisplayName(
                intention
              )} plan`
            : `Confirm plan changes to ${getPlanDisplayName(intention)}`
        }
        subtitle="By confirming your changes, your plan will change immediately."
      />
      <ModalContent>
        <ModalBody>
          <ColumnLeft>
            <Title>Pro-rata immediate charge</Title>
            <Message>
              We will charge your card on file{" "}
              {formatPriceShort(
                getAmountDueFromEstimate(intention.estimate) ?? {
                  amount_cents: 0,
                  currency_code: user_currency_code,
                }
              )}{" "}
              for the period up until your next billing date.
            </Message>
            {credit_note_estimate && (
              <>
                <Title>Account Credits</Title>
                <Message>
                  We will add a non-refundable account credit of{" "}
                  {formatPriceShort({
                    amount_cents: credit_note_estimate.total,
                    currency_code: credit_note_estimate.currency_code,
                  })}{" "}
                  after changing your plan. Your next invoice will use up this
                  credit amount.
                </Message>
              </>
            )}
          </ColumnLeft>
          <ColumnRight>
            <PlanCard>
              <HeaderCell>{getPlanDisplayName(intention)}</HeaderCell>
              <Table>
                <Cell>{intention.plan.igIncluded}</Cell>
                <Cell>
                  <Icon type="instagram" />
                  IG accounts
                </Cell>
                <Cell>{intention.plan.otherIncluded}</Cell>
                <Cell>
                  Other social accounts
                  <IconContainer>
                    <Icon type="facebook" />
                    <Icon type="googlemybusiness" />
                    <Icon type="pinterest" />
                    <Icon type="youtube" />
                    <Icon type="twitter" />
                    <Icon type="linkedin" />
                  </IconContainer>
                </Cell>
                <Cell>
                  {formatPriceLong(
                    getTotalFromIntention(intention, user_currency_code),
                    {
                      skipDecimals: true,
                    }
                  )}
                </Cell>
                <Cell>
                  {intention.isAnnual ? "Annual price*" : "Monthly price*"}
                </Cell>
              </Table>
            </PlanCard>
          </ColumnRight>
        </ModalBody>
        <div css="margin-top: 12px;">
          <p css="font-size: 12px !important; margin: 0;">
            * Excludes customers with addon accounts or other plan changes.{" "}
            <Link as="button" onClick={() => openSupportChat("", "plans")}>
              Contact support
            </Link>{" "}
            if you need to confirm
          </p>
        </div>
        <ModalActions css="margin-top: 12px;" variant="space-between">
          <Link
            as="button"
            color="neutral"
            css="font-weight: 500"
            disabled={isChangingPlan}
            onClick={onBack}
          >
            Back
          </Link>
          <SubmitButton
            primary
            size={FormSize.large}
            isSubmitting={isChangingPlan}
            onClick={onConfirm}
          >
            Confirm changes
          </SubmitButton>
        </ModalActions>
        <Dots css="margin: auto" total={2} active={1} />
      </ModalContent>
    </Root>
  );
};

const Root = styled.div`
  min-width: 600px;
`;

const ModalBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColumnLeft = styled.div`
  width: 426px;
`;

const ColumnRight = styled.div`
  padding-left: 136px;
`;

const Title = styled.div`
  padding-bottom: 16px;
  font-weight: 700;
`;

const Message = styled.div`
  padding-bottom: 50px;
`;

const PlanCard = styled.div`
  width: 291px;
  background-color: ${colors.primary50};
  border: 1px solid ${colors.primary100};
  border-radius: 5px;

  & > div:first-child {
    border-color: ${colors.primary100};
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
`;

const Cell = styled.div`
  &:nth-child(odd) {
    padding: 21px;
  }

  &:nth-child(even) {
    padding-right: 10px;
  }

  & > img {
    margin-right: 9px;
  }
`;

function getPlanDisplayName(intention: PlanChangeIntention) {
  if (intention.isAnnual) {
    return `${intention.plan.displayName} (annual)`;
  } else {
    return intention.plan.displayName;
  }
}
