import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Tooltip from "react-bootstrap/lib/Tooltip";
import OverlayTrigger from "../CustomOverlayTrigger/CustomOverlayTrigger";

import { getPlatformName } from "utils/accounts";
import { PlatformIcon } from "entities/accounts";

import "./AccountAvatar.less";
import { SkedIcon } from "ui";
import { ACCOUNT_DISCONNECTED_MSG } from "constants/TooltipMessages";
import youtubeIconUtils from "utils/youtube-icon-utils";
/**
 * @deprecated - use entities/accounts components
 */
export default function AccountAvatarComponent({
  profilePictureUrl,
  platformType,
  color,
  size = "medium",
  placement,
  tooltip,
  className = "",
  disconnected = false,
  useYtRedPlayIcon = false,
}) {
  let platformName = youtubeIconUtils.resolveYtRedIcon(
    getPlatformName(platformType)
  );

  let letters = "";
  if (!profilePictureUrl) {
    if (tooltip) {
      let array = tooltip.split(" ");
      letters = letters.concat(array[0].charAt(0).toUpperCase());
      if (array.length > 1) {
        // only show first 2 letters
        letters = letters.concat(array[1].charAt(0).toUpperCase());
      }
    }
  }
  if (!color) {
    color = "#223e9c";
  }

  return (
    <TooltipWrapper
      className={disconnected ? "disconnected-account" : null}
      tooltip={disconnected ? ACCOUNT_DISCONNECTED_MSG : tooltip}
      placement={placement}
    >
      <StyledAvatar size={size} color={color} className={className}>
        {disconnected && (
          <SkedIcon
            icon="exclam-round"
            size="14"
            color="white"
            style={{
              position: "absolute",
              top: "-3px",
            }}
          />
        )}
        {!letters && profilePictureUrl && (
          <img
            src={profilePictureUrl}
            className="img-responsive"
            onError={(e) => {
              e.target.onError = null;
              e.target.src = `https://imggen.skedsocial.com/api/v3/${
                tooltip ? tooltip.charAt(0).toUpperCase() : "%3F"
              }.jpg?target=profile&background=${encodeURIComponent(color)}`;
            }}
            alt={`Profile picture for ${tooltip}`}
          />
        )}
        {letters && (
          <div
            className={`tw-h-full tw-w-full tw-text-white tw-text-4xl tw-rounded-full tw-flex tw-items-center tw-font-medium tw-justify-center`}
            style={{ backgroundColor: color }}
          >
            <span>{letters}</span>
          </div>
        )}
        <PlatformIcon type={platformName} />
      </StyledAvatar>
    </TooltipWrapper>
  );
}

const TooltipWrapper = ({ className, children, tooltip, placement }) => {
  if (tooltip) {
    let tooltipJsx = (
      <TooltipStyled className={className} id={tooltip}>
        {tooltip}
      </TooltipStyled>
    );
    return (
      <OverlayTrigger placement={placement} overlay={tooltipJsx}>
        {children}
      </OverlayTrigger>
    );
  }

  return children;
};

const TooltipStyled = styled(Tooltip)`
  &.disconnected-account {
    .tooltip-inner {
      width: 200px;
      text-align: left;
      padding: 4px 8px;
    }
  }
`;

const avatarSizes = {
  small: {
    width: "30px",
    height: "30px",
    imgSize: "27px",
    border: "3px",
    platform: "15px",
  },
  medium: {
    width: "46px",
    height: "46px",
    imgSize: "43px",
    border: "4px",
    platform: "16px",
  },
  large: {
    width: "50px",
    height: "50px",
    imgSize: "50px",
    border: "4px",
    platform: "16px",
  },
};

const StyledAvatar = styled.div`
  width: ${({ size }) => avatarSizes[size].width};
  height: ${({ size }) => avatarSizes[size].height};
  min-width: ${({ size }) => avatarSizes[size].width};
  min-height: ${({ size }) => avatarSizes[size].height};
  position: relative;
  .img-responsive {
    width: ${({ size }) => avatarSizes[size].imgSize};
    height: ${({ size }) => avatarSizes[size].imgSize};
    min-width: ${({ size }) => avatarSizes[size].imgSize};
    min-height: ${({ size }) => avatarSizes[size].imgSize};
    border-radius: 50%;
    ${({ color, size }) =>
      `border: ${avatarSizes[size].border} solid ${
        color ? color : "rgba(0,0,0,0)"
      };`}
  }
  ${PlatformIcon} {
    position: absolute;
    right: 0;
    bottom: 0;
    width: ${({ size }) => avatarSizes[size].platform};
    height: ${({ size }) => avatarSizes[size].platform};
  }
`;

AccountAvatarComponent.propTypes = {
  profilePictureUrl: PropTypes.string,
  platformName: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  placement: PropTypes.string || undefined,
  tooltip: PropTypes.string,
};

/**
 * @deprecated - use entities/accounts components
 */
export const placementAccountAvatar = styled(AccountAvatarComponent)``;

export const AccountAvatar = AccountAvatarComponent;
