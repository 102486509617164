import { createContext, PropsWithChildren, ReactElement } from "react";
import { AccountType } from "../../../../shared/types/accounts";

export type AccountProviderProps = PropsWithChildren<{
  account: AccountType;
  className?: string;
}>;

export const AccountContext = createContext<AccountType>();

export function AccountProvider(props: AccountProviderProps): ReactElement {
  return (
    <AccountContext.Provider value={props.account}>
      {props.children}
    </AccountContext.Provider>
  );
}
