import React from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/color-tokens";

import SkedIcon from "ui/SkedIcon/SkedIcon";
import { FormSize, formSizeStyles } from "../form-theme-provider";

type CheckBoxProps = {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  onClick?: () => void;
  onLabelClick?: () => void;
  onChange?: (value: boolean) => void;
  checked?: boolean;
  isPartialChecked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  size?: FormSize;
};

function CheckboxComponent(props: CheckBoxProps) {
  const {
    children,
    className,
    label,
    onClick,
    onChange,
    checked,
    onLabelClick,
    isPartialChecked,
    disabled,
    readOnly = false,
    size = FormSize.medium,
  } = props;

  function handleChange() {
    if (!onChange && onClick) {
      onClick();
    } else {
      onChange?.(!checked);
    }
  }

  return (
    <CheckBoxRoot
      className={className}
      disabled={disabled && !readOnly}
      isPartialChecked={isPartialChecked}
      size={size}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={disabled || readOnly}
      />
      {isPartialChecked && <SkedIcon icon="horizontal-check" size="12" />}
      <SkedCheckboxLabel size={size} onClick={onLabelClick}>
        {children || label}
      </SkedCheckboxLabel>
    </CheckBoxRoot>
  );
}

export const SkedCheckboxLabel = styled.div<{ size?: FormSize }>`
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:before {
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "icomoon3";
    width: 20px;
    height: 20px;
    line-height: 20px;
    border: 1px solid ${colors.primary500};
    border-radius: 4px;
    color: ${colors.white};
    margin-right: 10px;
    font-size: 1.2rem;
  }

  &:empty &:before {
    margin-right: 0;
  }

  ${formSizeStyles(
    FormSize.small,
    css`
      font-size: 11px;
      line-height: 11px;

      &:before {
        width: 14px;
        height: 14px;
        font-size: 6px;
        border-radius: 3px;
        margin-right: 5px;
      }
    `
  )}
`;

const CheckBoxRoot = styled.label<{
  disabled?: boolean;
  isPartialChecked?: boolean;
  size?: FormSize;
}>`
  cursor: pointer;
  user-select: none;
  margin-bottom: 0;

  ${({ isPartialChecked }) =>
    isPartialChecked &&
    `
      display: grid;
      position: relative;
      place-items: center;

      span.SkedIcon {
        position: absolute;
        left: 4px;
      }
  `}

  input[type="checkbox"] {
    display: block;
    width: 0;
    height: 0;
    opacity: 0;
    clip: rect(0, 0, 0, 0);
    margin: 0;
  }

  input[type="checkbox"]:checked + ${SkedCheckboxLabel}:before {
    content: "\\e929";
    background: ${colors.primary500};
    border: 1px solid ${colors.primary500};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;

export default styled(CheckboxComponent)``;

export const Checkbox = styled(CheckboxComponent)``;
