import { createAction } from "@reduxjs/toolkit";
import { HIDE_MODAL, SHOW_MODAL } from "constants/ActionTypes";

// -------------- General Purpose Modals --------------- //
// can pass in a modal type to hide only modal(s) of that type.
// example of this would be hiding the modal that confirms user wants to close another modal
export const hideModal = createAction(
  HIDE_MODAL,
  function prepare(all = false, modalType = null) {
    return {
      payload: {
        all,
        modalType,
      },
    };
  }
);

export const showConfirmModal = (props) => ({
  type: SHOW_MODAL,
  payload: {
    modalType: "CONFIRM_MODAL",
    modalProps: {
      ...props,
    },
  },
});

export const showMediaPickerModal = (props) => ({
  type: SHOW_MODAL,
  payload: {
    modalType: "MEDIA_PICKER",
    modalProps: {
      ...props,
    },
  },
});

export const showUpgradePlanModal = createAction(
  SHOW_MODAL,
  function prepare() {
    return {
      payload: {
        modalType: "UPGRADE_PLAN",
        modalProps: {},
      },
    };
  }
);

// -------------- Posts Page Modals --------------- //
export const showPreviewPostModal = createAction(
  SHOW_MODAL,
  function prepare(post, accounts, posts) {
    return {
      payload: {
        modalType: "PREVIEW_POST",
        modalProps: { post, accounts, posts },
      },
    };
  }
);

export const showImportIgPostsModal = createAction(
  SHOW_MODAL,
  function prepare({ onImported, accountId }) {
    return {
      payload: {
        modalType: "IMPORT_IG_POSTS",
        modalProps: { onImported, accountId },
      },
    };
  }
);

// -------------- Post Form Modals --------------- //
export const showPostLocationModal = (props) => ({
  type: SHOW_MODAL,
  payload: {
    modalType: "POST_LOCATION",
    modalProps: {
      ...props,
    },
  },
});

export const showSkedUrlModal = createAction(
  SHOW_MODAL,
  function prepare(post, isSkedLinkActive) {
    return {
      payload: {
        modalType: "SKED_URL",
        modalProps: { post, isSkedLinkActive },
      },
    };
  }
);

export const showPostUserTagsModal = (props) => ({
  type: SHOW_MODAL,
  payload: {
    modalType: "POST_TAGS",
    modalProps: {
      type: "USER",
      ...props,
    },
  },
});

export const showPostProductTagsModal = (props) => ({
  type: SHOW_MODAL,
  payload: {
    modalType: "POST_TAGS",
    modalProps: {
      type: "PRODUCT",
      ...props,
    },
  },
});

// -------------- Media Library Modals --------------- //
export const showAddCollectionModal = (collection, users) => ({
  type: SHOW_MODAL,
  payload: {
    modalType: "ADD_COLLECTION",
    modalProps: {
      collection: collection ? collection : null,
      users: users ? users : null,
    },
  },
});

export const showEditAssetModal = createAction(
  SHOW_MODAL,
  function prepare(asset, isReadOnly) {
    return {
      payload: {
        modalType: "EDIT_COLLECTION_ASSET",
        modalProps: {
          asset,
          isReadOnly,
        },
      },
    };
  }
);

export const showBulkAssetTags = createAction(
  SHOW_MODAL,
  function prepare(assets) {
    return {
      payload: {
        modalType: "BULK_ASSET_TAGS",
        modalProps: {
          assets: assets,
        },
      },
    };
  }
);

export const showBulkMoveAssets = createAction(
  SHOW_MODAL,
  function prepare(assets) {
    return {
      payload: {
        modalType: "BULK_MOVE_ASSETS",
        modalProps: {
          assets: assets,
        },
      },
    };
  }
);

export const showDuplicateAssets = createAction(
  SHOW_MODAL,
  function prepare(assetIds, currentCollection) {
    return {
      payload: {
        modalType: "DUPLICATE_ASSETS",
        modalProps: {
          assetIds,
          currentCollection,
        },
      },
    };
  }
);

export const showFeedModal = createAction(SHOW_MODAL, function prepare() {
  return {
    payload: {
      modalType: "ADD_TO_COLLECTION",
      modalProps: {},
    },
  };
});

export const showViewHashtagFeedItemModal = createAction(
  SHOW_MODAL,
  function prepare(media) {
    return {
      payload: {
        modalType: "VIEW_HASHTAG_FEED_ITEM",
        modalProps: {
          media,
        },
      },
    };
  }
);
export const showExternalCollabModal = createAction(
  SHOW_MODAL,
  function prepare() {
    return {
      payload: {
        modalType: "EXTERNAL_COLLAB",
        modalProps: {},
      },
    };
  }
);

export const showAddInsightsReportsModal = createAction(
  SHOW_MODAL,
  function prepare() {
    return {
      payload: {
        modalType: "ADD_INSIGHTS_REPORTS",
        modalProps: {},
      },
    };
  }
);

export const showExternalPermissionModal = createAction(
  SHOW_MODAL,
  function prepare() {
    return {
      payload: {
        modalType: "EXTERNAL_PERMISSION",
        modalProps: {},
      },
    };
  }
);
