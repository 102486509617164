import styled from "styled-components";
import { components } from "react-select";
import { colors } from "styles/color-tokens";

interface LabelWrapperProps {
  selectedOptionsNumber?: number;
}

interface PostFilterValueTypes {
  valueContainerProps?: any;
  selectedOptions: any[];
  placeholder: string;
  selectedPlaceholder: string;
}

export const PostFilterValueContainer = (
  props: PostFilterValueTypes
): JSX.Element => {
  const {
    valueContainerProps,
    selectedOptions,
    placeholder,
    selectedPlaceholder,
  } = props;

  const selectedOptionsNumber = selectedOptions?.length;
  const hasValue = Boolean(selectedOptionsNumber);

  return (
    <components.ValueContainer {...valueContainerProps}>
      {hasValue && (
        <LabelWrapper selectedOptionsNumber={selectedOptionsNumber}>
          {selectedPlaceholder}
        </LabelWrapper>
      )}
      {!hasValue && <LabelWrapper>{placeholder}</LabelWrapper>}

      {valueContainerProps.children?.length &&
        valueContainerProps.children[valueContainerProps.children.length - 1]}
    </components.ValueContainer>
  );
};

const LabelWrapper = styled.div<LabelWrapperProps>`
  position: relative;
  margin-left: 10px;
  color: ${({ selectedOptionsNumber }) =>
    selectedOptionsNumber ? "inherit" : colors.neutral600};
  &:hover > div {
    display: block;
  }
`;
