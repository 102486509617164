/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";

//helpers
import { useDispatch, useSelector } from "react-redux";
import { getPostPreviewModel } from "utils/posts";
import { sortAccounts } from "utils/accounts";

import {
  cleanStoreComments,
  getCollaborationComments,
  getCollaborationUsers,
} from "actions/collaboration";
import { getTagTemplates } from "actions/tagTemplates";
import { createGlobalStyle } from "styled-components";
import BugsnagReactReports from "components/BugsnagReactReports/BugsnagReactReports";

//actions
import { selectPlatform } from "actions/postForm";

//selectors
import { hideModal, showConfirmModal } from "actions/modal";
import { getLoading } from "selectors/commonSelectors";

import { PostFormContext, PostFormTemplate } from "entities/posts";

import { FormSize, FormThemeProvider } from "ui/ecosystems/forms";
import { Alert, AlertType } from "ui/alert";
import { FIELDS } from "constants/PostFormFields";
import { useTrackEvent } from "libs/analytics";
import { useAllAccounts } from "entities/accounts";
import { useClickOutside } from "libs/use-click-outside";

import { ClusterPostForm } from "./components/cluster-post-form";
import { SinglePlatformPostForm } from "./components/single-post-form";
import { AccountPickerDropdown } from "../../AccountPicker";
import { COPY_TO_DRAFT_ADD_ACCOUNT } from "constants/Events";
import { POST_TYPES } from "../../../constants/PostsView";
import { useTikTok } from "./TiktokFields/useTikTok";
import { useLabels } from "./components/labels-hooks";
import { useThreadsFields } from "./threadsFields/useThreadsFields";

function PostForm(props) {
  const dispatch = useDispatch();

  const trackEvent = useTrackEvent();
  const allAccounts = useAllAccounts();

  useEffect(() => {
    dispatch(getTagTemplates());
    if (!props.post.newPost) {
      dispatch(getCollaborationComments(props.channelId));
      dispatch(getCollaborationUsers());
      return () => {
        dispatch(cleanStoreComments());
      };
    }
  }, []);

  const isLoading = useSelector(getLoading);
  const sortedAccounts = sortAccounts(Object.values(props.formAccounts || []));
  const selectedAccountIds = _.map(sortedAccounts || [], "_id");

  const PostFormTooltipGlobalStyles = createGlobalStyle`
  .modal-giselle.modal-dialog {
    [data-radix-popper-content-wrapper] {
      font-size: 12px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500; // figma shows 600 but too bold if we use 600
  
      a {
        color: #ffffff !important;
        text-decoration-line: underline;
      }
    }
  }
  `;

  // Obj passed to the post preview
  const postModel = useMemo(
    () =>
      sortedAccounts.length > 0
        ? getPostPreviewModel(
            { ...props.platformPost },
            sortedAccounts,
            props.selectedPlatform
          )
        : null,
    [props.platformPost, props.selectedPlatform, sortedAccounts]
  );

  const [initialPostFormValue, setInitialPostFormValue] = useState(null);
  const [formPostState, setFormPostState] = useState();

  const {
    isFetchingAccountGroups,
    selectedAccountGroupId,
    selectedContentLabel,
    contentLabelOptions,
    isContentLabelFeatureEnabled,
  } = useLabels({ postFormValue: props.postFormValue });

  // for edit post
  useEffect(() => {
    if (!initialPostFormValue && props.postFormValue) {
      setInitialPostFormValue(props.postFormValue);
    } else {
      props.setIsFormValueChanged(
        !_.isEqual(initialPostFormValue, props.postFormValue)
      );
    }
  }, [props.postFormValue]);

  const handlesSelectPlatform = useCallback(
    (platform) => dispatch(selectPlatform(platform)),
    [dispatch]
  );

  const isSinglePlatform = props.platforms?.length <= 1;

  const ref = useRef(null);
  const allDisabled =
    sortedAccounts.length === 0 || isLoading || props.post.past;

  const formContextValue = useMemo(
    () => ({
      post: props.post,
      postFormValue: props.postFormValue,
      platformPost: props.platformPost,
      originalPostState: props.originalPostState,
      allDisabled,
      updateField: props.updateField,
      updateFields: props.updateFields,
      selectPlatform: handlesSelectPlatform,
      platforms: props.platforms,
      errors: props.errors,
      warnings: props.warnings,
      postState: props.postState,
      channelId: props.channelId,
      onCancel: props.onCancel,
      onSubmit: props.onSubmit,
      saveDisabled: props.saveDisabled,
      isSubmitting: props.isSubmitting,
      isSummary: props.isSummary,
      selectedAccountIds,
      postModel,
      formPostState,
      setFormPostState,
    }),
    [
      props.post,
      props.postFormValue,
      props.platformPost,
      props.originalPostState,
      props.updateField,
      props.updateFields,
      props.platforms,
      props.errors,
      props.warnings,
      props.postState,
      props.channelId,
      props.onCancel,
      props.onSubmit,
      props.saveDisabled,
      props.isSubmitting,
      props.isSummary,
      allDisabled,
      handlesSelectPlatform,
      selectedAccountIds,
      postModel,
      formPostState,
    ]
  );

  const accountPickerProps = {
    accounts: allAccounts,
    selectedAccountIds: selectedAccountIds,
    isMulti: true,
    onChange: (selectedAccounts) => {
      const isTemplateChanged =
        (selectedAccounts.length === 1 && selectedAccountIds.length > 1) ||
        (selectedAccountIds.length === 1 && selectedAccounts.length > 1);

      if (isTemplateChanged && props.postState !== props.originalPostState) {
        setFormPostState(props.postState);
      }

      if (!props.post.failedAt && !props.post.originalPostState) {
        trackEvent({ eventName: COPY_TO_DRAFT_ADD_ACCOUNT });
      }
      // NOTE 3 argument use debounce or not
      props.updateField(FIELDS.ACCOUNTS, selectedAccounts);
    },
  };

  const onClusterChange = async (selectedAccounts) => {
    if (selectedAccounts && sortedAccounts.length > selectedAccounts.length) {
      const platform = sortedAccounts.find(
        (account) => !selectedAccounts.find((item) => account._id === item._id)
      );
      const isLastPlatform =
        sortedAccounts.filter(
          (item) => item.platformName === platform.platformName
        ).length === 1;
      const platformType = platform.platformType || "IG";
      const hasUnlinked =
        props.platformFields[platformType] &&
        props.platformFields[platformType].unlinked;

      if (hasUnlinked && isLastPlatform) {
        return await dispatch(
          showConfirmModal({
            title: "Cancel creating post",
            message: `Are you sure you want to cancel editing this ${platform.platformName} post? Any unsaved work will be lost.
            You will be able to continue editing posts on the other selected platforms.`,
            confirmType: "alert",
            confirmText: "Remove",
            handleConfirm: () => {
              // NOTE 3 argument use debounce or not
              props.updateField(FIELDS.ACCOUNTS, selectedAccounts);
              dispatch(hideModal());
            },
          })
        );
      }
    }
    // NOTE 3 argument use debounce or not
    props.updateField(FIELDS.ACCOUNTS, selectedAccounts);
  };

  // When user clicks outside from PostForm we shows him confirmation window
  useClickOutside(ref, (event) => {
    const path = event.composedPath() || [];
    const isModalOrIntercom = Array.from(path).some((elem) =>
      /modal|intercom/.test(elem.className)
    );
    if (!isModalOrIntercom) {
      props.onCancel();
    }
  });

  const renderAccountsSelector = (pickerProps) => {
    return (
      <>
        <AccountPickerDropdown
          {...accountPickerProps}
          {...pickerProps}
          platforms={
            isSinglePlatform
              ? formContextValue.postFormValue?.isStory
                ? ["IG"]
                : []
              : accountPickerProps.platforms
          }
          onChange={
            isSinglePlatform
              ? accountPickerProps.onChange || pickerProps.onChange
              : onClusterChange
          }
          disabled={props.accountEditingIsDisabled || props.post.past}
        />
        {selectedAccountIds.length === 0 && (
          <Alert type={AlertType.error}>
            Please select at least one account.
          </Alert>
        )}
      </>
    );
  };

  const {
    oldTiktokAccounts,
    ttIsNotification,
    showUrlField,
    hasTikTokPosting,
  } = useTikTok({
    sortedAccounts,
    platformFormValue: formContextValue.platformPost,
    visibleFields: props.visibleFields,
    selectedPlatform: props.selectedPlatform,
    updateField: props.updateField,
  });

  const { oldThreadsAccounts, thIsNotification, hasThreadsPosting } =
    useThreadsFields({
      sortedAccounts,
      platformFormValue: formContextValue.platformPost,
      visibleFields: props.visibleFields,
      selectedPlatform: props.selectedPlatform,
      updateField: props.updateField,
    });

  const hasPostTags = useMemo(() => {
    const isVideoType =
      formContextValue.postFormValue?.type === POST_TYPES.VIDEO;
    const hasTagsVisibleFields = [
      FIELDS.LOCATION_TAG,
      FIELDS.USER_TAGS,
      FIELDS.PRODUCT_TAGS,
      FIELDS.ALT_TEXT,
      FIELDS.COLLABORATOR_TAG,
    ].some((tagField) => props.visibleFields?.includes(tagField));

    return !isVideoType && hasTagsVisibleFields;
  }, [formContextValue.postFormValue?.type, props.visibleFields]);

  return (
    <BugsnagReactReports>
      <FormThemeProvider size={FormSize.large}>
        <PostFormTooltipGlobalStyles />
        <PostFormContext.Provider value={formContextValue}>
          {formContextValue.postFormValue && (
            <div ref={ref} className="tw-flex tw-flex-grow">
              <PostFormTemplate
                renderAccountsSelector={renderAccountsSelector}
                isContentLabelFeatureEnabled={isContentLabelFeatureEnabled}
                contentLabelOptions={contentLabelOptions}
                selectedContentLabel={selectedContentLabel}
                isFetchingAccountGroups={isFetchingAccountGroups}
                updateField={props.updateField}
                formAccounts={props.formAccounts}
                selectedAccountGroupId={selectedAccountGroupId}
              >
                {isSinglePlatform ? (
                  <SinglePlatformPostForm
                    post={props.post}
                    postFormValue={formContextValue.postFormValue}
                    errors={props.errors}
                    platforms={props.platforms}
                    platformFields={props.platformFields}
                    formAccounts={props.formAccounts}
                    updateFields={props.updateFields}
                    updateField={props.updateField}
                    platformPost={props.platformPost}
                    selectedPlatform={props.selectedPlatform}
                    visibleFields={props.visibleFields}
                    isSummary={props.isSummary}
                    hasPostTags={hasPostTags}
                    hasTikTokPosting={hasTikTokPosting}
                    oldTiktokAccounts={oldTiktokAccounts}
                    ttIsNotification={ttIsNotification}
                    hasThreadsPosting={hasThreadsPosting}
                    oldThreadsAccounts={oldThreadsAccounts}
                    thIsNotification={thIsNotification}
                    showUrlField={showUrlField}
                  />
                ) : (
                  <ClusterPostForm
                    post={props.post}
                    postFormValue={formContextValue.postFormValue}
                    errors={props.errors}
                    platforms={props.platforms}
                    platformFields={props.platformFields}
                    formAccounts={props.formAccounts}
                    updateFields={props.updateFields}
                    updateField={props.updateField}
                    platformPost={props.platformPost}
                    selectedPlatform={props.selectedPlatform}
                    visibleFields={props.visibleFields}
                    isSummary={props.isSummary}
                    hasPostTags={hasPostTags}
                    hasTikTokPosting={hasTikTokPosting}
                    oldTiktokAccounts={oldTiktokAccounts}
                    ttIsNotification={ttIsNotification}
                    hasThreadsPosting={hasThreadsPosting}
                    oldThreadsAccounts={oldThreadsAccounts}
                    thIsNotification={thIsNotification}
                    showUrlField={showUrlField}
                  />
                )}
              </PostFormTemplate>
            </div>
          )}
        </PostFormContext.Provider>
      </FormThemeProvider>
    </BugsnagReactReports>
  );
}

export default PostForm;
