import _ from "lodash";
import moment from "moment";

import { ONLY_MANUAL_PLATFORMS, PLATFORMS } from "../constants/Platforms";
import {
  accountHasGroup,
  groupAccounts,
} from "../entities/accounts/libs/accounts-groups";
import {
  AccountGroup,
  AccountPlatformType,
  AccountType,
  PlatformName,
} from "../shared/types/accounts";
import {
  setLocalStorageHomeSelectedGroupName,
  setLocalStorageSelectedAccounts,
} from "../libs/storage/adapters";
import createStorage from "../libs/storage/storage";
import { InboxLocalStorageKey } from "../types/conversationInbox";
import { PlatformNameToPlatformTypeMap } from "../features/post/format-post";
import { AccountGroupType } from "../features/account-groups-form/accountsGroups.model";

export const BRAND_DELIMITER = "->";

// NOTE: (Andrey) getAccountName has used by diff 'account' types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAccountName = (account: any): string => {
  return account.loginAlias ? account.loginAlias : account.login;
};

export const getAccountNameParts = (
  account: AccountType
): [string | null, string] => {
  const name = getAccountName(account);
  const parts = name.split(BRAND_DELIMITER);

  if (parts.length !== 2) {
    return [null, name.trim()];
  }

  return [parts[0].trim(), parts[1].trim()];
};

export const sortAccounts = (accounts: any[]): any[] => {
  return _.sortBy(accounts, [
    (a) => {
      const username = a?.loginAlias ? a?.loginAlias : a?.login;

      return (username || "").toLowerCase();
    },
  ]);
};

export const getPlatformName = (platformType: string): PlatformName =>
  (PLATFORMS[platformType] || PLATFORMS["IG"])["NAME"];

export const getPlatformLabel = (platformType: string): string =>
  (PLATFORMS[platformType] || PLATFORMS["IG"])["LABEL"];

export const findManualPlatform = (
  accounts: AccountType[],
  platformType: AccountPlatformType
) =>
  accounts?.find(
    (account) => account.platformType === platformType && !account.platformId
  );

export const findTiktokAutoPosting = (accounts: AccountType[]) =>
  accounts?.find(
    (platform) =>
      platform.platformType === PlatformNameToPlatformTypeMap.tiktok &&
      platform.platformId
  );

// used for talking about the current POST itself
export const getStoryLabel = function (
  type: string,
  platforms: any[],
  currentPlatformType: string,
  accounts: AccountType[]
): string {
  const hasManualTTPlatform = findManualPlatform(accounts || [], "TT");
  const hasManualTHPlatform = findManualPlatform(accounts || [], "TH");

  const hasNotification =
    hasManualTTPlatform ||
    hasManualTHPlatform ||
    platforms.some((platform: { TYPE: string }) =>
      ONLY_MANUAL_PLATFORMS.includes(platform.TYPE)
    );
  if (currentPlatformType === "TT") {
    return "TikTok post";
  } else if (currentPlatformType === "SC") {
    return "Snap";
  } else if (currentPlatformType === "TH") {
    return "Threads post";
  } else if (currentPlatformType === "OT" || hasNotification) {
    return "post";
  }
  switch (type) {
    case "igtv":
      return "IGTV";
    case "reel":
      return "Reel";
    case "story":
    default:
      return "story";
  }
};

// used when talking about the current ACCOUNT(S) for the post
export const getStoryAccountLabel = function (
  type: string,
  platforms: any[],
  currentPlatformType: string
): string {
  const hasNotification = platforms.some((platform: { TYPE: string }) =>
    ONLY_MANUAL_PLATFORMS.includes(platform.TYPE)
  );
  if (currentPlatformType === "TT") {
    return "TikTok";
  } else if (currentPlatformType === "SC") {
    return "Snapchat";
  } else if (currentPlatformType === "TH") {
    return "Threads";
  } else if (currentPlatformType === "OT" || hasNotification) {
    return "notification account";
  }
  switch (type) {
    case "igtv":
      return "IGTV";
    case "reel":
      return "Instagram Reel";
    case "story":
    default:
      return "story";
  }
};

// TODO now it runs a lot times, need run it once when get data and store it, maybe a selector `getAccountsByGroup`
export const splitAccounts = (
  accounts: any[],
  accountsGroups?: AccountGroupType[]
): { groupedAccounts: AccountGroup[]; otherAccounts: AccountType[] } => {
  const groupedAccounts = groupAccounts(accounts.filter(accountHasGroup));
  const emptyGroups = accountsGroups
    ? accountsGroups.filter((group) => group.accountIds.length === 0)
    : [];
  const otherAccounts = sortAccounts(
    accounts.filter((account: AccountType) => !accountHasGroup(account))
  );

  return {
    groupedAccounts: [
      ...groupedAccounts,
      ...emptyGroups.map((group) => ({
        title: group.name,
        accounts: [],
      })),
    ].sort((groupA, groupB) =>
      groupA.title.toLowerCase().localeCompare(groupB.title.toLowerCase())
    ),
    otherAccounts,
  };
};

export const getPlatforms = (accounts: any[]): string[] => {
  const platforms: string[] = [];
  const platformTypes: string[] = [];
  accounts.map((account: { platformType: string }) => {
    const platformType = account.platformType || "IG";
    if (!platformTypes.includes(platformType)) {
      platformTypes.push(platformType);
      platforms.push(PLATFORMS[platformType]);
    }
  });

  return _.orderBy(platforms, ["ORDER"], ["asc"]);
};

export const getFormattedDate = (
  timePreference: string,
  date: string | number | Date
): string =>
  moment(new Date(date)).format(
    "ddd D MMM YYYY " + (timePreference === "12h" ? "h:mm a" : "H:mm")
  );

export const hasTwitter = (accounts: AccountType[]): boolean =>
  accounts.some((account) => account.platformType === "TW");

export const filterAccountsByText =
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  (searchText: string | undefined) => (account: any) => {
    if (!account) return false;

    if (!searchText) return true;

    return getAccountName(account)
      .toLowerCase()
      .includes(searchText.trim().toLowerCase());
  };

export const filterAccountsByPlatformType =
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  (platformType: string | undefined) => (account: any) => {
    if (!account) return false;

    if (!platformType) return true;

    let accountPlatformType = account.platformType;
    // NOTE: (Andrey) some time we have account without platformType or platformName, by default this is IG account
    if (!account.platformType && !account.platformName) {
      accountPlatformType = "IG";
    }

    return accountPlatformType
      ? accountPlatformType === platformType
      : PLATFORMS[platformType] &&
          account.platformName === getPlatformName(platformType);
  };

const cleanUpStorage = () => {
  const localStorageWrapper = createStorage(window.localStorage);
  [
    InboxLocalStorageKey.selectedAccounts,
    "isConfirmPostCancel",
    "isConfirmPostDelete",
    "isConfirmDraftDelete",
    "libraryFilter",
    "selectedPostContentTypes",
    "accountFilter",
    "HomeSelectedGroupName",
  ].forEach((key: string) => localStorageWrapper.removeItem(key));
};

export const logout = (): void => {
  cleanUpStorage();
  setLocalStorageSelectedAccounts("[]");
  setLocalStorageHomeSelectedGroupName("");
  window.location.href = "/logout";
};

export const normalizeAccounts = (accounts: any) => {
  return accounts.map((account: any) => {
    const normalizedAccount = {
      ...account,
      platformName: getPlatformName(account.platformType),
    };

    if (
      account.platformType === PLATFORMS.PI.TYPE &&
      !account.piV5 &&
      normalizedAccount.status !== "failed"
    ) {
      normalizedAccount.status = "failed";
      normalizedAccount.failReason =
        "Pinterest upgrade requires you to reconnect your account. Please re-authenticate by 30 June 2023 or notify your account Administrator re-authenticate your Pinterest account.";
      normalizedAccount.userDisplayReason =
        "Pinterest upgrade requires you to reconnect your account. Please re-authenticate by 30 June 2023 or notify your account Administrator re-authenticate your Pinterest account.";
      normalizedAccount.notifiedApiDisconnect = new Date();
      normalizedAccount.failedAt = new Date();
    }

    return normalizedAccount;
  });
};

export const globalUserPreferencesToggles = {
  enableAdPosts: false,
};

export const selectAmountGroupAccountsIds = (
  accounts: AccountGroup[],
  amount = 3
): string[] => {
  const accountsAmount = accounts.slice(0, amount);
  const accountsFromGroup = accountsAmount
    .map((_accounts) => _accounts.accounts)
    .flat();
  return accountsFromGroup.map((el) => el._id);
};

export const selectAmountOfAccountsIds = (
  accounts: AccountType[],
  amount: number
): string[] => {
  return accounts.slice(0, amount).map((el) => el._id);
};

export const getFirstGroupOrAmountOfAccountsIds = (
  accounts: AccountType[],
  amountAccountsWithoutGroup = 5,
  amountGroup = 3
): string[] => {
  const { groupedAccounts } = splitAccounts(accounts);

  if (groupedAccounts.length === 0) {
    return selectAmountOfAccountsIds(accounts, amountAccountsWithoutGroup);
  } else if (groupedAccounts.length > 3) {
    return selectAmountGroupAccountsIds(groupedAccounts, 1);
  }

  return selectAmountGroupAccountsIds(groupedAccounts, amountGroup);
};

export const getPlatformNameType = (
  account?: AccountType,
  fullName?: string
) => {
  let platformName = fullName || account?.loginAlias || account?.login;
  let platformType = account?.platformType || "";

  const hasPlatform = Boolean(account?.platformId);
  const isTikTok =
    account?.platformType === PlatformNameToPlatformTypeMap.tiktok;

  const isThreads =
    account?.platformType === PlatformNameToPlatformTypeMap.threads;

  if (isTikTok && !hasPlatform) {
    platformName = `TikTok: ${platformName}`;
    platformType = PlatformNameToPlatformTypeMap.other as PlatformName;
  }
  if (isThreads && !hasPlatform) {
    platformName = `Threads: ${platformName}`;
    platformType = PlatformNameToPlatformTypeMap.other as PlatformName;
  }

  return {
    platformType,
    platformName,
  };
};
