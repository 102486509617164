import axios from "axios";
import {
  getLocalStorageSkedApiToken,
  setLocalStorageSkedApiToken,
} from "libs/storage/adapters";

export let baseUrl = "https://api.skedsocial.com";
// for when you're testing the API locally
let useLocal = false;

if (process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "dev") {
  if (useLocal) {
    baseUrl = "http://localhost:5000";
  } else {
    baseUrl = "https://api-staging.skedsocial.com";
  }
}

const defaultOptions = {
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
};

// Create instance
export function axiosCall() {
  return axios.get("/api-jwt").then((response) => response.data.jwt);
}

/** option to force getting token from server, there is a problem when
 * skedApiToken did not clean up after logout (or token expired), and pusher used the old token
 */
export async function getToken(forceRequest) {
  let token = getLocalStorageSkedApiToken();
  if (token && !forceRequest) {
    return token;
  } else {
    // gets token from Sked Core
    token = await axiosCall();
    setLocalStorageSkedApiToken(token);
    return token;
  }
}

export const skedApi = axios.create(defaultOptions);
window.skedApi = skedApi;

skedApi.interceptors.request.use(async (config) => {
  let token = getLocalStorageSkedApiToken();
  if (token) {
    // use JWT in all calls to the external API
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } else {
    console.log("No localstorage token");
    // gets token from Sked Core
    token = await axiosCall();
    setLocalStorageSkedApiToken(token);
    // use JWT in all calls to the external API
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  }
}, null);

skedApi.interceptors.response.use(undefined, async (error) => {
  console.group("Error in skedApi");
  console.error(error);
  console.dir(error);
  console.groupEnd();

  if (error?.response?.status === 401) {
    localStorage.removeItem("skedApiToken");
    console.log("Invalid token");
    const token = await axiosCall();
    setLocalStorageSkedApiToken(token);
    return skedApi(error.config);
  } else {
    localStorage.removeItem("skedApiToken");
    throw error;
  }
});
