import { MediaCollection } from "./types";
import { skedApi } from "../_skedApi";

export class FrontifySyncError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "FrontifySyncError";
  }
}

export const syncCollection = async (collection: MediaCollection) => {
  try {
    const {
      data: { data },
    } = await skedApi.post(
      `/content-inbox/inbox/${collection._id}/sync-frontify-assets`
    );
    return data;
  } catch (error) {
    throw new FrontifySyncError(
      `Failed to sync Frontify collection: ${
        error instanceof Error ? error.message : "Unknown error"
      }`
    );
  }
};

export const getCollectionSyncStatus = async (collection: MediaCollection) => {
  try {
    const { data } = await skedApi.get<{ isSyncing: boolean }>(
      `/content-inbox/inbox/${collection._id}/sync-status`
    );
    return data;
  } catch (error) {
    throw new FrontifySyncError(
      `Failed to get sync status: ${
        error instanceof Error ? error.message : "Unknown error"
      }`
    );
  }
};
export const duplicateCollectionAssets = async (
  sourceCollectionId: string,
  destinationCollectionId: string,
  assetIds: string[]
) => {
  try {
    const { data } = await skedApi.post(
      `/content-inbox/inbox/${sourceCollectionId}/duplicate-to/${destinationCollectionId}/assets`,
      { assetIds }
    );
    return data;
  } catch (error) {
    throw new FrontifySyncError(
      `Failed to duplicate assets: ${
        error instanceof Error ? error.message : "Unknown error"
      }`
    );
  }
};

const getFrontifySyncStatus = async (
  collection: MediaCollection,
  timeoutMs = 300000, // 5 minute timeout by default
  intervalMs = 2000
): Promise<boolean> => {
  const startTime = Date.now();

  const poll = async (): Promise<boolean> => {
    if (Date.now() - startTime > timeoutMs) {
      throw new FrontifySyncError("Sync status polling timed out");
    }

    const status = await getCollectionSyncStatus(collection);

    if (!status.isSyncing) {
      return true;
    }

    await new Promise((resolve) => setTimeout(resolve, intervalMs));
    return poll();
  };

  return poll();
};

export async function frontifySyncer(
  collection: MediaCollection,
  timeoutMs?: number
) {
  try {
    await syncCollection(collection);
    await getFrontifySyncStatus(collection, timeoutMs);
  } catch (error) {
    if (error instanceof FrontifySyncError) {
      throw error;
    }
    throw new FrontifySyncError(
      `Frontify sync failed: ${
        error instanceof Error ? error.message : "Unknown error"
      }`
    );
  }
}
