// import { AccountType } from "shared/types/accounts";
import { skedApi } from "./_skedApi";
import pick from "lodash/pick";

export async function getGroups() {
  return skedApi.get(`/account-groups`);
}

export async function addGroups(accountGroupData: any) {
  return skedApi.post(
    `/account-groups`,
    pick(accountGroupData, [
      "name",
      "description",
      "accountIds",
      "language",
      "applyLanguageToAllAction",
    ])
  );
}

export async function updateGroups(id: string, accountGroupData: any) {
  return skedApi.patch(
    `/account-groups/${id}`,
    pick(accountGroupData, [
      "name",
      "description",
      "accountIds",
      "language",
      "applyLanguageToAllAction",
    ])
  );
}

export async function deleteGroups(id: string) {
  return skedApi.delete(`/account-groups/${id}`);
}
