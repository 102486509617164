/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Post } from "shared/types/post";
import React, { useEffect } from "react";
import styled from "styled-components";
import { Label } from "./post-form-base-template";
import {
  MultiContentLabelSelector,
  Button,
  Tooltip,
  ContentLabelTag,
} from "@dialogueconsulting/sked-ui";
import _ from "lodash";
import { AccountType } from "shared/types/accounts";
import { groupAccounts } from "entities/accounts/libs/accounts-groups";
import { FIELDS } from "constants/PostFormFields";
import { useDispatch, useSelector } from "react-redux";
import { updatePostFormField } from "actions/postForm";
import { useTrackEvent } from "libs/analytics";
import { getUser } from "selectors/commonSelectors";
import { showUpsellModal } from "actions/typed-modal";
import { isUserPlan } from "features/upsell/plan-utils";
import { UPGRADE_LABELS_PLANNING_VIEWED } from "constants/Events";
import { openSupportChat } from "../../../../libs/support";

interface Props {
  isContentLabelFeatureEnabled: boolean;
  contentLabelOptions: Label[];
  selectedContentLabel: Label[];
  isSummary: boolean;
  postFormValue: Post;
  isFetchingAccountGroups: boolean;
  truncateLabelLength?: number;
}

export const useContentLabelSection = (props: Props) => {
  const dispatch = useDispatch();
  const trackEvent = useTrackEvent();

  const accounts = (props.postFormValue?.accounts || []) as AccountType[];
  const groups = groupAccounts(accounts);

  const isAccountsSelectedBelongToTheSameGroup = !!(
    groups.length === 1 && groups[0].title
  );
  // const isAccountsSelectedHasNoGroup = groups.length === 1 && !groups[0].title;
  const handleContentLabelChange = (label: Label[]) => {
    const newLabelIds = label.map((l) => l.value);
    const prevLabelIds = props.postFormValue?.labelIds || [];
    const diff = _.xor(prevLabelIds, newLabelIds)[0];

    const foundDiffLabel = diff
      ? props.contentLabelOptions.find((c) => c.value === diff)
      : undefined;
    if (foundDiffLabel) {
      const metadata = {
        Type: foundDiffLabel.isCampaign ? "Campaign" : "Content Label",
        Platform: accounts.map((c) => c.platformType || "IG").join(","),
      };
      if (newLabelIds.includes(foundDiffLabel.value)) {
        trackEvent({
          eventName: "Add label to post",
          metadata,
          useServices: ["AMP", "AC"],
        });
      }
      if (prevLabelIds.includes(foundDiffLabel.value)) {
        trackEvent({
          eventName: "Remove label from post",
          metadata,
          useServices: ["AMP"],
        });
      }
    }

    dispatch(
      updatePostFormField(props.postFormValue?._id, {
        [FIELDS.CONTENT_LABELS]: newLabelIds,
      })
    );
  };

  useEffect(() => {
    if (props.isFetchingAccountGroups) {
      return;
    }

    /** clear labels when accounts change and selected labels do not belong to account group */
    if (
      props.postFormValue?.labelIds?.length &&
      props.postFormValue?.accounts?.length &&
      (!isAccountsSelectedBelongToTheSameGroup ||
        !props.contentLabelOptions.some((c) =>
          props.postFormValue?.labelIds?.includes(c.value)
        ))
    ) {
      handleContentLabelChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.postFormValue?.accounts, props.isFetchingAccountGroups]);

  const user = useSelector(getUser);

  return {
    renderLabels: provideContentLabelFunc(
      props,
      isAccountsSelectedBelongToTheSameGroup,
      handleContentLabelChange,
      props.truncateLabelLength,
      () => {
        trackEvent({
          eventName: UPGRADE_LABELS_PLANNING_VIEWED,
          useServices: ["AMP", "AC", "HS"],
        });
        dispatch(showUpsellModal("upsell", "labels-planning"));
      },
      isUserPlan(user, "sked-fundamentals", "sked-essentials")
    ),
  };
};

const provideContentLabelFunc = (
  props: Props,
  isAccountsSelectedBelongToTheSameGroup: boolean,
  handleContentLabelChange: (labels: Label[]) => void,
  truncateLabelLength: number | undefined,
  upsellFunc: () => void,
  upsellRequired: boolean
): (() => React.ReactNode) | undefined => {
  if (!props.isSummary && !props.postFormValue?.accounts?.length) {
    return undefined;
  }

  if (
    props.isSummary &&
    !props.selectedContentLabel.length &&
    !props.postFormValue?.labelIds?.length
  ) {
    return undefined;
  }

  if (props.isSummary) {
    return () => {
      if (props.isFetchingAccountGroups) {
        return (
          <LabelSectionContainer mode="view">
            <FetchingLabels />
          </LabelSectionContainer>
        );
      }

      return (
        <LabelSectionContainer
          mode="view"
          flex={props.selectedContentLabel.length > (truncateLabelLength || 0)}
        >
          <ContentLabelTagWrap>
            {props.selectedContentLabel
              .filter(
                (_, index) =>
                  index <
                  (truncateLabelLength || props.selectedContentLabel.length)
              )
              .map((label) => (
                <ContentLabelTag
                  key={label.value}
                  id={label.value}
                  text={label.label}
                  size="md"
                  isCampaign={label.isCampaign}
                  {...(!label.isCampaign && {
                    color: label.color,
                    emoji: label.emoji,
                  })}
                />
              ))}
          </ContentLabelTagWrap>
          {props.selectedContentLabel &&
          truncateLabelLength &&
          props.selectedContentLabel.length > (truncateLabelLength || 0) ? (
            <ContentLabelRemainingCount>
              <span>+{props.selectedContentLabel.length - 2}</span>
            </ContentLabelRemainingCount>
          ) : null}
        </LabelSectionContainer>
      );
    };
  }

  return () => {
    if (props.isFetchingAccountGroups) {
      return (
        <LabelSectionContainer mode="edit">
          <FetchingLabels />
        </LabelSectionContainer>
      );
    }

    const contentLabelUpsellTooltipMessage = resolveUpsellTooltip(props, {
      isAccountsSelectedBelongToTheSameGroup,
      upsellRequired,
    });
    const toComponentLabelOption = (label: Label) => ({
      ...(label.isCampaign
        ? {
            label: label.label,
            value: label.value,
            color: label.color,
            isCampaign: true,
          }
        : {
            label: label.label,
            value: label.value,
            color: label.color,
            emoji: label.emoji,
          }),
    });
    return (
      <LabelSectionContainer mode="edit">
        <MultiContentLabelSelectorWrap>
          {props.isContentLabelFeatureEnabled &&
          isAccountsSelectedBelongToTheSameGroup &&
          props.contentLabelOptions.length > 0 ? (
            <MultiContentLabelSelector
              options={props.contentLabelOptions.map(toComponentLabelOption)}
              selectedOptions={props.selectedContentLabel.map(
                toComponentLabelOption
              )}
              onChange={handleContentLabelChange}
              zIndexOfMenu={50}
              isFetching={false} // to be implemented in s3
            />
          ) : (
            <UpsellWrap>
              <Tooltip
                text={contentLabelUpsellTooltipMessage}
                textSize="12px"
                side="right"
                container={
                  document.querySelector(
                    ".modal-giselle.modal-dialog"
                  ) as HTMLElement
                }
                contentWidth="200px"
              >
                <div data-testid="content-label-upsell-tooltip">
                  <AddLabelButton
                    startIcon="plus"
                    text="Add new label"
                    disabled={!upsellRequired}
                    endIcon={
                      upsellRequired ? "upsell-rocket-white-bg" : undefined
                    }
                    size="sm"
                    useDefaultIconColor
                    onClick={() => {
                      if (upsellRequired) {
                        upsellFunc();
                      }
                    }}
                  />
                </div>
              </Tooltip>
            </UpsellWrap>
          )}
        </MultiContentLabelSelectorWrap>
      </LabelSectionContainer>
    );
  };
};

const AddLabelButton = styled(Button)`
  [data-id="start-icon"] svg path {
    stroke: white;
  }
`;

function resolveUpsellTooltip(
  props: Props,
  {
    isAccountsSelectedBelongToTheSameGroup,
    upsellRequired,
  }: {
    isAccountsSelectedBelongToTheSameGroup: boolean;
    upsellRequired: boolean;
  }
) {
  let contentLabelUpsellTooltipMessage: JSX.Element | string = <></>;
  if (upsellRequired) {
    contentLabelUpsellTooltipMessage = (
      <span>Upgrade to access this feature, and many more.</span>
    );
  } else if (props.isContentLabelFeatureEnabled === false) {
    contentLabelUpsellTooltipMessage = (
      <span>
        Categorize posts with{" "}
        <a
          href="https://help.skedsocial.com/en/articles/8559434-labels-your-creativity-strategy-and-insights-superpower"
          target="_blank"
          rel="noreferrer"
        >
          content pillar and campaign labels
        </a>{" "}
        for better planning and reporting. Your current plan does not include
        adding labels, please{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            openSupportChat(
              "I’d like to talk to someone about upgrading my plan to get access to Labels..."
            );
          }}
        >
          Contact Support
        </a>{" "}
        to upgrade your plan.
      </span>
    );
  } else if (!isAccountsSelectedBelongToTheSameGroup) {
    contentLabelUpsellTooltipMessage =
      "Labels can only be added to accounts within the same group";
  } else if (props.contentLabelOptions.length === 0) {
    contentLabelUpsellTooltipMessage = (
      <span>
        Categorize posts with content pillar and campaign labels for better
        planning and reporting. Create labels in{" "}
        <a
          href="https://help.skedsocial.com/en/articles/8559434-labels-your-creativity-strategy-and-insights-superpower"
          target="_blank"
          rel="noreferrer"
        >
          Workshop
        </a>{" "}
        and add them here.
      </span>
    );
  }
  return contentLabelUpsellTooltipMessage;
}

const FetchingLabels = () => {
  return <FetchingContentLabelText>fetching...</FetchingContentLabelText>;
};

const LabelSectionContainer = styled.div<{
  mode: "edit" | "view";
  flex?: boolean;
}>`
  margin-bottom: ${({ mode }) => (mode === "view" ? "15px" : 0)};

  ${({ flex }) =>
    flex &&
    `
    display: flex;
    align-items: center;

  `}
`;

const FetchingContentLabelText = styled.div`
  font-family: "Inter";
  font-size: 12px;
  margin-bottom: 22px;
  padding: 2px;
`;

const MultiContentLabelSelectorWrap = styled.div`
  margin-bottom: 22px;
`;

const UpsellWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 3px;
  button {
    font-size: 16px;
    &:disabled {
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
      background: #c4c4c4 !important;
      border-color: transparent !important;
      cursor: not-allowed !important;
      p {
        color: #ffffff !important;
      }
      svg {
        path {
          stroke: #ffffff !important;
        }
      }
    }
  }
  > div > svg {
    margin-top: 3px;
  }
`;

const ContentLabelTagWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const ContentLabelRemainingCount = styled.div`
  display: grid;
  place-items: center;
  border-radius: 22px;
  width: 20px;
  height: 20px;
  background-color: #333;
  margin-left: 8px;

  span {
    ${({ theme }) => theme.fonts["Text xxs/Medium"]}
    color: #fff;
    margin-left: -2px;
  }
`;
