import React from "react";
import styled from "styled-components";
import { components } from "react-select";

import { AccountAvatar } from "entities/accounts";
import { TooltipWrapper } from "ui/TooltipWrapper";
import MovingToGroup from "assets/icons/sked-home/moving-to-group.svg";
import { getAccountNameParts } from "utils/accounts";

interface LabelWrapperProps {
  selectedOptionsNumber?: number;
}

interface PostFilterValueTypes {
  valueContainerProps?: any;
  selectedOptions: any[];
  placeholder: string;
  selectedPlaceholder: string;
  currentGroupName?: string;
}

enum AvatarSize {
  xsmall = "xsmall",
}

export const AccountsGroupValueContainer = (
  props: PostFilterValueTypes
): JSX.Element => {
  const {
    valueContainerProps,
    selectedOptions,
    placeholder,
    currentGroupName,
  } = props;

  const selectedOptionsNumber = selectedOptions?.length;
  const hasValue = Boolean(selectedOptionsNumber);

  return (
    <components.ValueContainer {...valueContainerProps}>
      {hasValue && (
        <LabelWrapper>
          {selectedOptions.map(({ account }: any) => {
            const [groupName] = getAccountNameParts(account);

            const isNotInCurrentGroup =
              groupName && currentGroupName !== groupName;

            return (
              <AvatarContainer key={account._id}>
                <AccountAvatar
                  size={AvatarSize.xsmall}
                  account={account}
                  useYtRedPlayIcon
                />
                {isNotInCurrentGroup && (
                  <>
                    <TooltipWrapperStyled
                      tooltip={
                        <>
                          You are moving this account
                          <br /> from group “{groupName}”
                        </>
                      }
                    >
                      <MovingToGroup />
                    </TooltipWrapperStyled>
                  </>
                )}
              </AvatarContainer>
            );
          })}
        </LabelWrapper>
      )}
      {!hasValue && <LabelWrapper>{placeholder}</LabelWrapper>}

      {valueContainerProps.children?.length &&
        valueContainerProps.children[valueContainerProps.children.length - 1]}
    </components.ValueContainer>
  );
};

const LabelWrapper = styled.div<LabelWrapperProps>`
  position: relative;
  color: #666666;
  display: flex;
  flex-direction: inherit;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 16px;
  font-family: Inter, Helvetica, Arial, sans-serif;
  font-weight: 400;
`;

const AvatarContainer = styled.div`
  position: relative;
`;

const TooltipWrapperStyled = styled(TooltipWrapper)`
  position: absolute;
  top: -7px;
  right: -7px;
`;
