import { css } from "styled-components";

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;
export const flexColumnMb = css`
  ${flexColumn};
  margin-bottom: 1rem;
`;
