import { useEffect, useState } from "react";
import { colors } from "styles/color-tokens";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { EventcalendarBase } from "@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar";

import { getCountriesHolidays } from "selectors/calendarFtilterSelectors";
import { CalendarHolidayType, HolidayType, UseHolidayTypes } from "./types";

interface UseHolidaysProps {
  calendarInstance?: EventcalendarBase;
}
const mapHolidaysToCalendar = (
  holidays: HolidayType[]
): CalendarHolidayType[] =>
  holidays.map((holiday) => ({
    start: holiday.start.startOf("day").format("yyyy-MM-DDTHH:mm"),
    end: holiday.start.endOf("day").format("yyyy-MM-DDTHH:mm"),
    background: colors.error100,
    title: holiday.title,
    location: holiday.location,
  }));

const getDayNumberByStingDayName = (dayName: string): number => {
  const dayNumber = moment
    .weekdaysMin()
    .map((n) => n.toLowerCase())
    .indexOf(dayName.toLowerCase());

  if (dayNumber === -1) {
    console.error(`Day ${dayName} not found`);

    return 0;
  }

  return dayNumber;
};

function nthWeekdayOfMonth(
  year: number,
  month: number,
  weekNumber: number,
  dayNumber: number
) {
  const date = new Date(year, month - 1, 7 * (weekNumber - 1) + 1);
  const day = date.getDay();
  date.setDate(date.getDate() + ((7 + dayNumber - day) % 7));

  return date;
}

const getMonthly = (start: any, bymonthday: number, byday: string): any[] => {
  const arr = [];
  const tmp = start.clone();

  if (bymonthday) {
    // NOTE 3 months current, current - 1, current + 1
    arr.push(tmp.date(bymonthday).clone());
    arr.push(tmp.add(1, "month").clone());
    arr.push(tmp.add(1, "month").clone());
  }

  if (byday) {
    const weekNumber = parseInt(byday.slice(0, 1));
    const dayNumber = getDayNumberByStingDayName(byday.slice(1, 3));

    // NOTE 3 months current, current - 1, current + 1
    [0, 1, 2].forEach(() => {
      const year = tmp.year();
      const month = tmp.month() + 1;
      const date = nthWeekdayOfMonth(year, month, weekNumber, dayNumber);
      arr.push(moment(date));
      tmp.add(1, "month");
    });
  }

  return arr;
};

const getDaily = (start: any, end: any): any[] => {
  const arr = [];
  const tmp = start.clone();
  arr.push(tmp.clone());

  while (tmp.isBefore(end)) {
    tmp.add(1, "day");
    arr.push(tmp.clone());
  }

  return arr;
};

function getRecurringDates(
  rule,
  startDate,
  startIntervalDate,
  endIntervalDate
) {
  const { byday, interval = 1 } = rule;
  const recurringDates = [];
  const currentDate = moment(startIntervalDate).startOf("day");
  const endDate = moment(endIntervalDate).startOf("day");

  while (currentDate.isSameOrBefore(endDate)) {
    const weekday = currentDate.day();

    if (weekday === getDayNumberByStingDayName(byday)) {
      recurringDates.push(currentDate.clone());
    }

    currentDate.add(1, "day");
    if (currentDate.diff(moment(startDate), "week") % interval !== 0) {
      currentDate.add(
        (interval - (currentDate.diff(moment(startDate), "week") % interval)) *
          7,
        "days"
      );
    }
  }

  return recurringDates;
}

const getWeekly = (
  start: any,
  end: any,
  holiday: HolidayType,
  dateSomeOrAfterFilter: (date: any) => any
): any[] => {
  const mapDate = (date: any) => {
    return {
      start: date,
      title: holiday.title,
      location: holiday.location,
    };
  };

  if (Array.isArray(holiday.rrule?.byday)) {
    return (
      holiday.rrule?.byday?.reduce((acum: HolidayType[], day: string) => {
        const arr = getRecurringDates(
          { interval: holiday.rrule?.interval, byday: day },
          holiday.start,
          start,
          end
        );

        acum.push(...arr.filter(dateSomeOrAfterFilter).map(mapDate));

        return acum;
      }, []) || []
    );
  }

  return getRecurringDates(holiday.rrule, holiday.start, start, end)
    .filter(dateSomeOrAfterFilter)
    .map(mapDate);
};

const getYearly = (start: any, date: any) => {
  const tmp = start.clone();
  const eventMonth = date.month();
  const eventDate = date.date();

  return [tmp.month(eventMonth).date(eventDate).clone()];
};

const periodToSingle = {
  DAILY: "day",
  WEEKLY: "week",
  MONTHLY: "month",
};

const summProgression = (base: number, diff: number, count: number) => {
  let summ = base;
  let i = 1;

  while (i <= count) {
    summ = summ + diff;
    i = i + 1;
  }

  return summ;
};

const generateRepeatedHolidays = (
  calendarInstance: EventcalendarBase,
  holiday: HolidayType
): HolidayType[] | [] => {
  if (!holiday.rrule) {
    return [];
  }

  // TODO: Need additional research, to catch all possible variants for the RRUlE
  // https://github.com/jkbrzt/rrule maybe can be used for simplify

  const dateSomeOrAfterFilter = (date: any) => {
    // we can have two variants for the rrule, "count" or "until".
    // https://dateutil.readthedocs.io/en/stable/rrule.html
    let additionalCondition = true;
    let intervalCondition = true;

    if (holiday.rrule && !holiday.rrule.until) {
      if (holiday.rrule.count) {
        additionalCondition = date.isSameOrBefore(
          moment(holiday.start).add(
            periodToSingle[holiday.rrule.freq],
            holiday?.rrule?.interval
              ? summProgression(
                  1,
                  holiday.rrule.interval,
                  holiday.rrule.count - 1
                )
              : holiday.rrule.count
          ),
          "day"
        );
      }

      if (holiday?.rrule?.interval) {
        const periodDiff = date.diff(
          holiday.start,
          periodToSingle[holiday.rrule.freq]
        );
        intervalCondition = !(periodDiff % holiday?.rrule?.interval);
      }
    } else if (holiday.rrule && holiday.rrule.until) {
      additionalCondition = date.isSameOrBefore(holiday.rrule.until, "day");
    }

    return (
      date.isSameOrAfter(holiday.start, "day") &&
      additionalCondition &&
      intervalCondition
    );
  };

  const start = moment(calendarInstance?._firstDay);
  const end = moment(calendarInstance?._lastDay);

  switch (holiday.rrule.freq) {
    case "YEARLY":
      return getYearly(start, holiday.start)
        .filter(dateSomeOrAfterFilter)
        .map((date) => {
          return {
            start: date,
            title: holiday.title,
            location: holiday.location,
          };
        });
    case "MONTHLY":
      return getMonthly(start, holiday.rrule.bymonthday, holiday.rrule.byday)
        .filter(dateSomeOrAfterFilter)
        .map((date) => {
          return {
            start: date,
            title: holiday.title,
            location: holiday.location,
          };
        });
    case "DAILY":
      return getDaily(start, end)
        .filter(dateSomeOrAfterFilter)
        .map((date) => {
          return {
            start: date,
            title: holiday.title,
            location: holiday.location,
          };
        });

    case "WEEKLY":
      return getWeekly(start, end, holiday, dateSomeOrAfterFilter);

    default:
      break;
  }

  return [];
};

export const useHolidays = (props: UseHolidaysProps): UseHolidayTypes => {
  const { calendarInstance } = props;
  const [holidayRef, setHolidayRef] = useState<HTMLDivElement | null>(null);
  const [holiday, setHoliday] = useState<CalendarHolidayType | null>(null);
  const [holidays, setHolidays] = useState<CalendarHolidayType[]>([]);
  const [maxHolidays, setMaxHolidays] = useState<number>(0);
  const [colors, setColors] = useState<CalendarHolidayType[]>([]);

  const allHolidays: HolidayType[] = useSelector(getCountriesHolidays);

  const updateColors = () => {
    if (!colors.length && allHolidays.length) {
      setColors(mapHolidaysToCalendar(allHolidays));
    }
  };

  const refreshHolidays = () => {
    if (calendarInstance && calendarInstance._calendarView) {
      const allHolidaysWithGeneratedForTodaysMonth: HolidayType[] =
        allHolidays.reduce((acum: HolidayType[], holiday: HolidayType) => {
          if (!holiday.rrule) {
            return [...acum, holiday];
          }

          return [
            ...acum,
            ...generateRepeatedHolidays(calendarInstance, holiday),
          ];
        }, []);

      const selected = allHolidaysWithGeneratedForTodaysMonth.filter(
        (holiday) => {
          const start = moment(calendarInstance?._firstDay).add(-7, "days");
          const end = moment(calendarInstance?._lastDay).add(7, "days");
          if (
            holiday.start.isSameOrAfter(start) &&
            holiday.start.isSameOrBefore(end)
          ) {
            return holiday;
          }

          return false;
        }
      );

      setHolidays(mapHolidaysToCalendar(selected));
    }
  };

  useEffect(() => {
    if (calendarInstance?._firstDay) {
      refreshHolidays();
    }
  }, [calendarInstance, allHolidays]);

  useEffect(() => {
    updateColors();
  }, [allHolidays]);

  return {
    holidays,
    setHolidayRef,
    holidayRef,
    holiday,
    setHoliday,

    maxHolidays,
    setMaxHolidays,

    colors,
    refreshHolidays,
  };
};
