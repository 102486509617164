import React from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

export type MLView = "grid" | "list";

import GridListIcon from "./grid-list.svg";

interface MLToggleViewProps {
  className?: string;
  value: MLView;
  onChange(newVal: MLView): void;
}

export const MLToggleView = styled(function MLToggleView({
  className,
  value,
  onChange,
}: MLToggleViewProps) {
  return (
    <button
      className={className}
      onClick={() => {
        onChange(value === "grid" ? "list" : "grid");
      }}
    >
      <MLViewIndicator value={value} className="icon" />
    </button>
  );
})`
  background: transparent;
  border: none;
  padding: 0;
  width: calc(70px * 0.8);
  height: calc(36px * 0.8);

  & > .icon {
    width: 100%;
    height: 100%;
  }
`;

const MLViewIndicator = styled(function MLViewIndicator({
  className,
  value,
}: {
  className?: string;
  value: MLView;
}) {
  return <GridListIcon className={value + " " + (className ?? "")} />;
})`
  &.grid {
    .background {
      transition: transform 0.3s;
    }

    .tiles {
      fill: ${colors.white};
      transition: fill 0.3s;
    }

    .bars {
      fill: ${colors.primary500};
      stroke: ${colors.white};
      transition: fill 0.3s, stroke 0.3s;
    }
  }

  &.list {
    .background {
      transform: translateX(35px);
      transition: transform 0.3s;
    }

    .tiles {
      fill: ${colors.primary500};
      transition: fill 0.3s;
    }

    .bars {
      fill: ${colors.white};
      stroke: ${colors.primary500};
      transition: fill 0.3s, stroke 0.3s;
    }
  }
`;
