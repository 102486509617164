import { createRoutine, createRoutineCreator } from "redux-saga-routines";
import { addIgnoreLoadingRoutineName } from "../reducers/ignore-loading-action";
const customCreateRoutine = createRoutineCreator([
  "TRIGGER",
  "REQUEST",
  "SUCCESS",
  "FAILURE",
  "FULFULL",
  "RETRY",
]);

// Ignore loading state for Instagram checktime request to prevent blocking UI
addIgnoreLoadingRoutineName("CHECK_INSTAGRAM_POST_OVERLAPPING");

// Modals
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

// Pagination
export const SET_PAGINATION = "SET_PAGINATION";
export const CHANGE_PAGE_SIZE = "CHANGE_PAGE_SIZE";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const UPDATE_POSTS_PAGE_NAME = "UPDATE_POSTS_PAGE_NAME";

// Common
export const INIT_GLOBALS = "INIT_GLOBALS";
export const EXPORT_POSTS_MODAL_ROUTINE = createRoutine("EXPORT_POSTS_MODAL");
export const SHOW_EDIT_POST = "SHOW_EDIT_POST";

// Users
export const UPDATE_USER_STATUSES = "UPDATE_USER_STATUSES";

// Accounts
export const UPDATE_ACCOUNT_FILTER = "UPDATE_ACCOUNT_FILTER";
export const UPDATE_ACCOUNTS = "UPDATE_ACCOUNTS";
export const UPDATE_ACCOUNTS_COUNT = "UPDATE_ACCOUNTS_COUNT";
export const CLEAR_ACCOUNTS = "CLEAR_ACCOUNTS";

// Account Picker
export const INIT_SELECTED_ACCOUNTS = "INIT_SELECTED_ACCOUNTS";
export const SET_SELECTED_ACCOUNTS = "SET_SELECTED_ACCOUNTS";

// Posts
export const INIT_POSTS_PAGE_ROUTINE = createRoutine("INIT_POSTS_PAGE");
export const FILTER_COLLAB_STATUS = "FILTER_COLLAB_STATUS";
export const FILTER_POST_TYPE = "FILTER_POST_TYPE";
export const FILTER_POST_CONTENT_TYPE = "FILTER_POST_CONTENT_TYPE";

// Posts - Fetching
export const RELOAD_POSTS_ROUTINE = createRoutine("RELOAD_POSTS");
export const LOAD_MORE_POSTS_ROUTINE = createRoutine("LOAD_MORE_POSTS");
export const FETCH_POSTS_ROUTINE = createRoutine("FETCH_POSTS");
export const FETCH_POSTS_IGNORE = "FETCH_POSTS/IGNORE";
export const FETCH_UPCOMING_POSTS_ROUTINE = createRoutine(
  "FETCH_UPCOMING_POSTS"
);
export const FETCH_DRAFT_POSTS_ROUTINE = createRoutine("FETCH_DRAFT_POSTS");
export const FETCH_ARCHIVED_POSTS_ROUTINE = createRoutine(
  "FETCH_ARCHIVED_POSTS"
);
export const CHANGE_POSTS_PAGINATION = createRoutine("CHANGE_POSTS_PAGINATION");

export const UPDATE_SELECTED_ACCOUNTS = "UPDATE_SELECTED_ACCOUNTS";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";

// Posts - Actions
export const REMOVE_POST_ROUTINE = createRoutine("REMOVE_POST");
export const REMOVE_POST_FROM_QUEUE_ROUTINE = createRoutine(
  "REMOVE_POST_FROM_QUEUE"
);
export const SEND_POST_TO_QUEUE_ROUTINE = createRoutine("SEND_POST_TO_QUEUE");
export const SEND_POSTS_TO_QUEUE_ROUTINE = createRoutine("SEND_POSTS_TO_QUEUE");
export const SEND_POST_TO_DRAFT_ROUTINE = createRoutine("SEND_POST_TO_DRAFT");
export const SEND_POSTS_TO_DRAFT_ROUTINE = createRoutine("SEND_POSTS_TO_DRAFT");
export const SAVE_POST_ROUTINE = createRoutine("SAVE_POST");
export const REMOVE_POSTS_ROUTINE = createRoutine("REMOVE_POSTS");
export const SORT_POST_ROUTINE = createRoutine("SORT_POST");
export const EDIT_POST_URL_ROUTINE = createRoutine("EDIT_POST_URL");
export const SHUFFLE_QUEUE_ROUTINE = createRoutine("SHUFFLE_QUEUE");
export const SEND_POST_NOTIFICATION_ROUTINE = createRoutine(
  "SEND_POST_NOTIFICATION"
);

// Posts - Collaboration
export const CHANGE_COLLAB_STATE_ROUTINE = createRoutine("CHANGE_COLLAB_STATE");
export const BULK_CHANGE_COLLAB_STATE_ROUTINE = createRoutine(
  "BULK_CHANGE_COLLAB_STATE"
);
export const HANDLE_STATUS_CHANGED = "HANDLE_STATUS_CHANGED";

// Posts - View
export const TOGGLE_EXPANDED = "TOGGLE_EXPANDED";
export const TOGGLE_SELECT_ALL_POSTS = "TOGGLE_SELECT_ALL_POSTS";
export const TOGGLE_POST_CHECKBOX = "TOGGLE_POST_CHECKBOX";
export const TOGGLE_COLLABORATE_WIDGET = "TOGGLE_COLLABORATE_WIDGET";

// Posts - Edit
export const START_POST_FORM_ROUTINE = createRoutine("START_POST_FORM");
export const CLOSE_POST_FORM = "CLOSE_POST_FORM";
export const UPDATE_POST_FORM_FIELD = "UPDATE_POST_FORM_FIELD";
export const SELECT_POST_FORM_PLATFORM = "SELECT_POST_FORM_PLATFORM";
export const TOGGLE_POST_FORM_PLATFORM_LINK = "TOGGLE_POST_FORM_PLATFORM_LINK";
export const SAVE_POST_FORM_ROUTINE = createRoutine("SAVE_POST_FORM");
export const UPDATE_POST_FORM_VALIDATION = "UPDATE_POST_FORM_VALIDATION";
export const CHECK_INSTAGRAM_POST_OVERLAPPING = createRoutine(
  "CHECK_INSTAGRAM_POST_OVERLAPPING"
);

// Pagination
export const CHANGE_POST_PAGE = "CHANGE_POST_PAGE";
export const CHANGE_POST_PAGE_SIZE = "CHANGE_POST_PAGE_SIZE";

// Sked Core
export const SKED_CORE_GET_ACCOUNTS_ROUTINE = createRoutine(
  "SKED_CORE_GET_ACCOUNTS"
);

// Media Library Settings
export const INIT_MEDIA_LIBRARY_PAGE_ROUTINE = createRoutine(
  "INIT_MEDIA_LIBRARY_PAGE"
);
export const LOAD_LIBRARY_USAGE = "LOAD_LIBRARY_USAGE";
export const LOAD_COLLECTIONS = "LOAD_COLLECTIONS";
export const TOGGLE_SELECT_ALL_MEDIA = "TOGGLE_SELECT_ALL_MEDIA";
export const TOGGLE_SELECT_NONE_MEDIA = "TOGGLE_SELECT_NONE_MEDIA";
export const TOGGLE_COLLECTION_ASSET = "TOGGLE_COLLECTION_ASSET";
export const FILTER_SELECTED_ASSETS = "FILTER_SELECTED_ASSETS";
export const TOGGLE_SIDEBAR_FILTERS = "TOGGLE_SIDEBAR_FILTERS";
export const SELECT_COLLECTION_LAYOUT = "SELECT_COLLECTION_LAYOUT";

export const LOAD_COLLECTIONS_ROUTINE = createRoutine(LOAD_COLLECTIONS);
export const ADD_COLLECTION_ROUTINE = createRoutine("ADD_COLLECTION");
export const DELETE_COLLECTION_ROUTINE = createRoutine("DELETE_COLLECTION");
export const UPDATE_COLLECTION_ROUTINE = createRoutine("UPDATE_COLLECTION");
export const DELETE_COLLECTION_USER_ROUTINE = createRoutine(
  "DELETE_COLLECTION_USER"
);
export const SELECT_COLLECTION_ROUTINE = createRoutine("SELECT_COLLECTION");
export const CREATE_OR_UPDATE_COLLECTION_USER_ROUTINE = createRoutine(
  "CREATE_OR_UPDATE_COLLECTION_USER"
);
export const GET_ALL_COLLECTION_ASSETS_ROUTINE = createRoutine(
  "GET_ALL_COLLECTION_ASSETS"
);
export const ADD_COLLECTION_ASSET_ROUTINE = createRoutine(
  "ADD_COLLECTION_ASSET"
);
export const ADD_COLLECTION_ASSETS_ROUTINE = createRoutine(
  "ADD_COLLECTION_ASSETS"
);
export const ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE = createRoutine(
  "ADD_COLLECTION_ASSETS_FROM_FEED"
);
export const ADD_COLLECTION_ASSETS_FROM_STOCK_ROUTINE = createRoutine(
  "ADD_COLLECTION_ASSETS_FROM_STOCK"
);
export const DELETE_COLLECTION_ASSET_ROUTINE = createRoutine(
  "DELETE_COLLECTION_ASSET"
);
export const DELETE_COLLECTION_ASSETS_ROUTINE = createRoutine(
  "DELETE_COLLECTION_ASSETS"
);
export const PRE_DOWNLOAD_ASSET_ROUTINE = createRoutine("PRE_DOWNLOAD_ASSET");
export const UPDATE_COLLECTION_ASSET_ROUTINE = createRoutine(
  "UPDATE_COLLECTION_ASSET"
);
export const SHOW_CREATE_POST_ROUTINE = createRoutine("SHOW_CREATE_POST");
export const FILTER_ASSETS_ROUTINE = createRoutine("FILTER_ASSETS");
export const CHANGE_ASSET_PAGINATION_ROUTINE = createRoutine(
  "CHANGE_ASSET_PAGINATION"
);
export const RELOAD_ASSET_TAGS_ROUTINE = createRoutine("RELOAD_ASSET_TAGS");
export const APPLY_BULK_TAGS_ROUTINE = createRoutine("APPLY_BULK_TAGS");
export const BULK_MOVE_ASSETS_ROUTINE = createRoutine("BULK_MOVE_ASSETS");
export const DUPLICATE_ASSETS_ROUTINE = createRoutine("DUPLICATE_ASSETS");
export const SET_COLLECTION_COLOR = "SET_COLLECTION_COLOR";
export const RELOAD_ASSET_CURRENT_PAGE = "RELOAD_ASSET_CURRENT_PAGE";

// Sked Library - Source Media / Hashtag Feed
export const SELECT_HASHTAG_FEED_COLLECTION = "SELECT_HASHTAG_FEED_COLLECTION";
export const TOGGLE_FEED_MEDIA = "TOGGLE_FEED_MEDIA";
export const SELECT_IG_ACCOUNT_ROUTINE = createRoutine("SELECT_IG_ACCOUNT");
export const SELECT_EXISTING_HASHTAG_ROUTINE = createRoutine(
  "SELECT_EXISTING_HASHTAG"
);
export const SELECT_NEW_HASHTAG_ROUTINE = createRoutine("SELECT_NEW_HASHTAG");
export const HASHTAG_FEED_GET_HASHTAG_MEDIA_ROUTINE = createRoutine(
  "HASHTAG_FEED_GET_HASHTAG_MEDIA"
);

export const HASHTAG_FEED_GET_RECENT_HASHTAGS_ROUTINE = createRoutine(
  "HASHTAG_FEED_GET_RECENT_HASHTAGS"
);
export const LOAD_MORE_IG_ASSETS_ROUTINE = createRoutine("LOAD_MORE_IG_ASSETS");
export const HANDLE_INVALID_IG_ACCOUNT = "HANDLE_INVALID_IG_ACCOUNT";

export const SELECT_HASHTAG_FEED_VIEW_ROUTINE = createRoutine(
  "SELECT_HASHTAG_FEED_VIEW"
);

export const SELECT_RECENT_SEARCH_CREATORS = createRoutine(
  "SELECT_RECENT_SEARCH_CREATORS"
);

export const SEARCH_CREATOR = createRoutine("SEARCH_CREATOR");

// Sked Content Inbox
export const INIT_INBOX_PAGE_ROUTINE = createRoutine("INIT_INBOX_PAGE");

// Sked Content Inbox - List View
export const TOGGLE_INBOX_ITEM = "TOGGLE_INBOX_ITEM";
export const TOGGLE_ALL_INBOX_ITEMS = "TOGGLE_ALL_INBOX_ITEMS";

// Sked Content Inbox - Actions
export const TOGGLE_OPEN_INBOX_ITEM_ROUTINE = createRoutine(
  "TOGGLE_OPEN_INBOX_ITEM"
);
export const REMOVE_INBOX_ITEMS_ROUTINE = createRoutine("REMOVE_INBOX_ITEMS");
export const TAG_INBOX_ITEMS_ROUTINE = createRoutine("TAG_INBOX_ITEMS");
export const TOGGLE_READ_INBOX_ITEMS_ROUTINE = createRoutine(
  "TOGGLE_READ_INBOX_ITEMS"
);
export const TOGGLE_ARCHIVE_INBOX_ITEMS_ROUTINE = createRoutine(
  "TOGGLE_ARCHIVE_INBOX_ITEMS"
);
export const ASSIGN_INBOX_ITEMS_TO_USER_ROUTINE = createRoutine(
  "ASSIGN_INBOX_ITEM_TO_USER"
);
export const FAVORITE_INBOX_ITEMS_ROUTINE = createRoutine(
  "FAVORITE_INBOX_ITEMS"
);
export const TOGGLE_INBOX_ITEM_COMMENTS_ROUTINE = createRoutine(
  "TOGGLE_INBOX_ITEM_COMMENTS"
);
export const COMMENT_ON_INBOX_ITEM_ROUTINE = createRoutine(
  "COMMENT_ON_INBOX_ITEM"
);
export const ACTION_INBOX_ITEM_ROUTINE = createRoutine("ACTION_INBOX_ITEM");

// Sked Content Inbox - Hotkeys
export const INBOX_HOTKEY = "INBOX_HOTKEY";
export const MOVE_INBOX_CURSOR = "MOVE_INBOX_CURSOR";

export const INIT_HASHTAG_FEED_ROUTINE = createRoutine("INIT_HASHTAG_FEED");
export const INIT_MENTIONED_FEED_ROUTINE = createRoutine("INIT_MENTIONED_FEED");
export const INIT_TAGGED_IN_FEED_ROUTINE = createRoutine("INIT_TAGGED_IN_FEED");
export const INIT_CREATOR_FEED_ROUTINE = createRoutine(
  "INIT_CREATOR_FEED_ROUTINE"
);

export const GET_MENTIONED_MEDIA_ROUTINE = createRoutine("GET_MENTIONED_MEDIA");
export const GET_TAGGED_IN_MEDIA_ROUTINE = createRoutine("GET_TAGGED_IN_MEDIA");
export const GET_CREATOR_MEDIA_ROUTINE = createRoutine("GET_CREATOR_MEDIA");

export const CHANGE_LIBRARY_PAGE = "CHANGE_LIBRARY_PAGE";

// External review themes & links
export const INIT_EXTERNAL_COLLAB_ROUTINE = createRoutine(
  "INIT_EXTERNAL_COLLAB"
);
export const ADD_EXTERNAL_COLLAB_THEME_ROUTINE = createRoutine(
  "ADD_EXTERNAL_COLLAB_THEME"
);
export const UPDATE_EXTERNAL_COLLAB_THEME_ROUTINE = createRoutine(
  "UPDATE_EXTERNAL_COLLAB_THEME"
);
export const DELETE_EXTERNAL_COLLAB_THEME_ROUTINE = createRoutine(
  "DELETE_EXTERNAL_COLLAB_THEME"
);
export const ADD_EXTERNAL_COLLAB_LINK_ROUTINE = createRoutine(
  "ADD_EXTERNAL_COLLAB_LINK"
);
export const UPDATE_EXTERNAL_COLLAB_LINK_ROUTINE = createRoutine(
  "UPDATE_EXTERNAL_COLLAB_LINK"
);
export const DELETE_EXTERNAL_COLLAB_LINK_ROUTINE = createRoutine(
  "DELETE_EXTERNAL_COLLAB_LINK"
);

// new collaboration with pusher
// http reqs
export const COLLABORATION_COMMENTS_LOADING_ON =
  "COLLABORATION_COMMENTS_LOADING_ON";
export const COLLABORATION_COMMENTS_LOADING_OFF =
  "COLLABORATION_COMMENTS_LOADING_OFF";
export const GET_COLLABORATION_COMMENTS_ROUTINE = createRoutine(
  "GET_COLLABORATION_COMMENTS"
);
export const GET_COLLABORATION_USERS_ROUTINE = createRoutine(
  "GET_COLLABORATION_USERS"
);
export const GET_COLLABORATION_UNREADS_COUNT_ROUTINE = createRoutine(
  "GET_COLLABORATION_UNREADS_COUNT_ROUTINE"
);
export const ADD_COLLABORATION_COMMENT_ROUTINE = createRoutine(
  "ADD_COLLABORATION_COMMENT_ROUTINE"
);
export const EDIT_COLLABORATION_COMMENT_ROUTINE = createRoutine(
  "EDIT_COLLABORATION_COMMENT_ROUTINE"
);
export const DELETE_COLLABORATION_COMMENT_ROUTINE = createRoutine(
  "DELETE_COLLABORATION_COMMENT_ROUTINE"
);

// pusher events
// dispatched when in list view received new comment
export const NEW_UNREAD_NOTIFICATION_RECEIVED = "NEW_UNREAD_COMMENT_RECEIVED";
// dispathced when in post editing modal received new comment
export const NEW_COLLABORATION_COMMENT_RECEIVED =
  "NEW_COLLABORATION_COMMENT_RECEIVED";
export const COLLABORATION_COMMENT_EDITED = "COLLABORATION_COMMENT_EDITED";
// rest
export const MARK_COLLABORATION_CHANNEL_AS_READ =
  "MARK_COLLABORATION_CHANNEL_AS_READ";
export const CLEAN_STORE_COMMENTS = "CLEAN_STORE_COMMENTS";

// tag templates
export const GET_TAG_TEMPLATES = createRoutine("GET_TAG_TEMPLATES");

/**wrap inside this function to make sure all Inbox routines/actions are exclude from
 * setting Loading flag, as Inbox does not use this flag at all, but it may affect other features.
 */
function createInboxRoutine(name) {
  addIgnoreLoadingRoutineName(name);
  return createRoutine(name);
}

function customCreateInboxRoutine(name) {
  addIgnoreLoadingRoutineName(name);
  return customCreateRoutine(name);
}
// Conversation Inbox
export const NOTIFY_INBOX_URL_CHANGE_ROUTINE = createInboxRoutine(
  "NOTIFY_INBOX_URL_CHANGE"
);
export const GET_CONVERSATIONS_ROUTINE =
  createInboxRoutine("GET_CONVERSATIONS");
export const GET_CONVERSATION_ROUTINE = createInboxRoutine("GET_CONVERSATION");
export const GET_MESSAGES_ROUTINE = createInboxRoutine("GET_MESSAGES");
export const SYNC_MESSAGES_ROUTINE = createInboxRoutine("SYNC_MESSAGES");
export const CREATE_MESSAGE_ROUTINE =
  customCreateInboxRoutine("CREATE_MESSAGE");
export const ADD_MESSAGE_ROUTINE = createInboxRoutine("ADD_MESSAGES");

export const ADD_MESSAGE_REACTION_ROUTINE = createInboxRoutine(
  "ADD_MESSAGE_REACTION"
);
export const REMOVE_MESSAGE_REACTION_ROUTINE = createInboxRoutine(
  "REMOVE_MESSAGE_REACTION"
);
export const SET_MESSAGE_REACTION_PROCESSING =
  "SET_MESSAGE_REACTION_PROCESSING";

export const REMOVE_MESSAGE_ROUTINE = createInboxRoutine("REMOVE_MESSAGES");
export const DELETE_MESSAGE_STATUS_ROUTINE = createInboxRoutine(
  "DELETE_MESSAGE_STATUS"
);
export const UPDATE_STAR_FILTER_ROUTINE = createInboxRoutine(
  "UPDATE_STAR_FILTER_ROUTINE"
);

export const STAR_CONV_ROUTINE = createInboxRoutine("STAR_CONV_ROUTINE");
export const ASSIGN_USER_CONV_ROUTINE = createInboxRoutine(
  "ASSIGN_USER_CONV_ROUTINE"
);

export const PATCH_CONVERSATION_ROUTINE = createInboxRoutine(
  "PATCH_CONVERSATION_ROUTINE"
);

export const PATCH_CONVERSATIONS_ROUTINE = createInboxRoutine(
  "PATCH_CONVERSATIONS_ROUTINE"
);

export const SELECT_CONVERSATION_ACCOUNTS_ROUTINE = createInboxRoutine(
  "SELECT_CONVERSATION_ACCOUNTS_ROUTINE"
);
export const UPDATE_CONVERSATION_CONFIG_ROUTINE = createInboxRoutine(
  "UPDATE_CONVERSATION_CONFIG_ROUTINE"
);
export const DELETE_CONVERSATION_ROUTINE = createInboxRoutine(
  "DELETE_CONVERSATION_ROUTINE"
);
export const GET_CONVERSATIONS_COUNTER_ROUTINE = createInboxRoutine(
  "GET_CONVERSATIONS_COUNTER_ROUTINE"
);
export const GET_CONVERSATIONS_COUNTER_LATEST_ROUTINE = createInboxRoutine(
  "GET_CONVERSATIONS_COUNTER_LATEST_ROUTINE"
);

export const ADD_CONVERSATION_ROUTINE = createInboxRoutine(
  "ADD_CONVERSATION_ROUTINE"
);

export const REMOVE_CONVERSATION_ROUTINE = createInboxRoutine(
  "REMOVE_CONVERSATION_ROUTINE"
);

export const ADD_COLLABORATOR_TYPING_ROUTINE = createInboxRoutine(
  "ADD_COLLABORATOR_TYPING_ROUTINE"
);

export const REMOVE_COLLABORATOR_TYPING_ROUTINE = createInboxRoutine(
  "REMOVE_COLLABORATOR_TYPING_ROUTINE"
);

export const SET_COLLABORATOR_TYPINGS_ROUTINE = createInboxRoutine(
  "SET_COLLABORATOR_TYPINGS_ROUTINE"
);

export const RESYNC_INTERLOCUTOR = createInboxRoutine(
  "RESYNC_INTERLOCUTOR_ROUTINE"
);

export const CLOSE_ALL_CONVERSATIONS_ROUTINE = createInboxRoutine(
  "CLOSE_ALL_CONVERSATIONS_ROUTINE"
);
export const SELECT_CONVERSATIONS_ROUTINE = createInboxRoutine(
  "SELECT_CONVERSATIONS_ROUTINE"
);

export const SET_CONVERSATION_TYPES_FILTER_ROUTINE = createInboxRoutine(
  "SET_CONVERSATION_TYPES_FILTER"
);

export const INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE = createRoutine(
  "INBOX_SET_MESSAGE_STATUS_REMOVED_ROUTINE"
);

export const INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE = createRoutine(
  "INBOX_SET_MESSAGE_STATUS_EDITING_ROUTINE"
);

export const INBOX_SET_MESSAGE_HIDDEN_ROUTINE = createRoutine(
  "INBOX_SET_MESSAGE_HIDDEN_ROUTINE"
);
export const RESYNC_POST_CONTENT = createInboxRoutine("RESYNC_POST_CONTENT");

export const SELECT_ACCOUNTS_WITH_CONV_TYPES = createInboxRoutine(
  "SELECT_ACCOUNTS_WITH_CONV_TYPES"
);

export const COMMON_CLEAN_UP_LOADING = "COMMON_CLEAN_UP_LOADING";

export const GET_CONTENT_LABELS_ROUTINE = createRoutine(
  "GET_CONTENT_LABELS_ROUTINE"
);
export const GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE = createRoutine(
  "GET_CAPTIVATE_TOPIC_SUGGESTION_ROUTINE"
);
export const ADD_CONTENT_LABEL_ROUTINE = createRoutine(
  "ADD_CONTENT_LABEL_ROUTINE"
);
export const UPDATE_CONTENT_LABEL_ROUTINE = createRoutine(
  "UPDATE_CONTENT_LABEL_ROUTINE"
);
export const REMOVE_CONTENT_LABEL_ROUTINE = createRoutine(
  "REMOVE_CONTENT_LABEL_ROUTINE"
);
export const SET_WORKSHOP_SELECTED_GROUP = createRoutine(
  "SET_WORKSHOP_SELECTED_GROUP"
);
export const SET_WORKSHOP_SELECTED_CONTENT_LABEL = createRoutine(
  "SET_WORKSHOP_SELECTED_CONTENT_LABEL"
);
export const UPDATE_CONTENT_LABEL_GENERATED_TOPIC_ROUTINE = createRoutine(
  "UPDATE_CONTENT_LABEL_GENERATED_TOPIC_ROUTINE"
);

export const UPDATE_USER_PRICING_PLAN = "UPDATE_USER_PRICING_PLAN";
