import * as React from "react";
import styled, { css } from "styled-components";
import { toast } from "react-toastify";
import { colors } from "styles/color-tokens";

import { PostMediaFile } from "shared/types/post";
import { useTrackEvent } from "libs/analytics";

import { convertCollectionFileToPostMediaFile } from "../../convert-collection-file-to-post-media-file";

import { SelectedMediaFiles } from "../selected-media-files";

import {
  PICKER_ADD_ASSETS,
  SELECTED_MEDIA_FROM_LIBRARY,
} from "constants/Events";
import { LibraryPicker } from "./library/library-picker";
import { HashtagPicker } from "./hashtag/hashtag-picker";
import { CollectionFile } from "shared/types/files";
import { TaggedInPicker } from "./tagged-in/tagged-in-picker";
import { CreatorPicker } from "./creator/creator-picker";
import { StocksPicker } from "./stocks/stocks-picker";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedIgAccount } from "selectors/hashtagFeedSelectors";
import { AccountType } from "shared/types/accounts";
import { useInitMediaFeed } from "./components/account-selector";
import {
  CollectionSelector,
  isLibraryRestricted,
} from "features/media-library";
import {
  getCollections,
  getIsLoadingCollections,
} from "selectors/mediaLibrarySelectors";
import { useState } from "react";
import {
  addCollectionAssetsFromFeed,
  addCollectionAssetsFromStock,
  loadCollections,
} from "actions/mediaLibrary";
import { ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE } from "constants/ActionTypes";
import { getRoutinesLoading, getUser } from "selectors/commonSelectors";
import {
  ConfirmRightsAlert,
  IgConfirmRightsModal,
} from "./components/confirm-rights-alert";
import { useDisclaimer } from "components/MediaLibrary/SourceMedia/Disclaimer/Disclaimer";
import { convertFileToFeed, convertFileToStockImage } from "./helper";
import { getAccountsForPlatform } from "selectors/skedCoreSelectors";
import { selectIgAccount } from "actions/sourceMedia";
import { TooltipWrapper } from "ui";
import InfoIcon from "assets/icons/info.svg";
import { SMSplashHashtagContent } from "../sm-splashes/sm-splash-hashtag";
import { SMSplashTaggedInContent } from "../sm-splashes/sm-splash-tagged-in";
import { SMSplashCreatorsContent } from "../sm-splashes/sm-splash-creators";
import { MediaCollection } from "api/media-library";

export function useInitCollections() {
  const dispatch = useDispatch();
  const collections = useSelector(getCollections);
  const isLoadingCollections = useSelector(getIsLoadingCollections);
  const isFetching = useSelector((state) =>
    getRoutinesLoading(state, [ADD_COLLECTION_ASSETS_FROM_FEED_ROUTINE])
  );

  React.useEffect(() => {
    if (!isLoadingCollections && collections.length === 0) {
      dispatch(loadCollections());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections.length, isLoadingCollections]);

  return {
    isLoadingCollections,
    isFetching,
    collections,
  };
}

export interface MediaPickerProps {
  onSelect(files: PostMediaFile[]): void;
  limit: number;
  onClose(): void;
  selectedAccountIds: string[];
}

const tabLabelMappings = {
  library: "Library",
  hashtag: "Hashtags",
  "tagged-in": "Tagged In",
  creator: "Influencers",
  stocks: "Stock Images",
};

export function MediaPicker(props: MediaPickerProps) {
  const dispatch = useDispatch();
  const [selectedMediaFiles, setSelectedMediaFiles] = React.useState<
    Map<string, CollectionFile>
  >(new Map());
  const [currentTab, setCurrentTab] = useState("library");
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [initializedTabs, setInitializedTabs] = useState<string[]>([]);

  const trackEvent = useTrackEvent();
  const currentUser = useSelector(getUser);
  const isUserRestricted = isLibraryRestricted(currentUser);
  const initMediaFeed = useInitMediaFeed();
  const { collections } = useInitCollections();
  const [selectedCollection, setSelectedCollection] =
    useState<MediaCollection | null>(null);
  const { onUpdate, disclaimerOpen } = useDisclaimer({});
  const selectedIgAccount: AccountType = useSelector(getSelectedIgAccount);
  const accounts: AccountType[] = useSelector((state) =>
    getAccountsForPlatform(state, "IG")
  );

  React.useEffect(() => {
    setInitializedTabs([currentTab]);
  }, [selectedIgAccount]);

  React.useEffect(() => {
    // 1. we need to select the first ig account by default
    // 2. if user selects multiple ig accounts when creating the post
    // we should select the first selected ig account instead.
    const postSelectedAccounts = props.selectedAccountIds
      .map((id) => accounts.find((acc) => acc._id === id))
      .filter(Boolean);

    const selectedAccounts =
      postSelectedAccounts.length > 0 ? postSelectedAccounts : accounts;

    if (isUserRestricted || selectedAccounts.length < 1) {
      return;
    }

    if (!selectedIgAccount) {
      dispatch(selectIgAccount(selectedAccounts[0]?._id));
    }
  }, [selectedIgAccount, isUserRestricted, accounts, props.selectedAccountIds]);

  const tabs = ["library", "hashtag", "tagged-in", "creator", "stocks"];

  const saveFiles = () => {
    if (!selectedCollection || filesToSave.length < 1) {
      return Promise.resolve();
    }

    const stockImages = filesToSave.filter((file) => file.picker === "stocks");
    const feedImages = filesToSave.filter((file) => file.picker !== "stocks");

    const addStockImages =
      stockImages.length > 0
        ? new Promise((resolve) => {
            selectedCollection.id,
              dispatch(
                addCollectionAssetsFromStock({
                  collectionId: selectedCollection.id,
                  stockItems: stockImages.map(convertFileToStockImage),
                  location: "picker",
                  done: resolve,
                })
              );
          })
        : Promise.resolve();

    const addFeedImages =
      feedImages.length > 0
        ? new Promise((resolve) => {
            selectedCollection.id,
              dispatch(
                addCollectionAssetsFromFeed({
                  collectionId: selectedCollection.id,
                  feedItems: feedImages.map(convertFileToFeed),
                  location: "picker",
                  done: resolve,
                })
              );
          })
        : Promise.resolve();

    return Promise.all([addStockImages, addFeedImages]).then((results) => {
      const [stockImagesResults = [], feedImagesResults = []] = results as [
        CollectionFile[],
        CollectionFile[]
      ];

      [...stockImagesResults, ...feedImagesResults].forEach((imageAsset) => {
        const localAssetId = imageAsset?.localId;

        const selectedMediaFile = localAssetId
          ? selectedMediaFiles.get(localAssetId)
          : undefined;

        if (!selectedMediaFile || !localAssetId || !imageAsset.asset_id) {
          return;
        }

        selectedMediaFiles.set(localAssetId, {
          ...selectedMediaFile,
          asset_id: imageAsset.asset_id,
        });
      });
      trackEvent({
        eventName: PICKER_ADD_ASSETS,
        metadata: {
          total: filesToSave.length,
        },
      });
    });
  };

  const filesToSave = React.useMemo(
    () =>
      Array.from(selectedMediaFiles.values()).filter(
        (file) => file.picker !== "library"
      ),
    [selectedMediaFiles]
  );

  async function handleSubmit(disclaimerOpen: boolean) {
    const filesNeededConfirmRights = filesToSave.filter(
      (file) => !["library", "stocks"].includes(file.picker || "")
    );

    if (disclaimerOpen && filesNeededConfirmRights.length > 0) {
      setShowConfirmModal(true);
      return;
    }

    trackEvent({
      eventName: SELECTED_MEDIA_FROM_LIBRARY,
      metadata: {
        length: selectedMediaFiles.size,
      },
    });

    if (filesToSave.length > 0) {
      await saveFiles();
    }

    props.onSelect(
      Array.from(selectedMediaFiles.values()).map(
        convertCollectionFileToPostMediaFile
      )
    );
  }

  function handleFileAdd(file: CollectionFile) {
    if (selectedMediaFiles.size < props.limit) {
      setSelectedMediaFiles((prev) =>
        new Map(prev).set(file._id, { ...file, picker: currentTab })
      );
    } else {
      toast.error(`A maximum of ${props.limit} assets can be selected`);
    }
  }

  function handleFileRemove(file: CollectionFile) {
    setSelectedMediaFiles((prev) => {
      const next = new Map(prev);
      next.delete(file._id);
      return next;
    });
  }

  function handleFilesClear() {
    setSelectedMediaFiles(new Map());
  }

  function onTabChanged(tab: string) {
    setCurrentTab(tab);
    if (selectedIgAccount && !initializedTabs.includes(tab)) {
      setInitializedTabs([...initializedTabs, tab]);

      if (tab === "tagged-in") {
        if (
          !(selectedIgAccount.session?.scopes || []).includes(
            "instagram_manage_comments"
          )
        ) {
          return;
        }
      }

      initMediaFeed(tab, selectedIgAccount);
    }
  }

  function onCollectionSelected(collectionId: string) {
    const targetCollection = collections.find(
      (colt) => colt.id === collectionId
    );

    if (targetCollection) {
      setSelectedCollection(targetCollection);
    }
  }

  const useMediaDisabled =
    selectedMediaFiles.size === 0 ||
    selectedMediaFiles.size > props.limit ||
    (filesToSave.length > 0 && !selectedCollection);

  return (
    <MediaPickerRoot>
      <IgConfirmRightsModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          onUpdate();
          setShowConfirmModal(false);
          handleSubmit(false);
        }}
      />
      <StickyContainer>
        <Header>
          Select Media
          <CloseButton onClick={props.onClose}>×</CloseButton>
        </Header>
        <SelectedFiles>
          <SelectedMediaFiles
            limit={props.limit}
            selectedMediaFiles={Array.from(selectedMediaFiles.values())}
            onRemove={handleFileRemove}
            onClear={handleFilesClear}
            onSubmit={() => handleSubmit(disclaimerOpen)}
            disabled={useMediaDisabled}
          />
        </SelectedFiles>
      </StickyContainer>

      <PickerTabs data-testid="picker-tabs">
        {tabs.map((tab) => (
          <PickerTab
            key={tab}
            data-testid={` picker-tab-${tab} `}
            selected={tab === currentTab}
            onClick={() => onTabChanged(tab)}
            portion={Object.keys(tabs).length}
          >
            {tabLabelMappings[tab]}
          </PickerTab>
        ))}
      </PickerTabs>

      <PickerTabContentWrapper>
        <PickerTabContent
          tab={currentTab}
          onClose={props.onClose}
          limit={props.limit}
          handleFileAdd={handleFileAdd}
          handleFileRemove={handleFileRemove}
          selectedMediaFiles={selectedMediaFiles}
          selectedCollection={selectedCollection}
          isUserRestricted={isUserRestricted}
        />
      </PickerTabContentWrapper>
      {currentTab !== "library" && filesToSave.length > 0 && (
        <SaveMediaActionRoot>
          {disclaimerOpen && (
            <ConfirmRightsAlert pickerType={currentTab} onAccept={onUpdate} />
          )}
          <CollectionSelectorWrapper data-testid="collection-selector-wrapper">
            <TooltipWrapper
              tooltip="All newly sourced assets used in this post will be saved to the same collection."
              placement="top"
            >
              <InfoIconStyled />
            </TooltipWrapper>
            <CollectionSelector
              selectedCollection={selectedCollection}
              onChange={onCollectionSelected}
              collections={collections}
              menuPlacement="top"
            />
          </CollectionSelectorWrapper>
        </SaveMediaActionRoot>
      )}
    </MediaPickerRoot>
  );
}

export interface PickerProps {
  onClose(): void;
  limit: number;
  handleFileAdd: (file: CollectionFile) => void;
  handleFileRemove: (file: CollectionFile) => void;
  selectedMediaFiles: Map<string, CollectionFile>;
  selectedCollection: MediaCollection | null;
  isUserRestricted: boolean;
}

interface PickerTabContentProps extends PickerProps {
  tab: string;
}

function PickerTabContent(props: PickerTabContentProps) {
  if (props.tab === "library") {
    return <LibraryPicker {...props} />;
  }

  if (props.tab === "stocks") {
    return <StocksPicker {...props} />;
  }

  switch (props.tab) {
    case "hashtag":
      return props.isUserRestricted ? (
        <SMSplashWrapper>
          <SMSplashHashtagContent />
        </SMSplashWrapper>
      ) : (
        <HashtagPicker {...props} />
      );

    case "tagged-in":
      return props.isUserRestricted ? (
        <SMSplashWrapper>
          <SMSplashTaggedInContent />
        </SMSplashWrapper>
      ) : (
        <TaggedInPicker {...props} />
      );

    case "creator":
      return props.isUserRestricted ? (
        <SMSplashWrapper>
          <SMSplashCreatorsContent />
        </SMSplashWrapper>
      ) : (
        <CreatorPicker {...props} />
      );

    default:
      return <EmptyContainer />;
  }
}

const CollectionSelectorWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const InfoIconStyled = styled(InfoIcon)`
  position: relative;
  top: 1px;
  margin-right: 10px;

  path {
    fill: ${colors.error500};
  }
`;

const SaveMediaActionRoot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  position: sticky;
  bottom: 0;
  padding: 10px 0;
  background: #fff;

  div[class*="-control"] {
    min-width: 240px;
    min-height: 38px;
  }

  .select__control {
    --inputHeight: 18px;
    min-width: 172px;

    div[class*="-indicatorContainer"] {
      padding: 5px;
    }
  }
`;

const PickerTabContentWrapper = styled.div``;

const EmptyContainer = styled.div`
  min-height: 300px;
`;

const PickerTabs = styled.div`
    background: #eee;
    display: flex;
    border-radius: 5px;
}
`;
const PickerTab = styled.div<{ selected: boolean; portion: number }>`
  flex-basis: ${(props) => `calc(100% / ${props.portion})`};
  height: 38px;
  line-height: 38px;
  text-align: center;
  border: 5px;
  font-size: 12px;
  align-items: center;
  color: #4f31ff;
  font-weight: 400;
  cursor: pointer;
  position: relative;
  border-radius: 5px;

  &:not(:last-child) {
    &:after {
      width: 1px;
      height: 30px;
      background: rgba(196, 196, 196, 0.5);
      display: block;
      content: "";
      position: absolute;
      right: 0;
      top: 4px;
    }
  }

  ${(props) =>
    props.selected &&
    css`
      background: ${colors.primary500};
      color: ${colors.white};
    `}
`;

const StickyContainer = styled.div`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 16px 0 0;
`;

const CloseButton = styled.div`
  font-size: 30px;
  font-weight: 300;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 22px;
  font-weight: 500;
  font-size: 18px;
  color: #333433;
`;

const SelectedFiles = styled.div``;

const Gallery = styled.div``;

const FileSelectorContainer = styled.div``;

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${colors.neutral400};
  box-sizing: border-box;
  border-radius: 5px;

  &:before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${colors.white}E6;
    backdrop-filter: blur(10px);
    z-index: -1;
  }
`;

const MediaPickerRoot = styled.div`
  --form-h-padding: 21px;
  border: 1px solid #dedafe;
  padding: 0 var(--form-h-padding) 17px;
  border-radius: 5px;
  position: relative;
  ${Header} {
    margin-bottom: 14px;
    font-weight: 800;
  }

  ${SelectedFiles} {
    margin-bottom: 11px;
  }

  ${FileSelectorContainer} {
    margin-bottom: 32px;
  }

  ${Gallery} {
    margin-bottom: 16px;
  }

  ${Overlay} {
    position: absolute;
    z-index: 1;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
  }
`;

const SMSplashWrapper = styled.div`
  font-size: 14px;
  padding: 20px;
  li {
    margin-bottom: 10px;
  }

  button {
    font-size: 12px;
    padding: 0 20px;
    margin-bottom: 20px;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;

    button {
      font-size: 14px;
      padding-left: 0;
    }
  }
`;
