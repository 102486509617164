import React from "react";

import { ExternalLink } from "./external-link";

export default {
  title: "ui/External Link",
};

export function A() {
  return <ExternalLink href="/" />;
}
