import React from "react";
import { StyledPlatform } from "./header";
import { Flex } from "ui/commonStyled/layout";

export function MultiplePlatforms(): React.ReactElement {
  return (
    <Flex align="center">
      <StyledPlatform size={32} type="facebook" />
      <StyledPlatform size={32} type="linkedin" />
      <StyledPlatform size={32} type="twitter" />
      <StyledPlatform size={32} type="pinterest" />
      <StyledPlatform size={32} type="googlemybusiness" />
      <StyledPlatform size={32} type="youtube" />
      <StyledPlatform size={32} type="tiktok" />
    </Flex>
  );
}

export function MultiplePlatformsWithIg(): React.ReactElement {
  return (
    <Flex align="center">
      <StyledPlatform size={32} type="instagram" />
      <StyledPlatform size={32} type="facebook" />
      <StyledPlatform size={32} type="linkedin" />
      <StyledPlatform size={32} type="twitter" />
      <StyledPlatform size={32} type="pinterest" />
      <StyledPlatform size={32} type="googlemybusiness" />
      <StyledPlatform size={32} type="youtube" />
      <StyledPlatform size={32} type="tiktok" />
    </Flex>
  );
}
