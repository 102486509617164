import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";
import { toast } from "react-toastify";

import { SketchPickerWithPresets } from "ui/color-picker/sketch-picker-with-presets";
import { FormSize, SubmitButton } from "ui/ecosystems/forms";
import { updateCollection } from "api/media-library";
import { getUserFriendlyErrorMessage } from "api/UserFriendlyError";

interface CollectionColorFormProps {
  className?: string;
  collection: {
    _id: string;
    preferences?: {
      color: string;
    };
  };
  onCollectionUpdated(collection: unknown): void;
}

export const CollectionColorForm = styled(function CollectionColorForm({
  className,
  collection,
  onCollectionUpdated,
}: CollectionColorFormProps) {
  const collectionColor = collection.preferences?.color ?? colors.neutral400;

  const [selectedColor, setSelectedColor] = useState(collectionColor);
  const [isSubmitting, setSubmitting] = useState(false);

  async function handleSubmit(evt: React.FormEvent) {
    evt.preventDefault();
    try {
      setSubmitting(true);
      const updatedCollection = {
        ...collection,
        preferences: {
          ...collection.preferences,
          color: selectedColor,
        },
      };
      await updateCollection(collection._id, updatedCollection);
      onCollectionUpdated(updatedCollection);
      toast.success("Successfully updated collection color!");
    } catch (err) {
      toast.error(getUserFriendlyErrorMessage(err));
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Form className={className} onSubmit={handleSubmit}>
      <SketchPickerWithPresets
        key="color"
        value={selectedColor}
        onChange={setSelectedColor}
        css="box-shadow: none !important; padding: 0 !important;"
        width={284}
      />
      {/* @ts-ignore */}
      <SaveColorButton
        onClick={handleSubmit}
        primary
        isSubmitting={isSubmitting}
        size={FormSize.small}
        color={selectedColor}
      >
        Save
      </SaveColorButton>
    </Form>
  );
})``;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 8px;
`;

// @ts-expect-error
const SaveColorButton = styled<{ color: string }>(SubmitButton)`
  margin: 8px 0 0;
  background-color: ${(props) =>
    /* @ts-ignore */
    props.color};
`;
