import styled, { css } from "styled-components";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { colors } from "styles/color-tokens";

import { aspectRatio } from "libs/styled-components-aspect-ratio";
import { getPlatformNameType } from "utils/accounts";
import { isTiktokNewPermissionRequired } from "entities/accounts";

import { AccountType } from "shared/types/accounts";
import {
  getAccountDisplayName,
  getAccountShortName,
} from "../../libs/get-accounts-name";
import { isPausedAccount } from "entities/accounts/libs/get-account-statuses";
import { TooltipWrapper } from "ui";
import ErrorIcon from "assets/icons/exclam-round.svg";
import { AccountPlatformIcon } from "./account-patform-icon";
import { AccountContext } from "./account-context";

const defaultColor = colors.primary800;

enum AvatarSize {
  xsmall = "xsmall",
  small = "small",
  medium = "medium",
  large = "large",
}

export type AvatarSizeType = keyof typeof AvatarSize;

export function isLiAccountNeedUpdate(account: AccountType) {
  const requiredLiScopes = [
    "w_organization_social_feed",
    "r_organization_social_feed",
  ];
  const isLinkedInCompanyProfile =
    account?.linkedinUrn?.includes("organization");
  return (
    isLinkedInCompanyProfile &&
    requiredLiScopes.some((scope) => !account?.session?.scopes?.includes(scope))
  );
}

function isAccountHasError(account: AccountType) {
  if (
    isLiAccountNeedUpdate(account) ||
    isTiktokNewPermissionRequired(account)
  ) {
    return true;
  }

  return (
    !isPausedAccount(account) &&
    (account?.status === "failed" || account?.promptApiReconnect)
  );
}

const AvatarWrapper = ({
  account,
  children,
  size,
}: {
  account: AccountType;
  children: ReactNode;
  size?: AvatarSizeType;
}) => {
  const tooltipContent = (
    <span>
      Account is disconnected or doesn&apos;t have enough permissions. Please go
      to the &apos;Social Accounts&apos; page to reconnect.
    </span>
  );

  const accountIsError = isAccountHasError(account);

  return accountIsError ? (
    <TooltipWrapper tooltip={tooltipContent}>
      <>
        <ErrorIconWrapper data-testid="avatar-disconnected-icon" size={size}>
          <ErrorIcon />
        </ErrorIconWrapper>
        {children}
      </>
    </TooltipWrapper>
  ) : (
    <>{children}</>
  );
};

export const AccountAvatar = styled(
  ({
    size,
    useYtRedPlayIcon,
    ...props
  }: {
    size?: AvatarSizeType;
    account?: AccountType;
    useYtRedPlayIcon?: boolean;
  }) => {
    // TODO: decide, with better, to make more easy to use, or to make another UI component
    const account = props.account || useContext(AccountContext);
    const color = account?.color || defaultColor;
    const [hasImage, setHasImage] = useState(!!account?.profilePictureUrl);
    const fullName = getAccountDisplayName(account);
    const shortName = getAccountShortName(account);

    const { platformName } = getPlatformNameType(account, fullName);

    useEffect(() => {
      const hasProfileUrl = !!account?.profilePictureUrl;
      if (hasImage !== hasProfileUrl) {
        setHasImage(hasProfileUrl);
      }
    }, [account]);

    return (
      <AccountAvatarRoot
        size={size}
        data-testid="account-avatar"
        {...props}
        title={platformName}
      >
        <AvatarWrapper account={account} size={size}>
          {hasImage ? (
            <ImageAvatar
              src={account.profilePictureUrl}
              color={account.color}
              onError={() => setHasImage(false)}
              alt="Account avatar"
            />
          ) : (
            <TextAvatar {...props} color={color} size={size}>
              {shortName}
            </TextAvatar>
          )}
          <AccountPlatformIcon
            account={account}
            useYtRedPlayIcon={useYtRedPlayIcon}
          />
        </AvatarWrapper>
      </AccountAvatarRoot>
    );
  }
)``;

const Avatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const ImageAvatar = styled(Avatar).attrs({
  as: "img",
})<{ color?: string; src?: string; alt: string }>`
  border: 4px solid ${(props) => props.color};
`;

const AccountAvatarRoot = styled.div<{ size?: AvatarSizeType }>`
  width: 55px;
  ${aspectRatio(1)};
  border-radius: 50%;
  display: inline-block;
  position: relative;

  ${AccountPlatformIcon} {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(5%);
    height: 36%;
  }

  ${(props) =>
    props.size === AvatarSize.xsmall &&
    css`
      width: 26px;
      height: 26px;

      ${ImageAvatar} {
        border-width: 2px;
      }

      ${TextAvatar} {
        width: 26px;
        height: 26px;
        font-size: inherit;
      }
    `}

  ${(props) =>
    props.size === AvatarSize.small &&
    css`
      width: 30px;

      ${ImageAvatar} {
        border-width: 2px;
      }
    `}

  ${(props) =>
    props.size === AvatarSize.medium &&
    css`
      width: 46px;
    `}

  ${(props) =>
    props.size === AvatarSize.large &&
    css`
      width: 50px;
    `}
`;

const ErrorIconWrapper = styled.div<{ size?: AvatarSizeType }>`
  svg {
    position: absolute;
    width: 15px;
    top: 0;
    left: 0;
    ${({ size }) =>
      size === AvatarSize.xsmall &&
      `
      width: 12px;
      top: -2px;
      left: -2px;
    `}
  }
`;

const TextAvatar = styled(Avatar)<{ color: string; size?: string }>`
  ${aspectRatio(1)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) =>
    props.size === AvatarSize.small ? "initial" : "22.5px"};
  font-weight: 500;
  color: ${colors.white};
`;
