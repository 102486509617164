import React from "react";
import styled from "styled-components";
import { colors } from "styles/color-tokens";

import RemoveIcon from "./remove.svg";

export function TextSuggestion({
  suggestion,
  onRemove,
}: {
  suggestion: string;
  onRemove?: () => void;
}) {
  return (
    <Wrapper>
      <Text>{suggestion}</Text>
      {onRemove && (
        <RemoveIconButton
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onRemove();
          }}
        >
          <RemoveIcon />
        </RemoveIconButton>
      )}
    </Wrapper>
  );
}

export const TextSuggestionHeight = 32;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${TextSuggestionHeight}px;
  padding: 0 8px;

  border-radius: 5px;
  border: 1px solid var(--suggestion-border-color);
  box-sizing: border-box;

  color: var(--suggestion-text-color);
  background-color: var(--suggestion-background-color);

  &:hover {
    svg {
      fill: ${colors.error500};
    }
  }
`;
export const RemoveIconButton = styled.button`
  padding: 0 4px;
  border: none;
  background: transparent;
`;

const Text = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.2;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
