import moment from "moment";
import FrontifyIcon from "assets/icons/frontify.logo.svg";

const formatDate = (date) => {
  const dateformt = "M/d/Y h:mma";
  return moment(date).format(dateformt);
};

const getUser = (cred) => {
  if (cred.admin) return "Admin";
};

const isManagedLibraryCollection = (collection) => {
  if (collection?.frontifyMeta) {
    return {
      isManagedCollection: true,
      platformName: "Frontify",
      PlatformIcon: FrontifyIcon,
    };
  }

  return {
    isManagedCollection: false,
  };
};

export { formatDate, getUser, isManagedLibraryCollection };
