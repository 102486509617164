import { useCallback } from "react";
import { useStore } from "react-redux";

import { getUser } from "selectors/commonSelectors";
import { attach, createEffect } from "effector";
import { $currentUser } from "entities/current-user";

import { User } from "features/user/types";

type TrackEventProps = {
  eventName: string;
  metadata?: Record<string, any>;
  adminId?: string;
  userId?: string;
  useServices?: SupportedEventService[];
};

// NOTE: this is port of public/components/app/services/event.service.js
function trackEvent({
  eventName,
  metadata = {},
  useServices = ["AMP", "CZ", "AC"],
}: TrackEventProps) {
  console.info("[trackEvent]", eventName, metadata, useServices);

  if (process.env.NODE_ENV !== "production")
    return console.info("Not sent because not production env");

  if (useServices.includes("HS") && window._hsq) {
    try {
      // use hubspot "legacy events" to track events
      window._hsq.push([
        "trackEvent",
        {
          id: eventName,
        },
      ]);
    } catch (e) {
      console.error("Error trackEvent Hubspot", e);
    }
  }
  const amplitude = getAmplitudeInstance();
  if (useServices.includes("AMP") && amplitude) {
    try {
      amplitude.track(eventName, metadata);
    } catch (e) {
      console.error("Error trackEvent Amplitude", e);
    }
  }
  if (useServices.includes("AC") && window.Appcues) {
    try {
      window.Appcues.track(eventName);
    } catch (e) {
      console.error("Error trackEvent Appcues", e);
    }
  }
}

function getAmplitudeInstance():
  | {
      track(eventName: string, metadata: Record<string, any> | undefined): void;
    }
  | undefined {
  return (window as any).amplitude;
}

export const trackEventFx = attach({
  effect: createEffect(
    (params: { eventName: string; metadata: Record<string, any> }) => {
      let useServices;

      if (
        [
          "accounts-clicked-add-instagram",
          "accounts-clicked-add-facebook",
          "accounts-clicked-add-linkedin",
          "accounts-clicked-add-twitter",
        ].includes(params.eventName)
      ) {
        useServices = ["AMP"];
      }

      return trackEvent({
        eventName: params.eventName,
        metadata: params.metadata,
        useServices,
      });
    }
  ),
  source: $currentUser,
  mapParams(
    params: any,
    user
  ): { eventName: string; metadata: Record<string, any> } {
    if (user) {
      params.metadata.plan = user.plan;
      params.metadata.role = user.role;
    }
    return params;
  },
});

export const trackEventWithUser = (
  eventName: string,
  metadata?: Record<string, any>,
  user?: User,
  useServices?: SupportedEventService[]
): void => {
  if (!metadata) {
    metadata = {};
  }

  if (user) {
    metadata.plan = user.plan;
    metadata.role = user.role;
  }

  trackEvent({
    eventName,
    metadata,
    adminId: user && user.role === "admin" ? user.userId : user?.adminId,
    userId: user?.userId,
    useServices,
  });
};

export function useTrackEvent() {
  const Store = useStore();

  return useCallback(
    ({
      eventName,
      metadata,
      useServices,
    }: {
      eventName: string;
      metadata?: Record<string, any>;
      useServices?: SupportedEventService[];
    }) => {
      const user: User = getUser(Store.getState());

      trackEventWithUser(eventName, metadata, user, useServices);
    },
    [Store]
  );
}

export const loadHotJarScript = () => {
  const hotjar = (h: any, o: any, t: any, j: any) => {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 2276564, hjsv: 6 };
    const a = o.getElementsByTagName("head")[0];
    const r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  };

  if ((window as any).hj === undefined) {
    hotjar(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }
};

type SupportedEventService = "AMP" | "CZ" | "AC" | "HS";
