import React from "react";
import styled, { css } from "styled-components";
import { colors } from "styles/color-tokens";

export function Cover(props: {
  in: boolean;
  children: React.ReactNode;
  renderCoverContent?: () => React.ReactNode;
}) {
  return (
    <CoverContainer $in={props.in}>
      {props.children}
      <CoverItself $in={props.in}>{props.renderCoverContent?.()}</CoverItself>
    </CoverContainer>
  );
}

const CoverContainer = styled.div<{ $in: boolean }>`
  position: relative;

  ${(props) =>
    props.$in &&
    css`
      overflow: hidden;
    `}
`;

const CoverItself = styled.div<{ $in: boolean }>`
  display: none;

  ${(props) =>
    props.$in &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background: ${colors.neutral50};
      opacity: 0.7;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @supports (backdrop-filter: blur(2px)) {
        opacity: 1;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;

        background: transparent;
        backdrop-filter: blur(2px);
      }
    `}
`;
