import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "styles/color-tokens";

// @ts-ignore
import QuokkasSvg from "./assets/quokkas.svg";
// @ts-ignore
import Loading from "./assets/loading.svg";

const QUOKKAS_FACTS = [
  "Quokkas weigh 2.5kg to 5kg (5.5lb to 11lb).",
  "A quokka's favourite food is a small shrub.",
  "Quokkas are native to Western Australia.",
  "Quokkas are not found in Sydney, Melbourne or along the East Coast of Australia.",
  "Quokkas are the size of domestic cats.",
  "Quokkas mostly live on a beautiful island called Rottnest Island.",

  "Quokka is derived from the Indigenous Nyungar word 'gawaga'.",
  "Quokkas are very promiscuous when they mate.",

  "Quokkas are macropods, a type of mammal including kangaroos.",
  "Quokkas are known as the happiest animal in the world.",
  "Quokkas are 40 to 54 cm (16 to 21 in) long, with a 25 to 30 cm (10 to 12 in) long tail.",
  "In 1658, Dutch mariner Samuel Volckertzoon first saw a quokka (described as a a wild cat) on Rottnest Island.",

  "Quokkas are known for their ability to climb trees.",
  "Quokkas carry their young in pouches like kangaroos, koalas and wombats.",
  "Quokkas are excellent at selfies! Chris Hemsworth, Roger Federer and Rafael Nadal have all taken smiling pictures with quokkas.",
  "Kangaroos can travel faster than 56km/h. They can also long jump over 9m!",
  "Kangaroos can stand at over 2 metres tall.",
  "Kangaroos prefer to travel in mobs of over 50.",
  "Male Kangaroos fight over females by kicking and punching each other. They are very strong!",
  "Baby Kangaroos & koalas are called Joeys.",
  "Baby Platypuses & echidnas are called Puggles.",
  "Echidnas and platypuses lay eggs.",
  "Echidnas can live up to 50 years.",
  "Platypuses have venomous barbs on the back of their hind legs.",
  "Platypuses find food by sensing electrical signals.",
  "Echidnas have no nipples, but rather patches that secrete milk.",
  "Wombat poo is cubed.",
  "Wombats have backwards facing pouches for their young, so they won’t get dirt in their pouches when they dig their burrows.",
  "Wombats are known to dig extensive burrow systems of up to 20 metres long.",
  "Wombats are shy and nocturnal.",
  "Koalas only eat eucalyptus leaves, which are poisonous to most animals.",
  "Koalas live in trees and sleep up to 18 hours a day.",
  "A newborn wombat weighs less than 1 gram and is 3cm long. It is required to crawl into its mother’s pouch and will live there for about 10 months.",
  "Koala populations are in decline due to deforestation & were severly impacted by the bush fires of 2020.",
  "Tasmania has the cleanest air in the world.",
  "Australia is the driest continent on earth.",
  "Australia has over 60 wine regions.",
  "Jellyfish are the most dangerous animals in Australia, killing more people than snakes, sharks and crocodiles combined!",
  "Australia has the largest population of feral camels (over 300 000!).",
  "Aboriginal Australians are the oldest continuous civilisation in the world.",
  "Crocodiles existed at the same time as dinosaurs.",
  "Crocodiles lay eggs and the temperature of the environment will determine the sex of the baby crocodiles.",
  "Quolls are an endangered Australian Marsupial that is a similar size to a domestic cat. They eat birds, reptiles and other mammals.",
];

interface LoadingSpinnerProps {
  isSmall?: boolean;
  isSlow?: boolean;
  noOverlay?: boolean;
  isCentered?: boolean;
  wrapperAbsolutePosition?: boolean;
}

const getRandomFact = () => {
  return Math.floor(Math.random() * QUOKKAS_FACTS.length);
};

export const LoadingSpinner = ({
  isSmall,
  isSlow,
  noOverlay,
  isCentered,
  wrapperAbsolutePosition,
  ...props
}: LoadingSpinnerProps) => {
  const [activeIndex, setActiveIndex] = useState(getRandomFact());

  const Wrapper = isSmall && !isCentered ? SmallLoadingWrapper : LoadingWrapper;
  const Spinner = isSmall ? SmallLoadingSpinner : LSpinner;
  const SWrapper = isSmall ? SmallSpinnerWrapper : SpinnerWrapper;

  let interval: NodeJS.Timeout;
  useEffect(() => {
    if (isSlow) {
      interval = setInterval(() => {
        setActiveIndex(getRandomFact());
        if (!isSlow) {
          clearInterval(interval);
        }
      }, 4500);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isSlow]);

  return (
    <Wrapper
      $noOverlay={!noOverlay}
      data-testid="loading-spinner"
      $absolutePosition={wrapperAbsolutePosition}
      {...props}
    >
      <SWrapper $isCentered={isCentered || false}>
        {!isSmall && <Quokkas />}
        <Spinner />
        {isSlow && (
          <SpinnerFacts>
            <Title>Quick Facts</Title>
            <Ul>
              {QUOKKAS_FACTS.sort(() => Math.random() - 0.5).map(
                (fact, index) => (
                  <Li
                    key={index}
                    className={`${activeIndex === index ? "active" : ""}`}
                  >
                    {fact}
                  </Li>
                )
              )}
            </Ul>
          </SpinnerFacts>
        )}
      </SWrapper>
    </Wrapper>
  );
};

const LoadingWrapper = styled.div<{
  $absolutePosition?: boolean;
  $noOverlay: boolean;
}>`
  display: flex;
  position: ${(p) => (p.$absolutePosition ? "absolute" : "fixed")};
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: ${(props: { $noOverlay: boolean }) =>
    props.$noOverlay ? `${colors.white}99` : "transparent"};
`;

const SpinnerWrapper = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
`;

const activeAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LSpinner = styled(Loading)`
  position: absolute;
  top: 0;
  left: 0;
  animation: ${activeAnim} 1.4s infinite linear;
  transform: translateZ(0);
  width: 120px;
  height: 120px;
`;
const Quokkas = styled(QuokkasSvg)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25%;
  margin-left: -25%;
`;

const SmallLoadingWrapper = styled.div`
  display: inline-block;
  width: 30px;
  max-height: 30px;
`;

const SmallSpinnerWrapper = styled.div<{ $isCentered: boolean }>`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  width: ${(props: { $isCentered?: boolean }) =>
    props.$isCentered ? "100%" : "50px"};
  height: 100%;
`;

export const SmallLoadingSpinner = styled(Loading)`
  max-width: 30px;
  max-height: 30px;
  animation: ${activeAnim} 1.4s infinite linear;
  transform: translateZ(0);
`;
const Title = styled.div`
  font-weight: 800;
  font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.025em;
  margin-top: 13px;
`;
const SpinnerFacts = styled.div`
  position: absolute;
  top: 100%;
  left: -60px;
  margin-top: -23px;
  width: 270px;
  height: 165px;
  background: ${colors.white};
  box-shadow: 0 4px 10px ${colors.black}26;
  border-radius: 5px;
  overflow: hidden;
  z-index: 5;
`;

const Ul = styled.ul`
  display: flex;
  position: absolute;
  top: 32px;
  left: 0;
  margin: 0;
  height: 133px;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1000;
  padding: 0;
`;

const Li = styled.li`
  position: absolute;
  width: 270px;
  height: 125px;
  float: left;
  opacity: 0;
  display: flex;
  padding: 8px;
  width: 100%;
  justify-items: center;
  align-items: center;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
  &.active {
    opacity: 1;
    transition-duration: 1s;
  }
`;
