import styled from "styled-components";
import { colors } from "styles/color-tokens";

const Cell = styled.div`
  padding: 10px 10px 12px;
`;

export const TableCell = styled(Cell).attrs({
  as: "td",
})`
  border-bottom: 1px solid ${colors.neutral100};
`;

export const Row = styled.tr`
  padding: 10px 0 12px;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.neutral800};
`;

export const HeadCell = styled(Cell).attrs({
  as: "th",
})``;

export const TableHead = styled.thead`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.black};
`;

export const TBody = styled.tbody``;

export const Table = styled.table`
  width: 100%;
`;
